import React from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup} from '../inputs';
import {splitCameraUri, isBlock} from "../utils/utils";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";
import {userFio} from "../utils/userUtils";

const required = value => value ? undefined : 'Обязательное поле';

const objSelectOptions = (options) => {
    if (options) {
            return {
                value: options.id,
                label: options.name
            }
    } else {
        return {};
    }
};

const empSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.name
        }
    })
};

const empty = {
    value: null,
    label: "Не выбрано"
};

const RoomOurForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, isEditMode, roomNotOurs}) => {
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';

    const ReduxFormObjSelect = ({input, validate}) => {
        const isMulti = false;
        const options = empSelectOptions(roomNotOurs.filter(item => item.mapped === false));
        options.unshift(empty);
        const placeholder = 'Выберите помещение';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="Основные данные">
                    <Field name="name" type="text" disabled="true" label="Название"
                           component={InputField} className={className('name')}
                           validate={[required]}/>
                    <InputGroup title="Помещение на станции" >
                        <Field
                            name="object_observation_ext_id"
                            className={className('object_observation_ext_id')}
                            component={ReduxFormObjSelect}
                            validate={[]}
                        />
                    </InputGroup>
                </Legend>
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state) => {
    if (!state.roomOur.current?.id) {
        return {};
    }
    return {
        initialValues: {
            ...state.roomOur.current,
            object_observation_ext_id: objSelectOptions(state.roomOur.current.object_observation_ext ? state.roomOur.current.object_observation_ext : []),
        },
        subdivisionId: state.roomOur.current?.id,
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'RoomOurForm',
        enableReinitialize: true,
    })(RoomOurForm)
)
