import React, {useState} from 'react';
import {connect} from 'react-redux';
import {change, Field, reduxForm} from 'redux-form';

import Legend from './Legend';
import {InputField, InputGroup} from '../inputs';
import FileInput from "./FileInput/FileInput";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";

const maxPhotoCount = 3;

const required = value => value ? undefined : 'Обязательное поле';
const maxSize = value => value ? console.log("223344", value) : 'Обязательное поле';

const AttachmentForm = ({input, attachmentData}) => {
    const [attachments, setAttachments] = useState(attachmentData ? Object.values(attachmentData) : []);

    return (
        <>
            {
                attachments.length !== 0 ?
                    attachments.map((attr, index) => (
                        <>
                            <Field key={index} isOnlyFileAdd={false} component={FileInput} href={attr.image ? attr.image : attr.base64} className="eventInput eventUserComment" type="image"
                                   onDelete={() => {
                                       const newData = [...attachments];
                                       newData.splice(index, 1);
                                       setAttachments(newData);
                                       input.onChange(newData);
                                   }}
                            />
                        </>
                    ) )
                    :
                        <>
                            <div className="invalid-feedback">Фото не выбрано</div>
                            <div className="invalid-feedback">Размер фото должен быть не более 500КБ</div>
                            <div className="invalid-feedback">Количество фото должно быть не более {maxPhotoCount}</div>
                        </>
            }
            {
                attachments.length > maxPhotoCount &&
                    <>
                        <div className="invalid-feedback red">Фото больше {maxPhotoCount}</div>
                    </>
            }
            <Field isOnlyFileAdd={true} component={FileInput} href={undefined} className="eventInput eventUserComment" type="image"
                   onChange={(fileData) => {
                       const data = [...attachments, {'base64': fileData.base64}];
                       setAttachments(data);
                       input.onChange(data);
                   }}
            />
        </>
    );
}

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.name
        }
    })
};

const objSelectActiveOptions = (obj) => {
    return {
        value: obj,
        label: obj
    }
};

const EmployeeForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, emplyeeId, isEditMode, initialValues, subdivisions}) => {
    const className = (fieldName) => forms && forms.user && !forms.user[fieldName].valid ?
        'is-invalid' : '';
    dispatch(change('EmployeeForm', 'id', emplyeeId)); // скрытое поле в форме с id юзера

    const ReduxFormSudivisionsSelect = ({input}) => {
        const isMulti = false;
        const options = objSelectOptions(subdivisions);
        const placeholder = 'Выберите подразделение';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset className="employee-wrapper" disabled={!isEditMode}>
                <div className="data">
                    <Legend title="Данные">
                        <Field name="personnel_number" type="text" label="Табельный номер"
                               component={InputField} className={className('personnel_number')}
                               validate={[required]}/>
                        <Field name="surname" type="text" label="Фамилия"
                               component={InputField} className={className('surname')}
                               validate={[required]}/>
                        <Field name="firstname" type="text" label="Имя"
                               component={InputField} className={className('firstname')}
                               validate={[required]}/>
                        <Field name="patronymic" type="text" label="Отчество"
                               component={InputField} className={className('patronymic')}
                               validate={[required]}/>
                        <InputGroup title="Подразделение">
                            <Field
                                name="subdivision"
                                className={className('subdivision')}
                                component={ReduxFormSudivisionsSelect}
                                validate={[required]}
                            />
                        </InputGroup>
                        <Field name="position" type="text" label="Должность"
                               component={InputField} className={className('position')}
                               validate={[]}/>
                    </Legend>
                </div>
                <div className="photo">
                    <Legend title="Фото">
                        <Field name='images' component={AttachmentForm}
                               attachmentData={initialValues ? initialValues.images : {}}
                               validate={[required, maxSize]}
                        />
                    </Legend>
                </div>
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state) => {
    if (!state.employees.current) {
        return {};
    }
    return {
        initialValues: {
            ...state.employees?.current,
            subdivision: {
                value: state.employees?.current.subdivision?.id,
                label: state.employees?.current.subdivision?.name
            }
        },
        emplyeeId: state.employees.current.id,
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'EmployeeForm',
        enableReinitialize: true,
    })(EmployeeForm)
)
