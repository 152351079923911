import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm, getFormSubmitErrors} from 'redux-form';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup} from '../inputs';
import {onlyRooms, cameraInObject, camerasWithoutDevices, cameraInObjectById} from "../utils/utils";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";
import {userFio} from "../utils/userUtils";

const required = value => value ? undefined : 'Обязательное поле';

const objectsSelectActiveOptions = (object) => {
    return {
        value: object.id,
        label: object.name
    }
};

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: `${entry.name} (${entry.power_unit.name})`
        }
    })
};

const deviceSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: `${entry.name}`
        }
    })
};

const camSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: `${entry.name}`
        }
    })
};


const NotificationDevicesMappingForm = ({handleSubmit, formValues, initialValues, notificationDevicesList, camerasList, forms, dispatch, userId, isEditMode, objectsList, cameras, employees, submitErrors, onChangeCams}) => {
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';
    dispatch(change('NotificationDevicesMappingForm', 'id', userId)); // скрытое поле в форме с id юзера
    const [objectId, setObjectId] = useState(initialValues.object_observation.value || 0);
    const [activeCamsList, setActiveCamsList] = useState(initialValues.cameraCurrent.value || 0);

    useEffect(() => {
        onChangeCams(!Object.keys(formValues).length ? initialValues.cameraCurrent.value : formValues.cameraCurrent.value)
    }, [activeCamsList])

    const onChangeName = (val) => {
        const obj = notificationDevicesList.find(item => item.id === val);
        // const fCamera = cameraInObject(obj.object_info.id, camerasList)[0];
        // setObjectId(obj.object_info.id);
        // dispatch(change('NotificationDevicesMappingForm', 'object_observation', {value: obj.object_info.id, label: `${obj.object_info.name}`}));
        // if (fCamera){
        //     dispatch(change('NotificationDevicesMappingForm', 'cameraCurrent', {value: fCamera.id, label: `${fCamera.name}`}));
        // } else {
        //     dispatch(change('NotificationDevicesMappingForm', 'cameraCurrent', {value: '', label: `В помещении нет камер`}));
        // }
    }

    const cameraListFind = () => {
        const actDev = initialValues.name.value;
        if (actDev === 0) {
            if (formValues.name !== undefined && formValues.name.value !== 0) {
                return notificationDevicesList.find(item => item.id === formValues.name.value).available_cameras;
            } else {
                return camerasList;
            }
        } else {
            return notificationDevicesList.find(item => item.id === initialValues.name.value).not_available_cameras
        }
    };

    const ReduxFormDeviceSelect = ({input, validate}) => {
        const isMulti = false;
        const disabled = initialValues.camera_info?.id;
        const options = deviceSelectOptions(notificationDevicesList);
        const placeholder = 'Выберите устройство';
        return ReduxFormSelectValid(isMulti, options, placeholder, disabled)({input});
    };

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = false;
        const disabled = false;
        const options = objSelectOptions(onlyRooms(objectsList));
        const placeholder = 'Выберите помещение';
        return ReduxFormSelectValid(isMulti, options, placeholder, disabled)({input});
    };

    const ReduxFormCamerasSelect = ({input, validate}) => {
        const isMulti = false;
        const options = camSelectOptions(objectId ?
            cameraInObject(objectId, camerasList) : camerasList) ;
        // const options = camSelectOptions(cameraListFind()) ;
        // options.unshift(empty);
        const disabled = false;
        const placeholder = 'Выберите камеру';
        return ReduxFormSelectValid(isMulti, options, placeholder, disabled)({input});
    };


    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="Основные данные">
                    <InputGroup title="Устройство оповещения" >
                        <Field name="name" type="text" label="Устройство оповещения"
                               component={ReduxFormDeviceSelect}
                               className={className('name')}
                               onChange={(e) => {
                                   onChangeName(e.value);
                               }}
                               validate={[required]}/>
                    </InputGroup>
                {/*<Field*/}
                {/*        name="is_enabled"*/}
                {/*        component={EnableCheckbox}*/}
                {/*        labelText="Вкл"*/}
                {/*    />*/}
                <InputGroup title="Помещение" >
                    <Field
                        name="object_observation"
                        className={className('object_observation')}
                        component={ReduxFormObjectsSelect}
                        onChange={(e) => {
                            dispatch(change('NotificationDevicesMappingForm', 'cameraCurrent', ''));
                            setObjectId(e.value);
                        }}
                        validate={[required]}
                    />
                </InputGroup>
                <InputGroup title="Камера" >
                    <Field
                        name="cameraCurrent"
                        className={className('camera')}
                        component={ReduxFormCamerasSelect}
                        validate={[required]}
                        onChange={e => {
                            setActiveCamsList(e.value);
                            onChangeCams(activeCamsList)
                        }}
                    />
                </InputGroup>
                  {/*  <InputGroup title="Работник" >
                        <Field
                            name="workers"
                            className={className('workers')}
                            component={ReduxFormPersonsSelect}
                            validate={[required]}
                        />
                    </InputGroup> */}
                </Legend>
                {/*<Legend title="Параметры сервера">*/}
                {/*    <Field name="login" type="text" label="Логин"*/}
                {/*           component={InputField} className={className('login')}*/}
                {/*           validate={[required]}/>*/}
                {/*    <Field name="password" type="text" label="Пароль"*/}
                {/*           component={InputField} className={className('password')}*/}
                {/*           validate={[]}/>*/}
                {/*</Legend>*/}

                {initialValues.submitErrors &&
                    <Legend title="Ошибка">
                        <div className='errorContainer'>
                            <p>{initialValues.submitErrors}</p>
                        </div>
                    </Legend>
                }
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state, props) => {
    if (!state.notificationDevicesMapping.current?.camera_info) {
        return {
            initialValues: {
                is_enabled: false,
                name: {value: 0, label: "Выберите устройство"},
                submitErrors: state.notificationDevicesMapping.current?.msg,
                object_observation: 0,
                cameraCurrent: 0,
                is_new: true,
            },
            formValues: getFormValues('NotificationDevicesMappingForm')(state) || {},
        };
    }
    return {
        initialValues: {
            ...state.notificationDevicesMapping.current,
            name: objectsSelectActiveOptions(state.notificationDevicesMapping.current.device_info),
            cameraCurrent: objectsSelectActiveOptions(state.notificationDevicesMapping.current.camera_info),
            object_observation: objectsSelectActiveOptions(state.notificationDevicesMapping.current.object_info),
            submitErrors: state.notificationDevicesMapping.current?.msg,
            is_new: false,
            // camera_id: state.notificationDevices.current.camera_info.id,
        },
        cameraId: state.notificationDevicesMapping.current.id,
        formValues: getFormValues('NotificationDevicesMappingForm')(state) || {},
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'NotificationDevicesMappingForm',
        enableReinitialize: true,
    })(NotificationDevicesMappingForm)
)
