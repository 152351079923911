import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-regular-svg-icons';

import Modal from './Modal';
import TabsWithContent from './TabsWithContent';
import RemoteSubmitButton from '../forms/RemoteSubmitButton';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import {cameraClearState, cameraCreate, cameraEdit, cameraList, cameraLoad} from '../store/Camera';
import CameraForm from "../forms/CameraForm";
import CameraPermissionsForm from "../forms/CameraPermissionsForm";
import {cameraToApiData} from "../utils/utils";
import {clearPPEs, loadPPEs} from "../store/PPEs";
import {clearWorkwear, loadWorkwear} from "../store/Workwear";
import CameraFormWithTabs from "../forms/CameraFormWithTabs";
import {
    subdivisionClearState,
    subdivisionCreate,
    subdivisionDelete,
    subdivisionEdit,
    subdivisionLoad
} from "../store/Subdivision";
import SubdivisionForm from "../forms/SubdivisionForm";
import {roomNotOurClearState, roomNotOurCurrentClearState, roomNotOurLoad} from "../store/RoomNotOur";
import {roomOurClearState, roomOurEdit, roomOurLoad} from "../store/RoomOur";
import RoomOurForm from "../forms/RoomOurForm";

class RoomOurModal extends React.Component {
    state = {
        isEditMode: false,
    };

    userFormValues = {};

    onEditForm() {
        this.setState({isEditMode: true})
    };

    render() {
        const {onClose, onCancel, isPending, isError, roomOur, roomNotOurs} = this.props;
        const {updateRoomOur} = this.props.actions;
        this.userFormValues = roomOur;

        return (
            <Modal
                title={`Помещение `}
                isOpen
                showEditButton={!this.state.isEditMode}
                closeButtonWithText={true}
                onClose={onClose}
                onEdit={() => this.onEditForm()}
                className="user-modal"
            >
                {isPending
                    ?
                    isError ?
                        <p>Ошибка при загрузке данных</p>
                        :
                        <Spinner/>
                    :
                    <>
                        <TabsWithContent
                            titles={['Данные']}
                            tabs={[
                                <>
                                    <RoomOurForm
                                        handleCancel={onCancel}
                                        roomNotOurs={roomNotOurs}
                                        onSubmit={values => {
                                            this.userFormValues = values;
                                            this.userFormValues.object_observation_ext_id = this.userFormValues.object_observation_ext_id ? this.userFormValues.object_observation_ext_id.value : null;
                                            if (this.userFormValues.id) {
                                                updateRoomOur(this.userFormValues.id, this.userFormValues);
                                            }
                                            onClose();
                                        }}
                                        isEditMode={this.state.isEditMode}
                                    />
                                </>
                            ]}
                        />
                        {isPending}
                        <div className="submit-wrapper">
                            {this.state.isEditMode &&
                            <RemoteSubmitButton
                                forms={['RoomOurForm']}
                                className=""
                            >
                                Сохранить
                            </RemoteSubmitButton>}
                        </div>
                    </>
                }

            </Modal>
        )
    }
}

export default connect(
    state => ({
        roomOur: state.roomOur.current,
        roomNotOurs: state.roomNotOur.list,
        isPending: state.roomOur.isListPending || state.roomNotOur.isListPending,
        isError: state.roomOur.isListError,
    }),
    dispatch => ({
        actions: {
            loadRoomOur: (room) => {
                dispatch(roomOurClearState());
                dispatch(roomOurLoad(room.id));
            },
            updateRoomOur: (roomOurId, roomOur) => {
                dispatch(roomOurEdit(roomOurId, roomOur));
            },
        }
    })
)(RoomOurModal);
