import axios from 'axios';

export const ZONE = {
    IS_PENDING: 'IS_PENDING',
    LIST: 'ZONE_LIST',
    DETAIL: 'ZONE_DETAIL',
    EDIT: 'ZONE_EDIT',
    CREATE: 'ZONE_CREATE',
    DELETE: 'ZONE_DELETE',
    SET: 'ZONE_SET',

    CLEAN_LIST: 'ZONE_CLEAN_LIST',
    CLEAN: 'ZONE_CLEAN',
};

const initialState = {};

const zoneReducer = (state = initialState, action) => {
    switch (action.type) {
        case ZONE.IS_PENDING:
            return {
                ...state,
                isPending: true,
            }
        case ZONE.LIST:
            return {
                ...state,
                list: action.payload,
                isPending: false,
            };
        case ZONE.CLEAN_LIST:
            return initialState;
        case ZONE.DETAIL:
        case ZONE.EDIT:
        case ZONE.CREATE:
        case ZONE.DELETE:
            return {
                ...state,
                zone: action.payload,
                isPending: false,
            }
        case ZONE.CLEAN:
            return initialState;
        case ZONE.SET:
            return {
                ...state,
                current: action.payload
            }
        default:
            return state;
    }
};

export default zoneReducer;


/* Загрузка списка доступных зон */
export const loadZoneList = (objId, cameraId) => dispatch => {
    return axios.get(`/api/statistics/camera-storage/${objId}/optical-cameras/${cameraId}/zones/`)
        .then(res => {
            dispatch({
                type: ZONE.LIST,
                payload: res.data,
            });
            return res.data;
        });
};

export const cleanZoneList = () => dispatch => {
    dispatch({
        type: ZONE.CLEAN_LIST,
        payload: {},
    });
}

export const loadZoneById = (objId, cameraId, zoneId) => dispatch => {
    dispatch({
        type: ZONE.IS_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/camera-storage/${objId}/optical-cameras/${cameraId}/zones/${zoneId}/`)
        .then(res => {
            dispatch({
                type: ZONE.DETAIL,
                payload: res.data,
            });
        });
};

export const zoneEdit = (objId, cameraId, zoneId, data) => dispatch => {
    dispatch({
        type: ZONE.IS_PENDING,
        payload: null,
    });
    return axios.put(`/api/statistics/camera-storage/${objId}/optical-cameras/${cameraId}/zones/${zoneId}/`, data)
        .then(res => {
            let resData = {isRedirect: true};
            dispatch({
                type: ZONE.EDIT,
                payload: resData,
            });
            return res;
        });
};

export const zoneCreate = (objId, cameraId, data) => dispatch => {
    dispatch({
        type: ZONE.IS_PENDING,
        payload: null,
    });
    return axios.post(`/api/statistics/camera-storage/${objId}/optical-cameras/${cameraId}/zones/`, data)
        .then(res => {
            dispatch({
                type: ZONE.CREATE,
                payload: res,
            });
            return res
        });
};

export const zoneDelete = (objId, cameraId, zoneId) => dispatch => {
    dispatch({
        type: ZONE.IS_PENDING,
        payload: null,
    });
    return axios.delete(`/api/statistics/camera-storage/${objId}/optical-cameras/${cameraId}/zones/${zoneId}/?power_unit_id=${objId}`)
        .then(res => {
            dispatch({
                type: ZONE.DELETE,
                payload: {},
            });
            return res;
        });
};


export const cleanZone = () => dispatch => {
    dispatch({
        type: ZONE.CLEAN,
        payload: {},
    });
}

export const setZone = (zone) => dispatch => {
    dispatch({
        type: ZONE.SET,
        payload: zone
    });
}
