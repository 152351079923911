import React from 'react';

import Modal from './Modal';
import TabsWithContent from './TabsWithContent';
import RemoteSubmitButton from '../forms/RemoteSubmitButton';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import CupboardManagementForm from "../forms/CupboardManagementForm";
import {cupboardManageNotOurEdit, cupboardManageOurEdit, cupboardManageOurCreate, cupboardManageNotOurCreate} from "../store/CupboardManagment";

class CupboardManagementModal extends React.Component {
    state = {
        isEditMode: false,
    };

    userFormValues = {};

    onEditForm() {
        this.setState({isEditMode: true})
    };

    render() {
        const {onClose, onCancel, isPending, isError, subdivision, our, objectsList} = this.props;
        const {updateCupboardManageOur, updateCupboardManageNotOur, createCupboardManageOur, createCupboardManageNotOur} = this.props.actions;
        this.userFormValues = subdivision;

        return (
            <Modal
                title={`Шкаф `}
                isOpen
                showEditButton={!this.state.isEditMode}
                closeButtonWithText={true}
                onClose={onClose}
                onEdit={() => this.onEditForm()}
                className="user-modal"
            >
                {isPending
                    ?
                    isError ?
                        <p>Ошибка при загрузке данных</p>
                        :
                        <Spinner/>
                    :
                    <>
                        <TabsWithContent
                            titles={['Данные']}
                            tabs={[
                                <>
                                    <CupboardManagementForm
                                        handleCancel={onCancel}
                                        our={our}
                                        objectsList={objectsList}
                                        onSubmit={values => {
                                            this.userFormValues = values;
                                            console.log("values11", values);
                                            if (this.userFormValues.object_observation?.length > 1 ){
                                                this.userFormValues.object_observation_id = this.userFormValues.object_observation[0]?.value;
                                            } else {
                                                this.userFormValues.object_observation_id = this.userFormValues.object_observation?.value;
                                            }
                                            if (this.userFormValues.cabinet_ext ){
                                                this.userFormValues.cabinet_ext_id = this.userFormValues.cabinet_ext?.value;
                                            }
                                            this.userFormValues.description = " ";
                                            if (our) {
                                                if (this.userFormValues.id) {
                                                    updateCupboardManageOur(this.userFormValues);
                                                } else {
                                                    createCupboardManageOur(this.userFormValues);
                                                }
                                            } else {
                                                if (this.userFormValues.id) {
                                                    updateCupboardManageNotOur(this.userFormValues);
                                                } else {
                                                    createCupboardManageNotOur(this.userFormValues);
                                                }
                                            }
                                            onClose();
                                        }}
                                        isEditMode={this.state.isEditMode}
                                    />
                                </>
                            ]}
                        />
                        <div className="submit-wrapper">
                            {this.state.isEditMode &&
                            <RemoteSubmitButton
                                forms={['CupboardManagementForm']}
                                className=""
                            >
                                Сохранить
                            </RemoteSubmitButton>}
                        </div>
                    </>
                }

            </Modal>
        )
    }
}

export default connect(
    state => ({
        isPending: state.cupboardManage.isListPending,
        isError: state.cupboardManage.isListError,
        cupboardItemOur: state.cupboardManage.current,
        objectsList: state.object.list,
    }),
    dispatch => ({
        actions: {
            updateCupboardManageOur: (cupboard) => {
                dispatch(cupboardManageOurEdit(cupboard.id, cupboard));
            },
            updateCupboardManageNotOur: (cupboard) => {
                dispatch(cupboardManageNotOurEdit(cupboard.id, cupboard));
            },
            createCupboardManageOur: (cupboard) => {
                dispatch(cupboardManageOurCreate(cupboard));
            },
            createCupboardManageNotOur: (cupboard) => {
                dispatch(cupboardManageNotOurCreate(cupboard));
            },
        }
    })
)(CupboardManagementModal);
