import React, {useEffect, useState} from 'react';
import {DATE_FORMAT} from '../utils/constants';
import {change, Field, formValues, getFormValues, reduxForm} from "redux-form";
import {EnableCheckbox, InputField, InputGroup, renderDateTimePicker} from "../inputs";

import {connect} from "react-redux";
import Legend from "./Legend";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";
import {userFio} from "../utils/userUtils";
import DatePicker from "react-datepicker/es";

const required = value => value ? undefined : 'Обязательное поле';

const timestamp = date => new Date(date).getTime() * 1000;

const dateFormatter = (date) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return new Date(date * 1000).toLocaleString('ru-RU', { timeZone });
}

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: `${entry.name} (${entry.power_unit.name})`
        }
    })
};

const primarySelectOptions = (options) => {
    return options.map(ppe => {
        return {
            value: ppe.name,
            label: ppe.ru_name
        }
    })
};

const empSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: userFio(entry)
        }
    })
};
const personsActive = (actIds, employees) => {
    let activeArr = [];
    if (actIds) {
        for (const actId of actIds) {
            const employee = employees.find(item => item.id === actId);
            if (employee) {
                activeArr.push({
                    value: employee.id,
                    label: userFio(employee)
                })
            }
        }
    }
    return activeArr;
}

const roomsActive = (actIds, rooms) => {
    let activeArr = [];
    if (actIds) {
        for (const actId of actIds) {
            const room = rooms.find(item => item.id === actId);
            if (room) {
                activeArr.push({
                    value: room.id,
                    label: `${room.name} (${room.power_unit.name})`,
                })
            }
        }
    }
    return activeArr;
}

const OutfitToleranceForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, employees, onSubmit, row, initialValues, objects, primaryList}) => {
    const className = (fieldName) => forms && forms.user && !forms.user[fieldName].valid ?
        'is-invalid' : '';

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = true;
        const options = objSelectOptions(objects);
        const placeholder = 'Выберите помещение';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormPrimarySelect = ({input, validate}) => {
        const isMulti = false;
        const options = primarySelectOptions(addEmptyValue(primaryList));
        const placeholder = 'Выберите тип спецодежды';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormPersonsSelect = ({input, validate}) => {
        const isMulti = true;
        const options = empSelectOptions(employees);
        const placeholder = 'Выберите работников';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    const addEmptyValue = (arr) => {
        if (arr.find((i) => i.name === "0") === undefined) {
            arr.unshift({
                name: "0",
                ru_name: "Все"
            });
        }
        return arr;
    };

    return (
        <div className="modal-content">
            <form onSubmit={values => handleSubmit(values)} className="eventForm ">
                <Legend title="Наряд допуск" className="long-title">
                    <Field name="priority" type="text" label="Тип работ"
                           component={InputField} className={className('priority')}
                           disabled={true}
                           validate={[required]}/>
                    <Field name="document_number" type="text" label="Номер наряд - допуск"
                           component={InputField} className={className('document_number')}
                           validate={[required]}/>
                    <InputGroup title="Помещение" >
                        <Field
                            name="job_rooms"
                            className={className('job_room')}
                            component={ReduxFormObjectsSelect}
                            validate={[required]}
                        />
                    </InputGroup>
                    <Field name="job_time_from" type="text"
                           label="Дата и время от"
                           title="Время указывается в часовом поясе Мск"
                           showTime={true}
                           component={renderDateTimePicker}
                           className={className('job_time_from')}
                           validate={[required]}/>
                    <Field name="job_time_to" type="text"
                           label="Дата и время до"
                           showTime={true}
                           component={renderDateTimePicker}
                           className={className('job_time_to')}
                           validate={[required]}/>
                    <InputGroup title="Типы спецодежды" >
                        <Field
                            name="primary_category"
                            className={className('primary_category')}
                            component={ReduxFormPrimarySelect}
                            validate={[required]}
                        />
                    </InputGroup>
                    <InputGroup title="Список работников" >
                        <Field
                            name="workers"
                            className={className('workers')}
                            component={ReduxFormPersonsSelect}
                            validate={[]}
                        />
                    </InputGroup>
                    <Field name="description" type="text" label="Описание"
                           component={InputField} className={className('cron_datetime')}
                           validate={[]}/>
                    <Field
                        name="is_active"
                        component={EnableCheckbox}
                        labelText="В работе"
                    />
                </Legend>
                <div className="submit-wrapper">
                    <button type="submit" >
                        Сохранить
                    </button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    if (Object.keys(props.row).length === 0){
        return {
            initialValues: {
                priority: 'Работа по наряду - допуску',
                document_type: 1,
            }
        }
    }
    return {
        initialValues: {
            ...props.row,
            priority: 'Работа по наряду - допуску',
            workers: personsActive(props.row.workers, props.employees),
            job_time_from: timestamp(props.row.job_time_from),
            job_time_to: timestamp(props.row.job_time_to),
            primary_category: {
                value: props.row.primary_category ? props.row.primary_category : "0",
                label: props.row.primary_category_ru ? props.row.primary_category_ru : "Все"
            },
            job_rooms: roomsActive(props.row.job_room_ids, props.objects)
        }
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'OutfitToleranceForm',
        enableReinitialize: true,
    })(OutfitToleranceForm)
)

// export default EventForm;
