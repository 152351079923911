import React from 'react';

const NotFound = () => {
    document.title = '404';

    return (
        <div>Нет такой страницы</div>
    );
};

export default NotFound;
