import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import Spinner from "../components/Spinner";

const WorkwearTable = ({actions, getColumns, objects, isPending, rowEvents = {}}) => {
  const {reload} = actions;

  const [items, setItems] = useState(objects);

  useEffect(() => {
    setItems(objects);
  }, [JSON.stringify(objects)]);

  const cancelNewRow = () => {};

  let tableRef;

  return (
    <>
      <div className="btn-wrapper">
        <button className="table-control-btn btn-reload" onClick={reload}>
          <FontAwesomeIcon icon={faSyncAlt} size="lg" color="#919191"/>
          Обновить
        </button>
      </div>
      <BootstrapTable
        ref={el => tableRef = el}
        keyField="name"
        data={items}
        columns={getColumns(cancelNewRow)}
        noDataIndication={() => isPending ? <Spinner/> : 'Данные отсутствуют'}
        rowEvents={rowEvents}
      />
    </>
  )
}

export default WorkwearTable;
