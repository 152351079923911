import axios from 'axios';
import imageUtils from "../utils/imageUtils";
import {clearEmploeesState, loadEmploeesList} from "./Employees";

const NOTIFICATION_DEVICES_MAPPING = {
    LIST:       'NOTIFICATION_DEVICES_MAPPING_LIST',
    LIST_PENDING: 'NOTIFICATION_DEVICES_LIST_MAPPING_PENDING',
    LIST_ERROR: 'NOTIFICATION_DEVICES_LIST_MAPPING_ERROR',
    DETAIL:     'NOTIFICATION_DEVICES_MAPPING_DETAIL',
    CREATE:     'NOTIFICATION_DEVICES_MAPPING_CREATE',
    EDIT:       'NOTIFICATION_DEVICES_MAPPING_EDIT',
    DELETE:     'NOTIFICATION_DEVICES_MAPPING_DELETE',
    CLEAR:      'NOTIFICATION_DEVICES_MAPPING_CLEAR',
    CURRENT_CLEAR:      'NOTIFICATION_DEVICES_CURRENT_MAPPING_CLEAR',
};

const initialState = {
    current: {},
};

const notificationDevicesMappingReducer = (state = initialState, action) => {
    switch(action.type) {
        case NOTIFICATION_DEVICES_MAPPING.LIST:
            return {
                ...state,
                list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case NOTIFICATION_DEVICES_MAPPING.DETAIL:
        case NOTIFICATION_DEVICES_MAPPING.CREATE:
        case NOTIFICATION_DEVICES_MAPPING.EDIT:
        case NOTIFICATION_DEVICES_MAPPING.DELETE:
        case NOTIFICATION_DEVICES_MAPPING.ALL_INFO:
            return {
                ...state,
                current: action.payload,
                isListPending: false,
            };
        case NOTIFICATION_DEVICES_MAPPING.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case NOTIFICATION_DEVICES_MAPPING.LIST_ERROR:
            return {
                isListError: true,
            }
        case NOTIFICATION_DEVICES_MAPPING.CLEAR:
            return initialState;
        case NOTIFICATION_DEVICES_MAPPING.CURRENT_CLEAR:
            return {
                ...state,
                current: []
            };
        default:
            return state;
    }
};

export default notificationDevicesMappingReducer;

export const notificationDevicesMappingList = () => dispatch => {
    dispatch({
        type: NOTIFICATION_DEVICES_MAPPING.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/device-alert/camera-settings/')
        .then(res => {
            dispatch({
                type: NOTIFICATION_DEVICES_MAPPING.LIST,
                payload: res.data
            });
        })
};


/* Загрузка камеры (cameraId) из объекта наблюдения (objId) */
export const notificationDevicesMappingLoad = (cameraId) => dispatch => {
    dispatch({
        type: NOTIFICATION_DEVICES_MAPPING.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/device-alert/camera-settings/${cameraId}/`)
        .then(res => {
            dispatch({
                type: NOTIFICATION_DEVICES_MAPPING.DETAIL,
                payload: res.data
            });
        });
};

/* Создание новой камеры */
export const notificationDevicesMappingCreate = (data) => dispatch => {
    axios.post(`/api/statistics/device-alert/camera-settings/`, data)
        .then(res => {
            dispatch({
                type: NOTIFICATION_DEVICES_MAPPING.CREATE,
                payload: {isReloadList: false},
            });
            dispatch(notificationDevicesMappingList());
        })
        .catch(err => {
            dispatch({
                type: NOTIFICATION_DEVICES_MAPPING.CREATE,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.response.data.error
                },
            });
        });
};

export const notificationDevicesMappingDelete = (cameraId) => dispatch => {
    axios.delete(`/api/statistics/device-alert/camera-settings/${cameraId}/`)
        .then(res => {
            let data = {isReloadList: false};
            dispatch({
                type: NOTIFICATION_DEVICES_MAPPING.DELETE,
                payload: data,
            });
            dispatch(notificationDevicesMappingClearState());
            dispatch(notificationDevicesMappingList());
        });
};

export const notificationDevicesMappingClearState = () => dispatch => {
    dispatch({
        type: NOTIFICATION_DEVICES_MAPPING.CLEAR,
        payload: null,
    });
};

export const notificationDevicesCurrentMappingClearState = () => dispatch => {
    dispatch({
        type: NOTIFICATION_DEVICES_MAPPING.CURRENT_CLEAR,
        payload: null,
    });
};

export const notificationDevicesMappingEdit = (deviceId, data) => dispatch => {
    axios.put(`/api/statistics/device-alert/${deviceId}/remap-camera-pairs/`, data)
        .then(res => {
            dispatch({
                type: NOTIFICATION_DEVICES_MAPPING.EDIT,
                payload: {isReloadList: false},
            });
            dispatch(notificationDevicesMappingClearState());
            dispatch(notificationDevicesMappingList());
        })
        .catch(err => {
            dispatch({
                type: NOTIFICATION_DEVICES_MAPPING.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};