import React from 'react';

import {getDateNow} from '../utils/time';
import RejectedEventsPage from '../tables/RejectedEventsPage';
import {DEFAULT_INTERVAL_HOURS} from '../utils/constants';

const RejectedEvents = () => {
    document.title = 'Отклоненные события';

    return (
        <RejectedEventsPage
            defaultQuery={{
                dateBegin: getDateNow(-DEFAULT_INTERVAL_HOURS),
                dateEnd: getDateNow(),
            }}
            isViolationsOnly={false}
        />
    );
}

export default RejectedEvents;
