import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import withDataErrorIndication from '../components/withDataErrorIndication';
import WorkwearTable from "../tables/WorkwearTable";
import {
    loadRegulatoryDocSecondary,
    loadRegulatoryDocsSecondary,
    removeRegulatoryDocSecondary,
    updateRegulatoryDocSecondary
} from "../store/RegulatoryDocsSecondary";
import PPEsModal from "../components/PPEsModal";
import {loadRegulatoryDocPrimary} from "../store/RegulatoryDocsPrimary";

const LinkTextFormatter = ({text}) => {
    let linkText = ''
    if (text) {
        const arr = text.split('/');
        linkText = arr[arr.length - 1];
    }
    return (
      <div>{text ? <a href={text} target="_blank">{linkText}</a> : <></>}</div>
    );
}

const PPEs = ({ isPending, actions, list = [], selectedDoc }) => {
    document.title = 'Типы СИЗ';

    const {
        selectDoc,
        loadRegulatoryDocs,
        saveDoc,
        removeDoc
    } = actions;

    useEffect(() => {
        loadRegulatoryDocs();
    }, []);

    const [shouldShowModal, setShowModal] = useState(false);
    const [workwearFormValues, setWorkwearFormValues] = useState({});

    useEffect(() => {
        const fields = {};
        let valuesChanged = [];
        let saved = false;
        const hasData = !!Object.keys(workwearFormValues).length;
        if (hasData) {
            fields.name = workwearFormValues.name;
            fields.description = workwearFormValues.description;
            fields.article = workwearFormValues.article;
            valuesChanged = Object.keys(fields).reduce((acc, key) => {
                if (fields[key] !== selectedDoc[key]) {
                    acc.push(fields[key])
                }
                return acc;
            }, []);
        }
        if(workwearFormValues.isEdited && workwearFormValues.file) {
            saveDoc({
                ...fields,
                file: workwearFormValues.file
            });
            saved = true;
        }
        if(workwearFormValues.isEdited && !workwearFormValues.file) {
            removeDoc({
                name: fields.name
            });
        }
        if(valuesChanged.length && !saved) {
            saveDoc(fields);
        }
        setShowModal(false);
    }, [workwearFormValues])

    const Columns = () => [{
        text: 'Нарушение',
        dataField: 'ru_name',
        headerStyle: () => ({width: '300px'}),
        editable: false
    }, {
        text: 'Расшифровка нарушения',
        dataField: 'description',
        headerStyle: () => ({width: '300px'}),
        editable: false
    }, {
        text: ' статья  ТБ и ПБ',
        dataField: 'article',
        headerStyle: () => ({width: '300px'}),
        editable: false
    }, {
        text: 'Cсылка на документ по ТБ и ПБ',
        dataField: 'file_uri',
        editable: false,
        formatter: (cell) => (
          <LinkTextFormatter text={cell}/>
        )
    }];

    return (
      <>
          <WorkwearTable
            objects={list}
            getColumns={Columns}
            isEditable={false}
            isPending={isPending}
            rowEvents={{ onDoubleClick: (e, { name }) => {
                    selectDoc(name);
                    setShowModal(true);
                }}}
            actions={{
                reload: loadRegulatoryDocs
            }}
          />
          {shouldShowModal &&
          <PPEsModal
            onCancel={() => {
                setShowModal(false);
            }}
            onClose={() => {
                setShowModal(false);
            }}
            onSave={(data) => {
                setWorkwearFormValues(data);
            }}
          />}
      </>
    )
}

export default connect(
  state => ({
      list: state.regulatoryDocsSecondary.list,
      isPending: state.regulatoryDocsSecondary.busy,
      isError: state.regulatoryDocsSecondary.error,
      selectedDoc: state.regulatoryDocsSecondary.current
  }),
  dispatch => ({
      actions: {
          loadRegulatoryDocs: () => {
              dispatch(loadRegulatoryDocsSecondary());
          },
          selectDoc: name => {
              dispatch(loadRegulatoryDocSecondary(name));
          },
          saveDoc: data => {
              dispatch(updateRegulatoryDocSecondary(data));
          },
          removeDoc: data => {
              dispatch(removeRegulatoryDocSecondary(data));
          }
      }
  }),
)
(withDataErrorIndication(PPEs));
