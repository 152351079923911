import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {loadObjectsObservationList} from '../store/ObjectObservation';
import EventsReportForm from '../forms/EventsReportForm';
import EventsReportCameraForm from '../forms/EventsReportCameraForm'
import EventsReportIndexForm from '../forms/EventsReportIndexForm'
import EventsReportViolationForm from '../forms/EventsReportViolationForm'
import Spinner from '../components/Spinner';
import {cameraClearState, cameraFlatDetailedList} from "../store/Camera";
import {thermalCameraList} from "../store/ThermalCamera";
import UserReportAccessesForm from "../forms/UserReportAccessesForm";
import {loadRegulatoryDocsSecondary} from "../store/RegulatoryDocsSecondary";

export const dateEndTime = (date) => {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
};

export const dateBeginTime = (date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(1);
    return date;
};

const EventsReport = ({type, objects, loadObjects, loadAllCameras, cameras, thermalCameras, loadThermalCamerasData, loadRegulatoryDocs, ppeList}) => {
    useEffect(() => {
        // loadObjects();
        loadAllCameras();
        loadThermalCamerasData();
        loadRegulatoryDocs();
    }, []);

    let title;
    if (type === 'period') {
        title = 'Отчет "Нарушения за выбранный период"';
    } else if (type === 'camera') {
        title = 'Отчет "Нарушения ТБ, зафиксированные камерой"';
    } else if (type === 'scores') {
        title = 'Отчет "Нарушения с индексом вероятности, превышающим допустимое значение"';
    } else if (type === 'confirmed') {
        title = 'Отчет "Нарушения ТБ, подтвержденные Оператором"'
    } else if (type === 'user') {
        title = 'Отчет "Доступы пользователей к камерам"'
    }

    const changeForm = () => {
      console.log('333')
    };

    document.title = title;

    if (!cameras || !thermalCameras || !ppeList) {
        return <Spinner/>
    }
    return (
        <div className="report-form-wrapper">
            <p className="title-form">{title}</p>
            <>
                {type === "period" &&
                    <EventsReportForm onSubmit={changeForm}/>
                }
            </>
            <>
                {type === "camera" &&
                    <EventsReportCameraForm onSubmit={changeForm}/>
                }
            </>
            <>
                {type === "scores" &&
                    <EventsReportIndexForm onSubmit={changeForm}/>
                }
            </>
            <>
                {type === "confirmed" &&
                    <EventsReportViolationForm onSubmit={changeForm}/>
                }
            </>
            <>
                {type === "user" &&
                    <UserReportAccessesForm onSubmit={changeForm}/>
                }
            </>
        </div>
    )
}

export default connect(
    state => ({
        objects: state.object,
        cameras: state.camera.flatList || [],
        thermalCameras: state.thermalCamera.list || [],
        ppeList: state.regulatoryDocsSecondary.list,
    }),
    dispatch => ({
        loadObjects: () => {
            dispatch(loadObjectsObservationList());
        },
        loadAllCameras: () => {
            dispatch(cameraClearState());
            dispatch(cameraFlatDetailedList());
        },
        loadThermalCamerasData: () => {
            dispatch(thermalCameraList());
        },
        loadRegulatoryDocs: () => {
            dispatch(loadRegulatoryDocsSecondary());
        },
    }),
)(EventsReport);
