import React from 'react';
import DatePicker from 'react-datepicker/es';

import {DATE_FORMAT} from './constants';
import {ROLE_NAMES, ROLES} from '../roles';
import Select from 'react-select';

export const DatePickerInput = ({input, placeholder}) => (
    <div className="input-wrapper">
        <DatePicker
            selected={input.value}
            dateFormat={DATE_FORMAT}
            onChange={input.onChange}
            placeholderText={placeholder}
        />
    </div>
);

export const objectsListToOptions = (objects = []) => objects.reduce(
    (acc, item) => ({...acc, [item.id]: item.name ? item.name : item.number}), {});

export const objectToOption = (cell, objects = []) => objects.filter(item => item.id === cell)[0]?.name;

export const selectOptions = (options) => Object.entries(options)
    .map(entry => (
        <option value={entry[0]} key={entry[0]}>{entry[1]}</option>
    ))

export const rolesOptionsByName = (availableRoles) => availableRoles.map(roleVal => {
    const roleKey = Object.keys(ROLES).find(key => ROLES[key] === roleVal);
    return (
        <option value={ROLES[roleKey]} key={roleKey}>
            {ROLE_NAMES[roleKey]}
        </option>
    )}
);

export const rolesOptionsByKey = (availableRoles) => availableRoles.map(role => {
    return (
        <option value={ROLES[role]} key={role}>
            {ROLE_NAMES[role]}
        </option>
    )}
);

const selectOptionStyle = (base) =>  ({
    ...base,
    fontSize: `13px`
})

export const ReduxFormSelectValid = (isMulti = false, options, placeholder, disabled = false) => ({input}) => {
    return (
    <div className={input.value.length === 0 ? "empty select-list" : "select-list"}>
        <Select
            {...input}
            styles={{
                option: (base) => (selectOptionStyle(base)),
            }}
            isMulti={isMulti}
            options={options}
            placeholder={placeholder}
            isDisabled={disabled}
            className="react-select"
            classNamePrefix="react-select"
            onChange={val => {
                input.onChange(val)
            }}
            onBlur={() => {
                input.onBlur(input.value)
            }}
        />
    </div>
)}

export const ReduxFormSelectViol = (isMulti = false, options, placeholder) => ({input}) => (
    <div className={input.value.length === 0 ? "empty select-list" : "select-list"}>
        <select {...input} className="react-select custom">
            <option value="0">{placeholder}</option>
            {options.map(item =>
                <option value={item.value}>{item.label}</option>
            )}
        </select>
    </div>
)

export const ReduxFormSelect1 = (isMulti = false, options, placeholder) => ({input}) => (
    <div className="select-list">
        <Select
            {...input}
            styles={{
                option: (base) => (selectOptionStyle(base)),
            }}
            defaultMenuIsOpen={true}
            isMulti={isMulti}
            options={options}
            placeholder={placeholder}
            className="react-select"
            classNamePrefix="react-select"
            onChange={val => {
                input.onChange(val)
            }}
            onBlur={() => {
                input.onBlur(input.value)
            }}
        />
    </div>
)

export const ReduxFormSelect = (isMulti = false, options, placeholder) => ({input}) => (
    <div className="select-list">
        <Select
            {...input}
            styles={{
                option: (base) => (selectOptionStyle(base)),
            }}
            isMulti={isMulti}
            options={options}
            placeholder={placeholder}
            className="react-select"
            classNamePrefix="react-select"
            onChange={val => {
                input.onChange(val)
            }}
            onBlur={() => {
                input.onBlur(input.value)
            }}
        />
    </div>
)

