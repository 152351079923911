import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-regular-svg-icons';

import {loadObjectsObservationList, objectObservationClearState} from '../store/ObjectObservation';
import {apiDataToCameras, cameraToApiData, omitRooms, thermalCameraToApiData, thermalCamerasToApiData} from '../utils/utils';
import {objectsListToOptions} from '../utils/inputs';
import {nbsp} from '../utils/string';
import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import SelectWithButtons from '../tables/SelectWithButtons';
import TextInputWithButtons from '../tables/TextInputWithButtons';
import CredentialsInputWithButtons from '../tables/CredentialsInputWithButtons';
import withDataErrorIndication from '../components/withDataErrorIndication';
import CheckboxWithButtons from '../tables/CheckboxWithButtons';
import UserModal from "../components/UserModal";
import CameraModal from "../components/CameraModal";
import {
    thermalCameraClearState,
    thermalCameraCreate, thermalCameraDelete, thermalCameraEdit,
    thermalCameraFlatDetailedList,
    thermalCameraLoad
} from "../store/ThermalCamera";
import ThermalCameraModal from "../components/ThermalCameraModal";
import Spinner from "../components/Spinner";

const HiddenTextFormatter = ({text}) => {
    const [isTextHidden, setIsTextHidden] = useState(true);

    return (
        <div onMouseDown={() => setIsTextHidden(false)}
             onMouseUp={() => setIsTextHidden(true)}>
            {isTextHidden ? <FontAwesomeIcon icon={faEye}/> : <>{text || nbsp}</>}
        </div>
    );
}

const ThermalCamerasManagement = ({actions, thermalCameras, thermalCamera, objects = [], isListPending}) => {
    const {loadAllCameras, createCamera, updateCamera, deleteCamera, loadObjects, loadCamera} = actions;

    const loadData = () => {
        loadAllCameras();
        loadObjects();
    }

    const [shouldShowModal, setShowModal] = useState(false);

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (thermalCamera?.isReloadList) {
            loadAllCameras();
        }
    }, [thermalCamera]);


    const Columns = (cellCancelHandler) => [{
        text: '№',
        dataField: 'id',
        sort: true,
        headerStyle: () => ({width: '59px'}),
        editable: false,
    }, {
        text: 'Блок',
        dataField: 'power_unit',
        editable: false,
        headerStyle: () => ({width: '200px'}),
        formatter: (cell) => cell.name,
        required: true,
    }, {
        text: 'Помещение',
        dataField: 'object_name',
        editable: false,
        headerStyle: () => ({width: '200px'}),
        type: 'number',
        required: true,
    }, {
        text: 'Название',
        dataField: 'name',
        sort: true,
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons
                {...editorProps}
                onCancel={cellCancelHandler} className="required-field"/>
        ),
        headerStyle: () => ({width: '200px'}),
        required: true,
    }, {
        text: 'Значение пороговой температуры, °C',
        dataField: 'temp_thresh_celsius',
        sort: true,
        formatter: (cell) => cell.toFixed(1),
        headerStyle: () => ({width: '301px'}),
    }, {
        text: 'URL',
        dataField: 'media_uri',
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons {...editorProps} onCancel={cellCancelHandler}/>
        ),
        required: true,
    }, {
        text: 'Вкл',
        dataField: 'is_enabled',
        sort: true,
        editable: false,
        editorRenderer: (editorProps) => (
            <CheckboxWithButtons {...editorProps} onCancel={cellCancelHandler}/>
        ),
        formatter: (cell) => <input type="checkbox" checked={cell} className="tableCheckbox"/>,
        type: 'bool',
        headerStyle: () => ({width: '50px'}),
    }];

    document.title = 'Тепловизионные камеры';

    return (
        <>
            <GeneralObjectsTable
                isPending={isListPending}
                objects={thermalCamerasToApiData(thermalCameras)}
                isEditable={false}
                actions={{
                    update: updateCamera,
                    // create: createCamera,
                    create: () => {
                        setShowModal(true);
                    },
                    purge: deleteCamera,
                    reload: loadData,
                }}
                getColumns={Columns}
                itemDummy={{
                    id: 0,
                    code: 0,
                    obj: '',
                    name: '',
                    protocol: '',
                    host: '',
                    suffix: '',
                    credentials: [],
                    is_enabled: true,
                }}
                rowEvents={{
                    onDoubleClick: (e, row, rowIndex) => {
                        loadCamera(row);
                        setShowModal(true);
                    }
                }}
            />
            {shouldShowModal &&
            <ThermalCameraModal
                objectsList={objects}
                onCancel={() => {
                    setShowModal(false);
                }}
                onClose={() => {
                    setShowModal(false);
                }}
            />}
        </>
    )
}

export default connect(
    state => ({
        user: state.user,
        thermalCameras: state.thermalCamera.flatList,
        thermalCamera: state.thermalCamera.current,
        isListPending: state.thermalCamera.isListPending || state.object.isListPending,
        objects: state.object.list,
        isError: state.camera.isListError || state.thermalCamera.isListError,
    }),
    dispatch => ({
        actions: {
            loadAllCameras: () => {
                dispatch(thermalCameraClearState());
                dispatch(thermalCameraFlatDetailedList());
            },
            createCamera: (camera) => {
                const apiData = cameraToApiData(camera);
                dispatch(thermalCameraCreate(apiData.object_observation, apiData));
            },
            loadCamera: (camera) => {
                // dispatch(cameraClearState());
                dispatch(thermalCameraLoad(camera.object, camera.id));
            },
            updateCamera: (cameraId, camera) => {
                const apiData = cameraToApiData(camera);
                dispatch(thermalCameraEdit(apiData.object_observation, cameraId, apiData));
            },
            deleteCamera: (cameraId, camera) => {
                dispatch(thermalCameraDelete(camera.object, cameraId));
            },
            loadObjects: () => {
                dispatch(objectObservationClearState());
                dispatch(loadObjectsObservationList());
            },
        },
    }),
)(withDataErrorIndication(ThermalCamerasManagement));
