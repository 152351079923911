import React, {useEffect, useState, useCallback} from 'react';
import Icons from '../../Icons';
import {faTimes, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const FaqDialog = ({ modal, step, clickClose, nextStep }) => {
  return (
    <div className="dialog-faq">
      <div className="dialog-faq_header">
        <div className="dialog-faq_header--titlePage">
          <FontAwesomeIcon icon={faQuestionCircle}/>
          {modal.slides[step].page || modal.page}
        </div>
        <button
          className="dialog-faq_header--closeButton"
          onClick={() => clickClose()}
        >
          <FontAwesomeIcon icon={faTimes}/>
        </button>
      </div>
      <div className="dialog-faq_caption">
        { modal.slides[step].title }
      </div>
      <div className="dialog-faq_container">
        <img src={modal.slides[step].img} alt="" />
      </div>
      <div className="dialog-faq_footer">
        <button className="dialog-faq_footer--nextButton" onClick={() => nextStep()}>Следующий шаг</button>
      </div>
    </div>
  );
}

export const Faq = ({ faqModal, close }) => {
  const [modal, setModal] = useState(null);
  const [step, setStep] = useState(0);
  useEffect(() => {
    setModal(faqModal);
  }, [faqModal]);

  const clickClose = useCallback(() => {
    close();
    setStep(0);
  }, []);

  const nextStep = useCallback(() => {
    if (faqModal.slides.length > step + 1) {
      setStep(step + 1);
    } else {
      close();
      setStep(0)
    }
  }, [step, faqModal]);

  return (
    <>
      { modal && modal.slides.length ? <div className="dialog-wrapper">{ FaqDialog({ modal, clickClose, nextStep, step }) }</div> : <></> }
    </>
  );
}
