import React from 'react';
import CellSaveButtons from './CellSaveButtons';
import {selectOptions} from '../utils/inputs';

class SelectWithButtons extends React.Component {
    inputEl = React.createRef();

    cancelHandler = () => {
        this.props.onBlur();
        this.props.onCancel();
    }

    render = () => {
        const {onUpdate, defaultValue, className, options} = this.props;
        return (
            <div className="edit-wrapper select-with-buttons">
                <div className="field-wrapper">
                    <select defaultValue={defaultValue} ref={this.inputEl} className={className}>
                        {selectOptions(options, defaultValue)}
                    </select>
                </div>
                <CellSaveButtons
                    onSave={() => {
                        onUpdate(this.inputEl.current.value)
                    }}
                    onCancel={this.cancelHandler}
                />
            </div>
        );
    }
}

export default SelectWithButtons;
