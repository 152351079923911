import React, {useState} from 'react';

// import {Select as ReactSelect} from 'react-select'
import Select from 'react-select'
import DatePicker from 'react-datepicker/es';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import ru from 'date-fns/locale/ru';

registerLocale('ru', ru);
setDefaultLocale(ru);

export const InputGroup = ({title, children}) => (
    <div className='form-input'>
        <div className="title-group">
            <p>{title}</p>
        </div>
        <div className="input">
            {children}
        </div>
    </div>
);

export const InputField = ({input, label, type, disabled, meta: {touched, error, warning}}) => (
    <InputGroup title={label}>
        <input {...input} placeholder={label} type={type} autoComplete="off"
               className={error ? 'is-invalid' : ''} disabled={disabled}
        />
        {touched && ((error && <div className="invalid-feedback">{error}</div>) || (warning && <div className="invalid-feedback">{warning}</div>))}
    </InputGroup>
);

export const renderDateTimePicker = ({ input, placeholder, showTime, minDate, maxDate, label, title, type, disabled, meta: {touched, error, warning} }) =>
    <InputGroup title={label}>
        <div className="simple-input-with-title-top">
            <p>{title}</p>
            <DatePicker
                className={error ? 'is-invalid' : ''}
                showTimeSelect={showTime}
                dateFormat="dd.MM.yyyy, h:mm:ss"
                selected={input.value || null}
                onChange={input.onChange}
                minDate={minDate}
                maxDate={maxDate}
                placeholderText={placeholder}
                dateFormat="Pp"
            />
            {touched && ((error && <div className="invalid-feedback">{error}</div>) || (warning && <div className="invalid-feedback">{warning}</div>))}
        </div>
    </InputGroup>;



export const InputSimple = ({ input, type, placeholder, disabled, meta: { touched, error } }) => (
    <>
        <input {...input} disabled={disabled} placeholder={placeholder} type={type} className={error ? 'is-invalid' : ''}/>
    </>
);
export const InputSimpleWithTitle = ({ input, type, placeholder, meta: { touched, error } }) => (
    <div className="simple-input-with-title-buttom">
        <label>
            <input {...input} type={type} className={error ? 'is-invalid' : ''}/>
            <p>{placeholder}</p>
        </label>
    </div>
);

export const InputSimpleWithTitleTop = ({ input, type, placeholder, meta: { touched, error } }) => (
    <div className="simple-input-with-title-top">
        <label>
            <p>{placeholder}</p>
            <input {...input} type={type} className={error ? 'is-invalid' : ''}/>
        </label>
    </div>
);

export const EnableCheckbox = ({input, label, type, meta: {touched, error, warning}, labelText, rightTitle}) => {
    return (
        <div className={rightTitle ? 'form-input right-title' : 'form-input'}>
            <div className="title-group">
                <p>
                    {labelText}
                </p>
            </div>
            <div className="title-group">
                <div className="custom-control custom-toggle custom-toggle-sm mb-1">
                    <input {...input}
                           type="checkbox"
                           id={label}
                           name={label}
                           checked={input.value}
                           className="custom-control-input"
                    />
                    <label className="custom-control-label"
                           htmlFor={label}
                           onClick={(data) => {
                               input.onChange(!input.value);
                           }}> </label>
                </div>
            </div>
        </div>
    );
};

export const NotificationModernCheckbox = ({input, label}) => {
    return (
        <div className="">
            <div className="custom-control custom-toggle custom-toggle-sm mb-1">
                <input {...input}
                       type="checkbox"
                       id={label}
                       name={label}
                       checked={input.value}
                       className="custom-control-input"
                />
                <label className="custom-control-label"
                       htmlFor={label}
                       onClick={(data) => {
                           input.onChange(!input.value);
                       }}> </label>
            </div>
        </div>
    );
};


export const RadioField = ({input, type, meta: {touched, error, warning}, id, checked, initialValues}) => {
    const [radioValue, setRadioValue] = useState(initialValues);
    const [check, setCheck] = useState(false);

    return (
        <>
            <input {...input}
                   style={{"display": "none"}}

                   type="text"
                   id={id}
                   value={input.value || initialValues}
                   checked={checked}
            />
            <input
                   type="radio"
                   id={id + "detect"}
                   checked={check ? radioValue === 'detect' : initialValues === 'detect'}
                   onChange={val => {
                       setRadioValue("detect");
                       setCheck(true);
                       input.onChange("detect")
                   }}
            />
            <label className="detect" htmlFor={id + "detect"}>Детекция</label>
            <input
                type="radio"
                id={id + "pass"}
                checked={check ? radioValue === 'pass' : initialValues === 'pass'}
                onChange={val => {
                    setRadioValue("pass");
                    setCheck(true);
                    input.onChange("pass")
                }}
            />
            <label className="pass" htmlFor={id + "pass"}>Пропустить</label>
            <input
                type="radio"
                id={id + "viol"}
                checked={check ? radioValue === 'viol' : initialValues === 'viol'}
                onChange={val => {
                    setRadioValue("viol");
                    setCheck(true);
                    input.onChange("viol")
                }}
            />
            <label className="viol" htmlFor={id + "viol"}>Нарушение</label>
        </>
    );
};

export const RadioFieldDevices = ({input, type, meta: {touched, error, warning}, id, checked, initialValues, ppeVal}) => {
    const [radioValue, setRadioValue] = useState(initialValues);
    const [check, setCheck] = useState(false);

    return (
        <>
            <input {...input}
                   style={{"display": "none"}}

                   type="text"
                   id={id}
                   value={input.value || initialValues}
                   checked={checked}
            />
            <input
                type="radio"
                id={id + "detect"}
                checked={check ? radioValue === 'detect' : initialValues === 'detect'}
                onChange={val => {
                    setRadioValue("detect");
                    setCheck(true);
                    input.onChange("detect")
                }}
            />
            <label className="detect" htmlFor={id + "detect"}>Уведомление</label>
            <input
                type="radio"
                id={id + "pass"}
                checked={check ? radioValue === 'pass' : initialValues === 'pass'}
                onChange={val => {
                    setRadioValue("pass");
                    setCheck(true);
                    input.onChange("pass")
                }}
            />
            <label className="pass" htmlFor={id + "pass"}>Пропустить</label>
            <input
                type="radio"
                id={id + "viol"}
                checked={check ? radioValue === 'viol' : initialValues === 'viol'}
                onChange={val => {
                    setRadioValue("viol");
                    setCheck(true);
                    input.onChange("viol")
                }}
            />
            <label className="viol" htmlFor={id + "viol"}>Длинное уведомление</label>
        </>
    );
};

export const SelectInput = (props) => {
    const { input, options, currentValue } = props;
    let value = input.value;
    if (input.value) {
        for (const v of options) {
            if (v.value === input.value) {
                value = v;
            }
        }
    } else {
        value = currentValue;
    }
    input.onChange(value);
    return (
        <div className={input.value.length === 0 ? "empty select-list" : "select-list"}>
            <Select
                {...input}
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 99999 })
                }}
                options={options}
                className="react-select"
                classNamePrefix="react-select"
                value={value}
                maxMenuHeight={180}
                onChange={(v) => {
                    input.onChange(v)
                }}
                onBlur={(v) => {
                    input.onBlur(value)
                }}
            />
        </div>
    )
};