import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import {cameraLoad} from '../store/Camera';
import Spinner from '../components/Spinner';
import VideoPlayerHls from '../components/VideoPlayerHls';
import SelectPlayer from "../components/SelectPlayer";
import Zones from "../components/Zones";
import {thermalCameraLoad} from "../store/ThermalCamera";
import {isHEAD} from "../utils/userUtils";

const Camera = ({user, params, cameraLoad, camera, thermalCamera, thermalCameraLoad}) => {
    let thisCamera;

    useEffect(() => {
        if (params.type === "optical") {
            cameraLoad(params.objId, params.camId);
        } else {
            thermalCameraLoad(params.objId, params.camId);
        }
    }, [params.camId, params.objId]);

    if (params.type === "optical") {
        thisCamera = camera;
    } else {
        thisCamera = thermalCamera;
    }

    document.title = 'Просмотр камеры';

    // if (!camera || !camera.id
    //     || params.camId.toString() !== camera.id.toString()
    //     || params.objId.toString() !== camera.obj_id.toString()) {
    //     return <Spinner/>;
    // }

    if (!thisCamera || !thisCamera.id
        || params.camId.toString() !== thisCamera.id.toString()
        || params.objId.toString() !== thisCamera.obj_id.toString()) {
        return <Spinner/>;
    }

    if (!thisCamera.is_enabled) {
        return (
            <div className="camera_item">
                <div className="title">
                    <p>Камера {thisCamera.name}</p>
                </div>

                <Tabs>
                    <TabList>
                        <Tab>Камера</Tab>
                        <Tab>Нейронная сеть</Tab>
                        {isHEAD(user.role) &&
                            <Tab>Зоны</Tab>
                        }
                    </TabList>

                    <TabPanel>
                        <div className="tabs_list-content">
                            <p>Камера отключена</p>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="tabs_list-content">
                            <p>Камера отключена</p>
                        </div>
                    </TabPanel>
                    {isHEAD(user.role) &&
                        <TabPanel>
                            <div className="tabs_list-content">
                                <p>Камера отключена</p>
                            </div>
                        </TabPanel>
                    }
                </Tabs>

            </div>
        )
    }

    return (
        <div className="camera_item">
            <div className="title">
                <p>Камера {thisCamera.name}</p>
            </div>

            <Tabs>
                <TabList>
                    {params.type === "optical" ?
                        <>
                            <Tab>Камера</Tab>
                            <Tab>Нейронная сеть</Tab>
                            {isHEAD(user.role) &&
                                <Tab>Зоны</Tab>
                            }
                        </>
                        :
                        <>
                            <Tab>ИК-поток</Tab>
                            <Tab>Оптический поток</Tab>
                        </>
                    }
                </TabList>

                <TabPanel>
                    <div className="tabs_list-content">
                        {params.type === "optical" ?
                            <SelectPlayer
                                hlsUrl={`/api/camera/stream/video/hls/${thisCamera.id}.m3u8`}
                                mjpegURL={`/api/camera/stream/video/mjpeg/${thisCamera.id}.jpeg`}
                            />
                            :
                            <VideoPlayerHls src={`/api/thermal-mediaserver/camera/stream/video/hls/inference-${thisCamera.id}.m3u8`}/>
                            //<SelectPlayer
                            //    hlsUrl={`/api/thermal-mediaserver/camera/stream/video/hls/thermal-${thisCamera.id}.m3u8`}
                                // mjpegURL={`/api/camera/stream/video/mjpeg/${thisCamera.id}.jpeg`}
                            ///>
                        }
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="tabs_list-content">
                        {params.type === "optical" ?
                            <SelectPlayer
                                hlsUrl={`/api/camera/stream/video/hls/inference-${thisCamera.id}.m3u8`}
                                mjpegURL={`/api/camera/stream/video/mjpeg/inference-${thisCamera.id}.jpeg`}
                            />
                            :
                            <VideoPlayerHls src={`/api/thermal-mediaserver/camera/stream/video/hls/${thisCamera.id}.m3u8`}/>
                        }
                    </div>
                </TabPanel>
                {isHEAD(user.role) &&
                    <TabPanel>
                        <div className="tabs_list-content">
                            <Zones objId={params.objId} camId={thisCamera.id}/>
                        </div>
                    </TabPanel>
                }
            </Tabs>

        </div>
    );
};

export default connect(
    state => ({
        user: state.user,
        camera: state.camera.current,
        thermalCamera: state.thermalCamera.current,
    }),
    dispatch => ({
        cameraLoad: (objId, cameraId) => {
            dispatch(cameraLoad(objId, cameraId));
        },
        thermalCameraLoad: (objId, cameraId) => {
            dispatch(thermalCameraLoad(objId, cameraId));
        },
    }),
)(Camera);
