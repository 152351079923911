import React from 'react';

const CupboardLayoutIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0938 9.28125L14.3438 7.03125L16.5938 9.28125L15.1875 9.28125L15.1875 14.3437L16.5938 14.3438L14.3438 16.5937L12.0938 14.3437L13.5 14.3437L13.5 9.28125L12.0938 9.28125Z" fill="#4C82B1"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.40625 7.03125L1.40625 16.5937L10.9688 16.5937L10.9688 7.03125L1.40625 7.03125ZM9.28125 8.71875L3.09375 8.71875L3.09375 14.9062L9.28125 14.9062L9.28125 8.71875Z" fill="#4C82B1"/>
        <path d="M1.40625 3.65625L3.65625 5.90625L3.65625 4.5L8.71875 4.5L8.71875 5.90625L10.9688 3.65625L8.71875 1.40625L8.71875 2.8125L3.65625 2.8125L3.65625 1.40625L1.40625 3.65625Z" fill="#4C82B1"/>
    </svg>
);

export default CupboardLayoutIcon;