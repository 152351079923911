import React from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {positionDefault, positionDefaultCupboard} from "../../components/PolygonEditor/helpers";
import Spinner from "../../components/Spinner";
import PolygonEditorCupboard from "../../components/PolygonEditorCupboard/PolygonEditorCupboard";

const required = value => value ? undefined : 'Required';

const selectOptions = [
  { value: '#00ff00', label: 'Green' },
  { value: '#ff0000', label: 'Red' },
  { value: '#0000ff', label: 'Blue' },
];

const zoneColor = { value: '#00ff00', label: 'Green' };
// const imgSize =  { width: 1070, height: 600 };

const isEmpty = val => val === null || !(Object.keys(val) || val).length;

const renderPolygonField =  (props) => {
  const { input, screenshot, color, initZones, zoneConfigName, isNewZone } = props;

  let zones = initZones;
  if (isNewZone === true) {
    zones = [{
      polygon: positionDefaultCupboard(screenshot.sizes),
      name: zoneConfigName,
      color: zoneColor,
    }];
  } else if (input.value) {
    zones = input.value;
  }

  return (
      <PolygonEditorCupboard
          zones={zones}
          zoneEditedCallback={(data) => {
            input.onChange(data)
          }}
          screenshot={screenshot}
          color={color}
          edit={true}
      />
  );
}

const ZoneEditFormCupboard  = (props) => {

  const { handleSubmit } = props;

  if (!props.initialValues.screenshot.img && !props.isPending) {
    return <p className="upload-title">Камера отключена</p>;
  }

  if (props.isPending) {
    return <Spinner/>;
  }

  console.log('props.initZones>>', props)

  return (
      <form onSubmit={handleSubmit} className='zoneeditform cupboard-wrapper'>
        <Field name="polygon"
               component={renderPolygonField}
               validate={[required,]}
               screenshot={props.initialValues.screenshot}
               zoneConfigName={props.initialValues.name}
               initZones={props.initZones}
               isNewZone={props.isNewZone}
               color={props.color}
        />
      </form>
  )
}

const mapStateToProps = (state) => {
  return {
    isPending: state.camera.isListPending && isEmpty(state.camera.current),
    initialValues: {
      name: 'Зона шкафа',
      screenshot: {
        img: state.camera.current.image,
        sizes: state.camera.current.image_size
      },
      color: zoneColor
    }
  }
}

export default connect(mapStateToProps)(reduxForm({ form: 'ZoneEditFormCupboard', enableReinitialize: true})(ZoneEditFormCupboard));
