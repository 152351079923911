import React from 'react';
import {connect} from 'react-redux';
import {ConnectedLink as Link} from 'react-router5';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBell,
    faBookOpen,
    faCalendarAlt,
    faCheckSquare,
    faCube,
    faExclamationTriangle,
    faGraduationCap, faGripHorizontal, faHardHat,
    faHome,
    faImage, faIndustry,
    faList,
    faParagraph,
    faPencilAlt,
    faPercent,
    faTh,
    faUser,
    faVideo,
    faCog
} from '@fortawesome/free-solid-svg-icons';
import {faSquare,} from '@fortawesome/free-regular-svg-icons';
import CupboardLayoutIcon from "../assets/img/icons/CupboardLayoutIcon";
import CupboardManagementIcon from "../assets/img/icons/CupboardManagementIcon";

import {ROUTES} from '../router/routes';
import {PAGES_AVAILABLE} from '../roles';

const tabs = {
    [ROUTES.CAMERAS]: {
        icon: faVideo, text: 'Видеопотоки камер'
    },
    [ROUTES.VIOLATIONS]: {
        icon: faExclamationTriangle, text: 'Реестр нарушений'
    },
    [ROUTES.EVENTS]: {
        icon: faList, text: 'Все детектированные события'
    },
    [ROUTES.REJECTED_EVENTS]: {
        icon: faList, text: 'Отклоненные события'
    },
    [ROUTES.FRAMES]: {
        icon: faImage, text: 'Изображения'
    },
    [ROUTES.NOTIFICATIONS]: {
        icon: faBell, text: 'Оповещения'
    },
    [ROUTES.NOTIFICATIONS]: {
        icon: faBell, text: 'Оповещения'
    },
    [ROUTES.REPORT_PERIOD]: {
        icon: faCalendarAlt, text: 'Нарушения за период'
    },
    [ROUTES.REPORT_CAMERA]: {
        icon: faVideo, text: 'Нарушения зафиксированные камерой'
    },
    [ROUTES.REPORT_SCORES]: {
        icon: faPercent, text: 'Нарушения с индексом вероятности'
    },
    [ROUTES.REPORT_CONFIRMED]: {
        icon: faUser, text: 'Нарушения подтвержденные оператором'
    },
    [ROUTES.REPORT_USER]: {
        icon: faUser, text: 'Доступы пользователей к камерам'
    },
    [ROUTES.SETS]: {
        icon: faGraduationCap, text: 'Список наборов тренировки'
    },
    [ROUTES.TENSORBOARD]: {
        icon: faBookOpen, text: 'Обучение нейронной сети'
    },
    [ROUTES.QUALITY]: {
        icon: faCheckSquare, text: 'Проверка и утверждение модели'
    },
    [ROUTES.SOURCES]: {
        icon: faList, text: 'Список источников разметки'
    },
    [ROUTES.ANNOTATION]: {
        icon: faPencilAlt, text: 'Разметка'
    },
    [ROUTES.DECISION_MATRIX]: {
        icon: faGripHorizontal, text: 'Матрица параметров детектирования'
    },
    [ROUTES.ROOM_WORK]: {
        icon: faGripHorizontal, text: 'Помещения + работы'
    },
    [ROUTES.WORK_PERMIT]: {
        icon: faList, text: 'Наряд допуск'
    },
    [ROUTES.SWITCHING_FORM]: {
        icon: faList, text: 'Бланк переключений'
    },
    [ROUTES.ZONES]: {
        icon: faSquare, text: 'Редактирование безопасных зон'
    },
    [ROUTES.CATEGORIES]: {
        icon: faParagraph, text: 'Редактирование категорий'
    },
    [ROUTES.MODELS]: {
        icon: faCube, text: 'Список моделей'
    },
    [ROUTES.ROOMS_MANAGEMENT]: {
        icon: faHome, text: 'Помещения'
    },
    [ROUTES.CAMERAS_MANAGEMENT]: {
        icon: faVideo, text: 'Камеры'
    },
    [ROUTES.THERMAL_CAMERAS_MANAGEMENT]: {
        icon: faVideo, text: 'Тепловизионные камеры'
    },
    [ROUTES.BLOCKS_MANAGEMENT]: {
        icon: faTh, text: 'Блоки'
    },
    [ROUTES.WORKWEAR]: {
        icon: faIndustry, text: 'Типы спецодежды'
    },
    [ROUTES.PPE]: {
        icon: faHardHat, text: 'Типы СИЗ'
    },
    [ROUTES.USERS_MANAGEMENT]: {
        icon: faUser, text: 'Управление пользователями'
    },
    [ROUTES.SUBDIVISION_MANAGEMENT]: {
        icon: faUser, text: 'Управление подразделениями'
    },
    [ROUTES.EMPLOYEES_MANAGEMENT]: {
        icon: faUser, text: 'Управление работниками'
    },
    [ROUTES.CLEAN_SETTINGS]: {
        icon: faCog, text: 'Настройки'
    },
    [ROUTES.LOGS]: {
        icon: faUser, text: 'Лог системы'
    },
    [ROUTES.MONITORING_SYSTEM]: {
        icon: faCog, text: 'Мониторинг системы'
    },
    [ROUTES.MONITORING_SERVER]: {
        icon: faCog, text: 'Мониторинг сервера'
    },
    [ROUTES.EMPLOYEES]: {
        icon: faUser, text: 'Детектированные лица'
    },
    [ROUTES.CUPBOARD_OUR_MANAGEMENT]: {
        customIcon: <CupboardManagementIcon/>, text: 'Шкафы в системе'
    },
    [ROUTES.CUPBOARD_NOT_OUR_MANAGEMENT]: {
        customIcon: <CupboardManagementIcon/>, text: 'Шкафы на станции'
    },
    [ROUTES.CUPBOARD_LAYOUT]: {
        customIcon: <CupboardLayoutIcon/>, text: 'Разметка шкафов'
    },
    [ROUTES.ROOM_NOT_OUR]: {
        icon: faHome, text: 'Помещения в системе'
    },
    [ROUTES.ROOM_NOT_OUR_MANAGEMENT]: {
        icon: faHome, text: 'Помещения на станции'
    },
    [ROUTES.STATISTIC]: {
        icon: faList, text: 'Статистика событий'
    },
    [ROUTES.VIDEO_RECORDER_MANAGEMENT]: {
        icon: faVideo, text: 'Видеорегистраторы'
    },
    [ROUTES.VIDEO_RECORDER_USERS_LIST]: {
        icon: faUser, text: 'Журнал выдачи видеорегистраторов'
    },
    [ROUTES.VIDEO_RECORDER_UP_VIDEO]: {
        icon: faVideo, text: 'Загрузка видеофайлов с регистратора'
    },
    [ROUTES.NOTIFICATION_DEVICES]: {
        icon: faBell, text: 'Устройства оповещения'
    },
    [ROUTES.NOTIFICATION_DEVICES_MAPPING]: {
        icon: faBell, text: 'Привязка устройств оповещения'
    },
};

const NavigationTabs = ({user}) => {
    const userPages = PAGES_AVAILABLE[user.role] || [];
    return (
        <div className="small-24">
            <div className="urls-wrapper">
                {userPages.map((route) => tabs[route] ?
                    <Link routeName={route} key={route}>
                        {tabs[route].icon ?
                            <FontAwesomeIcon icon={tabs[route].icon}/>
                            :
                            tabs[route].customIcon
                        }
                        <p>{tabs[route].text}</p>
                    </Link>
                    : null
                )}
            </div>
        </div>
    );
}

export default connect(
    state => ({
        user: state.user,
    })
)(NavigationTabs);
