import axios from 'axios';

const WORKWEAR = {
    LIST: 'WORKWEAR_LIST',
    LIST_PENDING: 'WORKWEAR_PENDING',
    CLEAR: 'WORKWEAR_CLEAR',
    ERROR: 'WORKWEAR_ERROR',
}

const initialState = {};

const WorkwearReducer = (state = initialState, action) => {
    switch (action.type) {
        case WORKWEAR.LIST:
            return {
                list: action.payload.persons_categories
            };
        case WORKWEAR.LIST_PENDING:
            return {
                isPending: true
            };
        case WORKWEAR.CLEAR:
            return initialState;
        case WORKWEAR.ERROR:
            return {
                isError: true
            }
        default:
            return state;
    }
}

export default WorkwearReducer;

export const loadWorkwear = () => dispatch => {
    dispatch({
        type: WORKWEAR.LIST_PENDING,
        payload: null,
    });
      axios.get('/api/kola/persons/types/')
        .then(res => {
            dispatch({
                type: WORKWEAR.LIST,
                payload: res.data
            });
        })
        .catch(e => {
            dispatch({
                type: WORKWEAR.ERROR,
                payload: null
            })
        })
}

export const clearWorkwear = () => dispatch => {
    dispatch({
        type: WORKWEAR.CLEAR,
        payload: null,
    });
};
