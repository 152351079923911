import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {RouterProvider} from 'react-router5'
import {Provider} from 'react-redux';

import configureStore from './store/configureStore';
import createRouter from './router/createRouter';

import App from './App';

import './index.css';

const router = createRouter();
window.router = router;
const store = configureStore();

router.start(() => {
    ReactDOM.render(
        <Provider store={store}>
            <RouterProvider router={router}>
                <App/>
            </RouterProvider>
        </Provider>,
        document.getElementById('root')
    )
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
