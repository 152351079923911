import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {
    videoRecorderUserList,
    videoRecorderUserClearState,
    videoRecorderUserCurrentClearState
} from '../store/VideoRecorderUsers';
import {loadObjectsObservationList, objectObservationClearState} from '../store/ObjectObservation';
import {apiDataToCameras, apiDataToVideoRecorderUser} from '../utils/utils';
import {nbsp} from '../utils/string';
import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import withDataErrorIndication from '../components/withDataErrorIndication';
import VideoRecorderUsersModal from '../components/VideoRecorderUsersModal';
import {clearEmploeesState, loadEmploeesList} from "../store/Employees";
import {userFio} from "../utils/userUtils";
import {
    videoRecorderClearState, videoRecorderCurrentClearState,
    videoRecorderList, videoRecorderLoad
} from '../store/VideoRecorder';

const VideoRecorderUsers = ({actions, videoRecorderUserList, videoRecorderList, objects = [], isListPending, employees}) => {
    const {loadAllCameras, updateCamera, deleteCamera, loadObjects, loadCamera, clearCamera, loadEmployeesList} = actions;

    const loadData = () => {
        loadAllCameras();
        loadObjects();
        loadEmployeesList();
    }

    const [shouldShowModal, setShowModal] = useState(false);
    const [modalHandout, setShowModalHandout] = useState(false);
    const [modalReturn, setShowModalReturn] = useState(false);
    const [activeDevice, setActiveDevice] = useState({value: 0, label: ''});


    useEffect(() => {
        loadData();
    }, [])

    const Columns = (cellCancelHandler) => [{
        //     text: '№',
        //     dataField: 'id',
        //     sort: true,
        //     headerStyle: () => ({width: '59px'}),
        //     editable: false,
        // }, {
        text: 'Дата и время выдачи',
        dataField: 'handing_out_ts',
        editable: false,
        headerStyle: () => ({width: '200px'}),
        formatter: (cell) => cell ? new Date(cell * 1000).toLocaleString() : '',
        required: true,
    }, {
        text: 'Дата и время возврата',
        dataField: 'return_ts',
        editable: false,
        headerStyle: () => ({width: '200px'}),
        formatter: (cell) => cell ? new Date(cell * 1000).toLocaleString() : '',
        required: true,
    }, {
        text: 'Видеорегистратор',
        dataField: 'dashcam',
        sort: true,
        editable: false,
        formatter: (cell) => cell ? cell.number : '',
        required: true,
    }, {
        text: 'Работник',
        dataField: 'person',
        sort: true,
        editable: false,
        formatter: (cell) => cell ? userFio(cell) : '',
        required: true,
    },
        , {
            text: 'Статус',
            dataField: 'handed_out',
            sort: true,
            editable: false,
            formatter: (cell) => cell ? 'Выдан' : 'Не выдан',
            type: 'bool',
            headerStyle: () => ({width: '100px'}),
        }
    ];

    document.title = 'Журнал выдачи видеорегистраторов';

    return (
        <>
            <GeneralObjectsTable
                isPending={isListPending}
                videoRecorder={true}
                objects={apiDataToVideoRecorderUser(videoRecorderUserList)}
                isEditable={false}
                actions={{
                    update: updateCamera,
                    // create: createCamera,
                    create: () => {
                        setShowModal(true);
                    },
                    purge: deleteCamera,
                    reload: loadData,
                    handout: () => {
                        setShowModalHandout(true);
                    },
                    returnItem: () => {
                        setShowModalReturn(true);
                    },
                }}
                getColumns={Columns}
                itemDummy={{
                    id: 0,
                    code: 0,
                    obj: '',
                    name: '',
                    protocol: '',
                    host: '',
                    suffix: '',
                    credentials: [],
                    is_enabled: true,
                }}
                rowEvents={{
                    onDoubleClick: (e, row, rowIndex) => {
                        setActiveDevice({
                            value: row.dashcam.id,
                            label: row.dashcam.number
                        })
                        setShowModalReturn(true);
                    }
                }}
            />
            {modalHandout &&
            <VideoRecorderUsersModal
                objectsList={objects}
                employees={employees}
                videoRecorderList={videoRecorderList}
                modalHandout={true}
                activeDevice={activeDevice}
                onCancel={() => {
                    setShowModalHandout(false);
                    clearCamera();
                }}
                onClose={() => {
                    setShowModalHandout(false);
                    clearCamera();
                }}
            />
            }
            {modalReturn &&
            <VideoRecorderUsersModal
                objectsList={objects}
                employees={employees}
                modalHandout={false}
                videoRecorderList={videoRecorderList}
                activeDevice={activeDevice}
                onCancel={() => {
                    setShowModalReturn(false);
                    setActiveDevice({
                        value: 0,
                        label: '',
                    });
                    clearCamera();
                }}
                onClose={() => {
                    setShowModalReturn(false);
                    setActiveDevice({
                        value: 0,
                        label: '',
                    });
                    clearCamera();
                }}
            />
            }
        </>
    )
}

export default connect(
    state => ({
        user: state.user,
        videoRecorderList: state.videoRecorder.list,
        videoRecorderUserList: state.videoRecorderUser.list,
        employees: state.employees.list,
        isListPending: state.videoRecorderUser.isListPending || state.videoRecorder.isListPending || state.object.isListPending || state.employees.isListPending,
        objects: state.object.list,
        isError: state.videoRecorderUser.isListError,
        videoRecorder: state.videoRecorder.current,
    }),
    dispatch => ({
        actions: {
            loadAllCameras: () => {
                dispatch(videoRecorderUserClearState());
                dispatch(videoRecorderUserList());
                dispatch(videoRecorderClearState());
                dispatch(videoRecorderList());
            },
            loadObjects: () => {
                dispatch(objectObservationClearState());
                dispatch(loadObjectsObservationList());
            },
            clearCamera: () => {
                dispatch(videoRecorderUserCurrentClearState());
            },
            loadEmployeesList: () => {
                dispatch(clearEmploeesState());
                dispatch(loadEmploeesList());
            },
        },
    }),
)(withDataErrorIndication(VideoRecorderUsers));
