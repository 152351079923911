import axios from 'axios';
import imageUtils from "../utils/imageUtils";
import {clearEmploeesState, loadEmploeesList} from "./Employees";

const VIDEO_RECORDER_UP = {
    LIST:       'VIDEO_RECORDER_UP_USER_LIST',
    LIST_PENDING: 'VIDEO_RECORDER_UP_LIST_PENDING',
    LIST_ERROR: 'VIDEO_RECORDER_UP_LIST_ERROR',
    DETAIL:     'VIDEO_RECORDER_UP_DETAIL',
    CREATE:     'VIDEO_RECORDER_UP_CREATE',
    EDIT:       'VIDEO_RECORDER_UP_EDIT',
    DELETE:     'VIDEO_RECORDER_UP_DELETE',
    CLEAR:      'VIDEO_RECORDER_UP_CLEAR',
    CURRENT_CLEAR:      'VIDEO_RECORDER_UP_CURRENT_CLEAR',
};

const initialState = {
    current: {},
};

const videoRecorderUpReducer = (state = initialState, action) => {
    switch(action.type) {
        case VIDEO_RECORDER_UP.LIST:
            return {
                ...state,
                list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case VIDEO_RECORDER_UP.DETAIL:
        case VIDEO_RECORDER_UP.CREATE:
        case VIDEO_RECORDER_UP.EDIT:
        case VIDEO_RECORDER_UP.DELETE:
        case VIDEO_RECORDER_UP.ALL_INFO:
            return {
                ...state,
                current: action.payload,
                isListPending: false,
            };
        case VIDEO_RECORDER_UP.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case VIDEO_RECORDER_UP.LIST_ERROR:
            return {
                isListError: true,
            }
        case VIDEO_RECORDER_UP.CLEAR:
            return initialState;
        case VIDEO_RECORDER_UP.CURRENT_CLEAR:
            return {
                ...state,
                current: []
            };
        default:
            return state;
    }
};

export default videoRecorderUpReducer;

export const videoRecorderUpList = () => dispatch => {
    dispatch({
        type: VIDEO_RECORDER_UP.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/kola-dashcam/videos/')
        .then(res => {
            dispatch({
                type: VIDEO_RECORDER_UP.LIST,
                payload: res.data
            });
        })
};

const dataURLtoFile = (dataurl, filename) => { 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}


export const videoRecorderUpVideo = (dataArr, personId, dashcam_id) => dispatch => {
    dispatch({
        type: VIDEO_RECORDER_UP.LIST_PENDING,
        payload: null,
    });

    const form = new FormData();
    form.append('user_uploaded_id', personId);
    form.append('dashcam_id', dashcam_id);
    form.append('rec_start_ts', 0);
    form.append('files', dataURLtoFile(dataArr.base64, dataArr.name));
    axios.post(`/api/statistics/kola-dashcam/videos/`, form, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    },)
        .then(res => {
            dispatch({
                type: VIDEO_RECORDER_UP.EDIT,
                payload: {isReloadList: false},
            });
            dispatch(videoRecorderUpClearState());
            dispatch(videoRecorderUpList());
        })
        .catch(err => {
            dispatch({
                type: VIDEO_RECORDER_UP.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

export const videoRecorderUpClearState = () => dispatch => {
    dispatch({
        type: VIDEO_RECORDER_UP.CLEAR,
        payload: null,
    });
};

export const videoRecorderUpCurrentClearState = () => dispatch => {
    dispatch({
        type: VIDEO_RECORDER_UP.CURRENT_CLEAR,
        payload: null,
    });
};
