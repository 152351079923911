import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Field, getFormValues, reduxForm, change} from 'redux-form';

import Legend from './Legend';
import Tree from 'rc-tree';
import {ActiveAccessesObjectsToKeys, CameraAndObjectsWithoutCatToApiData} from "../utils/utils";
import Multiselect from "react-widgets/Multiselect";

const UserAccessesForm = ({handleSubmit, formValues, cameras, thermalCamera, dirty, reset, submitting, isEditMode, objects, userAccesses}) => {
    const onCheck = (checkedKeys) => {
        formValues["accecess"] = checkedKeys;
    };
    const data = CameraAndObjectsWithoutCatToApiData(objects, cameras);

    const defaultChecked = ActiveAccessesObjectsToKeys(userAccesses);

    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode} >
                <div className={isEditMode ? '' : 'disabled-fieldset'}>
                    <div className="">
                        <Legend title="Существующие доступы" className="long-title">
                            <Field name="accecess" component={Multiselect} type="hidden" style={{ display: "none" }}/>
                            <Tree
                                className="myCls2"
                                defaultExpandAll
                                checkable
                                height={526}
                                onCheck={onCheck}
                                treeData={data}
                                defaultCheckedKeys={defaultChecked}
                            />
                        </Legend>
                    </div>
                </div>
            </fieldset>
        </form>
    );
};


const mapStateToProps = (state) => {
    return {
        initialValues: {
            accecess: ActiveAccessesObjectsToKeys(state.users.current?.accessesList) ||[]
        },
        cameras: state.camera.list || [],
        thermalCamera: state.thermalCamera.list || [],
        objects: state.object.list,
        userAccesses: state.users.current?.accessesList,
        formValues: getFormValues('UserAccessesForm')(state) || {},
        isSaving: false, //state.settings.isSaving,
    }
};

export default connect(mapStateToProps)(
    reduxForm({
        form: 'UserAccessesForm',
        enableReinitialize: true
    })(UserAccessesForm)
)
