const timestamp = date => new Date(date).getTime() / 1000;

export const eventReportQueryString = (query) => {
    let url = '';
    const [dateBegin, dateEnd] = dateSetNewTime(query);
    url += `&date_begin=${timestamp(dateBegin.toISOString())}&date_end=${timestamp(dateEnd.toISOString())}&confirmation_status=`;
    if (query.hasViolation !== undefined) {
        url += query.hasViolation;
    }
    if (query.object_id) {
        url += `&object_observation_id=${query.object_id}`;
    }
    if (query.power_unit) {
        url += `&power_unit_id=${query.power_unit}`;
    }
    if (query.cameraId) {
        url += `&camera_id=${query.cameraId}`;
    }
    if (query.zoneId) {
        url += `&zone_id=${query.zoneId}`;
    }
    if (query.secondaryCategory) {
        url += `&secondary_category_id=${query.secondaryCategory}`;
    }
    if (query.withFaces) {
        url += `&with_faces=${query.withFaces}`;
    }
    if (query.withRecognizedFaces) {
        url += `&with_recognized_faces=${query.withRecognizedFaces}`;
    }
    return url;
};

export const rejectedEventReportQueryString = (query) => {
    let url = '';
    const [dateBegin, dateEnd] = dateSetNewTime(query);
    url += `&date_begin=${timestamp(dateBegin.toISOString())}&date_end=${timestamp(dateEnd.toISOString())}&confirmation_status=`;
    if (query.hasViolation !== undefined) {
        url += query.hasViolation;
    }
    if (query.object_id) {
        url += `&object_observation_id=${query.object_id}`;
    }
    if (query.power_unit) {
        url += `&power_unit_id=${query.power_unit}`;
    }
    if (query.cameraId) {
        url += `&camera_id=${query.cameraId}`;
    }
    if (query.zoneId) {
        url += `&zone_id=${query.zoneId}`;
    }
    if (query.secondaryCategory) {
        url += `&secondary_category_id=${query.secondaryCategory}`;
    }
    if (query.withFaces) {
        url += `&with_faces=${query.withFaces}`;
    }
    if (query.withRecognizedFaces) {
        url += `&with_recognized_faces=${query.withRecognizedFaces}`;
    }
    return url;
};

export const eventNotificationQueryString = (query, dateBegin, dateEnd) => {
    let url = '';
    const [dateBeginNew, dateEndNew] = dateSetNewTime(query);
    url += `&date_begin=${timestamp(dateBeginNew.toISOString())}&date_end=${timestamp(dateEndNew.toISOString())}`;
    if (query.hasViolation !== undefined) {
        url += query.hasViolation;
    }
    if (query.object_id) {
        url += `&object_observation_id=${query.object_id}`;
    }
    if (query.power_unit) {
        url += `&power_unit_id=${query.power_unit}`;
    }
    if (query.cameraId) {
        url += `&camera_id=${query.cameraId}`;
    }
    if (query.withFaces) {
        url += `&with_faces=${query.withFaces}`;
    }
    if (query.surname) {
        url += `&surname=${query.surname}`;
    }
    return url;
};

export const statisticReportQueryString = (query) => {
    let url = '';
    const [dateBegin, dateEnd] = dateSetNewTime(query);
    url += `?date_begin=${timestamp(dateBegin.toISOString())}&date_end=${timestamp(dateEnd.toISOString())}&group_by=category`;
    if (query.withRecognizedFaces) {
        url += `&with_recognized_faces=${query.withRecognizedFaces}`;
    }
    return url;
};

const dateSetNewTime = (query) => {
    let dateBegin = query.dateBegin;
    dateBegin.setHours(0);
    dateBegin.setMinutes(0);
    dateBegin.setSeconds(1);
    let dateEnd = query.dateEnd;
    dateEnd.setHours(23);
    dateEnd.setMinutes(59);
    dateEnd.setSeconds(59);
    return [dateBegin, dateEnd]
};

export const base64Pixel = 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==';

export const nbsp = '\u00A0';
