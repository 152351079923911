import React from 'react';

const Footer = () => (
    <footer>
        <div className="row expanded align-middle">
            <div className="small-24">
                <p>Copyright @ 2022</p>
            </div>
        </div>
    </footer>
);

export default Footer;
