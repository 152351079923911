import React from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup, RadioField} from '../inputs';
import {splitCameraUri, isBlock} from "../utils/utils";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";
import TabsWithContent from "../components/TabsWithContent";

const required = value => value ? undefined : 'Обязательное поле';

const ReduxFormProtocolSelect = ({input}) => {
    const isMulti = false;
    const options = protocolSelectOptions(['rtsp://', 'file:///', 'https://', 'http://']);
    const placeholder = 'Выберите протокол';
    return ReduxFormSelectValid(isMulti, options, placeholder)({input});
};

const protocolSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry,
            label: entry
        }
    })
};

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.name ? entry.name : entry.ru_name
        }
    })
};

const protocolSelectActiveOptions = (protocol) => {
    return {
        value: protocol,
        label: protocol
    }
};

const objectsSelectActiveOptions = (objects, obj_id) => objects.map(object => {
    const activeObj = objects.find(item => item.id === obj_id);
    return {
        value: activeObj.id,
        label: activeObj.name
    }
});

const CameraFormWithTabs = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, userId, isEditMode, objectsList, ppes, modPpes}) => {
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';
    dispatch(change('UserForm', 'id', userId)); // скрытое поле в форме с id юзера

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = objSelectOptions(isBlock(objectsList));
        const placeholder = 'Выберите помещение';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    return (
        <>
            <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
                <TabsWithContent
                    titles={['Данные', 'Детекторы']}
                    tabs={[
                        <>
                            <fieldset disabled={!isEditMode}>
                                <Legend title="Основные данные">
                                    <Field name="name" type="text" label="Название"
                                           component={InputField} className={className('name')}
                                           validate={[required]}/>
                                    <InputGroup title="Помещение" >
                                        <Field
                                            name="object"
                                            className={className('object')}
                                            component={ReduxFormObjectsSelect}
                                            validate={[required]}
                                        />
                                    </InputGroup>
                                </Legend>
                                <Legend title="Параметры" >
                                    <InputGroup title="Протокол">
                                        <Field
                                            name="protocol"
                                            className={className('protocol')}
                                            component={ReduxFormProtocolSelect}
                                            validate={[required]}
                                        />
                                    </InputGroup>
                                    <Field name="host" type="text" label="Хост"
                                           component={InputField} className={className('host')}
                                           validate={[required]}/>
                                    <Field name="suffix" type="text" label="Суффикс"
                                           component={InputField} className={className('suffix')}
                                           validate={[required]}/>
                                    <Field
                                        name="is_enabled"
                                        component={EnableCheckbox}
                                        labelText="Вкл"
                                    />
                                </Legend>
                            </fieldset>
                        </>
                    ,
                    <>
                        <fieldset disabled={!isEditMode}>
                            <Legend title="События">
                                <div className="overflow-div">
                                    {modPpes.map((ppe,i) => (
                                        <div key={i} className="viol-types-detect">
                                            <label>{ppe.label}</label>
                                            <div className="viol-types-detect-answers">
                                                <Field name={`${ppe.name}`} id={`${ppe.name}`} component={RadioField} custom="11111" value={ppe.value} initialValues={ppe.value}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Legend>
                        </fieldset>
                    </>
                    ]}
                />
            </form>
        </>
    );
};

const mapStateToProps = (state) => {
    if (!state.camera.current.id) {
        return {};
    }
    return {
        initialValues: {
            ...state.camera.current,
            ...splitCameraUri(state.camera.current.media_uri),
            protocol: protocolSelectActiveOptions(splitCameraUri(state.camera.current.media_uri).protocol),
            object: objectsSelectActiveOptions(isBlock(state.object.list ? state.object.list : []), state.camera.current.obj_id),
        },
        cameraId: state.camera.current.id,
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'CameraFormWithTabs',
        enableReinitialize: true,
    })(CameraFormWithTabs)
)
