export const employeesReportQueryString = (query) => {
    let url = '';
    if (query?.personnelNumber) {
        url += `&personnel_number=${query.personnelNumber}`;
    }
    if (query?.surname) {
        url += `&surname=${query.surname}`;
    }
    if (query?.subdivisionName) {
        url += `&subdivision_name=${query.subdivisionName}`;
    }
    if (query?.position) {
        url += `&position=${query.position}`;
    }
    return url;
};
