import {change, Field, reduxForm} from "redux-form";
import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import Legend from './Legend';
import {InputField, InputGroup} from "../inputs";
import Attach from "./FileInput/Attach";
import close from "./FileInput/close.png";
import { parseFileName } from "../components/WorkwearModal";

const required = value => value ? undefined : 'Обязательное поле';

const AttachmentForm = ({ attachmentData, removeAttached }) => {
  const [attachments, setAttachments] = useState([]);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if(attachmentData.file_uri) {
      setAttachments([{ name: parseFileName(attachmentData.file_uri) }]);
    }
  }, [attachmentData])

  const remove = () => {
    setAttachments([]);
    removeAttached('');
  }

  return (
    <>
      {
        attachments.length !== 0 &&
          attachments.map((attr, index) => {
            return (
              <div key={'fileattached' + index} className="attach-wrapper">
                <p className="attach-attached">{ attr.name }</p>
                <img src={close} className="attach-attached--remove" onClick={remove} alt="" />
              </div>)
          })

      }
      <Field isOnlyFileAdd={true} component={Attach} href={undefined} className="eventInput eventUserComment"
             onError={(error) => {
               setErrors(error);
             }}
             name="attached"
             type="file"
             fileReady={attachments}
             onChange={(fileData) => {
               if (fileData && fileData.base64) {
                 setErrors(null);
                 const data = [...attachments, {...fileData}];
                 setAttachments(data);
               }
             }}
      />
      {errors ? <>
        <div className="attach-attached--invalid">{ errors }</div>
      </> : '' }
    </>
  );
}

let WorkwearForm = ({handleSubmit, isEditMode, dispatch, selected = {}}) => {
  const [currentDoc, setCurrentDoc] = useState({ name: '' });
  useEffect(() => {
    setCurrentDoc(selected);
    if(selected.file_uri) {
      dispatch(change('WorkwearForm', 'attached', { name: parseFileName(selected.file_uri) }));
    }
  }, [selected]);
  const removeAttached = () => {
    dispatch(change('WorkwearForm', 'attached', ''));
  }
  return (
    <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
      <fieldset className="workwear-wrapper" disabled={!isEditMode}>
        <Legend title="Основные данные">
          <div className="form-input">
            <div className="title-group">
              <p>Нарушение</p>
            </div>
            <div className="input">
              <div className="workwear-wrapper_docname">
                <p>{ currentDoc.ru_name }</p>
              </div>
            </div>
          </div>
          <Field name="description" type="text" label="Расшифровка нарушения" component={InputField} validate={[required]}/>
          <Field name="article" type="text" label="Статья ТБ и ПБ" component={InputField} validate={[required]}/>
          <InputGroup title="Документ">
            <Field name="file" component={AttachmentForm}
                   attachmentData={currentDoc}
                   removeAttached={removeAttached}
            />
          </InputGroup>
        </Legend>
      </fieldset>
    </form>
  )
}

const mapStateToProps = (state) => {
  if (!state.regulatoryDocsPrimary.current) {
    return {};
  }
  return {
    initialValues: {
      description: state.regulatoryDocsPrimary.current?.description,
      article: state.regulatoryDocsPrimary.current?.article
    }
  }
};

export default connect(mapStateToProps)(reduxForm({
  form: 'WorkwearForm',
  enableReinitialize: true,
})(WorkwearForm));
