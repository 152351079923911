import axios from 'axios';

import {statisticReportQueryString} from '../utils/string';

const STATISTIC = {
    LIST: 'STATISTIC_LIST',
    CLEAR: 'STATISTIC_CLEAR',
    PENDING: 'STATISTIC_PENDING',
    ERROR: 'STATISTIC_ERROR',
};

const initialState = {};

const statisticReducer = (state = initialState, action) => {
    switch (action.type) {
        case STATISTIC.PENDING:
            return {
                ...state,
                isPending: true,
            };
        case STATISTIC.LIST:
            return {
                list: action.payload,
            };
        case STATISTIC.CLEAR:
            return initialState;
        case STATISTIC.ERROR:
            return {
                isError: true,
            }
        default:
            return state;
    }
};

export default statisticReducer;

const setTimezone = (date) => {
    date.setTime(date.getTime());
    return date;
};

/* Загрузка списка статистики */
export const loadStatisticReport = (query) => dispatch => {
    let url = `/api/statistics/event-storage/stats/`;
    const dateBegin = setTimezone(query.dateBegin);
    const dateEnd = setTimezone(query.dateEnd);
    url += statisticReportQueryString(query, dateBegin, dateEnd);
    dispatch({
        type: STATISTIC.PENDING,
        payload: null,
    });
    axios.get(url)
        .then(res => {
            window.scrollTo(0, 0); // мотаю страницу на верх
            dispatch({
                type: STATISTIC.LIST,
                payload: [
                    ...res.data,
                ],
            });
        })
        .catch(e => {
            dispatch({
                type: STATISTIC.ERROR,
                payload: null
            })
        })
};

export const clearStatisticReport = () => dispatch => {
    dispatch({
        type: STATISTIC.CLEAR,
        payload: null,
    });
};
