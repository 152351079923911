import React from 'react';

const Zones = () => {

  document.title = 'Редактирование безопасных зон';

  return (
    <div>
      Zone editor goes here
    </div>
  )
}

export default Zones;
