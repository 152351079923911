import React, {useState} from 'react';

const TabsWithContent = ({tabs=[], titles=[]}) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <>
            <div className="small-24">
                <div className="urls-wrapper modal-urls">
                    {tabs.map((tab, index) => (
                        <a onClick={() => setActiveTab(index)} key={index}
                            className={`${activeTab === index ? 'active' : ''}`}>
                            <p>{titles[index]}</p>
                        </a>
                    ))}
                </div>
            </div>
            {tabs.map((tab, index) => (
                // Чтобы формы на вкладках не уничтожались и данные в них не терялись,
                // неактивные вкладки просто скрываем
                <div key={index} style={{
                    display: index === activeTab ? 'block' : 'none'
                }}>{tabs[index]}</div>
            ))}
        </>
    )
};

export default TabsWithContent;
