import React, {useState} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {faRedo, faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {DEFAULT_INTERVAL_HOURS} from '../utils/constants';
import {getDateNow} from '../utils/time';
import {DatePickerInput, objectsListToOptions, selectOptions} from '../utils/inputs';
import {omitRooms, onlyRooms, roomsInObject} from '../utils/utils';

const EmploeeFilter = (props) => {
    const {objects, handleSubmit, pristine, reset, submitting} = props;

    const objectOptions = objectsListToOptions(omitRooms(objects));
    const [objectId, setObjectId] = useState(null);

    const hasViolationOptions = {
        'CONFIRMED': 'Нарушение',
        'NO_STATUS': 'Не проверено',
        'DENIED': 'Не нарушение',
    };

    const withRecognizedFacesOptions = {
        '0': 'Все лица',
        '1': 'Лица идентифицированы',
        '-1': 'Лица не идентифицированы',
    };

    return (
        <form onSubmit={handleSubmit} className="images_header">
            <div className="images_header_item images_clean">
                <button onClick={reset}>
                    <FontAwesomeIcon icon={faRedo} />
                    Очистить
                </button>
            </div>
            <div className="images_header_item images_filter">
                <p>Фильтрация</p>
                <Field name="power_unit" component="select"
                       onChange={(e) => {
                           setObjectId(parseInt(e.target.value));
                       }}>
                    <option value="">Все блоки</option>
                    {selectOptions(objectOptions)}
                </Field>
            </div>
            <div className="images_header_item images_room">
                <Field name="room" component="select">
                    <option value="">Все помещения</option>
                    {selectOptions(objectsListToOptions(
                        objectId ? roomsInObject(objectId, onlyRooms(objects)) : onlyRooms(objects)
                    ))}
                </Field>
            </div>
            <div className="images_header_item images_dates">
                <Field name="from" component={DatePickerInput} placeholder="с"/>
            </div>
            <div className="images_header_item images_dates">
                <Field name="to" component={DatePickerInput} placeholder="по"/>
            </div>
            <div className="images_header_item images_violation">
                <Field name="confirmation_status" component="select">
                    <option value="">Все</option>
                    {selectOptions(hasViolationOptions)}
                </Field>
            </div>
            <div className="images_header_item images_violation">
                <Field name="with_recognized_faces" component="select">
                    {selectOptions(withRecognizedFacesOptions)}
                </Field>
            </div>
            <div className="images_header_item images_search">
                <button type="submit">
                    <FontAwesomeIcon icon={faSearch} />
                    Искать
                </button>
            </div>
        </form>
    );
}

const mapStateToProps = (state) => ({
    objects: state.object.list,
    initialValues: {
        from: getDateNow(-DEFAULT_INTERVAL_HOURS),
        to: getDateNow(),
    }
})

export default connect(mapStateToProps)(
    reduxForm({form: 'EmploeeFilter', enableReinitialize: false})(EmploeeFilter)
);
