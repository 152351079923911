import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {loadModelStorage} from '../store/ModelStorage';
import {loadObjectObservation} from '../store/ObjectObservation';
import {
    cleanZone,
    cleanZoneList,
    loadZoneById,
    loadZoneList,
    setZone,
    zoneCreate,
    zoneDelete,
    zoneEdit
} from '../store/Zone';
import {cameraCurrentClearState, cameraLoad} from '../store/Camera';

import ZoneEditForm from "../forms/ZoneEditForm/ZoneEditForm";
import Spinner from './Spinner';
import ZoneView from "./ZoneView";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import ZoneEditFormCupboard from "../forms/ZoneEditForm/ZoneEditFormCupboard";
import {polygon} from "leaflet/dist/leaflet-src.esm";

const ZonesCupboard = (props) => {
    const {
        objId, camId, camera, zones, model,
        loadModelStorage, loadCamera,
        loadZoneList,
        saveZone,
        cleanZone,
        deleteZone,
        setZoneToState,
        createZone,
        onSave,
        cupboardLayout
    } = props;

    const [isEditMode, setEditMode] = useState(false);
    const [isNewZone, setIsNewZone] = useState(true);
    const [selectedZoneId, setSelectedZoneId] = useState('');
    const [selectedZone, setSelectedZone] = useState(null);
    const [objectZones, setObjectZones] = useState(null);
    const [shouldShowModal, setShowModal] = useState(false);
    // const [bigEditorWidth, setBigEditorWidth] = useState(0);

    useEffect(() => {
        // reinitZones();
        loadModelStorage();
    }, []);

    useEffect(() => {
        setObjectZones(zones);
    }, [zones]);

    useEffect(() => {
        loadCamera(objId, camId);
        reinitZones();
    }, [objId, camId]);

    // const bigEditorWidth = cupboardLayout.camera.image.width;
    // const smallEditorWidth = 890;

    // let kt = +(bigEditorWidth / smallEditorWidth).toFixed(3);
    let kt = 1;

    const selectNewZone = (zone) => {
        if (!zone) {
            setSelectedZoneId('');
            return;
        }
        setIsNewZone(true);
        setZoneToState(zone ? model.config_zones.find(val => val.zone_id_cfg === zone) : null);
        setSelectedZoneId(zone);
        setEditMode(true);
    }

    const selectZone = (zone) => {
        setIsNewZone(false);
        setZoneToState(zone);
        setSelectedZone(zone);
        setEditMode(true);
    }

    const handleSave = async (values) => {
        const withNewCoord = values.polygon[0].polygon.map(zone => {
            // console.log("kt", kt);
            return zone.map(coord => {
                // console.log("kt2", coord[0]);
                // console.log("kt3", coord[0] * kt);
                return [coord[0] * kt, coord[1] * kt]
            })
        })

        const data = {
            polygon: withNewCoord,
            color_hex: values.color,
            zid: isNewZone ? selectedZoneId : selectedZone.zid,
            is_active: true,
            power_unit_id: objId,
        };

        if (isNewZone) {
            // await createZone(objId, camId, data);
            // reinitZones();
            onSave(data);
        } else {
            // await saveZone(objId, camId, selectedZone.id, data)
            // reinitZones();
            onSave(data);
        }

    }

    const removeZone = async () => {
        await deleteZone(objId, camId, selectedZone.id);
        reinitZones();
    }

    const handleCancel = () => {
        setSelectedZoneId('');
        setEditMode(false);
    }

    const reinitZones = async () => {
        setEditMode(true);
        setShowModal(false);
        cleanZone();
        setSelectedZoneId('');
        if (zones) {
            selectZone(setNewCoord(zones));
            setObjectZones(zones);
            setIsNewZone(false)
        } else {
            setIsNewZone(true)
        }
        await loadZoneList(objId, camId);
    };

    const setNewCoord = (zones) => {
        // const kt = (+ (bigEditorWidth / smallEditorWidth).toFixed(3) ) * 3;
        const newArr = [];
        const withNewCoord = zones.map(zone => {
            return zone.map(coord => {
                // console.log("1kt1", kt);
                // console.log("1kt2", coord[0]);
                // console.log("1kt3", coord[0] /kt);
                // return [coord[0], coord[1]]
                return [coord[0]/kt, coord[1]/kt]
            })
        })
        newArr["polygon"] = (withNewCoord);
        newArr["name"] = "Зона шкафа";
        return newArr;
    };

    if (!camera || !model) {
        return <Spinner/>;
    }

    const screenshot = {
        img: camera.current.image,
        sizes: camera.current.image_size
    };

    return (
        <>
            <ZoneEditFormCupboard
                handleCancel={() => handleCancel()}
                onSubmit={data => handleSave(data)}
                showConfirmDialog={() => setShowModal(true)}
                initZones={[selectedZone]}
                screenshot={screenshot}
                isNewZone={isNewZone}
            />
            {shouldShowModal && <ConfirmDeleteModal
                onClose={() => setShowModal(false)}
                onDelete={() => removeZone()}
            />}
        </>
    )
}

export default connect(
    state => ({
        cupboardLayout: state.cupboardLayout.current,
        camera: state.camera,
        object: state.object,
        // zones: state.zone.list,
        zoneDetails: state.zone.zone,
        model: state.model,
    }),
    dispatch => ({
        loadObjectObservation: (objId) => {
            dispatch(loadObjectObservation(objId));
        },
        loadCamera: (objId, cameraId) => {
            dispatch(cameraCurrentClearState());
            dispatch(cameraLoad(objId, cameraId));
        },
        loadZoneList: async (objId, cameraId) => {
            return await dispatch(loadZoneList(objId, cameraId));
        },
        cleanZoneList: () => {
            dispatch(cleanZoneList());
        },
        loadZone: (objId, camId, zoneId) => {
            dispatch(loadZoneById(objId, camId, zoneId));
        },
        cleanZone: () => {
            dispatch(cleanZone());
        },
        loadModelStorage: () => {
            dispatch(loadModelStorage());
        },
        setZoneToState: (zone) => {
            dispatch(setZone(zone));
        },
        deleteZone: async (objId, cameraId, zoneId) => {
            await dispatch(zoneDelete(objId, cameraId, zoneId));
        },
        saveZone: async (objId, cameraId, zoneId, data) => {
            return await dispatch(zoneEdit(objId, cameraId, zoneId, data));
        },
        createZone: async (objId, cameraId, data) => {
            return await dispatch(zoneCreate(objId, cameraId, data));
        },
    }),
)(ZonesCupboard);
