import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import 'react-tabs/style/react-tabs.css';

import {cameraLoad} from '../store/Camera';

const Monitoring = ({params, title, url}) => {
    document.title = title;

    return (
        <>
            <iframe title="system" src={url} align="left"/>
        </>
    );
};

export default connect()(Monitoring);
