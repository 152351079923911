import React from 'react'

import Pagination from '@mui/material/Pagination';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select  from '@mui/material/Select';



const rowsCountOptions = [
    {value: 10, label: '10'},
    {value: 25, label: '25'},
    {value: 30, label: '30'},
    {value: 50, label: '50'},
]


const MuiPagination = ({count, page, rowsPerPage, handleChangePagination, handleChangeRowsPerPage}) => {

    return(
        <div className='paginationContainer'>
            <div className="item-on-page">
                <p>Записей на странице</p>
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rowsPerPage}
                        label="Age"
                        onChange={handleChangeRowsPerPage}
                    >
                        {rowsCountOptions.map(item =>
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>
            <div className="items-pagination">
                <p>Страница</p>
                <Pagination
                    count={count}
                    color='primary'
                    page={page}
                    onChange={handleChangePagination}
                />
            </div>
        </div>
    )
}


export default MuiPagination;
