import React from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

import Legend from './Legend';
import ZonesThermal from "../components/ZonesThermal";

const ThermalCameraZoneForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, userId, isEditMode, objectsList, ppes, onChange, formValues, initialValues}) => {
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';

    console.log("formValues1111", initialValues);

    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="Разметка зон">
                    <ZonesThermal
                        preview={initialValues.preview}
                        preview_size={initialValues.preview_size}
                        zones={initialValues.zone_temp_mon?.zones}
                        onSave={data => {
                            setTimeout(() => {
                                console.log("data1111", data);
                                dispatch(change('ThermalCameraZoneForm', 'polygonForm', data.polygon));
                                formValues["polygonForm"] =  data.polygon
                            }, 1000)
                        }
                        }
                    />
                </Legend>
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state) => {
    if (!state.thermalCamera.current.id) {
        return {
            initialValues: {
                formValuesPolygon: getFormValues('ZoneEditFormThermal')(state) || {},
                // polygonForm: {},
            },
        };
    }
    return {
        initialValues: {
            ...state.thermalCamera.current,
            formValuesPolygon: getFormValues('ZoneEditFormThermal')(state) || {},
            ...state.thermalCamera.list.find(item => item.id === state.thermalCamera.current.id),
            temp_thresh_celsius: state.thermalCamera.current?.face_temp_mon.temp_thresh_celsius.toFixed(1),
            // polygonForm: {},
        },
        cameraId: state.thermalCamera.current.id,
        formValues: getFormValues('ThermalCameraForm')(state) || {},
        formValuesPolygon: getFormValues('ZoneEditFormThermal')(state) || {},
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'ThermalCameraZoneForm',
        enableReinitialize: true,
    })(ThermalCameraZoneForm)
)
