import React from 'react';
import ModelRepository from '../components/ModelRepository';

const ModelsList = () => {

    document.title = 'Список моделей';

    return (
        <ModelRepository/>
    )
}

export default ModelsList;
