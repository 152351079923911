import axios from 'axios';

import {rejectedEventReportQueryString} from '../utils/string';

const REJECTED_EVENTS = {
    LIST: 'REJECTED_EVENTS_LIST',
    CLEAR: 'REJECTED_EVENTS_CLEAR',
    PENDING: 'REJECTED_EVENTS_PENDING',
    ERROR: 'REJECTED_EVENTS_ERROR',
};

const initialState = {};

const rejectedEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REJECTED_EVENTS.PENDING:
            return {
                ...state,
                isPending: true,
            };
        case REJECTED_EVENTS.LIST:
            return {
                list: action.payload,
            };
        case REJECTED_EVENTS.CLEAR:
            return initialState;
        case REJECTED_EVENTS.ERROR:
            return {
                isError: true,
            }
        default:
            return state;
    }
};

export default rejectedEventsReducer;

const setTimezone = (date) => {
    date.setTime(date.getTime());
    return date;
};

/* Загрузка списка непринятых оповещений (событий) */
export const loadRejectedEventReport = (query, pageNumber, pageSize) => dispatch => {
    let url = `/api/statistics/decision-matrix/rejected-events/?page=${pageNumber}&page_size=${pageSize}`;
    const dateBegin = setTimezone(query.dateBegin);
    const dateEnd = setTimezone(query.dateEnd);
    url += rejectedEventReportQueryString(query, dateBegin, dateEnd);
    dispatch({
        type: REJECTED_EVENTS.PENDING,
        // payload: null,
    });
    axios.get(url)
        .then(res => {
            window.scrollTo(0, 0); // мотаю страницу на верх
            dispatch({
                type: REJECTED_EVENTS.LIST,
                payload: {
                    currentPage: pageNumber,
                    ...res.data,
                },
            });
        })
        .catch(e => {
            dispatch({
                type: REJECTED_EVENTS.ERROR,
                payload: null
            })
        })
};

export const clearRejectedEventReport = () => dispatch => {
    dispatch({
        type: REJECTED_EVENTS.CLEAR,
        payload: null,
    });
};
