import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import withDataErrorIndication from '../components/withDataErrorIndication';
import {
    subdivisionClearState,
    subdivisionCreate,
    subdivisionList,
    subdivisionLoad,
    subdivisionEdit,
    subdivisionDelete,
    subdivisionCurrentClearState
} from "../store/Subdivision";
import {CupboardLayoutData} from "./test_data/testData";
import CupboardLayoutModal from "../components/CupboardLayoutModal";
import {cameraClearState, cameraFlatDetailedList, cameraLoad, cameraCurrentClearState} from "../store/Camera";
import {thermalCameraList} from "../store/ThermalCamera";
import Spinner from "../components/Spinner";
import {
    cupboardLayoutClearState,
    cupboardLayoutCurrentClearState,
    cupboardLayoutList,
    cupboardLayoutLoad,
    cupboardLayoutDelete
} from "../store/CupboardLayout";
import {cupboardManageClearState, cupboardManageNotOurList, cupboardManageOurList} from "../store/CupboardManagment";
import {loadObjectsObservationList} from "../store/ObjectObservation";
import {isBlock, onlyRooms} from "../utils/utils";

const CupboardLayout = ({actions, cupboardLayout, cupboardLayoutList = [], isListPending, cameras, thermalCameras, objects = []}) => {
    const {updateSubdivision, loadCamera, clearCupboardLayout, loadObjects, loadCupboardLayout, loadAllCameras, loadThermalCamerasData, loadAllCupboardLayout, loadCupboardManageOurList, loadCupboardManageNotOurList, cupboardLayoutDelete} = actions;

    const [shouldShowModal, setShowModal] = useState(false);
    const [cameraActive, setCameraActive] = useState();

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (cupboardLayout?.isReloadList) {
            loadData();
        }
    }, [cupboardLayout]);

    const loadData = () => {
        // loadAllSubdivision();
        loadAllCameras();
        loadThermalCamerasData();
        loadObjects();

        loadAllCupboardLayout();
        loadCupboardManageOurList();
        loadCupboardManageNotOurList();
    };

    if (!cameras || !thermalCameras || !objects) {
        return <Spinner/>
    }

    const Columns = (cellCancelHandler) => [{
        text: 'Полигон',
        dataField: 'camera.preview.img',
        sort: true,
        headerStyle: () => ({width: '158px'}),
        editable: false,
        formatter: (cell) => cell ? <div className="layout-wrapper"><img className="layout-img" src={cell}/></div> : 'Фото нет',
    },{
        text: 'Название',
        dataField: 'cabinet_int.name',
        sort: true,
        headerStyle: () => ({width: '334px'}),
        editable: false,
    },{
        text: 'Камера',
        dataField: 'camera.name',
        headerStyle: () => ({width: '334px'}),
        sort: true,
        editable: false,
    }, {
        text: 'Помещение',
        dataField: 'cabinet_int.object_observation.name',
        headerStyle: () => ({width: '334px'}),
        sort: true,
        editable: false,
    }, {
        text: 'Блок',
        dataField: 'cabinet_int.object_observation.id',
        sort: true,
        editable: false,
        formatter: (cell) => {
            const block = isBlock(objects).filter(item => item.id === cell);
            return <p>{block[0] ? block[0].power_unit.name : ''}</p>
        },
    }
    ];

    document.title = 'Шкафы';

    return (
        <>
            <GeneralObjectsTable
                isPending={isListPending}
                objects={cupboardLayoutList}
                isEditable={false}
                actions={{
                    update: updateSubdivision,
                    // create: createCamera,
                    create: () => {
                        setShowModal(true);
                    },
                    purge: cupboardLayoutDelete,
                    reload: loadData,
                }}
                getColumns={Columns}
                rowEvents={{
                    onDoubleClick: (e, row, rowIndex) => {
                        loadCupboardLayout(row);
                        setCameraActive(row.camid);
                        setShowModal(true);
                        loadCamera(row.cabinet_int.object_observation.id, row.camera.id);
                    }
                }}
            />
            {shouldShowModal &&
            <CupboardLayoutModal
                cameraActive={cameraActive}
                objects={onlyRooms(objects)}
                onCancel={() => {
                    setShowModal(false);
                    clearCupboardLayout();
                }}
                onClose={() => {
                    setShowModal(false);
                    clearCupboardLayout();
                }}
            />}
        </>
    )
}

export default connect(
    state => ({
        subdivisions: state.subdivision.list,
        objects: state.object.list,
        // subdivision: state.subdivision.current,
        isListPending:  state.camera.isListPending || state.thermalCamera.isListPending || state.cupboardLayout.isListPending || state.cupboardManage.isListPending || state.object.isListPending,
        isError: state.cupboardLayout.isListError,


        cameras: state.camera.flatList || [],
        thermalCameras: state.thermalCamera.list || [],
        cupboardLayoutList: state.cupboardLayout.list,
        cupboardLayout: state.cupboardLayout.current,
    }),
    dispatch => ({
        actions: {
            loadAllSubdivision: () => {
                dispatch(subdivisionClearState());
                dispatch(subdivisionList());
            },
            createSubdivision: (subdivision) => {
                dispatch(subdivisionCreate(subdivision));
            },
            loadSubdivision: (subdivision) => {
                // dispatch(subdivisionCurrentClearState());
                dispatch(subdivisionLoad(subdivision.id));
            },
            updateSubdivision: (subdivision) => {
                dispatch(subdivisionEdit(subdivision.id, subdivision));
            },
            deleteSubdivision: (subdivision, data) => {
                dispatch(subdivisionDelete(subdivision));
            },
            clearSubdivision: () => {
                dispatch(subdivisionCurrentClearState());
            },

            loadAllCameras: () => {
                dispatch(cameraClearState());
                dispatch(cameraFlatDetailedList());
            },
            loadThermalCamerasData: () => {
                dispatch(thermalCameraList());
            },
            loadAllCupboardLayout: () => {
                dispatch(cupboardLayoutClearState());
                dispatch(cupboardLayoutList());
            },
            loadCupboardLayout: (cupboardLayout) => {
                // dispatch(subdivisionCurrentClearState());
                dispatch(cupboardLayoutLoad(cupboardLayout.id));
            },
            clearCupboardLayout: () => {
                dispatch(cupboardLayoutCurrentClearState());
            },
            loadCupboardManageOurList: () => {
                dispatch(cupboardManageClearState());
                dispatch(cupboardManageOurList());
            },
            loadCupboardManageNotOurList: () => {
                dispatch(cupboardManageClearState());
                dispatch(cupboardManageNotOurList());
            },
            cupboardLayoutDelete: (id) => {
                dispatch(cupboardLayoutDelete(id));
            },
            loadObjects: () => {
                dispatch(loadObjectsObservationList());
            },
            loadCamera: (objId, cameraId) => {
                dispatch(cameraCurrentClearState());
                dispatch(cameraLoad(objId, cameraId));
            },
        },
    }),
)(withDataErrorIndication(CupboardLayout));
