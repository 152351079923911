import React, {useState} from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';
import {createTextMask } from 'redux-form-input-masks';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup, InputSimpleWithTitleTop, renderDateTimePicker} from '../inputs';
import {splitCameraUri, isBlock, rtspCredentials, omitRooms} from "../utils/utils";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";
import {userFio} from "../utils/userUtils";
import {dashcamInObject} from "../utils/utils";

const required = value => value ? undefined : 'Обязательное поле';

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.name ? `${entry.name}` : entry.ru_name
        }
    })
};

const protocolSelectActiveOptions = (protocol) => {
    return {
        value: protocol,
        label: protocol
    }
};

const objectsSelectActiveOptions = (objects, obj_id) => objects.map(object => {
    const activeObj = objects.find(item => item.id === obj_id);
    return {
        value: activeObj.id,
        label: `${activeObj.name}`
    }
});

const empSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: userFio(entry)
        }
    })
};

const cameraSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.number
        }
    })
};

const statusSelectOptions = [{
    value: 0,
    label: "Не выдан"
},{
    value: 1,
    label: "Выдан"
}];

const VideoRecorderUserForm = ({handleSubmit, videoRecorderList, forms, pristine, reset, modalHandout, dispatch, userId, isEditMode, objectsList, ppes, formValues, cameras, employees, activeDevice}) => {
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';
    dispatch(change('VideoRecorderModal', 'id', userId)); // скрытое поле в форме с id юзера
    const [objectId, setObjectId] = useState(null);

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = objSelectOptions(omitRooms(objectsList));
        const placeholder = 'Выберите блок';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    const ReduxFormPersonsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = empSelectOptions(employees);
        const placeholder = 'Выберите работника';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormStatusSelect = ({input, validate}) => {
        const isMulti = false;
        const options = statusSelectOptions;
        const placeholder = 'Выберите статус';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormHandoutSelect = ({input, validate}) => {
        const isMulti = false;
        const options = cameraSelectOptions(objectId ?
            dashcamInObject(objectId, videoRecorderList.filter(cam => !cam.handed_out)) : videoRecorderList.filter(cam => !cam.handed_out));
        const placeholder = 'Выберите видеорегистратор';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormrReturnSelect = ({input, validate}) => {
        const isMulti = false;
        const options = cameraSelectOptions(objectId ?
            dashcamInObject(objectId, videoRecorderList.filter(cam => cam.handed_out)) : videoRecorderList.filter(cam => cam.handed_out));
        const placeholder = 'Выберите видеорегистратор';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="Основные данные">
                    {modalHandout &&
                    <InputGroup title="Энергоблок">
                        <Field
                            name="object"
                            className={className('object')}
                            component={ReduxFormObjectsSelect}
                            validate={[]}
                            onChange={(e) => {
                                setObjectId(e.value);
                            }}
                        />
                    </InputGroup>
                    }
                    <InputGroup title="Видеорегистратор" >
                        <Field
                            name="dashcam"
                            className={className('dashcam')}
                            component={modalHandout ? ReduxFormHandoutSelect : ReduxFormrReturnSelect}
                            validate={[required]}
                            disabled={!modalHandout && true}
                        />
                    </InputGroup>
                    {modalHandout ?
                        <Field name="timestamp_str" type="text"
                               label="Дата и время выдачи"
                               showTime={true}
                               component={renderDateTimePicker}
                               className={className('timestamp_str')}
                               validate={[required]}
                        />
                        :
                        <Field name="timestamp_str" type="text"
                               label="Дата и время возврата"
                               showTime={true}
                               component={renderDateTimePicker}
                               className={className('timestamp_str')}
                               validate={[required]}
                        />}
                    {/* <Field name="job_time_to" type="text"
                           label="Дата и время возврата"
                           showTime={true}
                           component={renderDateTimePicker}
                           className={className('job_time_to')}
                        //    validate={[required]}
                           /> */}
                    {modalHandout &&
                    <InputGroup title="Работник" >
                        <Field
                            name="person"
                            className={className('person')}
                            component={ReduxFormPersonsSelect}
                            validate={[required]}
                        />
                    </InputGroup>
                    }
                </Legend>
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state, props) => {
    // if (!state.camera.current?.id) {
    //     return {
    //         initialValues: {
    //             status: {
    //                 value: 0,
    //                 label: "Не выдан"
    //             },
    //         },
    //         formValues: getFormValues('VideoRecorderUserForm')(state) || {},
    //     };
    // }
    return {
        initialValues: {
            // !modalHandout && dispatch(change('VideoRecorderUserForm', 'dashcam', activeDevice)); // скрытое поле в форме с id юзера
            ...state.camera.current,
            dashcam: !props.modalHandout && props.activeDevice
        },
        cameraId: state.camera.current.id,
        formValues: getFormValues('VideoRecorderUserForm')(state) || {},
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'VideoRecorderUserForm',
        enableReinitialize: true,
    })(VideoRecorderUserForm)
)
