import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
import {reducer as FormReducer} from 'redux-form';
import { composeWithDevTools } from 'redux-devtools-extension';

import {loadState, saveState} from '../utils/storage';
import AuthReducer from './Auth';
import objectObservationReducer from './ObjectObservation';
import cameraReducer from './Camera';
import eventsReducer from './Events';
import eventReducer from './Event';
import modelStorageReducer from './ModelStorage';
import zoneReducer from './Zone';
import gitReducer from './Git';
import decisionMatrixReducer from './DecisionMatrix';
import usersReducer from './Users';
import usersNotificationSettingsReducer from './UsersNotificationSettings';
import userSettingsReducer from './UserSettings';
import ppeReducer from './PPEs';
import WorkwearReducer from './Workwear';
import NotificationLogReducer from './NotificationLog';
import websocketsReducer from './Websockets';
import employeesReducer from './Employees';
import RegulatoryDocsSecondaryReducer from "./RegulatoryDocsSecondary";
import RegulatoryDocsPrimaryReducer from "./RegulatoryDocsPrimary";
import thermalCameraReducer from "./ThermalCamera"
import clearSettingsReducer from "./CleanSettings";
import LogsReducer from "./Logs";
import FAQReducer from "./FAQ";
import feedbackReducer from "./FeedBack";
import SystemInfoReducer from "./SystemInfo";
import subdivisionReducer from "./Subdivision";
import jobDocumentsReducer from "./JobDocuments";
import cupboardManageReducer from "./CupboardManagment";
import cupboardLayoutReducer from "./CupboardLayout";
import rejectedEventsReducer from "./RejectedEvents";
import roomNotOurReducer from "./RoomNotOur";
import roomOurReducer from "./RoomOur";
import statisticReducer from "./Statistic";
import notificationDevicesReducer from './NotificationDevices';
import videoRecorderReducer from './VideoRecorder';
import videoRecorderUserReducer from './VideoRecorderUsers';
import videoRecorderUpReducer from './VideoRecorderUp';
import notificationDevicesMappingReducer from "./NotificationDevicesMapping";

export default function configureStore(router, initialState = {}) {

    const reducers = combineReducers({
        form: FormReducer,
        user: AuthReducer,
        employees: employeesReducer,
        object: objectObservationReducer,
        camera: cameraReducer,
        thermalCamera: thermalCameraReducer,
        event: eventReducer,
        events: eventsReducer,
        notificationLog: NotificationLogReducer,
        model: modelStorageReducer,
        zone: zoneReducer,
        git: gitReducer,
        decisionMatrix: decisionMatrixReducer,
        users: usersReducer,
        userSettings: userSettingsReducer,
        userNotificationSettings: usersNotificationSettingsReducer,
        ppes: ppeReducer,
        workwear: WorkwearReducer,
        regulatoryDocsSecondary: RegulatoryDocsSecondaryReducer,
        regulatoryDocsPrimary: RegulatoryDocsPrimaryReducer,
        websockets: websocketsReducer,
        clearSetting: clearSettingsReducer,
        logs: LogsReducer,
        feedback: feedbackReducer,
        systemInfo: SystemInfoReducer,
        subdivision: subdivisionReducer,
        faq: FAQReducer,
        jobDocuments: jobDocumentsReducer,
        cupboardManage: cupboardManageReducer,
        cupboardLayout: cupboardLayoutReducer,
        rejectedEvents: rejectedEventsReducer,
        roomNotOur: roomNotOurReducer,
        roomOur: roomOurReducer,
        statistic: statisticReducer,
        notificationDevices: notificationDevicesReducer,
        videoRecorder: videoRecorderReducer,
        videoRecorderUser: videoRecorderUserReducer,
        videoRecorderUp: videoRecorderUpReducer,
        notificationDevicesMapping: notificationDevicesMappingReducer,
    });

    const createStoreWithMiddleware = composeWithDevTools(applyMiddleware( // создаем стор и добавляем плагины
        thunk,                      // асинхронные экшены
        // createLogger()              // лог в консоль
    )) (createStore);

    const persistedState = loadState();

    const store = createStoreWithMiddleware( // собираем редюсеры
        reducers,
        { ...initialState, ...persistedState },
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );

    store.subscribe(() => {
        saveState({
            user: store.getState().user,
        });
    });

    window.store = store;
    return store;
}
