import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-regular-svg-icons';

import {
    notificationDevicesClearState,
    notificationDevicesCreate,
    notificationDevicesCurrentClearState,
    notificationDevicesDelete,
    notificationDevicesEdit,
    notificationDevicesList,
    notificationDevicesLoad
} from '../store/NotificationDevices';
import {loadObjectsObservationList, objectObservationClearState} from '../store/ObjectObservation';
import {apiDataToCameras, cameraToApiData, omitRooms, apiDataToNotificationDevices} from '../utils/utils';
import {nbsp} from '../utils/string';
import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import SelectWithButtons from '../tables/SelectWithButtons';
import TextInputWithButtons from '../tables/TextInputWithButtons';
import withDataErrorIndication from '../components/withDataErrorIndication';
import VideoRecorderUpVideoModal from '../components/VideoRecorderUpVideoModal';
import {clearEmploeesState, loadEmploeesList} from "../store/Employees";
import NotificationDevicesModal from '../components/NotificationDevicesModal';
import {cameraList} from "../store/Camera";

const NotificationDevices = ({actions, cameras, notificationDevices, objects = [], isListPending, employees}) => {
    const {loadAllCameras, createCamera, loadAllCamerasList, updateCamera, deleteCamera, loadObjects, loadCamera, clearCamera, loadEmployeesList} = actions;

    const loadData = () => {
        loadAllCameras();
        loadObjects();
        loadEmployeesList();
        loadAllCamerasList();
    }

    const [shouldShowModal, setShowModal] = useState(false);
    const [cameraActive, setCameraActive] = useState();

    useEffect(() => {
        loadData();
    }, [])

    const Columns = (cellCancelHandler) => [{
        text: '№',
        dataField: 'id',
        sort: true,
        headerStyle: () => ({width: '59px'}),
        editable: false,
    }, {
        text: 'Название',
        dataField: 'name',
        sort: true,
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons
                {...editorProps}
                onCancel={cellCancelHandler} className="required-field"/>
        ),
        required: true,
    },{
        text: 'IP адрес устройства',
        dataField: 'device_uri',
        sort: true,
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons
                {...editorProps}
                onCancel={cellCancelHandler} className="required-field"/>
        ),
        required: true,
    },{
        text: 'Статус',
        dataField: 'is_enabled',
        sort: true,
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons
                {...editorProps}
                onCancel={cellCancelHandler} className="required-field"/>
        ),
        required: true,
        formatter: (cell) => cell ? 'Активно' : 'Неактивно',
        type: 'bool',
        headerStyle: () => ({width: '110px'}),
    }];

    document.title = 'Устройства оповещения';

    return (
        <>
            <GeneralObjectsTable
                isPending={isListPending}
                objects={apiDataToNotificationDevices(notificationDevices)}
                isEditable={false}
                actions={{
                    update: updateCamera,
                    // create: createCamera,
                    create: () => {
                        setShowModal(true);
                    },
                    purge: deleteCamera,
                    reload: loadData,
                }}
                getColumns={Columns}
                itemDummy={{
                    id: 0,
                    code: 0,
                    obj: '',
                    name: '',
                    protocol: '',
                    host: '',
                    suffix: '',
                    credentials: [],
                    is_enabled: true,
                }}
                rowEvents={{
                    onDoubleClick: (e, row, rowIndex) => {
                        loadCamera(row);
                        setShowModal(true);
                    }
                }}
            />
            {shouldShowModal &&
            <NotificationDevicesModal
                objectsList={objects}
                cameras={notificationDevices}
                employees={employees}
                camerasList={cameras}
                onCancel={() => {
                    setShowModal(false);
                    clearCamera();
                }}
                onClose={() => {
                    setShowModal(false);
                    clearCamera();
                }}
            />}
        </>
    )
}

export default connect(
    state => ({
        user: state.user,
        cameras: state.camera.list || [],
        notificationDevices: state.notificationDevices.list?.result,
        camera: state.notificationDevices.current,
        employees: state.employees.list,
        isListPending:  state.camera.isListPending || state.notificationDevices.isListPending || state.object.isListPending || state.employees.isListPending,
        objects: state.object.list,
        isError: state.notificationDevices.isListError,
    }),
    dispatch => ({
        actions: {
            loadAllCamerasList: () => {
                dispatch(cameraList());
            },
            loadAllCameras: () => {
                dispatch(notificationDevicesClearState());
                dispatch(notificationDevicesList());
            },
            createCamera: (camera) => {
                const apiData = cameraToApiData(camera);
                dispatch(notificationDevicesCreate(camera.object, apiData));
            },
            loadCamera: (camera) => {
                dispatch(notificationDevicesCurrentClearState());
                dispatch(notificationDevicesLoad(camera.id));
            },
            updateCamera: (cameraId, camera) => {
                const apiData = cameraToApiData(camera);
                dispatch(notificationDevicesEdit(cameraId, apiData));
            },
            deleteCamera: (cameraId) => {
                dispatch(notificationDevicesDelete(cameraId));
            },
            loadObjects: () => {
                dispatch(objectObservationClearState());
                dispatch(loadObjectsObservationList());
            },
            clearCamera: () => {
                dispatch(notificationDevicesCurrentClearState());
            },
            loadEmployeesList: () => {
                dispatch(clearEmploeesState());
                dispatch(loadEmploeesList());
            },
        },
    }),
)(withDataErrorIndication(NotificationDevices));
