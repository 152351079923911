import React, {useEffect, useState} from 'react';

import Modal from './Modal';


//открытие формы по-умолчанию
const ModalIsOpen = false;

const EventNotification = ({event = {id: 1}}) => {
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        setIsOpen(ModalIsOpen);
    }, [event.id]);

    return (
        <Modal
            title="Обнаружено нарушение"
            className="event-notification-modal"
            showEditButton={false}
            closeButtonWithText={false}
            isOpen={isOpen}
            onClose={() => {
                setIsOpen(false)
            }}
        >
            <div className="event-notification">
                <div className="primary-label">Блок:</div>
                <div className="primary-label">Помещение:</div>
                <div className="primary-label">person_spec</div>
                <div className="secondary-label">person_spec</div>
                <div className="violation-crop">
                    <img src={event.violationCrop} alt=""/>
                </div>
                <div className="event-notification-buttons">
                    <button style={{color: 'red'}} type="button" className="form-control">Нарушение</button>
                    <button style={{color: 'green'}}>Нет</button>
                </div>
            </div>
        </Modal>
    )
}

export default EventNotification;
