import axios from 'axios';
import imageUtils from "../utils/imageUtils";
import {clearEmploeesState, loadEmploeesList} from "./Employees";

const NOTIFICATION_DEVICES = {
    LIST:       'NOTIFICATION_DEVICES_LIST',
    LIST_PENDING: 'NOTIFICATION_DEVICES_LIST_PENDING',
    LIST_ERROR: 'NOTIFICATION_DEVICES_LIST_ERROR',
    DETAIL:     'NOTIFICATION_DEVICES_DETAIL',
    CREATE:     'NOTIFICATION_DEVICES_CREATE',
    EDIT:       'NOTIFICATION_DEVICES_EDIT',
    DELETE:     'NOTIFICATION_DEVICES_DELETE',
    CLEAR:      'NOTIFICATION_DEVICES_CLEAR',
    CURRENT_CLEAR:      'NOTIFICATION_DEVICES_CURRENT_CLEAR',
};

const initialState = {
    current: {},
};

const notificationDevicesReducer = (state = initialState, action) => {
    switch(action.type) {
        case NOTIFICATION_DEVICES.LIST:
            return {
                ...state,
                list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case NOTIFICATION_DEVICES.DETAIL:
        case NOTIFICATION_DEVICES.CREATE:
        case NOTIFICATION_DEVICES.EDIT:
        case NOTIFICATION_DEVICES.DELETE:
        case NOTIFICATION_DEVICES.ALL_INFO:
            return {
                ...state,
                current: action.payload,
                isListPending: false,
            };
        case NOTIFICATION_DEVICES.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case NOTIFICATION_DEVICES.LIST_ERROR:
            return {
                isListError: true,
            }
        case NOTIFICATION_DEVICES.CLEAR:
            return initialState;
        case NOTIFICATION_DEVICES.CURRENT_CLEAR:
            return {
                ...state,
                current: []
            };
        default:
            return state;
    }
};

export default notificationDevicesReducer;

export const notificationDevicesList = () => dispatch => {
    dispatch({
        type: NOTIFICATION_DEVICES.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/device-alert/')
        .then(res => {
            dispatch({
                type: NOTIFICATION_DEVICES.LIST,
                payload: res.data
            });
        })
};


/* Загрузка камеры (cameraId) из объекта наблюдения (objId) */
export const notificationDevicesLoad = (cameraId) => dispatch => {
    dispatch({
        type: NOTIFICATION_DEVICES.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/device-alert/${cameraId}/`)
        .then(res => {
            dispatch({
                type: NOTIFICATION_DEVICES.DETAIL,
                payload: res.data
            });
        });
};

/* Создание новой камеры */
export const notificationDevicesCreate = (data) => dispatch => {
    axios.post(`/api/statistics/device-alert/`, data)
        .then(res => {
            dispatch({
                type: NOTIFICATION_DEVICES.CREATE,
                payload: {isReloadList: false},
            });
            dispatch(notificationDevicesList());
        })
        .catch(err => {
            dispatch({
                type: NOTIFICATION_DEVICES.CREATE,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.response.data.error
                },
            });
        });
};

export const notificationDevicesDelete = (cameraId) => dispatch => {
    axios.delete(`/api/statistics/device-alert/${cameraId}/`)
        .then(res => {
            let data = {isReloadList: false};
            dispatch({
                type: NOTIFICATION_DEVICES.DELETE,
                payload: data,
            });
            dispatch(notificationDevicesClearState());
            dispatch(notificationDevicesList());
        });
};

export const notificationDevicesClearState = () => dispatch => {
    dispatch({
        type: NOTIFICATION_DEVICES.CLEAR,
        payload: null,
    });
};

export const notificationDevicesCurrentClearState = () => dispatch => {
    dispatch({
        type: NOTIFICATION_DEVICES.CURRENT_CLEAR,
        payload: null,
    });
};

export const notificationDevicesEdit = (cameraId, data) => dispatch => {
    axios.put(`/api/statistics/device-alert/${cameraId}/`, data)
        .then(res => {
            dispatch({
                type: NOTIFICATION_DEVICES.EDIT,
                payload: {isReloadList: false},
            });
            dispatch(notificationDevicesClearState());
            dispatch(notificationDevicesList());
        })
        .catch(err => {
            dispatch({
                type: NOTIFICATION_DEVICES.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};