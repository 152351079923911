import React from 'react';

import {objectsListToOptions} from '../utils/inputs';
import {omitRooms} from '../utils/utils';
import TextInputWithButtons from './TextInputWithButtons';
import SelectWithButtons from './SelectWithButtons';

const RoomsColumns = (objects) => (cellCancelHandler) => [{
    text: '№',
    dataField: 'id',
    headerStyle: () => ({width: '59px'}),
}, {
//     text: 'Код',
//     dataField: 'code',
//     headerStyle: () => ({width: '200px'}),
//     editorRenderer: (editorProps) => (
//         <TextInputWithButtons {...editorProps}
//                               onCancel={cellCancelHandler} />
//     ),
//     required: false,
// }, {
    text: 'Название',
    dataField: 'name',
    headerStyle: () => ({width: '450px'}),
    editorRenderer: (editorProps) => (
        <TextInputWithButtons {...editorProps}
                              onCancel={cellCancelHandler} className="required-field"/>
    ),
    required: true,
}, {
    text: 'Энергоблок',
    dataField: 'parent',
    headerStyle: () => ({width: '450px'}),
    formatter: (cell) => {
        const parent = objects.find(obj => obj.id === cell);
        return parent ? parent.name : '';
    },
    editorRenderer: (editorProps => (
        <SelectWithButtons
            {...editorProps}
            onCancel={cellCancelHandler}
            options={objectsListToOptions(omitRooms(objects))}
            className="required-field"
        />
    )),
    type: 'number',
    required: true,
}, {
    text: 'Описание',
    dataField: 'description',
    editorRenderer: (editorProps) => (
        <TextInputWithButtons {...editorProps} onCancel={cellCancelHandler}/>
    ),
}];

export default RoomsColumns;
