import axios from 'axios';

const SUBDIVISION = {
    LIST:       'SUBDIVISION_LIST',
    LIST_PENDING: 'SUBDIVISION_PENDING',
    LIST_ERROR: 'SUBDIVISION_LIST_ERROR',
    DETAIL:     'SUBDIVISION_DETAIL',
    CREATE:     'SUBDIVISION_CREATE',
    EDIT:       'SUBDIVISION_EDIT',
    DELETE:     'SUBDIVISION_DELETE',
    CLEAR:      'SUBDIVISION_CLEAR',
    CURRENT_CLEAR:      'SUBDIVISION_CURRENT_CLEAR',
};

const initialState = {
    current: {},
};

const subdivisionReducer = (state = initialState, action) => {
    switch(action.type) {
        case SUBDIVISION.LIST:
            return {
                ...state,
                list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case SUBDIVISION.DETAIL:
        case SUBDIVISION.CREATE:
        case SUBDIVISION.EDIT:
        case SUBDIVISION.DELETE:
            return {
                ...state,
                current: action.payload,
                isListPending: false,
            };
        case SUBDIVISION.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case SUBDIVISION.LIST_ERROR:
            return {
                isListError: true,
            }
        case SUBDIVISION.CLEAR:
            return initialState;
        case SUBDIVISION.CURRENT_CLEAR:
            return {
                ...state,
                current: undefined
            };
        default:
            return state;
    }
};

export default subdivisionReducer;

export const subdivisionList = () => dispatch => {
    dispatch({
        type: SUBDIVISION.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/facecloud/person-storage/subdivisions/?page=1&size=100')
        .then(res => {
            dispatch({
                type: SUBDIVISION.LIST,
                payload: res.data.subdivisions
            });
        })
};


/* Загрузка подразделения */
export const subdivisionLoad = (subId) => dispatch => {
    dispatch({
        type: SUBDIVISION.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/facecloud/person-storage/subdivisions/${subId}/`)
        .then(res => {
            dispatch({
                type: SUBDIVISION.DETAIL,
                payload: res.data,
            });
        });
};

/* Создание нового подразделения */
export const subdivisionCreate = (data) => dispatch => {
    axios.post(`/api/facecloud/person-storage/subdivisions/`, data)
        .then(res => {
            dispatch({
                type: SUBDIVISION.CREATE,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: SUBDIVISION.CREATE,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

export const subdivisionDelete = (subId) => dispatch => {
    axios.delete(`/api/facecloud/person-storage/subdivisions/${subId}/`)
        .then(res => {
            let data = {isReloadList: true};
            dispatch({
                type: SUBDIVISION.DELETE,
                payload: data,
            });
        });
};

export const subdivisionClearState = () => dispatch => {
    dispatch({
        type: SUBDIVISION.CLEAR,
        payload: null,
    });
};

export const subdivisionCurrentClearState = () => dispatch => {
    dispatch({
        type: SUBDIVISION.CURRENT_CLEAR,
        payload: null,
    });
};

export const subdivisionEdit = (subId, data) => dispatch => {
    axios.put(`/api/facecloud/person-storage/subdivisions/${subId}/`, data)
        .then(res => {
            dispatch({
                type: SUBDIVISION.EDIT,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: SUBDIVISION.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};
