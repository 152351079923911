import React from 'react'
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class Attach extends React.Component {

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    onFileChange = async (e) => {
        const { input } = this.props;
        const targetFile = e.target.files[0];
        const validMIMETypes = [
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];

        if (targetFile && validMIMETypes.includes(targetFile.type)) {
            const fileName = targetFile.name;
            const ext = fileName.split('.').pop();
            const val = await this.getBase64(targetFile);

            const res = val.split('base64,');

            const contentData = res[1];

            const newVal = `data:application/${ext};base64,${contentData}`;

            input.onChange({'name': targetFile.name, 'base64': newVal});
        }
        if (targetFile && !validMIMETypes.includes(targetFile.type)) {
            this.props.onError('Поддерживаемые форматы pdf, doc, docx, xls, xlsx');
            input.onChange(null);
        }
        if (!targetFile && !validMIMETypes.includes(targetFile.type)) {
            input.onChange(null);
        }
    };

    deleteFile = () => {
        this.props.onDelete();
    };


    render() {
        const isAttached = (this.props.input.value || this.props.href) && this.props.isOnlyFileAdd === false;
        const fileReady = this.props.fileReady;
        return (
            <>
                <div className={`attach-file${isAttached ? '-attached' : ''}`} >
                    <label htmlFor="file-upload" className="attach-label">
                        <FontAwesomeIcon icon={faPlus} className="attach-label--icon"/>
                        Прикрепить
                    </label>
                    <input id="file-upload" type="file" onChange={this.onFileChange} disabled={fileReady !== undefined && fileReady.length}/>
                </div>
            </>
        )
    }
}

export default Attach;
