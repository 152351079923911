import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {loadDecisionMatrix, saveDecisionMatrix} from '../store/DecisionMatrix';
import Spinner from '../components/Spinner';
import withDataErrorIndication from '../components/withDataErrorIndication';
import {cameraList} from "../store/Camera";
import {loadObjectsObservationList} from "../store/ObjectObservation";
import {omitRooms, onlyRooms} from "../utils/utils";
import SwithingFromTableForm from "../tables/SwithingFromTableForm";
import {clearEmploeesState, loadEmploeesList} from "../store/Employees";
import OutfitToleranceTableForm from "../tables/OutfitToleranceTableForm";
import {createJobDocument, deleteJobDocument, loadJobDocumentsList, saveJobDocument} from "../store/JobDocuments";
import {cupboardLayoutClearState, cupboardLayoutList} from "../store/CupboardLayout";
import {loadRegulatoryDocsPrimary} from "../store/RegulatoryDocsPrimary";

const type = 2; // тип документа - бланк перемещений

const SwithingForm = ({isPending, cupboardLayoutList, actions, jobDocuments, cameras, objects, employees, primaryList}) => {
    const {loadModel, loadRegulatoryDocs, loadDecisionMatrix, loadAllCameras, loadObjects, loadEmployeesList, saveJobDocument, createJobDocument, deleteJobDocument, loadAllCupboardLayout} = actions;

    useEffect(() => {
        loadModel();
        loadDecisionMatrix();
        loadAllCameras();
        loadObjects();
        loadEmployeesList();
        loadAllCupboardLayout();
        loadRegulatoryDocs();
    }, []);

    document.title = 'Бланк переключений';

    if (!jobDocuments || isPending || !cameras ) {
        return <Spinner/>;
    }

    const onSaveDoc = (data) => {
        if (data.id) {
            saveJobDocument(data.id, data, type)
        } else {
            createJobDocument(data, type)
        }
    }

    const onDelDoc = (id) => {
        deleteJobDocument(id, type);
    }


    return (
        <SwithingFromTableForm
            dataRows={jobDocuments}
            primaryList={primaryList}
            modelstorage={jobDocuments}
            handleSave={onSaveDoc}
            deleteDoc={onDelDoc}
            cameras={cameras}
            employees={employees}
            objects={onlyRooms(objects)}
            cupboardLayoutList={cupboardLayoutList}
        />
    )
};

export default connect(
    state => ({
        jobDocuments: state.jobDocuments.list,
        primaryList: state.regulatoryDocsPrimary.list,
        cupboardLayoutList: state.cupboardLayout.list,
        employees: state.employees.list,
        objects: state.object.list,
        cameras: state.camera.list,
        isPending: state.camera.isListPending || state.regulatoryDocsPrimary.busy || state.cupboardLayout.isListPending || state.object.isListPending || state.employees.isListPending || state.jobDocuments.isListPending,
        isError: state.jobDocuments ? state.jobDocuments.isError : false,
    }),
    dispatch => ({
        actions: {
            loadModel: () => {
                dispatch(loadJobDocumentsList(type));
            },
            loadRegulatoryDocs: () => {
                dispatch(loadRegulatoryDocsPrimary());
            },
            loadObjects: () => {
                dispatch(loadObjectsObservationList());
            },
            loadAllCameras: () => {
                dispatch(cameraList());
            },
            loadDecisionMatrix: () => {
                dispatch(loadDecisionMatrix());
            },
            saveDecisionMatrix: (data) => {
                dispatch(saveDecisionMatrix(data));
            },
            loadEmployeesList: () => {
                dispatch(clearEmploeesState());
                dispatch(loadEmploeesList());
            },
            saveJobDocument: (id, data, type) => {
                dispatch(saveJobDocument(data.id, data, type))
            },
            createJobDocument: (data, type) => {
                dispatch(createJobDocument(data, type))
            },
            deleteJobDocument: (id, type) => {
                dispatch(deleteJobDocument(id, type))
            },
            loadAllCupboardLayout: () => {
                dispatch(cupboardLayoutClearState());
                dispatch(cupboardLayoutList());
            },
        }
    }),
)(withDataErrorIndication(SwithingForm));
