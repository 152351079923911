import React from 'react';

import Spinner from './Spinner';

const TaskIndicator = () => (
    <div className="task-indicator">
        <div className="task-indicator__spinner-wrapper">
            <Spinner/>
        </div>
        <span>Действие выполняется</span>
    </div>
)

export default TaskIndicator;
