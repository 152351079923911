import axios from 'axios';

const EVENT = {
    EVENT: 'EVENT',
    CLEAR_EVENT: 'EVENTS_EVENT_CLEAR',
    IS_PENDING: 'IS_PENDING_EVENT',
};

const initialState = {};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case EVENT.IS_PENDING:
            return {
                ...state,
                isPending: true,
            };
        case EVENT.EVENT:
            return {
                ...state,
                event: action.payload,
                isPending: false,
            };
        case EVENT.CLEAR_EVENT:
            const {event, ...newState} = state;
            return newState;
        default:
            return state;
    }
};

export default eventReducer;

/* Загрузка события (оповещения) */
export const loadEvent = (eventId) => dispatch => {
    dispatch({
        type: EVENT.IS_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/event-storage/event/${eventId}/`)
        .then(res => {
            dispatch({
                type: EVENT.EVENT,
                payload: res.data
            });
        })
};

export const clearEvent = () => dispatch => {
    dispatch({
        type: EVENT.CLEAR_EVENT,
        payload: null,
    });
};

/* Подтверждение события */
export const updateEvent = (eventId, hasViolation, userId, personId) => dispatch => {
    dispatch({
        type: EVENT.IS_PENDING,
        payload: null,
    });
    axios.put(`/api/statistics/event-storage/event/${eventId}/`, {
        event_id: eventId,
        user_id: userId,
        confirmation_status: hasViolation,
        person_id: personId
    })
        .then(res => {
            dispatch({
                type: EVENT.EVENT,
                payload: res.data
            });
        })
};
