import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-regular-svg-icons';

import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import withDataErrorIndication from '../components/withDataErrorIndication';
import {
    subdivisionClearState,
    subdivisionCreate,
    subdivisionList,
    subdivisionLoad,
    subdivisionEdit,
    subdivisionDelete,
    subdivisionCurrentClearState
} from "../store/Subdivision";

import CupboardLayoutCheckedIcon from "../assets/img/icons/CupboardLayoutCheckedIcon";
import CupboardLayoutNotCheckedIcon from "../assets/img/icons/CupboardLayoutNotCheckedIcon";
import {
    cupboardManageClearState,
    cupboardManageOurList,
    cupboardManageNotOurList,
    cupboardManageOurLoad,
    cupboardManageNotOurLoad, cupboardManageOurEdit, cupboardManageNotOurEdit,
    cupboardManageCurrentClearState,
    cupboardManageOurDelete, cupboardManageNotOurDelete

} from "../store/CupboardManagment";
import CupboardManagementModal from "../components/CupboardManagementModal";
import {loadObjectsObservationList, objectObservationClearState} from "../store/ObjectObservation";
import {isBlock} from "../utils/utils";
import Spinner from "../components/Spinner";


const CupboardMenagement = ({actions, subdivision, subdivisions = [], isListPending, our, cupboardOur = [], cupboardNotOur = [], cupboardReload, objects = []}) => {
    const { loadObjects, loadCupboardManageOurList, loadCupboardManageNotOurList, loadCupboardManageOur, loadCupboardManageNotOur, clearCupboardManage, cupboardManageOurDelete, cupboardManageNotOurDelete} = actions;

    const loadData = () => {
        loadObjects();
        loadCupboardManageOurList();
        loadCupboardManageNotOurList();
    };

    const [shouldShowModal, setShowModal] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (cupboardReload) {
            loadData();
        }
    }, [cupboardReload]);

    const ColumnsOur = (cellCancelHandler) => [{
        text: 'Разметка',
        dataField: 'layout_added',
        sort: true,
        headerStyle: () => ({width: '120px'}),
        editable: false,
        formatter: (cell) => <div className="layout-wrapper">{cell ? <CupboardLayoutCheckedIcon/> : <CupboardLayoutNotCheckedIcon/>}</div>,
    },{
        text: 'Название',
        dataField: 'name',
        sort: true,
        headerStyle: () => ({width: '334px'}),
        required: true,
        editable: false,
    },{
        text: 'Код',
        dataField: 'code',
        sort: true,
        headerStyle: () => ({width: '222px'}),
        editable: false,
    },{
        text: 'Шкафы на станции',
        dataField: 'cabinet_ext.name',
        sort: true,
        headerStyle: () => ({width: '222px'}),
        editable: false,
    },{
        text: 'Помещение',
        dataField: 'object_observation.name',
        headerStyle: () => ({width: '334px'}),
        sort: true,
        editable: false,
    },
    {
        text: 'Блок',
        dataField: 'object_observation.id',
        sort: true,
        editable: false,
        formatter: (cell) => {
            const block = isBlock(objects).filter(item => item.id === cell);
            return <p>{block[0] ? block[0].power_unit.name : ''}</p>
        },
    }
    ];

    const ColumnsNotOur = (cellCancelHandler) => [{
        text: 'Сопоставлен?',
        dataField: 'mapped',
        sort: true,
        headerStyle: () => ({width: '120px'}),
        editable: false,
        formatter: (cell) => <div className="layout-wrapper">{cell ? <CupboardLayoutCheckedIcon/> : <CupboardLayoutNotCheckedIcon/>}</div>,
    },{
        text: 'Название',
        dataField: 'name',
        sort: true,
        headerStyle: () => ({width: '334px'}),
        required: true,
        editable: false,
    },{
        text: 'Код',
        dataField: 'code',
        sort: true,
        headerStyle: () => ({width: '222px'}),
        editable: false,
    },{
        text: '',
        dataField: 'object_observation.id',
        sort: true,
        editable: false,
    }
    ];

    if (isListPending) {
        return <Spinner/>
    }

    document.title = 'Шкафы';

    return (
        <>
            <GeneralObjectsTable
                isPending={isListPending}
                objects={our ? cupboardOur : cupboardNotOur}
                isEditable={false}
                actions={{
                    create: () => {
                        setShowModal(true);
                    },
                    purge: our ? cupboardManageOurDelete : cupboardManageNotOurDelete,
                    reload: loadData,
                }}
                getColumns={our ? ColumnsOur : ColumnsNotOur}
                rowEvents={{
                    onDoubleClick: (e, row, rowIndex) => {
                        if (our){
                            loadCupboardManageOur(row)
                        } else {
                            loadCupboardManageNotOur(row)
                        }
                        setShowModal(true);
                    }
                }}
            />
            {shouldShowModal &&
            <CupboardManagementModal
                our={our}
                onCancel={() => {
                    setShowModal(false);
                    clearCupboardManage();
                }}
                onClose={() => {
                    setShowModal(false);
                    clearCupboardManage();
                }}
            />}
        </>
    )
}

export default connect(
    state => ({
        subdivisions: state.subdivision.list,
        subdivision: state.subdivision.current,
        isListPending: state.cupboardManage.isListPending || state.object.isListPending,
        isError: state.cupboardManage.isListError,
        cupboardOur: state.cupboardManage.listOur,
        cupboardReload: state.cupboardManage.current?.isReloadList,
        cupboardNotOur: state.cupboardManage.listNotOur,
        objects: state.object.list,
    }),
    dispatch => ({
        actions: {
            loadAllSubdivision: () => {
                dispatch(subdivisionClearState());
                dispatch(subdivisionList());
            },
            createSubdivision: (subdivision) => {
                dispatch(subdivisionCreate(subdivision));
            },
            loadSubdivision: (subdivision) => {
                // dispatch(subdivisionCurrentClearState());
                dispatch(subdivisionLoad(subdivision.id));
            },
            updateSubdivision: (subdivision) => {
                dispatch(subdivisionEdit(subdivision.id, subdivision));
            },


            loadCupboardManageOurList: () => {
                dispatch(cupboardManageClearState());
                dispatch(cupboardManageOurList());
            },
            loadCupboardManageNotOurList: () => {
                dispatch(cupboardManageClearState());
                dispatch(cupboardManageNotOurList());
            },
            loadCupboardManageOur: (cupboardManage) => {
                // dispatch(subdivisionCurrentClearState());
                dispatch(cupboardManageOurLoad(cupboardManage.id));
            },
            loadCupboardManageNotOur: (cupboardManage) => {
                // dispatch(subdivisionCurrentClearState());
                dispatch(cupboardManageNotOurLoad(cupboardManage.id));
            },
            loadObjects: () => {
                dispatch(objectObservationClearState());
                dispatch(loadObjectsObservationList());
            },
            clearCupboardManage: () => {
                dispatch(cupboardManageCurrentClearState());
            },
            cupboardManageOurDelete: (cupboardManage) => {
                dispatch(cupboardManageOurDelete(cupboardManage));
            },
            cupboardManageNotOurDelete: (cupboardManage) => {
                dispatch(cupboardManageNotOurDelete(cupboardManage));
            },
        },
    }),
)(withDataErrorIndication(CupboardMenagement));
