import axios from 'axios';
import {userFio} from '../utils/userUtils';

const JOB_DOCUMENTS = {
    LIST: 'JOB_DOCUMENTS_LIST',
    LIST_PENDING: 'JOB_DOCUMENTS_LIST_PENDING',
    LIST_ERROR: 'JOB_DOCUMENTS_LIST_ERROR',
    JOB_DOCUMENT: 'JOB_DOCUMENTS_LOAD',
    JOB_DOCUMENT_PENDING: 'JOB_DOCUMENTS_PENDING',
    SAVED: 'JOB_DOCUMENTS_SAVED',
    CREATED: 'JOB_DOCUMENTS_CREATED',
    CLEAR: 'JOB_DOCUMENTS_CLEAR',
    JOB_DOCUMENT_CLEAR: 'JOB_DOCUMENTS_CLEAR',
}

const initialState = {};

const jobDocumentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case JOB_DOCUMENTS.LIST:
            return {
                ...state,
                list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case JOB_DOCUMENTS.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case JOB_DOCUMENTS.LIST_ERROR:
            return {
                ...state,
                isListError: true,
            }
        case JOB_DOCUMENTS.JOB_DOCUMENT:
            return {
                ...state,
                current: action.payload,
                isDocumentPending: false,
            }
        case JOB_DOCUMENTS.SAVED:
            return {
                ...state,
                current: null,
                isDocumentPending: false,
            }
        case JOB_DOCUMENTS.JOB_DOCUMENT_PENDING:
            return {
                ...state,
                isDocumentPending: true,
            }
        case JOB_DOCUMENTS.CLEAR:
            return {
                ...state,
                list: undefined
            };
        case JOB_DOCUMENTS.JOB_DOCUMENT_CLEAR:
            return {
                ...state,
                current: undefined
            };
        default:
            return state;
    }
};

export default jobDocumentsReducer;

export const loadJobDocumentsList = (type) => dispatch => {
    dispatch({
        type: JOB_DOCUMENTS.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/decision-matrix/job-documents/?document_type=${type}`)
        .then(res => {
            dispatch({
                type: JOB_DOCUMENTS.LIST,
                payload: res.data,
            });
        })
        .catch(() => {
            dispatch({
                type: JOB_DOCUMENTS.LIST_ERROR,
                payload: null,
            })
        })
};

export const loadJobDocumentSetting = (id) => dispatch => {
    dispatch({
        type: JOB_DOCUMENTS.JOB_DOCUMENT_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/decision-matrix/job-documents/${id}/switchover-info/`)
        .then(res => {
            dispatch({
                type: JOB_DOCUMENTS.JOB_DOCUMENT,
                payload: res.data,
            })
        })
}

export const saveJobDocument = (id, data, type) => dispatch => {
    dispatch({
        type: JOB_DOCUMENTS.JOB_DOCUMENT_PENDING,
        payload: null,
    });
    axios.put(`/api/statistics/decision-matrix/job-documents/${id}/`, data)
        .then(res => {
            dispatch({
                type: JOB_DOCUMENTS.SAVED,
                payload: data,
            });
            dispatch(clearJobDocumentsState());
            dispatch(loadJobDocumentsList(type));
        });
};


export const createJobDocument = (data, type) => dispatch => {
    axios.post('/api/statistics/decision-matrix/job-documents/', data)
        .then(res => {
            dispatch(loadJobDocumentsList(type));
        });
};

export const deleteJobDocument = (id, type) => dispatch => {
    dispatch({
        type: JOB_DOCUMENTS.LIST_PENDING,
        payload: null,
    });
    axios.delete(`/api/statistics/decision-matrix/job-documents/${id}/`)
        .then(res => {
            dispatch(clearJobDocumentsState());
            dispatch(loadJobDocumentsList(type));
        });
};

export const clearJobDocumentsState = () => dispatch => {
    dispatch({
        type: JOB_DOCUMENTS.CLEAR,
        payload: null,
    });
};

export const clearJobDocumentState = () => dispatch => {
    dispatch({
        type: JOB_DOCUMENTS.JOB_DOCUMENT_CLEAR,
        payload: null
    })
};
