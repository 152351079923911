import React, {useEffect, useState} from 'react';
import {PAGE_SIZE, START_PAGE, VIOLATION, VIOLATION_SETTINGS} from '../utils/constants';
import {change, Field, formValues, getFormValues, reduxForm} from "redux-form";
import {EnableCheckbox, InputGroup, SelectInput} from "../inputs";


import {StatusToRus} from "../utils/utils";
import {isHEAD, userFio} from '../utils/userUtils';
import {connect} from "react-redux";
import {TimestampToIntAndLocal} from "../utils/time";
import SpinnerSmall from "../components/SpinnerSmall";
import Modal from "../components/Modal";
import { Lightbox } from "react-modal-image";

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: userFio(entry)
        }
    })
};


const EventForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, event, saveEvent, employees, onChangePerson, onSubmit, user, formValues, initialValues, employee, isEmploeePending}) => {
    const [openPhoto, setOpenPhoto] = useState(false);
    const [openPhotoViol, setOpenPhotoViol] = useState(false);

    let currentValue = { value: 0, label: 'Не выбрано' };
    let persons = [];
    for (const p of employees) {
        const v = {
            value: p.id,
            label: userFio(p)
        };
        if (p.id === event.person) {
            currentValue = v;
        }
        persons.push(v);
    }

// onSubmit={handleSubmit}
    return (
        <form onSubmit={values => {}} className="eventForm ">
            <div className="border-wrapper expanded row">
                <div className="column small-7">
                    <div className="form-group">
                        <div className="title">
                            <div className="title-text">
                                <p>Нарушение</p>
                            </div>
                            <div className="title-line"/>
                        </div>
                        <div className="form-input">
                            <div className="title-group">
                                <p>Помещение:</p>
                            </div>
                            <div className="input">
                                <input type="text" readOnly value={`${event.room} (${event?.power_unit ?? ''})`}/>
                            </div>
                        </div>
                        <div className="form-input">
                            <div className="title-group">
                                <p>Дата и время:</p>
                            </div>
                            <div className="input">
                                <input type="text" readOnly
                                       value={event.date}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <div className="title-group">
                                <p>Вероятность нарушения:</p>
                            </div>
                            <div className="input">
                                <input type="text" readOnly
                                       value={event.score}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <div className="title-group">
                                <p>Тип:</p>
                            </div>
                            <div className="input">
                                <input type="text" readOnly
                                       value={event.type}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <div className="title-group">
                                <p>Статья ТБ и ПБ:</p>
                            </div>
                            <div className="input">
                                <input type="text" readOnly
                                       value={event.article}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <div className="title-group">
                                <p>Статус:</p>
                            </div>
                            <div className="input">
                                <input type="text" readOnly
                                       value={StatusToRus(event.confirmation_status)}
                                />
                            </div>
                        </div>
                        <div className="form-input textarea">
                            <div className="title-group">
                                <p>Описание:</p>
                            </div>
                            <div className="input">
                                <textarea name="name" readOnly
                                          value={event.description}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="title">
                            <div className="title-text">
                                <p>Отметка оператора</p>
                            </div>
                            <div className="title-line">
                            </div>
                        </div>
                        <div className="form-input">
                            <div className="title-group">
                                <p>Статус:</p>
                            </div>
                            <div className="input">
                                <input type="text" readOnly
                                       value={StatusToRus(event.operator_decision?.confirmation_status)}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <div className="title-group">
                                <p>Дата и время:</p>
                            </div>
                            <div className="input">
                                <input type="text" readOnly
                                       value={TimestampToIntAndLocal(event.operator_decision?.date).toLocaleString()}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <div className="title-group">
                                <p>Оператор:</p>
                            </div>
                            <div className="input">
                                <input type="text" readOnly
                                       value={userFio(event.operator_decision?.user)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="title">
                            <div className="title-text">
                                <p>Отметка руководителя</p>
                            </div>
                            <div className="title-line">
                            </div>
                        </div>
                        <div className="form-input">
                            <div className="title-group">
                                <p>Статус:</p>
                            </div>
                            <div className="input">
                                <input type="text" readOnly
                                       value={StatusToRus(event.head_decision?.confirmation_status)}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <div className="title-group">
                                <p>Дата и время:</p>
                            </div>
                            <div className="input">
                                <input type="text" readOnly
                                       value={TimestampToIntAndLocal(event.head_decision?.date).toLocaleString()}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <div className="title-group">
                                <p>Руководитель:</p>
                            </div>
                            <div className="input">
                                <input type="text" readOnly
                                       value={userFio(event.head_decision?.user)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column small-11">
                    <div className="form-group">
                        <div className="title">
                            <div className="title-text">
                                <p>Изображение</p>
                            </div>
                            <div className="title-line"/>
                        </div>
                        <div className="modal-imgs">
                            <img
                                src={event.violationCrop}
                                alt=""
                                key={`viol_crop_${event.id}`}
                                onClick={()=> setOpenPhotoViol(true)}
                            />
                            {openPhotoViol &&
                                <Lightbox
                                    medium={event.violationCrop}
                                    large={event.violationCrop}
                                    hideDownload
                                    // hideZoom
                                    onClose={()=> setOpenPhotoViol(false)}
                                />
                            }
                            <img
                                src={event.crop}
                                alt=""
                                key={`crop_${event.id}`}
                                onClick={()=> setOpenPhoto(true)}
                            />
                            {openPhoto &&
                                <Lightbox
                                    medium={event.crop}
                                    large={event.crop}
                                    hideDownload
                                    hideZoom
                                    onClose={()=> setOpenPhoto(false)}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="column person small-6">
                    <div className="form-group">
                        <div className="title">
                            <div className="title-text">
                                <p>Видео</p>
                            </div>
                            <div className="title-line"/>
                        </div>
                        {event.video ?
                            <div className="person-wrapper">
                                <div className="player-area">
                                    <div>
                                        <video
                                            controls
                                            width="100%"
                                            height="100%"
                                            className="video-js-event vjs-default-skin vjs-big-play-centered"
                                        >
                                            <source src={event.video} type="video/mp4"/>
                                        </video>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <p className="small">Нет видео</p>
                            </div>
                        }
                    </div>
                    <div className="form-group">
                        <div className="title">
                            <div className="title-text">
                                <p>Работник</p>
                            </div>
                            <div className="title-line"/>
                        </div>
                        {event.photo_person ?
                            <div className="person-wrapper">
                                <div className="img-wrapper">
                                    <img src={event.photo_person} alt="" key={`photo_person${event.id}`}/>
                                </div>
                                <div className="select-person">
                                    { initialValues?.person_id === 0 ?
                                        <InputGroup title="Выберите работника">
                                            <Field
                                                id={"person_id"}
                                                name={"person_id"}
                                                component={SelectInput}
                                                options={persons}
                                                currentValue={currentValue}
                                                onChange={(e) => onChangePerson(e)}
                                            />
                                        </InputGroup>
                                        :
                                        <div className="hidden-input">
                                            <InputGroup title="Выберите работника">
                                                <Field
                                                    id={"person_id"}
                                                    name={"person_id"}
                                                    component={SelectInput}
                                                    options={persons}
                                                    currentValue={currentValue}
                                                    onChange={(e) => onChangePerson(e)}
                                                />
                                            </InputGroup>
                                            <p className="small">{currentValue.label}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            <div>
                                <p className="small">Нет фото работника</p>
                            </div>
                        }
                        {event.photo_person && formValues?.person_id?.value !== 0 && initialValues?.person_id === 0 && isHEAD(user.role) &&
                            <Field
                                name="add_person"
                                component={EnableCheckbox}
                                rightTitle={true}
                                labelText="Добавить фото работника в базу"
                            />
                        }
                        {employee && initialValues?.person_id === 0 && event.photo_person !== "" &&
                            <div className="photo-list" data-photo={event.photo_person}>
                                {employee.images.map(item => <img src={item.preview} alt=""/>)}
                            </div>
                        }
                        {isEmploeePending && initialValues?.person_id === 0 && event.photo_person !== "" &&
                            <div className="spinner-small-wrapper" data-photo={event.photo_person}>
                                <SpinnerSmall/>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {(!event.head_decision || isHEAD(user.role)) &&
                <div className="modal-btns">
                    <div className="margin-wrapper">
                        <button onClick={handleSubmit(values => {
                            const person_id = values.person_id; // из select в ID
                            const add_person = values.add_person ? values.add_person : false;
                            onSubmit({
                                values,
                                person_id: person_id ? person_id.value : 0,
                                violation: VIOLATION_SETTINGS.HAS_VIOLATION,
                                add_person: add_person,
                            });
                        })}
                        >
                            Нарушение
                        </button>

                        <button onClick={handleSubmit(values => {
                            const person_id = values.person_id; // из select в ID
                            const add_person = values.add_person ? values.add_person : false;
                            onSubmit({
                                values,
                                person_id: person_id ? person_id.value : 0,
                                violation: VIOLATION_SETTINGS.NO_VIOLATION,
                                add_person: add_person,
                            });
                        })}
                        >
                            Нет
                        </button>
                    </div>
                </div>
            }
        </form>
    )
}

const getCurrentPersonSelect = (personId, persons) => {

    for (const p of persons) {
        if (p.id === personId) {
            return {
                value: p.id,
                label: `${p.surname} ${p.firstname} ${p.patronymic}`
            }
        }
    }
    return {value: 0, label: 'Не выбрано'};
}

const getPersonsSelect = (person_id, persons) => {
    const currentValue = { value: 0, label: 'Не выбрано' };
    const resultPersons = [currentValue,];
    for (const p of persons) {
        resultPersons.push({
            value: p.id,
            label: `${p.surname} ${p.firstname} ${p.patronymic}`
        })
    }
    return resultPersons;
}

const mapStateToProps = (state) => {
    if (!state.event.event) {
        return {
            initialValues: {},
            formValues: getFormValues('EventForm')(state) || {},
        };
    }
    return {
        initialValues: {
            ...state.event.event,
            person_id: state.event.event.primary_objects[0].person.person_id,
            photo_person: state.event.event.primary_objects[0].person.faces[0]?.img_url,
            add_person: false,
            // currentPersonSelect: getCurrentPersonSelect(state.event.current.primary_objects[0].person.person_id, state.employees),
            // personsSelect: getPersonsSelect(state.employees),
            // employees
        },
        formValues: getFormValues('EventForm')(state) || {},
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'EventForm',
        enableReinitialize: true,
    })(EventForm)
)

// export default EventForm;
