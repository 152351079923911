import React from 'react';

import UserForm from '../forms/UserForm';
import Modal from './Modal';
import TabsWithContent from './TabsWithContent';
import UserSettingsForm from '../forms/UserSettingsForm';
import UserRolesForm from '../forms/UserRolesForm';
import RemoteSubmitButton from '../forms/RemoteSubmitButton';
import {connect} from 'react-redux';
import {createUser, saveUser, saveUserAccesses, saveUserPermissions} from '../store/Users';
import {SETTINGS} from '../utils/constants';
import Spinner from './Spinner';
import {cameraList} from '../store/Camera';
import {loadObjectsObservationList} from "../store/ObjectObservation";
import {clearPPEs, loadPPEs} from "../store/PPEs";
import UserAccessesForm from "../forms/UserAccessesForm";
import {thermalCameraList} from "../store/ThermalCamera";
import {userFio} from "../utils/userUtils";

const processNotificationSettingsValues = (values, userId) => {
    const cameras = [];
    const objectObservations = [];

    if (values.object_observations) {
        for (const obj of values.object_observations) {
            if (obj.id && obj.id.value) {
                objectObservations.push(obj)
            }
        }
    }
    if (values.cameras) {
        for (const obj of values.cameras) {
            if (obj.id && obj.id.value) {
                cameras.push(obj)
            }
        }
    }


    const data = {
        id: userId,
        cameras: cameras.map(value => ({...value, id: value.id.value})),
        object_observations: objectObservations.map(value => ({...value, id: value.id.value})),
        email: values.email,
        is_notify: values.isActive,
        send_type: values.sendType.active.value,
        notification_format: values.notificationFormat.active.value,
    };

    if (values.sendType.active.value === SETTINGS.NOTIFICATIONS_INTERVAL) {
        data.send_interval = parseInt(values.sendInterval, 10)
    }
    if (values.sendType.active.value === SETTINGS.NOTIFICATIONS_TIMETABLE) {
        data.send_at = values.sendAt
            .map(value => value.time)
            .filter(value => value !== undefined);
    }

    return data;
}

const permissionsSetValues = (values, cameras) => {
    const data = [];

    for (const value of values.permissionsList){
        const parseValue = value.split('-');
        if (parseValue.length > 3){
            const camId = parseValue[2];
            const cameraValue = cameras.filter(obj => obj.id === parseInt(camId));
            data.push({
                "id": cameraValue[0].id,
                "is_notification_enabled": true,
                "violation_categories": [
                    parseValue[3]
                ]
            });
        }
    }
    console.log("data", values.permissionsList);
    return data;
};

const accessesSetValues = (values, cameras) => {
    const data = {
        "cameras": [],
        "power_units": [],
        "objects": [],
    };

    for (const value of values.accecess){
        const parseValue = value.split('-');
        if (parseValue.length === 3){
            const camId = parseValue[2];
            const cameraValue = cameras.filter(obj => obj.id === parseInt(camId));
            data.cameras.push({
                "id": cameraValue[0].id,
                "is_accessed": true
            });
        }
        if (parseValue.length === 2){
            const ooId = parseValue[1];
            data.objects.push({
                "id": parseInt(ooId),
                "is_accessed": true
            });
        }
        if (parseValue.length === 1){
            const blockId = parseValue[0];
            data.power_units.push({
                "id": parseInt(blockId),
                "is_accessed": true
            });
        }
    }
    return data;
};

class UserModal extends React.Component {
    state = {
        isEditMode: false,
    };

    userFormValues = {};

    componentDidMount() {
        this.props.actions.loadCamerasData();
        this.props.actions.loadThermalCamerasData();
        this.props.actions.loadObjects();
    }

    onEditForm() {
        this.setState({isEditMode: true})
    };


    render() {
        const {onClose, onCancel, isPending, cameras, thermalCameras, userId, user} = this.props;
        const {saveUser, saveSettings, createUser, savePermissions, saveAccesses} = this.props.actions;

        return (
            <Modal
                title={userId ? `Пользователь ${userFio(user)}` : `Новый пользователь`}
                isOpen
                showEditButton={!this.state.isEditMode}
                closeButtonWithText={true}
                onClose={onClose}
                onEdit={() => this.onEditForm()}
                className="user-modal"
            >{isPending ?
                <Spinner/> :
                <>
                {userId ?
                    <>
                        <TabsWithContent
                            titles={['Данные', 'Доступы', 'Оповещения']}
                            tabs={[
                                <>
                                    <UserForm
                                        handleCancel={onCancel}
                                        createUser={false}
                                        onSubmit={values => {
                                            this.userFormValues = values;
                                            this.userFormValues = {...this.userFormValues, roles: values.roles.map(el => el.value)};
                                            if (this.userFormValues.id) {
                                                saveUser(this.userFormValues.id, this.userFormValues);
                                            } else {
                                                createUser(this.userFormValues);
                                            }
                                            onClose();
                                        }}
                                        isEditMode={this.state.isEditMode}
                                    />
                                    <div className="submit-wrapper">
                                        {this.state.isEditMode &&
                                        <RemoteSubmitButton
                                            forms={['UserForm']}
                                            className=""
                                        >
                                            Сохранить
                                        </RemoteSubmitButton>}
                                    </div>
                                </>,
                                <>
                                    <UserAccessesForm
                                        handleCancel={onCancel}
                                        onSubmit={values => {
                                            console.log("values", values);
                                            const data = accessesSetValues(values, [...cameras, ...thermalCameras]);
                                            saveAccesses(userId, data);
                                            onClose();
                                        }}
                                        isEditMode={this.state.isEditMode}
                                    />
                                    <div className="submit-wrapper">
                                        {this.state.isEditMode &&
                                        <RemoteSubmitButton
                                            forms={['UserAccessesForm']}
                                            className=""
                                        >
                                            Сохранить
                                        </RemoteSubmitButton>}
                                    </div>
                                </>,
                                <>
                                    <UserSettingsForm
                                        handleCancel={onCancel}
                                        onSubmit={values => {
                                            console.log("values1122", values);
                                            const data = permissionsSetValues(values, [...cameras, ...thermalCameras]);

                                            let resultData = [];
                                            const isExists = (c) => {
                                                for (const r of resultData) {
                                                    if (r.id === c.id) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            };
                                            const getItem = (c) => {
                                                for (const r in resultData) {
                                                    if (r.id === c.id) {
                                                        return r;
                                                    }
                                                }
                                                return null;
                                            };

                                            for (const d of data) {
                                                if (d['violation_categories'].length) {
                                                    if (!isExists(d)) {
                                                        resultData.push(d);
                                                    } else {

                                                        for (const item of resultData) {
                                                            if (item.id === d.id) {
                                                                item['violation_categories'].push(d['violation_categories'][0]);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            resultData = {
                                                'permissions': [...resultData],
                                                'is_periodic_reports_enable': values.is_periodic_reports_enable
                                            };

                                            savePermissions(userId, resultData);
                                            onClose();
                                        }}
                                        isEditMode={this.state.isEditMode}
                                    />
                                    <div className="submit-wrapper">
                                        {this.state.isEditMode &&
                                        <RemoteSubmitButton
                                            forms={['UserSettingsForm']}
                                            className=""
                                        >
                                            Сохранить
                                        </RemoteSubmitButton>}
                                    </div>
                                </>
                            ]}
                        />
                        </>
                    :
                    <>
                        <TabsWithContent
                            titles={['Данные']}
                            tabs={[
                                <UserForm
                                    handleCancel={onCancel}
                                    createUser={true}
                                    onSubmit={values => {
                                        this.userFormValues = values;
                                        this.userFormValues = {...this.userFormValues, roles: values.roles.map(el => el.value)};
                                        if (this.userFormValues.id) {
                                            saveUser(this.userFormValues.id, this.userFormValues);
                                        } else {
                                            createUser(this.userFormValues);
                                        }
                                        onClose();
                                    }}
                                    isEditMode={this.state.isEditMode}
                                />
                            ]}
                        />
                        <div className="submit-wrapper">
                            {this.state.isEditMode &&
                            <RemoteSubmitButton
                                forms={['UserForm']}
                                className=""
                            >
                                Сохранить
                            </RemoteSubmitButton>}
                        </div>
                    </>
                    }
                </>
            }</Modal>
        )
    }
}

export default connect(
    state => ({
        isPending: state.users.isUserPending || state.users.isListPending || state.thermalCamera.isListPending || !state.camera.list || state.camera.isListPending || state.object.isListPending || state.ppes.isPending,
        userId: state.users.current?.id,
        cameras: state.camera.list || [],
        user: state.users.current,
        thermalCameras: state.thermalCamera.list || [],
    }),
    dispatch => ({
        actions: {
            saveUser: (id, values) => {
                const {passwordRepeat, ...rest} = values;
                dispatch(saveUser(id, rest));
            },
            saveSettings: (id, values) => {
                // dispatch(saveUserNotificationSettings(id, values))
            },
            createUser: (values) => {
                const {passwordRepeat, ...rest} = values;
                dispatch(createUser(rest));
            },
            savePermissions: (id, data) => {
                dispatch(saveUserPermissions(id, data))
            },
            saveAccesses: (id, data) => {
                dispatch(saveUserAccesses(id, data))
            },
            loadObjects: () => {
                dispatch(loadObjectsObservationList());
            },
            loadPPEs: () => {
                dispatch(loadPPEs());
            },
            loadCamerasData: () => {
                dispatch(cameraList());
            },
            loadThermalCamerasData: () => {
                dispatch(thermalCameraList());
            },
        }
    })
)(UserModal);
