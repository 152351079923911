import {ROLES} from "../roles";

const userSelectCameraOptions = (cameras = []) => {
    let objectList = [];
    let newArr = [];
    cameras.map(object => {
        if (!objectList.includes(object.obj.name)){
            objectList.push(object.obj.name);
        }}
    );
    objectList.map(object => {
        let arrItem = [];
        cameras.map(camera => {
            if (object === camera.obj.name){
                arrItem.push({
                    label: camera.name,
                    value: camera.id
                });
            }}
        );
        newArr.push({
            label: object,
            options: arrItem,
        });
    });

    return newArr;
//     cameras.map(object => ({
//     label: object.obj.name,
//     options: object.cameras.map(
//         camera => ({
//             label: camera.name,
//             value: camera.id
//         })
//     )
// }
// ))
};

const userSelectObjectOptions = (cameras = []) => cameras.map(object => ({
    label: object.name,
    value: object.id
}));

const userSelectSendEventOptions = (sendTypes) => sendTypes.map(object => ({
        label: object[1],
        value: object[0],
    })
);

const userFormDataToObject = (data) => {
    const roles = data.roles ? data.roles.map(role => role.value) : [];
    return {...data, roles}
};

const cameraIdToName = (cameraId, cameras = []) => {
    const flatArray = cameras.reduce((acc, object) => [
            ...acc,
            ...(object.cameras || [])
        ], []
    );
    const match = flatArray.find(camera => camera.id === cameraId);
    if (match) {
        return match.name;
    }
};

const objectIdToName = (objectId, cameras = []) => {
    const flatArray = cameras.reduce((acc, object) => [...acc, object], []);
    const match = flatArray.find(object => object.id === objectId);
    if (match) {
        return match.name;
    }
};

const camerasIdListToOptions = (idList = [], cameras) => idList.map(
    cameraId => ({
        value: cameraId,
        label: cameraIdToName(cameraId, cameras)
    }));

const userFio = (user) => {
    if (!user) return '';
    let fio = user.surname;
    if (user.firstname) {
        fio += ` ${user.firstname[0]}.`;
        if (user.patronymic) {
            fio += ` ${user.patronymic[0]}.`;
        }
    }
    return fio;
};

export const roleNameToConst = (roleName) => Object.keys(ROLES).find(key => ROLES[key] === roleName);

export const isHEAD = (roleName) => Object.keys(ROLES).find(key => ROLES[key] === roleName) === "HEAD";
export const isADMIN = (roleName) => Object.keys(ROLES).find(key => ROLES[key] === roleName) === "AUTH_ROLE_ADMIN";

export {
    userSelectCameraOptions,
    userSelectObjectOptions,
    userFormDataToObject,
    cameraIdToName,
    objectIdToName,
    camerasIdListToOptions,
    userSelectSendEventOptions,
    userFio,
}
