import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-regular-svg-icons';

import Modal from './Modal';
import TabsWithContent from './TabsWithContent';
import RemoteSubmitButton from '../forms/RemoteSubmitButton';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import {cameraClearState, cameraCreate, cameraEdit, cameraList, cameraLoad} from '../store/Camera';
import {cameraToApiData} from "../utils/utils";
import {clearPPEs, loadPPEs} from "../store/PPEs";
import {clearWorkwear, loadWorkwear} from "../store/Workwear";
import CameraFormWithTabs from "../forms/CameraFormWithTabs";
import VideoRecorderForm from '../forms/VideoRecorderForm';
import VideoRecorderUserForm from '../forms/VideoRecorderUserForm';
import VideoRecorderUpVideoForm from '../forms/VideoRecorderUpVideoForm';
import {videoRecorderUpVideo} from '../store/VideoRecorderUp'

const objToIdsArr = (items) => items.map(item => item.name);

class VideoRecorderUpVideo extends React.Component {
    state = {
        isEditMode: true,
    };

    userFormValues = {};

    componentDidMount() {
        // console.log("this.props.cameraActive", this.props.cameraActive);
        // this.props.actions.loadCamerasData();
        // this.props.actions.loadWorkwear();
        // this.props.actions.loadPPEs();
        // this.props.actions.loadCamera();
    }

    onEditForm() {
        this.setState({isEditMode: true})
    };

    render() {
        const {onClose, onCancel, isPending, isError, objectsList, camera, employees, cameras, user} = this.props;
        const {createCamera, updateCamera, createVideo} = this.props.actions;
        this.userFormValues = camera;

        return (
            <Modal
                title={`Загрузить видеофайл `}
                isOpen
                closeButtonWithText={true}
                onClose={onClose}
                onEdit={() => this.onEditForm()}
                className="user-modal"
            >
                {isPending
                    ?
                    isError ?
                        <p>Ошибка при загрузке данных</p>
                        :
                        <Spinner/>
                    :
                    <>
                        <TabsWithContent
                            titles={['Данные']}
                            tabs={[
                                <>
                                    <VideoRecorderUpVideoForm
                                        handleCancel={onCancel}
                                        onSubmit={values => {
                                            this.userFormValues = values;
                                            // console.log("this.userFormValues", this.userFormValues);
                                            createVideo(this.userFormValues.attached, user.userId, this.userFormValues.dashcam_id.value);
                                            onClose();
                                        }}
                                        isEditMode={this.state.isEditMode}
                                        objectsList={objectsList}
                                        employees={employees}
                                        cameras={cameras}
                                    />
                                    <div className="submit-wrapper">
                                        {this.state.isEditMode &&
                                        <RemoteSubmitButton
                                            forms={['VideoRecorderUpVideoForm']}
                                            className=""
                                        >
                                            Сохранить
                                        </RemoteSubmitButton>}
                                    </div>
                                </>
                            ]}
                        />
                    </>
                }

            </Modal>
        )
    }
}

export default connect(
    state => ({
        user: state.user,
        isPending: state.videoRecorderUp.isListPending,
        isError: state.videoRecorderUp.error,
    }),
    dispatch => ({
        actions: {
            loadCamerasData: () => {
                dispatch(cameraList());
            },
            createVideo: (fileArr, person, dashcam_id) => {
                dispatch(videoRecorderUpVideo(fileArr, person, dashcam_id));
            },
            loadCamera: (camera) => {
                // dispatch(cameraClearState());
                dispatch(cameraLoad(camera.object, camera.id));
            },
            updateCamera: (cameraId, camera) => {
                const apiData = cameraToApiData(camera);
                dispatch(cameraEdit(camera.obj_id, cameraId, apiData));
            },
            loadPPEs: () => {
                dispatch(clearPPEs());
                dispatch(loadPPEs());
            },
            loadWorkwear: () => {
                dispatch(clearWorkwear());
                dispatch(loadWorkwear());
            },
        }
    })
)(VideoRecorderUpVideo);
