import axios from 'axios';

const spaceSeparatedToArray = str => str ? str.split(' ') : [];

const GIT = {
    LOG:             'GIT_LOG',
    ASYNC_TASK:      'GIT_ASYNC_TASK',
    CURRENT_COMMIT:  'GIT_CURRENT_COMMIT',
    IS_PENDING:      'GIT_IS_PENDING',
    IS_PENDING_TASK: 'GIT_IS_PENDING_TASK',
};

const initialState = {};

const gitReducer = (state = initialState, action) => {
    switch (action.type) {
        case GIT.LOG:
            return {
                ...state,
                log: action.payload.map(commit => ({
                    ...commit,
                    parents: spaceSeparatedToArray(commit.parent),
                    parentsAbbrev: spaceSeparatedToArray(commit.abbreviated_parent),
                    hash: commit.commit,
                    hashAbbrev: commit.abbreviated_commit,
                    treeAbbrev: commit.abbreviated_tree,
                    committer: {
                        ...commit.commiter,
                        timestamp: new Date(commit.commiter.date).getTime()
                    },
                })),
                isPending: false
            };
        case GIT.CURRENT_COMMIT:
            return {
                ...state,
                currentCommit: action.payload,
            };
        case GIT.IS_PENDING:
            return {
                ...state,
                isPending: true
            }
        case GIT.IS_PENDING_TASK:
            return {
                ...state,
                taskResult: null,
                isPendingTask: true
            }
        case GIT.ASYNC_TASK:
            return {
                ...state,
                taskResult: action.payload,
                isPendingTask: false
            };
        default:
            return state;
    }
};

export default gitReducer;

export const loadGitLog = () => {
    return dispatch => {
        dispatch({
            type: GIT.IS_PENDING,
            payload: null
        });
        axios.get(`/api/model-storage/git/log/`)
            .then(res => {
                console.log('res.data>>>>', res.data);
                dispatch({
                    type: GIT.LOG,
                    payload: res.data,
                });
            });
    }
};

export const loadCurrentCommit = () => {
    return dispatch => {
        axios.get(`/api/model-storage/git/current/commit/`)
            .then(res => {
                dispatch({
                    type: GIT.CURRENT_COMMIT,
                    payload: res.data,
                });
            });
    }
};

export const checkout = (hash) => {
    return dispatch => {
        dispatch({
            type: GIT.IS_PENDING_TASK,
            payload: null
        });
        axios.post(`/api/model-storage/git/checkout/`, {hash})
            .then(res => {
                dispatch({
                    type: GIT.ASYNC_TASK,
                    payload: res.data
                });
            })
            .then(() => {
                dispatch(loadCurrentCommit())
            });
    }
};

export const updateModelRepository = () => {
    return dispatch => {
        dispatch({
            type: GIT.IS_PENDING,
            payload: null
        });
        axios.post(`/api/model-storage/git/pull/`)
            .then(res => {
                dispatch({
                    type: GIT.ASYNC_TASK,
                    payload: res.data
                });
            })
            .then(() => {
                dispatch(loadGitLog());
            });
    }
}
