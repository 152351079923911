import React from 'react';
import videojs from 'video.js'
import 'videojs-errors';
import 'video.js/dist/video-js.css';
//import 'video-react/dist/video-react.css';

videojs.options.hls.overrideNative = true;
videojs.options.html5.nativeAudioTracks = false;
videojs.options.html5.nativeVideoTracks = false;

window.videojs = videojs;

// require('videojs-contrib-hls/dist/videojs-contrib-hls.js');

class VideoPlayerHls extends React.Component {
    componentDidMount() {
        // instantiate Video.js
        const config = {
            html5: {
                nativeAudioTracks: false,
                nativeVideoTracks: false,
                hls: {
                    debug: true,
                    overrideNative: true
                }
            }
        };
        this.player = videojs(this.videoNode, config, () => {
            console.log('onPlayerReady', this);
            const errorDisplay = this.player.getChild('errorDisplay');
            errorDisplay.off(this.player, 'error', errorDisplay.open);

            try {
                this.player.src({
                    src: this.props.src,
                    type: 'application/x-mpegURL',
                    withCredentials: true
                });
                this.player.on('error', (e) => {
                    // еще раз
                    // this.player.error({code: 'foo', dismiss: true});
                    if (!this.player || !this.props.src) {
                        return;
                    }
                    setTimeout(() => {
                        if (this.player && this.props.src) {
                            this.player.src({
                                src: this.props.src,
                                type: 'application/x-mpegURL',
                                withCredentials: true
                            });
                        }
                    }, 1000);
                });
            } catch (e) {
                console.log('player err>', e);
            }
        });
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
            delete this.player;
        }
    }

    render() {
        return (
            <div className="player-area">
                <div data-vjs-player>
                    <video
                        ref={node => this.videoNode = node}
                        autoPlay
                        controls
                        width="100%"
                        height="100%"
                        className="video-js vjs-default-skin vjs-big-play-centered"
                        data-setup={'{"fluid": true}'}
                    />
                </div>
            </div>
        )
    }
}

export default VideoPlayerHls;
