import React from 'react';

import Modal from './Modal';
import TabsWithContent from './TabsWithContent';
import RemoteSubmitButton from '../forms/RemoteSubmitButton';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import {notificationDevicesCreate, notificationDevicesEdit, notificationDevicesList, notificationDevicesLoad} from '../store/NotificationDevices';
import {clearPPEs, loadPPEs} from "../store/PPEs";
import {clearWorkwear, loadWorkwear} from "../store/Workwear";
import DevicesPermissionsForm from "../forms/DevicesPermissionsForm";
import NotificationDevicesForm from '../forms/NotificationDevicesForm';


const modCategoryDetViol = (ppes, camera) => {
    let modData = [];

    if (camera && Object.keys(camera).length !== 0) {
        modData = ppes.map(ppe => {
            if (camera.dangerous_categories?.find(object => object.name === ppe.id)) {
                return {
                    value: "viol",
                    name: ppe.id,
                    label: ppe.ru_name
                }
            } else if (camera.usual_categories?.find(object => object.name === ppe.id)) {
                return {
                    value: "detect",
                    name: ppe.id,
                    label: ppe.ru_name
                }
            } else {
                return {
                    value: "pass",
                    name: ppe.id,
                    label: ppe.ru_name
                }
            }
        })
    } else {
        modData = ppes.map(ppe => {
            return {
                value: "pass",
                name: ppe.id,
                label: ppe.ru_name
            }
        })
    }
    return modData;
};

const valuesToApiForm = (modData, values) => {
    let detectionCategories = [];
    let violationCategories = [];
    const newModData = modData.map(item => {
        let newVal;
        for (const object of Object.entries(values)) {
            if (object[0] === item.name) {
                newVal = object[1];
            }
        }
        if (newVal) {
            return {
                value: newVal,
                name: item.name,
                label: item.label
            }
        } else {
            return {
                value: item.value,
                name: item.name,
                label: item.label
            }
        }
    });


    for (const item of newModData) {
        if (item.value === "detect") {
            detectionCategories.push(item.name)
        } else if (item.value === "viol") {
            violationCategories.push(item.name)
        }
    }

    return [detectionCategories, violationCategories];
}
    

class NotificationDevicesModal extends React.Component {
    state = {
        isEditMode: false,
    };

    userFormValues = {};

    componentDidMount() {
        // console.log("this.props.cameraActive", this.props.cameraActive);
        // this.props.actions.loadCamerasData();
        this.props.actions.loadWorkwear();
        // this.props.actions.loadPPEs();
        // this.props.actions.loadCamera();
    }

    onEditForm() {
        this.setState({isEditMode: true})
    };

    render() {
        const {onClose, onCancel, isPending, isError, objectsList, ppes, camerasList, workwear, camera, employees, cameras, notificationDevices} = this.props;
        const {createCamera, updateCamera} = this.props.actions;
        this.userFormValues = camera;
        return (
            <Modal
                title={`Устройство оповещения `}
                isOpen
                showEditButton={!this.state.isEditMode}
                closeButtonWithText={true}
                onClose={onClose}
                onEdit={() => this.onEditForm()}
                className="user-modal"
            >
                {isPending
                    ?
                    isError ?
                        <p>Ошибка при загрузке данных</p>
                        :
                        <Spinner/>
                    :
                    <>
                        <TabsWithContent
                            titles={['Данные', 'Детекторы']}
                            tabs={[
                                <>
                                    <NotificationDevicesForm
                                        handleCancel={onCancel}
                                        onSubmit={values => {
                                            this.userFormValues = values;
                                            console.log("this.userFormValues", this.userFormValues);
                                            this.userFormValues.device_type = 1;
                                            this.userFormValues.credentials = {
                                                'login': this.userFormValues.login,
                                                'password': this.userFormValues.password
                                            }
                                            if (this.userFormValues.id) {
                                                updateCamera(this.userFormValues.id, this.userFormValues);
                                            } else {
                                                createCamera(this.userFormValues);
                                            }
                                            onClose();
                                        }}
                                        isEditMode={this.state.isEditMode}
                                        objectsList={objectsList}
                                        employees={employees}
                                        cameras={cameras}
                                        camerasList={camerasList}
                                    />
                                    <div className="submit-wrapper">
                                    {this.state.isEditMode &&
                                        <RemoteSubmitButton
                                            forms={['NotificationDevicesForm']}
                                            className=""
                                        >
                                        Сохранить
                                    </RemoteSubmitButton>}
                                </div>
                                </>,
                            //     <>
                            //     <DevicesPermissionsForm
                            //         handleCancel={onCancel}
                            //         onSubmit={values => {
                            //             let [detectionCategories, violationCategories] = valuesToApiForm(modCategoryDetViol([...workwear, ...ppes], camera), values);
                            //             this.userFormValues.dangerous_category_names = violationCategories;
                            //             this.userFormValues.usual_category_names = detectionCategories;
                            //             if (this.userFormValues.id) {
                            //                 updateCamera(this.userFormValues.id, this.userFormValues);
                            //             } else {
                            //                 createCamera(this.userFormValues);
                            //             }
                            //             onClose();
                            //         }}
                            //         isEditMode={this.state.isEditMode}
                            //         objectsList={objectsList}
                            //         modPpes={modCategoryDetViol([...workwear, ...ppes], camera)}
                            //     />
                            //     <div className="submit-wrapper">
                            //         {this.state.isEditMode &&
                            //         <RemoteSubmitButton
                            //             forms={['NotificationDevicesForm', 'DevicesPermissionsForm']}
                            //             className=""
                            //         >
                            //             Сохранить
                            //         </RemoteSubmitButton>}
                            //     </div>
                            // </>
                            ]}
                        />
                    </>
                }

            </Modal>
        )
    }
}

export default connect(
    state => ({
        isPending: state.notificationDevices.isListPending || !state.workwear.list || state.object.isListPending,
        isError: state.workwear.isError || state.notificationDevices.isError,
        ppes: state.ppes.list,
        workwear: state.workwear.list,
        camera: state.notificationDevices.current,
    }),
    dispatch => ({
        actions: {
            loadCamerasData: () => {
                dispatch(notificationDevicesList());
            },
            createCamera: (camera) => {
                dispatch(notificationDevicesCreate(camera));
            },
            loadCamera: (camera) => {
                // dispatch(cameraClearState());
                dispatch(notificationDevicesLoad(camera.id));
            },
            updateCamera: (cameraId, camera) => {
                dispatch(notificationDevicesEdit(cameraId, camera));
            },
            loadPPEs: () => {
                dispatch(clearPPEs());
                dispatch(loadPPEs());
            },
            loadWorkwear: () => {
                dispatch(clearWorkwear());
                dispatch(loadWorkwear());
            },
        }
    })
)(NotificationDevicesModal);
