import React, {useEffect, useState} from 'react';
import {PAGE_SIZE, START_PAGE, VIOLATION, VIOLATION_SETTINGS} from '../utils/constants';
import {change, Field, formValues, getFormValues, reduxForm, FieldArray} from "redux-form";
import {EnableCheckbox, InputField, InputGroup, InputSimple, renderDateTimePicker, SelectInput} from "../inputs";

import {connect} from "react-redux";
import Legend from "./Legend";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";
import {userFio} from "../utils/userUtils";
import DeleteItemList from "../assets/img/icons/DeleteItemList";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const required = value => value ? undefined : 'Обязательное поле';

const timestamp = date => new Date(date).getTime() * 1000;

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: `${entry.name} (${entry.power_unit.name})`
        }
    })
};

const primarySelectOptions = (options) => {
    return options.map(ppe => {
        return {
            value: ppe.name,
            label: ppe.ru_name
        }
    })
};

const empSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: userFio(entry)
        }
    })
};

const cupboardSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.cabinet_int.name
        }
    })
};

const personsActive = (actIds, employees) => {
    let activeArr = [];
    if (actIds) {
        for (const actId of actIds) {
            const employee = employees.find(item => item.id === actId);
            if (employee) {
                activeArr.push({
                    value: employee.id,
                    label: userFio(employee)
                })
            }
        }
    }
    return activeArr;
}

const cupboardActive = (actIds, cupboards) => {
    let activeArr = [];
    if (actIds) {
        for (const actId of actIds) {
            const cupboard = cupboards.find(item => item.id === actId);
            if (cupboard) {
                activeArr.push({
                    value: cupboard.id,
                    label: cupboard.cabinet_int.name
                })
            }
        }
    }
    return activeArr;
}

const roomsActive = (actIds, rooms) => {
    let activeArr = [];
    if (actIds) {
        for (const actId of actIds) {
            const room = rooms.find(item => item.id === actId);
            if (room) {
                activeArr.push({
                    value: room.id,
                    label: `${room.name} (${room.power_unit.name})`,
                })
            }
        }
    }
    return activeArr;
}

const SwithingForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, event, saveEvent, employees, objects, onSubmit, row, initialValues, cupboardLayoutList, primaryList}) => {
    const className = (fieldName) => forms && forms.user && !forms.user[fieldName].valid ?
        'is-invalid' : '';

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = true;
        const options = objSelectOptions(objects);
        const placeholder = 'Выберите помещение';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormPrimarySelect = ({input, validate}) => {
        const isMulti = false;
        const options = primarySelectOptions(addEmptyValue(primaryList));
        const placeholder = 'Выберите тип спецодежды';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormPersonsSelect = ({input, validate}) => {
        const isMulti = true;
        const options = empSelectOptions(employees);
        const placeholder = 'Выберите работников';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormCupboardsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = cupboardSelectOptions(cupboardLayoutList);
        const placeholder = 'Выберите шкафы';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const addEmptyValue = (arr) => {
        if (arr.find((i) => i.name === "0") === undefined) {
            arr.unshift({
                name: "0",
                ru_name: "Все"
            });
        }
        return arr;
    };

    const renderList = ({ fields, meta: { error } }) => (
        <ul>
            {fields.map((hobby, index) => (
                <li key={index}>
                    <Field
                        name={hobby}
                        type="text"
                        component={ReduxFormCupboardsSelect}
                        label={`${index + 1}`}
                    />
                    <button
                        className="remove-btn"
                        type="button"
                        onClick={() => fields.remove(index)}
                    >
                        <DeleteItemList/>
                    </button>
                </li>
            ))}
            {error && <li className="error">{error}</li>}
            <li>
                <button type="button" className="add-cupboard" onClick={() => fields.push()}>
                    <FontAwesomeIcon icon={faPlus} className="attach-label--icon"/>
                    Добавить шкаф
                </button>
            </li>
        </ul>
    )

    return (
        <div className="modal-content">
            <form onSubmit={values => {}} className="eventForm ">
                <Legend title="Бланк переключений" className="long-title">
                    <Field name="priority" type="text" label="Тип работ"
                           component={InputField} className={className('priority')}
                           disabled={true}
                           validate={[required]}/>
                    <Field name="document_number" type="text" label="Номер бланка переключений"
                           component={InputField} className={className('document_number')}
                           validate={[required]}/>
                    <InputGroup title="Помещение" >
                        <Field
                            name="job_rooms"
                            className={className('job_rooms')}
                            component={ReduxFormObjectsSelect}
                            validate={[required]}
                        />
                    </InputGroup>
                    <Field name="job_time_from" type="text"
                           label="Дата и время от"
                           showTime={true}
                           title="Время указывается в часовом поясе Мск"
                           component={renderDateTimePicker}
                           className={className('job_time_from')}
                           validate={[required]}/>
                    <Field name="job_time_to" type="text"
                           label="Дата и время до"
                           showTime={true}
                           component={renderDateTimePicker}
                           className={className('job_time_to')}
                           validate={[required]}/>
                    <InputGroup title="Типы спецодежды" >
                        <Field
                            name="primary_category"
                            className={className('primary_category')}
                            component={ReduxFormPrimarySelect}
                            validate={[required]}
                        />
                    </InputGroup>
                    <InputGroup title="Список работников" >
                        <Field
                            name="workers"
                            className={className('workers')}
                            component={ReduxFormPersonsSelect}
                            validate={[required]}
                        />
                    </InputGroup>

                    <InputGroup title="Порядок обхода" >
                        <FieldArray name="switchover_ordering" component={renderList} />
                    </InputGroup>

                    <Field
                        name="is_active"
                        component={EnableCheckbox}
                        labelText="В работе"
                    />
                </Legend>
                <div className="submit-wrapper">
                    <button onClick={handleSubmit(values => {
                        onSubmit(values)
                    })}
                    >
                        Сохранить
                    </button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    if (Object.keys(props.row).length === 0){
        return {
            initialValues: {
                priority: 'Работа по бланку переключений',
                document_type: 2,
            }
        }
    }
    return {
        initialValues: {
            ...props.row,
            priority: 'Работа по бланку переключений',
            workers: personsActive(props.row.workers, props.employees),
            switchover_ordering: cupboardActive(props.row.switchover_info.ordering, props.cupboardLayoutList),
            job_time_from: timestamp(props.row.job_time_from),
            job_time_to: timestamp(props.row.job_time_to),
            primary_category: {
                value: props.row.primary_category ? props.row.primary_category : "0",
                label: props.row.primary_category_ru ? props.row.primary_category_ru : "Все"
            },
            //job_room: {
            //    value: props.row.job_room.id,
            //    label: props.row.job_room.name
            //}
            job_rooms: roomsActive(props.row.job_room_ids, props.objects)
        }
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'SwithingForm',
        enableReinitialize: true,
    })(SwithingForm)
)

// export default EventForm;
