import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const ConfirmDeleteModal = (props) => {
  return (
    <>
      <div className={'modal-wrapper'}>
        <div className="zonedelete-modal">
          <div className="modal-header">
            <div className="modal-title">Подтвердите действие</div>
            <div className="btn-wrapper">
              <button onClick={props.onClose}>
                <FontAwesomeIcon icon={faTimes}/>
              </button>
            </div>
          </div>
          <div className='zonedelete-modal-modalwrapper'>
            <div className="zonedelete-modal-modalwrapper-dialog">
              <p>Вы действительно хотите удалить выбранную зону?</p>
            </div>
            <div className='zonedelete-modal-modalwrapper-submitwrapper'>
              <div className="zonedelete-modal-modalwrapper-submitwrapper--button">
                <button type="button"
                        onClick={props.onClose}
                        className="zonedelete-modal-modalwrapper-submitwrapper--button__cancel">
                  Отмена
                </button>
              </div>
              <div className="zonedelete-modal-modalwrapper-submitwrapper--button">
                <button type="button"
                        onClick={props.onDelete}
                        className="zonedelete-modal-modalwrapper-submitwrapper--button__submit">
                  Удалить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDeleteModal;
