import React, {useState} from 'react';
import {connect} from 'react-redux';
import {change, Field, reduxForm} from 'redux-form';

import Legend from './Legend';
import {InputField} from '../inputs';

const required = value => value ? undefined : 'Обязательное поле';

const days = [
  { name: 'Понедельник', value: 1 },
  { name: 'Вторник', value: 2 },
  { name: 'Среда', value: 3 },
  { name: 'Четверг', value: 4 },
  { name: 'Пятница', value: 5 },
  { name: 'Суббота', value: 6 },
  { name: 'Воскресенье', value: 7 },
];

const renderDaysSelectInput = ({input, day, label}) => {
  const stateOptions = () => days.map(option => (
    <option value={option.value} key={option.value}>
      {`${option.name}`}
    </option>
  ));
  return (
    <div className='form-input'>
      <div className="title-group">
        <p>{label}</p>
      </div>
      <select
        className="input"
        value={input.value}
        onChange={(e) => input.onChange(e.target.value)}
        style={{height: '30px', marginTop: '15px', marginLeft: '30px'}}
      >
        {stateOptions()}
      </select>
    </div>
  );
}

const ClearSettingsForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting}) => {
    const className = (fieldName) => forms && forms.user && !forms.user[fieldName].valid ?
        'is-invalid' : '';

    return (
        <div className="small-24">
            <div className="column small-8">
                <form onSubmit={handleSubmit} >
                    <fieldset className="settings-wrapper" >
                            <Legend title="Настройки очистки устаревших событий">
                                <Field name="events_cleanup_interval_sec" type="number" label="Интервал запуска очистки, сек"
                                       component={InputField} className={className('events_cleanup_interval_sec')}
                                       validate={[required]}/>
                                <Field name="events_storing_duration_days" type="number" label="События актуальны в течении, дней"
                                       component={InputField} className={className('events_storing_duration_days')}
                                       validate={[required]}/>
                            </Legend>
                        <Legend title="Настройки рассылки отчетов">
                          <Field name="cron_report_day"
                                 type="number"
                                 label="День недели отправки отчета"
                                 component={renderDaysSelectInput}
                                 className={className('cron_report_day')}
                                 validate={[required]}/>
                          <Field name="cron_report_time"
                                 type="text"
                                 label="Время отправки отчета"
                                 component={InputField}
                                 className={className('cron_report_time')}
                                 validate={[required]}/>
                        </Legend>
                        <button type="submit" disabled={pristine || submitting}>
                            Сохранить
                        </button>
                    </fieldset>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    if (!state.clearSetting.values) {
        return {};
    }
    return {
        initialValues: {
            ...state.clearSetting.values,
            ...state.clearSetting.schedule,
        },
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'ClearSettingsForm',
        enableReinitialize: true,
    })(ClearSettingsForm)
)
