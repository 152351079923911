import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import {InputGroup} from '../inputs';
import Legend from './Legend';
import {ReduxFormSelect} from '../utils/inputs';
import {ROLE_NAMES, ROLES} from '../roles';

const ReduxFormRolesSelect = ({input}) => {
    const isMulti = true;
    const options = userSelectRoleOptions();
    const placeholder = 'Выберите одну или несколько ролей';
    return ReduxFormSelect(isMulti, options, placeholder)({input});
};

const UserRolesForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, isEditMode}) => {
    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="Права пользователя на роли" className="rols-form">
                    <InputGroup title="Роли">
                        <Field
                            name="roles"
                            component={ReduxFormRolesSelect}
                        />
                    </InputGroup>
                </Legend>
            </fieldset>
        </form>
    );
}

const userSelectRoleOptions = () => {
    return Object.entries(ROLE_NAMES).map(entry => {
        const [key, value] = entry;
        return {
            value: ROLES[key],
            label: value
        }
    })
};

const userSelectRoleActiveOptions = (roles) => roles.map(role => {
    const roleKey = Object.keys(ROLES).find(key => ROLES[key] === role);
    return {
        value: role,
        label: ROLE_NAMES[roleKey] || role
    }
});

const mapStateToProps = (state) => {
    if (!state.users.current) {
        return {};
    }
    return {
        initialValues: {
            roles: userSelectRoleActiveOptions(state.users.current.roles),
        },
    }
};

export default connect(mapStateToProps)(
    reduxForm({
        form: 'UserRolesForm',
        enableReinitialize: true,
    })(UserRolesForm)
)
