import React, {useEffect, useState} from 'react';
import DataErrorIndication from './DataErrorIndication';

const withDataErrorIndication = (WrappedComponent) => (props) => {
    const {isError, ...rest} = props;

    const [shouldRetry, setShouldRetry] = useState(true);

    useEffect(() => {
        setShouldRetry(false);
    }, [])

    return (
        !isError || shouldRetry ?
            <WrappedComponent {...rest} /> : <DataErrorIndication/>
    )
};

export default withDataErrorIndication;
