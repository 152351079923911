import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

import Legend from './Legend';
import {RadioField, RadioFieldDevices} from '../inputs';

const DevicesPermissionsForm = ({handleSubmit, formValues, handleCancel, forms, pristine, reset, submitting, dispatch, userId, isEditMode, initialValues, modPpes, activeCams, camerasActive}) => {
    const [modPpesNew, setModPpesNew] = useState([]);
    const [newStatus, setNewStatus] = useState(0);

    useEffect(() => {
        setModPpesNew([]);
    }, []);

    useEffect(() => {
        setModPpesNew(modPpes);
        setNewStatus(newStatus + 1);
        modPpes.map (item =>
            dispatch(change('DevicesPermissionsForm', item.name, item.value))
        )
    }, [modPpes]);

    const className = (fieldName) => forms && forms.user && !forms.user[fieldName].valid ?
        'is-invalid' : '';

    return (
        <form id={newStatus} onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="События">
                    <div className="overflow-div">
                        {/*{modPpes.length === 0 && <p>Выберите камеры</p>}*/}
                        {/*{modPpes?.map((ppes,j) => (*/}
                        {/*    <>*/}
                                {modPpesNew.length !== 0 ?
                                    modPpesNew.map((ppe,i) => (
                                        <div key={i} className="viol-types-detect">
                                            <label>{ppe.label}</label>
                                            <div className="viol-types-detect-answers">
                                                <Field name={`${ppe.name}`} id={`${ppe.name}`} component={RadioFieldDevices} custom="11111" value={ppe.value} initialValues={ppe.value}/>
                                            </div>
                                        </div>
                                    ))
                                :
                                    <p>Категорий нарушений нет</p>
                                }
                        {/*    </>*/}
                        {/*))*/}
                        {/*}*/}
                    </div>
                </Legend>
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state, props) => {
    if (!state.notificationDevicesMapping.current?.camera_info) {
        return {
            initialValues: {},
        };
    }
    return {
        initialValues: {
            // ...state.notificationDevicesMapping.current,
            modPpes: props.modPpes,
        },
        formValues: getFormValues('DevicesPermissionsForm')(state) || {},
        cameraId: state.notificationDevicesMapping.current.id,
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'DevicesPermissionsForm',
        enableReinitialize: true,
    })(DevicesPermissionsForm)
);

