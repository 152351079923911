import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';
import {createTextMask } from 'redux-form-input-masks';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup, InputSimpleWithTitleTop, renderDateTimePicker} from '../inputs';
import {splitCameraUri, isBlock, rtspCredentials, omitRooms} from "../utils/utils";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";
import {userFio} from "../utils/userUtils";
import { parseFileName } from "../components/WorkwearModal";
import close from "./FileInput/close.png";
import AttachVideo from "./FileInput/AttachVideo";
import {dashcamInPower} from "../utils/utils";

const required = value => value ? undefined : 'Обязательное поле';

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.name ? `${entry.name}` : entry.ru_name
        }
    })
};

const protocolSelectActiveOptions = (protocol) => {
    return {
        value: protocol,
        label: protocol
    }
};

const objectsSelectActiveOptions = (objects, obj_id) => objects.map(object => {
    const activeObj = objects.find(item => item.id === obj_id);
    return {
        value: activeObj.id,
        label: `${activeObj.name}`
    }
});

const empSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: userFio(entry)
        }
    })
};

const cameraSelectOptions = (options) => {
    console.log("options", options);
    return options.map(entry => {
        return {
            value: entry.id,
            label: `${entry.number} (${entry.obj.name})`
        }
    })
};

const AttachmentForm = ({ attachmentData, removeAttached }) => {
    const [attachments, setAttachments] = useState([]);
    const [errors, setErrors] = useState(null);
  
    useEffect(() => {
      if(attachmentData.file_uri) {
        setAttachments([{ name: parseFileName(attachmentData.file_uri) }]);
      }
    }, [attachmentData])
  
    const remove = () => {
      setAttachments([]);
      removeAttached('');
    }
  
    return (
      <>
        {
          attachments.length !== 0 ?
            attachments.map((attr, index) => {
              return (
                <div key={'fileattached' + index} className="attach-wrapper video-upload">
                  <p className="attach-attached">{ attr.name }</p>
                  <img src={close} className="attach-attached--remove" onClick={remove} alt="" />
                </div>)
            })
  
        :
            <Field isOnlyFileAdd={true} component={AttachVideo} href={undefined} className="eventInput eventUserComment"
                onError={(error) => {
                    setErrors(error);
                }}
                name="attached"
                type="file"
                fileReady={attachments}
                onChange={(fileData) => {
                    if (fileData && fileData.base64) {
                    setErrors(null);
                    const data = [...attachments, {...fileData}];
                    setAttachments(data);
                    }
                }}
            />
        }
        {errors ? <>
          <div className="attach-attached--invalid">{ errors }</div>
        </> : '' }
      </>
    );
  }

const VideoRecorderUpVideoForm = ({handleSubmit, forms, pristine, reset, submitting, dispatch, userId, isEditMode, objectsList, ppes, formValues, cameras, employees}) => {
    const [currentDoc, setCurrentDoc] = useState({ name: '' });
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';
    dispatch(change('VideoRecorderUpVideoForm', 'id', userId)); // скрытое поле в форме с id юзера

    const [objectId, setObjectId] = useState(null);

    const removeAttached = () => {
        dispatch(change('VideoRecorderUpVideoForm', 'attached', ''));
      }

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = objSelectOptions(omitRooms(objectsList));
        const placeholder = 'Выберите энергоблок';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    const ReduxFormPersonsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = empSelectOptions(employees);
        const placeholder = 'Выберите пользователя';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormCameraSelect = ({input, validate}) => {
        const isMulti = false;
        console.log("objectId", cameras);
        const options = cameraSelectOptions(objectId ?
            dashcamInPower(objectId, cameras) : cameras);
        const placeholder = 'Выберите видеорегистратор';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };


    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="Основные данные">
                {/* <InputGroup title="Энергоблок" >
                        <Field
                            name="object"
                            className={className('object')}
                            component={ReduxFormObjectsSelect}
                            validate={[]}
                            onChange={(e) => {
                                setObjectId(e.value);
                            }}
                        />
                    </InputGroup>
                    <InputGroup title="Видеорегистратор" >
                        <Field
                            name="workers"
                            className={className('workers')}
                            component={ReduxFormCameraSelect}
                            validate={[required]}
                        />
                    </InputGroup>
                    <Field name="job_time_to" type="text"
                           label="Дата и время загрузки"
                           showTime={true}
                           component={renderDateTimePicker}
                           className={className('job_time_to')}
                        //    validate={[required]}
                           /> */}
                    <InputGroup title="Видеорегистратор" >
                        <Field
                            name="dashcam_id"
                            className={className('dashcam_id')}
                            component={ReduxFormCameraSelect}
                            validate={[required]}
                        />
                    </InputGroup>
                    {/*<Field name="job_time_from" type="text"*/}
                    {/*       label="Дата и время от"*/}
                    {/*       title="Время указывается в часовом поясе Мск"*/}
                    {/*       showTime={true}*/}
                    {/*       component={renderDateTimePicker}*/}
                    {/*       className={className('job_time_from')}*/}
                    {/*       validate={[required]}/>*/}
                    {/*<InputGroup title="Пользователь" >*/}
                    {/*    <Field*/}
                    {/*        name="workers"*/}
                    {/*        className={className('workers')}*/}
                    {/*        component={ReduxFormPersonsSelect}*/}
                    {/*        validate={[required]}*/}
                    {/*    />*/}
                    {/*</InputGroup>*/}
                    <InputGroup title="Загрузить видеофайл">
                        <Field name="file" component={AttachmentForm}
                            attachmentData={currentDoc}
                            removeAttached={removeAttached}
                        />
                    </InputGroup>
                </Legend>
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state, props) => {
    if (!state.videoRecorderUp.current?.id) {
        return {
            initialValues: {},
            formValues: getFormValues('VideoRecorderUserForm')(state) || {},
        };
    }
    return {
        initialValues: {
            ...state.camera.current,
            ...splitCameraUri(state.camera.current.media_uri),
            protocol: protocolSelectActiveOptions(splitCameraUri(state.camera.current.media_uri).protocol),
            object: objectsSelectActiveOptions(isBlock(state.object.list ? state.object.list : []), state.camera.current.obj_id),
            credentials: rtspCredentials(state.camera.current.media_uri)[0] ? `${rtspCredentials(state.camera.current.media_uri)[0]}:${rtspCredentials(state.camera.current.media_uri)[1]}` : ''
        },
        cameraId: state.camera.current.id,
        formValues: getFormValues('VideoRecorderUpVideoForm')(state) || {},
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'VideoRecorderUpVideoForm',
        enableReinitialize: true,
    })(VideoRecorderUpVideoForm)
)
