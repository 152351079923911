import React from 'react';
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

//import './RemotePagination.css';


const RemotePagination = ({data, page, sizePerPage, onSizePerPageChange, onTableChange, totalSize, children}) => (
    <div>
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                onSizePerPageChange,
                totalSize
            })}
        >
            {({paginationProps, paginationTableProps}) => (
                <div>
                    {React.cloneElement(children, {
                        ...paginationTableProps, data, onTableChange, remote: true
                    })}
                    <div className="paginationContainer">
                        <div className="item-on-page">
                            <p>Записей на странице</p>
                            <SizePerPageDropdownStandalone {...paginationProps}/>
                        </div>
                        <div className="items-pagination">
                            <p>Страница</p>
                            <PaginationListStandalone {...paginationProps}/>
                        </div>
                    </div>
                </div>
            )}
        </PaginationProvider>
    </div>
);

export default RemotePagination;
