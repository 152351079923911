import logo from './assets/img/logo.jpg';
import user from './assets/img/user.png';
import folder from './assets/img/folder.png'
import graduate from './assets/img/graduate.png';
import questionMark from './assets/img/question-mark.png';
import logout from './assets/img/logout.png';
import home from './assets/img/home.png';
import camera from './assets/img/camera.png';
import screen from './assets/img/screen.svg';
import fullScreen from './assets/img/fullScreen.svg'

export default {logo, user, folder, graduate, questionMark, logout, home, camera, screen, fullScreen};
