import React from 'react';

import CellSaveButtons from './CellSaveButtons';

class CredentialsInputWithButtons extends React.Component {
    nameInputEl = React.createRef();
    pwInputEl = React.createRef();

    componentDidMount() {
        this.nameInputEl.current.focus();
    }

    cancelHandler = () => {
        this.props.onBlur();
        this.props.onCancel();
    }

    render = () => {
        const {onUpdate, defaultValue, className} = this.props;
        return (
            <div className="edit-wrapper input-with-buttons">
                <div className="double-field-wrapper">
                    <div className="field-wrapper">
                        <input defaultValue={defaultValue[0] || ''} type="text"
                               ref={this.nameInputEl} name="username"
                               className={className} placeholder="Логин"/>
                    </div>
                    <div className="field-wrapper">
                        <input defaultValue={defaultValue[1] || ''} type="text"
                               ref={this.pwInputEl} name="password"
                               className={className} placeholder="Пароль"/>
                    </div>
                </div>
                <CellSaveButtons
                    onSave={() => {
                        const username = this.nameInputEl.current.value;
                        const password = this.pwInputEl.current.value;
                        const result = username && password
                            ? [username, password]
                            : [];
                        onUpdate(result);
                    }}
                    onCancel={this.cancelHandler}
                />
            </div>
        );
    }
}

export default CredentialsInputWithButtons;
