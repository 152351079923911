import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import TextInputWithButtons from '../tables/TextInputWithButtons';
import withDataErrorIndication from '../components/withDataErrorIndication';
import {
    roomNotOurClearState,
    roomNotOurCreate, roomNotOurCurrentClearState, roomNotOurDelete,
    roomNotOurEdit,
    roomNotOurList,
    roomNotOurLoad
} from "../store/RoomNotOur";
import {
    roomOurClearState,
    roomOurCreate, roomOurCurrentClearState, roomOurDelete,
    roomOurEdit,
    roomOurList,
    roomOurLoad
} from "../store/RoomOur";
import CupboardLayoutCheckedIcon from "../assets/img/icons/CupboardLayoutCheckedIcon";
import CupboardLayoutNotCheckedIcon from "../assets/img/icons/CupboardLayoutNotCheckedIcon";
import RoomOurModal from "../components/RoomOurModal";


const RoomNotOurMenagment = ({actions, roomNotOur, roomNotOurs = [], roomOur, roomOurs = [], isListPendingOur}) => {
    const {loadAllRoomNotOur, deleteRoomNotOur,
            loadAllRoomOur, updateRoomOur, loadRoomOur, clearRoomOur} = actions;

    const loadData = () => {
        loadAllRoomNotOur();
        loadAllRoomOur();
    };

    const [shouldShowModal, setShowModal] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (roomOur?.isReloadList) {
            loadData();
        }
    }, [roomOur]);

    const Columns = (cellCancelHandler) => [{
        text: '№',
        dataField: 'id',
        headerStyle: () => ({width: '59px'}),
        editable: false,
    }, {
        text: 'Разметка',
        dataField: 'mapped',
        sort: true,
        headerStyle: () => ({width: '120px'}),
        editable: false,
        formatter: (cell) => <div className="layout-wrapper">{cell ? <CupboardLayoutCheckedIcon/> : <CupboardLayoutNotCheckedIcon/>}</div>,
    }, {
        text: 'Название',
        dataField: 'name',
        sort: true,
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons
                {...editorProps}
                onCancel={cellCancelHandler} className="required-field"/>
        ),
    }, {
        text: 'Помещения на станции',
        dataField: 'object_observation_ext.name',
        sort: true,
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons
                {...editorProps}
                onCancel={cellCancelHandler} className="required-field"/>
        ),
    }
    ].map(col => ({
        ...col,
        editable: false,
        classes: 'px-0 py-0 table-cell',
    }));

    document.title = 'Помещения';

    return (
        <>
            <div className="row expanded">
                <div className="small-24 room-wrapper">
                    <GeneralObjectsTable
                        isPending={isListPendingOur}
                        objects={roomOurs}
                        isEditable={false}
                        actions={{
                            update: updateRoomOur,
                            // create: createCamera,
                            create: () => {
                                setShowModal(true);
                            },
                            purge: deleteRoomNotOur,
                            reload: loadData,
                        }}
                        getColumns={Columns}
                        itemDummy={{
                            id: 0,
                            code: 0,
                            obj: '',
                            name: '',
                            protocol: '',
                            host: '',
                            suffix: '',
                            credentials: [],
                            is_enabled: true,
                        }}
                        rowEvents={{
                            onDoubleClick: (e, row, rowIndex) => {
                                loadRoomOur(row);
                                setShowModal(true);
                            }
                        }}
                    />
                    {shouldShowModal &&
                        <RoomOurModal
                            onCancel={() => {
                                setShowModal(false);
                                clearRoomOur();
                            }}
                            onClose={() => {
                                setShowModal(false);
                                clearRoomOur();
                            }}
                        />}
                </div>
            </div>
        </>
    )
}

export default connect(
    state => ({
        roomNotOurs: state.roomNotOur.list,
        roomNotOur: state.roomNotOur.current,
        isListPendingNotOur: state.roomNotOur.isListPending,
        isErrorNotOur: state.roomNotOur.isListError,

        roomOurs: state.roomOur.list,
        roomOur: state.roomOur.current,
        isListPendingOur: state.roomOur.isListPending || state.roomNotOur.isListPending,
        isErrorOur: state.roomOur.isListError,
    }),
    dispatch => ({
        actions: {
            loadAllRoomNotOur: () => {
                dispatch(roomNotOurClearState());
                dispatch(roomNotOurList());
            },
            createRoomNotOur: (room) => {
                dispatch(roomNotOurCreate(room));
            },
            loadRoomNotOur: (room) => {
                // dispatch(subdivisionCurrentClearState());
                dispatch(roomNotOurLoad(room.id));
            },
            updateRoomNotOur: (room) => {
                dispatch(roomNotOurEdit(room.id, room));
            },
            deleteRoomNotOur: (room) => {
                dispatch(roomNotOurDelete(room));
            },
            clearRoomNotOur: () => {
                dispatch(roomNotOurCurrentClearState());
            },

            loadAllRoomOur: () => {
                dispatch(roomOurClearState());
                dispatch(roomOurList());
            },
            createRoomOur: (room) => {
                dispatch(roomOurCreate(room));
            },
            loadRoomOur: (room) => {
                // dispatch(subdivisionCurrentClearState());
                dispatch(roomOurLoad(room.id));
            },
            updateRoomOur: (room) => {
                dispatch(roomOurEdit(room.id, room));
            },
            deleteRoomOur: (room) => {
                dispatch(roomOurDelete(room));
            },
            clearRoomOur: () => {
                dispatch(roomOurCurrentClearState());
            },
        },
    }),
)(withDataErrorIndication(RoomNotOurMenagment));
