import React from 'react';
import Loader from 'react-loader-spinner'

const SpinnerSmall = () => (
    <div className="vz-spinner-container">
        <div className="vz-spinner">
            <Loader
                type="ThreeDots"
                color="#CCCCCCFF"
                height={30}
                width={50}
                timeout={300000}
            />
        </div>
    </div>
);

export default SpinnerSmall;
