import React from 'react';

const prefix = '';

const ExternalPage = ({ page, height='800px', title='' }) => {
    document.title = title;

    return (
        <p>Интеграция модуля в разработке</p>
        // <iframe src={`${prefix}${page}`} style={{height, width: '100%'}} />
    );
}

export default ExternalPage;
