import axios from 'axios';

const ROOM_NOT_OUR = {
    LIST:       'ROOM_NOT_OUR_LIST',
    LIST_PENDING: 'ROOM_NOT_OUR_PENDING',
    LIST_ERROR: 'ROOM_NOT_OUR_LIST_ERROR',
    DETAIL:     'ROOM_NOT_OUR_DETAIL',
    CREATE:     'ROOM_NOT_OUR_CREATE',
    EDIT:       'ROOM_NOT_OUR_EDIT',
    DELETE:     'ROOM_NOT_OUR_DELETE',
    CLEAR:      'ROOM_NOT_OUR_CLEAR',
    CURRENT_CLEAR:  'ROOM_NOT_OUR_CURRENT_CLEAR',
};

const initialState = {
    current: {},
};

const roomNotOurReducer = (state = initialState, action) => {
    switch(action.type) {
        case ROOM_NOT_OUR.LIST:
            return {
                ...state,
                list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case ROOM_NOT_OUR.DETAIL:
        case ROOM_NOT_OUR.CREATE:
        case ROOM_NOT_OUR.EDIT:
        case ROOM_NOT_OUR.DELETE:
            return {
                ...state,
                current: action.payload,
                isListPending: false,
            };
        case ROOM_NOT_OUR.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case ROOM_NOT_OUR.LIST_ERROR:
            return {
                isListError: true,
            }
        case ROOM_NOT_OUR.CLEAR:
            return initialState;
        case ROOM_NOT_OUR.CURRENT_CLEAR:
            return {
                ...state,
                current: undefined
            };
        default:
            return state;
    }
};

export default roomNotOurReducer;

export const roomNotOurList = () => dispatch => {
    dispatch({
        type: ROOM_NOT_OUR.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/kola-mapping/object-observation-ext/')
        .then(res => {
            dispatch({
                type: ROOM_NOT_OUR.LIST,
                payload: res.data
            });
        })
};


/* Загрузка помещения */
export const roomNotOurLoad = (subId) => dispatch => {
    dispatch({
        type: ROOM_NOT_OUR.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/kola-mapping/object-observation-ext/${subId}/`)
        .then(res => {
            dispatch({
                type: ROOM_NOT_OUR.DETAIL,
                payload: res.data,
            });
        });
};

/* Создание новой комнаты */
export const roomNotOurCreate = (data) => dispatch => {
    axios.post(`/api/statistics/kola-mapping/object-observation-ext/`, data)
        .then(res => {
            dispatch({
                type: ROOM_NOT_OUR.CREATE,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: ROOM_NOT_OUR.CREATE,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

export const roomNotOurDelete = (subId) => dispatch => {
    axios.delete(`/api/statistics/kola-mapping/object-observation-ext/${subId}/`)
        .then(res => {
            let data = {isReloadList: true};
            dispatch({
                type: ROOM_NOT_OUR.DELETE,
                payload: data,
            });
        });
};

export const roomNotOurClearState = () => dispatch => {
    dispatch({
        type: ROOM_NOT_OUR.CLEAR,
        payload: null,
    });
};

export const roomNotOurCurrentClearState = () => dispatch => {
    dispatch({
        type: ROOM_NOT_OUR.CURRENT_CLEAR,
        payload: null,
    });
};

export const roomNotOurEdit = (subId, data) => dispatch => {
    axios.put(`/api/statistics/kola-mapping/object-observation-ext/${subId}/`, data)
        .then(res => {
            dispatch({
                type: ROOM_NOT_OUR.EDIT,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: ROOM_NOT_OUR.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};
