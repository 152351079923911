import axios from 'axios';

const FEEDBACK = {
    PENDING: 'FEEDBACK_PENDING',
    SEND: 'FEEDBACK_SEND',
    CLEAR: 'FEEDBACK_CLEAR',
    ERROR: 'FEEDBACK_ERROR',
};

const initialState = {};

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case FEEDBACK.PENDING:
            return {
                isPending: true,
                isSend: false,
            };
        case FEEDBACK.SEND:
            return {
                isPending: false,
                isSend: true,
            };
        case FEEDBACK.ERROR:
            return {
                isError: true
            };
        case FEEDBACK.CLEAR:
            return {
                initialState,
                isPending: false,
                isSend: false,
            };
        default:
            return state;
    }
}

export default feedbackReducer;

export const sendFeedBack = (data) => dispatch => {
    dispatch({
        type: FEEDBACK.PENDING,
        payload: null,
    });
    axios.post('/api/statistics/feedback/', data)
        .then(res => {
            dispatch({
                type: FEEDBACK.CLEAR,
                payload: null
            });
        })
        .catch(e => {
            dispatch({
                type: FEEDBACK.ERROR,
                payload: null
            })
        })
}

export const clearFeedBack = () => dispatch => {
    dispatch({
        type: FEEDBACK.CLEAR,
        payload: null,
    });
};
