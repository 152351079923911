import React from 'react';
import ReactDataGrid from 'react-data-grid';
import {Editors} from 'react-data-grid-addons';
import {faFile, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { createPortal } from 'react-dom';
import Modal from "../components/Modal";
import Event from "../components/Event";
import OutfitToleranceForm from "../forms/OutfitToleranceForm";
import {userFio} from "../utils/userUtils";
import CheckboxWithButtons from "./CheckboxWithButtons";

const {DropDownEditor} = Editors;

const {
    Draggable: {
        Container: DraggableContainer,
        RowActionsCell,
        DropTargetRowContainer
    },
    Data: {Selectors}
} = require('react-data-grid-addons');
// const RowRenderer = DropTargetRowContainer(ReactDataGrid.Row);

const RowRenderer = ({ renderBaseRow, ...props }) => {
    // let height = 35;
    let heightW = 35;
    let heightS = 35;
    let heightR = 35;

    if (props.row.workers) {
        if (props.row.workers.length > 1) {
            heightW = props.row.workers.length * 24;
        }
    }
    if (props.row.switchover_info?.ordering) {
        if (props.row.switchover_info?.ordering.length > 1) {
            heightS = props.row.switchover_info?.ordering.length * 24;
        }
    }

    if (props.row.job_room_ids) {
        if (props.row.job_room_ids.length > 1) {
            heightR = props.row.job_room_ids.length * 24;
        }
    }

    return <div>{renderBaseRow({...props, height: Math.max(heightW, heightS, heightR)})}</div>;
};

const zeroTabbed = (value) => `0${value}`.slice(-2);

const timestamp = date => new Date(date).getTime() / 1000;

const dateFormatter = (date) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return new Date(date * 1000).toLocaleString('ru-RU', { timeZone });
}

class OutfitToleranceTableForm extends React.Component {
    constructor(props) {
        super(props);
        this.resolveTypes = [
            {id: 0, value: 'Пропустить'},
            {id: 1, value: 'Зафиксировать'},
        ];
        const ResolveTypeEditor = <DropDownEditor options={this.resolveTypes}/>;


        // создаю список параметров для выбора объекта наблюдения
        this.objectObservation = [{id: null, value: 'Все'}];
        if (this.props.objects) {
            for (const obj of this.props.objects) {
                this.objectObservation.push({id: obj.id, value: `${obj.name} (${obj.power_unit.name})`});
            }
        }
        // создаю список параметров для выбора зоны
        const ObjectsObservationEditor = <DropDownEditor options={this.objectObservation}/>;


        this.zoneTypes = [{id: null, value: 'Все'}];
        if (this.props.modelstorage.config_zones) {
            for (const zone of this.props.modelstorage.config_zones) {
                this.zoneTypes.push({id: zone.zone_id_cfg, value: zone.zone_name_cfg});
            }
        }
        const ZoneTypeEditor = <DropDownEditor options={this.zoneTypes}/>;

        // создаю список параметров для камеры
        this.cameraTypes = [{id: null, value: 'Все'}];
        if (this.props.cameras) {
            for (const camera of this.props.cameras) {
                let object_observation_id = null;
                if (camera.obj_id) {
                    object_observation_id = camera.obj_id;
                }
                this.cameraTypes.push({
                    id: camera.id,
                    value: camera.name,
                    object_observation_id: object_observation_id
                });
            }
        }
        // const CameraTypeEditor = <DropDownEditor options={this.cameraTypes} />;

        // создаю список параметров для выбора категории
        this.primaryCategoryTypes = [{id: null, value: 'Все'}];
        if (this.props.modelstorage.primary_categories) {
            for (const category of this.props.modelstorage.primary_categories) {
                this.primaryCategoryTypes.push({id: category.name, value: category.ru_name});
            }
        }
        const PrimaryCategoryTypeEditor = <DropDownEditor options={this.primaryCategoryTypes}/>;

        // создаю список параметров для выбора подкатегории
        this.secondaryCategoryTypes = [{id: null, value: 'Все'}];
        if (this.props.modelstorage.secondary_categories) {
            for (const category of this.props.modelstorage.secondary_categories) {
                this.secondaryCategoryTypes.push({id: category.name, value: category.ru_name});
            }
        }
        const SecondaryCategoryTypeEditor = <DropDownEditor options={this.secondaryCategoryTypes}/>;

        // создаю список параметров для время
        this.timeOptions = [{id: null, value: 'Все'}];
        for (let hour = 0; hour < 24; hour += 1) {
            for (let minute = 0; minute < 60; minute += 5) {
                this.timeOptions.push({
                    id: `${zeroTabbed(hour)}:${zeroTabbed(minute)}:00`,
                    value: `${zeroTabbed(hour)}:${zeroTabbed(minute)}:00`
                });
            }
        }
        const TimeTypeEditor = <DropDownEditor options={this.timeOptions}/>;

        this.state = {
            isDirty: false,
            rows: this.toSelectValue(props.dataRows),
            selectedIds: [],
            selectedCamerasObjectObservation: this.cameraTypes,
            modalOpen: false,
            row: [],
        };

        const CameraTypeEditor = <DropDownEditor options={this.state.selectedCamerasObjectObservation}/>;

        const emploeeActiv = (val) => {
            if (val.value) {
                return val.value.map(item => {
                    return <div>{userFio(this.props.employees.find(empI => empI.id === item))}</div>
                })
            } else {
                return <p></p>
            }
        }

        const roomActiv = (val) => {
            if (val.value) {
                return val.value.map(item => {
                    const room = this.props.objects.find(empI => empI.id === item);
                    if (room) {
                        return <p>{room.name} ({room.power_unit.name})</p>
                    } else {
                        return <p/>
                    }
                })
            } else {
                return <p/>
            }
        }

        this.columns = [{
            key: 'priority',
            name: 'Тип работ',
            formatter: () => 'Работа по наряду - допуску'
        }, {
            key: 'document_number',
            name: 'Номер наряд - допуск',
        }, {
            key: 'job_room_ids',
            name: 'Помещения',
            formatter: cell => roomActiv(cell)
        }, {
            key: 'job_time_from',
            name: 'Дата и время от',
            formatter: (date) => dateFormatter(date.value),
        }, {
            key: 'job_time_to',
            name: 'Дата и время до',
            formatter: (date) => dateFormatter(date.value),
        }, {
            key: 'primary_category_ru',
            name: 'Типы спецодежды',
            formatter: cell => cell.value ? cell.value : "Все"
        }, {
            key: 'workers',
            name: 'Список работников',
            // editable: true,
            formatter: cell => emploeeActiv(cell)
        }, {
            key: 'is_active',
            name: 'Статус',
            formatter: (cell) => <input type="checkbox" checked={cell.value} className="tableCheckbox"/>,
        }, {
            key: 'description',
            name: 'Описание',
        }];
    }

    preSave = (e) => {
        e.preventDefault();
        const rows = this.fromSelectValue(this.state.rows);
        this.props.handleSave(rows)
    }

    toSelectValue = (data) => {
        console.debug('toSelectValue>', data)
        let modifedRows = [];
        const dataRows = [...data];

        const secondsToTimeString = (totalSeconds) => {
            if (totalSeconds === null || totalSeconds === -1) {
                return "Все";
            }
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
            const seconds = 0;
            const hh = zeroTabbed(hours);
            const mn = zeroTabbed(minutes);
            const ss = zeroTabbed(seconds);
            return `${hh}:${mn}:${ss}`;
        };

        for (const row of dataRows) {
            for (const solution of this.resolveTypes) {
                if (row['solution'] === solution.id) {
                    row['solution'] = solution.value;
                }
            }
            for (const zone of this.zoneTypes) {
                if (row['zone'] === zone.id) {
                    row['zone_config'] = zone.value;
                }
            }
            for (const category of this.primaryCategoryTypes) {
                if (row['primary_category'] === category.id) {
                    row['primary_category'] = category.value;
                }
            }
            for (const category of this.secondaryCategoryTypes) {
                if (row['secondary_category'] === category.id) {
                    row['secondary_category'] = category.value;
                }
            }
            for (const camera of this.cameraTypes) {
                if (row['camera_id'] === camera.id) {
                    row['camera'] = camera.value;
                }
            }
            for (const obj of this.objectObservation) {
                if (row['job_room_id'] === obj.id) {
                    row['job_room_name'] = obj.value;
                }
            }
            row['time_from'] = secondsToTimeString(row['time_from']);
            row['time_to'] = secondsToTimeString(row['time_to']);
            modifedRows.push(row);
        }
        return modifedRows;
    }

    fromSelectValue = (data) => {
        console.debug('fromSelectValue> ', data)
        const rows = [...data];

        const timeStringToSeconds = (time) => {
            if (time === "Все") {
                return -1;
            }
            const [hours, minutes, seconds] = time.split(':');
            return parseInt(seconds, 10) +
                parseInt(minutes, 10) * 60 +
                parseInt(hours, 10) * 60 * 60;
        };

        for (const row of rows) {
            for (const solution of this.resolveTypes) {
                if (row['solution'] === solution.value) {
                    row['solution'] = solution.id;
                }
            }
            for (const zone of this.zoneTypes) {
                if (row['zone_config'] === zone.value) {
                    row['zone'] = zone.id;
                }
            }
            for (const category of this.primaryCategoryTypes) {
                if (row['primary_category'] === category.value) {
                    row['primary_category'] = category.id;
                }
            }
            for (const category of this.secondaryCategoryTypes) {
                if (row['secondary_category'] === category.value) {
                    row['secondary_category'] = category.id;
                }
            }
            for (const camera of this.cameraTypes) {
                if (row['camera'] === camera.value) {
                    row['camera_id'] = camera.id;
                }
            }
            for (const obj of this.objectObservation) {
                if (row['object_observation'] === obj.value) {
                    row['object_observation_id'] = obj.id;
                }
            }
            row['event_threshold'] = parseInt(row['event_threshold']);
            row['cron_datetime'] = row['cron_datetime']?.trim();
            row['time_from'] = timeStringToSeconds(row['time_from']);
            row['time_to'] = timeStringToSeconds(row['time_to']);
        }
        return rows;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            rows: this.toSelectValue(nextProps.dataRows),
            selectedIds: [],
        });
    }

    addRules = (e) => {
        e.preventDefault();

        const rows = [...this.state.rows];
        const last = rows[rows.length - 1];

        let item = null;
        if (rows.length) {
            item = {...last};
            item.priority += 1;
        } else {
            item = {priority: 1};
        }
        // item.camera = this.cameraTypes[0].value;
        // item.object_observation = this.objectObservation[0].value;
        // item.primary_category = this.primaryCategoryTypes[0].value;
        // item.secondary_category = this.secondaryCategoryTypes[0].value;
        // item.time_from = this.timeOptions[0].value;
        // item.time_to = this.timeOptions[0].value;
        // item.cron_datetime = "";
        // item.zone_config = this.zoneTypes[0].value;
        // item.solution = this.resolveTypes[0].value;
        item.document_type = 1;


        rows.push(item);
        this.setState({
            modalOpen: true,
            row: {},
        });

        // this.updateRows(rows);
    }

    onCellSelected = ({rowIdx, idx}) => {
        console.debug('onCellSelected>', rowIdx, idx, this.state.rows[rowIdx]);
        const row = this.state.rows[rowIdx];

        console.debug('row>', row)

        if (row && row.object_observation) {
            const selectedObjectObservationId = row.object_observation.id;
            let cameras = [];
            for (const camera of this.cameraTypes) { // в селекторе только камеры данного объекта наблюдения
                if (camera.object_observation_id === selectedObjectObservationId) {
                    cameras.push(camera);
                }
            }
            this.setState({
                selectedCamerasObjectObservation: cameras,
            })
        } else {
            this.setState({
                selectedCamerasObjectObservation: this.cameraTypes,
            })
        }

        console.debug('selectedCamerasObjectObservation>', this.state.selectedCamerasObjectObservation)

        if (this.grid) {
            this.grid.openCellEditor(rowIdx, idx);
        }
    }

    onCellDeSelected = ({rowIdx, idx}) => {
    }

    deleteRow = () => {
        if (!this.state.selectedIds.length) {
            return;
        }
        let rows = [...this.state.rows];
        // rows.splice(this.state.selectedIds[0], 1); //
        // let number = 1;
        // for (const row of rows) {
        //     row.priority = number++;
        // }
        // this.updateRows(rows);
        // rows = this.fromSelectValue(rows);
        // this.props.handleSave(rows);

        this.props.deleteDoc(rows[this.state.selectedIds[0]].id)
    }

    onGridRowsUpdated = ({fromRow, toRow, updated}) => {
        this.setState(state => {
            const rows = state.rows.slice();
            for (let i = fromRow; i <= toRow; i++) {
                rows[i] = {...rows[i], ...updated};
            }
            return {
                isDirty: true,
                rows
            };
        });
    };

    onRowsSelected = rows => {
        this.setState({
            selectedIds: this.state.selectedIds.concat(
                rows.map(r => r.rowIdx)
            )
        });
    };

    onRowsDeselected = rows => {
        let rowIndexes = rows.map(r => r.rowIdx);
        this.setState({
            selectedIds: this.state.selectedIds.filter(
                i => rowIndexes.indexOf(i) === -1
            )
        });
    };

    reorderRows = e => {
        console.debug('reorderRows>');
        let selectedRows = Selectors.getSelectedRowsByKey({
            rowKey: this.props.rowKey,
            selectedKeys: this.state.selectedIds,
            rows: this.state.rows
        });
        let draggedRows = this.isDraggedRowSelected(selectedRows, e.rowSource)
            ? selectedRows
            : [e.rowSource.data];
        let undraggedRows = this.state.rows.filter(function (r) {
            return draggedRows.indexOf(r) === -1;
        });
        let args = [e.rowTarget.idx, 0].concat(draggedRows);
        Array.prototype.splice.apply(undraggedRows, args);

        let number = 1;
        for (const row of undraggedRows) {
            row.priority = number++;
        }

        this.updateRows(undraggedRows);
    };

    isDraggedRowSelected = (selectedRows, rowDragSource) => {
        console.debug('isDraggedRowSelected>')
        if (selectedRows && selectedRows.length > 0) {
            let key = this.props.rowKey;
            return (
                selectedRows.filter(r => r[key] === rowDragSource.data[key]).length > 0
            );
        }
        return false;
    };

    onRowClick = (rowIdx, row) => {
        this.setState({
            selectedIds: this.state.selectedIds.includes(rowIdx) ?
                [] : [rowIdx]
        });
    }

    updateRows = (newRows) => {
        this.setState({
            isDirty: true,
            rows: newRows
        });
    }

    onDBClickRow = (e) => {
        console.log("e!!!!", this.state.rows[e]);
        this.setState({modalOpen: true, row: this.state.rows[e]})
    }

    onCloseModal = () => {
        this.setState({modalOpen: false})
    }

    onSaveDoc = (values) => {
        console.log("values", values);
        let data = {...values};

        if (data['job_time_from']){
            data['job_time_from'] = timestamp(data['job_time_from'])
        }
        if (data['job_time_to']){
            data['job_time_to'] = timestamp(data['job_time_to'])
        }
        if (data['job_rooms']){
            data['job_room_ids'] = data['job_rooms'].map(item => item.value);
        }

        if (data['workers']){
            data['workers'] = data['workers'].map(item => item.value);
        }

        if (!data['is_active']){
            data['is_active'] = false;
        }

        if (data['primary_category'].value !== "Все" && data['primary_category'].value !== "0"){
            data['primary_category'] = data['primary_category'].value;
        } else {
            data['primary_category'] = null;
        }

        this.props.handleSave(data);
    };

    render() {
        const tableHeight = (rows) => {
            let minHeight = 33;
            for (const row of rows) {
                let heightW = 35;
                let heightS = 35;
                let heightR = 35;

                if (row.workers) {
                    if (row.workers.length > 1) {
                        heightW = row.workers.length * 24;
                    }
                }
                if (row.switchover_info?.ordering) {
                    if (row.switchover_info?.ordering.length > 1) {
                        heightS = row.switchover_info?.ordering.length * 24;
                    }
                }

                if (row.job_room_ids) {
                    if (row.job_room_ids.length > 1) {
                        heightR = row.job_room_ids.length * 24;
                    }
                }

                minHeight = minHeight + Math.max(heightW, heightS, heightR);
            }
            return minHeight;
        }
        return (
            <div>
                <div className="btn-wrapper">
                    <button className="table-control-btn btn-create" onClick={e => this.addRules(e)}>
                        <FontAwesomeIcon icon={faFile} size="lg" color="#919191"/>
                        Создать
                    </button>
                    <button className="table-control-btn btn-delete"
                            disabled={!this.state.selectedIds.length}
                            onClick={this.deleteRow}>
                        <FontAwesomeIcon icon={faTimes} size="lg" color="#919191"/>
                        Удалить
                    </button>
                </div>
                <div>
                    <DraggableContainer>
                        <ReactDataGrid
                            ref={node => this.grid = node}
                            columns={this.columns}
                            rowGetter={i => this.state.rows[i]}
                            rowsCount={this.state.rows.length}
                            onGridRowsUpdated={this.onGridRowsUpdated}
                            enableCellSelect={true}
                            onCellSelected={this.onCellSelected}
                            onCellDeSelected={this.onCellDeSelected}
                            getCellActions={this.getCellActions}
                            rowActionsCell={RowActionsCell}
                            onRowClick={this.onRowClick}
                            minHeight={tableHeight(this.state.rows)}
                            onRowDoubleClick={(e) => this.onDBClickRow(e)}
                            rowRenderer={
                                <RowRenderer
                                    onRowDrop={this.reorderRows}
                                />
                            }
                            rowSelection={{
                                enableShiftSelect: false,
                                onRowsSelected: this.onRowsSelected,
                                onRowsDeselected: this.onRowsDeselected,
                                selectBy: {
                                    indexes: this.state.selectedIds
                                }
                            }}
                        />
                    </DraggableContainer>
                </div>
                {this.state.modalOpen &&
                <Modal
                    title={`Наряд допуск`}
                    isOpen
                    className="user-modal"
                    onClose={this.onCloseModal}>
                    <OutfitToleranceForm
                        row={this.state.row}
                        primaryList={this.props.primaryList}
                        objects={this.props.objects}
                        employees={this.props.employees}
                        onSubmit={(val) => {
                            this.onSaveDoc(val)
                        }}
                    />
                </Modal>}
            </div>
        );
    }
}

export default OutfitToleranceTableForm;
