import React from 'react';

const Legend = ({title, children, className}) => (
    <div className={className ? 'form-group ' + className : 'form-group'}>
        <div className="title">
            <div className="title-text">
                <p>{title}</p>
            </div>
            <div className="title-line"/>
        </div>
        {children}
    </div>
)

export default Legend;
