import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {isAuthError} from '../utils/utils';

const required = value => value ? undefined : 'Обязательное поле';

const Input = ({input, name, type, placeholder, className, meta, ...rest}) => (
    <>
        <input {...input} name={name} type={type} placeholder={placeholder}
               className={meta.error ? className + ' auth-form-control-error' : className}
        />
        {meta.touched &&
        <p className="auth-profile-error" style={{color: 'red'}}>
            {meta.error}
        </p>}
    </>
);

const AuthForm = (props) => {
    const {handleSubmit, errorText} = props;
    return (
        <form onSubmit={handleSubmit} className="form">
            <div className="form-item">
                <Field
                    name="login"
                    type="text"
                    component={Input}
                    placeholder="Логин"
                    validate={[required]}
                />
            </div>
            <div className="form-item top">
                <Field
                    name="password"
                    type="password"
                    component={Input}
                    placeholder="Пароль"
                    validate={[required]}
                />
            </div>
            <div className="form-item">
                <input className="submit" type="submit" value="Войти"/>
            </div>
            <p className="auth-profile-error" style={{color: 'red'}}>
                {errorText}
            </p>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        initialValues: {
            login: '',
            password: '',
        },
        ...(isAuthError(state.user) && {errorText: state.user.errorText})
    }
};

export default connect(mapStateToProps)(
    reduxForm({
        form: 'auth',
        enableReinitialize: true,
    })(AuthForm)
)
