import React, {useCallback, useEffect} from 'react';
import {connect} from 'react-redux';

import {isAuthUser} from './utils/utils';
import {messageReceived} from './store/Websockets';
import {onLogout, refreshAccessToken, setRedirectTo} from './store/Auth';
import withAuthHeaders from './withAuthHeaders';
import AuthPage from './pages/AuthPage';
import SwitchPages from './components/SwitchPages';
import Header from './components/Header';
import NavigationTabs from './components/NavigationTabs';
import Footer from './components/Footer';
import EventNotification from './components/EventNotification';
import {startsWithSegment} from 'router5-helpers';
import {useRouteNode} from 'react-router5';

import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './scss/App.scss';
import { Faq } from "./components/Faq/Faq";
import { closeFaq } from "./store/FAQ";
import {ROUTES} from './router/routes';

const App = ({ user, faq, closeFaqModal, setRedirectTo }) => {

    console.log('hello')
    const clickClose = useCallback(() => {
      closeFaqModal();
    }, []);


    if (!isAuthUser(user)) {
        return <AuthPage/>;
    }

    return (
        <>
            <Header/>
            <div className="content row expanded">
                <NavigationTabs/>
                <div className="content-body small-24">
                    <SwitchPages/>
                </div>
            </div>
            <Footer/>
            <EventNotification/>
            <Faq faqModal={faq} close={() => clickClose()} />
        </>
    );
};

export default connect(
    state => ({
        user: state.user,
        faq: state.faq.modal
    }),
    dispatch => ({
        refreshAccessToken: (refreshToken) => {
            dispatch(refreshAccessToken(refreshToken));
        },
        onLogout: () => {
            dispatch(onLogout());
        },
        onMessageReceived: (message) => {
            dispatch(messageReceived(message));
        },
        closeFaqModal: () => {
            dispatch(closeFaq());
        },
        setRedirectTo: (redirectTo) => {
            dispatch(setRedirectTo(redirectTo));
        },
    }),
)(withAuthHeaders(App));
