import React from 'react';
import Loader from 'react-loader-spinner'

const Spinner = () => (
    <div className="vz-spinner-container">
        <div className="vz-spinner">
            <Loader
                type="ThreeDots"
                color="#CCCCCCFF"
                height={100}
                width={100}
                timeout={300000}
            />
        </div>
    </div>
);

export default Spinner;
