import axios from 'axios';

const SYSTEM_INFO = {
    LIST_PENDING: 'SYSTEM_INFO_LIST_PENDING',
    LIST_ERROR: 'SYSTEM_INFO_LIST_ERROR',
    LIST:   'SYSTEM_INFO_LIST',
};

const initialState = {
    current: {},
};

const SystemInfoReducer = (state = initialState, action) => {
    switch(action.type) {
        case SYSTEM_INFO.LIST:
            return {
                ...state,
                list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case SYSTEM_INFO.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case SYSTEM_INFO.LIST_ERROR:
            return {
                isListError: true,
            }
        default:
            return state;
    }
};

export default SystemInfoReducer;

export const SystemInfoList = () => dispatch => {
    dispatch({
        type: SYSTEM_INFO.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/kola-backend/count_info/')
        .then(res => {
            dispatch({
                type: SYSTEM_INFO.LIST,
                payload: res.data
            });
        })
};
