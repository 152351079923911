import React from 'react';

import CellSaveButtons from './CellSaveButtons';

class TextInputWithButtons extends React.Component {
    inputEl = React.createRef();

    componentDidMount() {
        this.inputEl.current.focus();
    }

    cancelHandler = () => {
        this.props.onBlur();
        this.props.onCancel();
    }

    render = () => {
        const {onUpdate, defaultValue, className} = this.props;
        return (
            <div className="edit-wrapper input-with-buttons">
                <div className="field-wrapper">
                    <input defaultValue={defaultValue} type="text" ref={this.inputEl}
                           className={className}/>
                </div>
                <CellSaveButtons
                    onSave={() => onUpdate(this.inputEl.current.value)}
                    onCancel={this.cancelHandler}
                />
            </div>
        );
    }
}

export default TextInputWithButtons;
