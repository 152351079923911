export const VIOLATION = {
    HAS_VIOLATION: 1,
    NO_VIOLATION: -1,
};

export const VIOLATION_SETTINGS = {
    HAS_VIOLATION: "CONFIRMED",
    NO_VIOLATION: "DENIED",
    NO_STATUS: "NO_STATUS"
};

export const DATE_FORMAT = 'dd.MM.yyyy';

export const DEFAULT_INTERVAL_HOURS = 24;

export const START_PAGE = 1;
export const PAGE_SIZE = 25;

export const SETTINGS = {
    LOAD_SETTINGS: 'LOAD_SETTINGS',
    SAVE_SETTINGS: 'SAVE_SETTINGS',
    NOTIFICATIONS_DISABLED: 0,
    NOTIFICATIONS_INSTANT: 1,
    NOTIFICATIONS_INTERVAL: 2,
    NOTIFICATIONS_TIMETABLE: 3,
};
