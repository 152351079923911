import React, {useState, useEffect} from 'react';
import {Field, reduxForm} from 'redux-form';
import axios from 'axios';

import {DatePickerInput, objectsListToOptions, ReduxFormSelectValid, selectOptions} from '../utils/inputs';
import {onlyRooms} from '../utils/utils';
import {downloadFile} from '../utils/requests';
import {connect} from 'react-redux';
import {getDateNow} from '../utils/time';
import {DEFAULT_INTERVAL_HOURS} from '../utils/constants';
import SpinnerSmall from "../components/SpinnerSmall";
import {dateBeginTime, dateEndTime} from "../pages/EventsReport";

const timestamp = date => new Date(date).getTime() / 1000;

const EventsReportIndexForm = (props) => {
    const {cameras, thermalCameras, handleSubmit, pristine, reset, submitting, ppeList} = props;
    const [dateFrom, setDateFrom] = useState(timestamp(dateBeginTime(getDateNow(-DEFAULT_INTERVAL_HOURS))));
    const [dateTo, setDateTo] = useState(timestamp(dateEndTime(getDateNow())));
    const [cameraId, setCameraId] = useState("");
    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

    const [cameraArray, setCameraArray] = useState([{value: "0", label: "Все"}]);
    const [ppeArray, setPpeArray] = useState([{value: "0", label: "Все"}]);

    const getReportPdf = () => {
        let pageNumber = 1;
        let url = `/api/statistics/reports/score_higher/pdf/?start_date=${dateFrom}&end_date=${dateTo}`;
        // url = `/api/kola/report/violation/pdf/?page=${pageNumber}&date_begin=${dateFrom}&date_end=${dateTo}&has_violation=1`;
        // if (cameraId === '') {
        //     url += `&camera_id=${cameras[0].id}`;
        // } else {
        //     url += `&camera_id=${cameraId}`;
        // }
        if (cameraArray){
            cameraArray.map(camera => {
                if (camera.value !== "0") {
                    url += `&cameras_ids=${camera.value}`
                }
            });
        } else {
            return;
        }

        if (ppeArray){
            ppeArray.map(ppe => {
                if (ppe.value !== "0") {
                    url += `&secondary_categories=${ppe.value}`
                }
            });
        } else {
            return;
        }
        setIsDownloadingPdf(true);
        downloadFile(url, 'report.pdf')
            .then(() => {
                setIsDownloadingPdf(false);
            })
    };

    const addEmptyValue = (arr) => {
        if (arr.find((i) => i.id === "0") === undefined) {
            arr.unshift({
                id: "0",
                name: "Все"
            });
        }
        return arr;
    };

    const changeDateFrom = (date) => {
      setDateFrom(timestamp(dateBeginTime(date)));
    };

    const changeDateTo = (date) => {
      setDateTo(timestamp(dateEndTime(date)));
    };
    const changeCam = (activeId) => {
        setCameraId(activeId.target.value)
    };

    const camerasOptions = (cameras) => {
        return cameras.map(camera => {
            return {
                value: camera.id,
                label: camera.name
            }
        })
    };

    const ReduxFormRoomSelect = ({input}) => {
        const isMulti = true;
        const options = camerasOptions(addEmptyValue([...cameras, ...thermalCameras]));
        const placeholder = 'Выберите камеры';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const addEmptyValuePPE = (arr) => {
        if (arr.find((i) => i.name === "0") === undefined) {
            arr.unshift({
                name: "0",
                ru_name: "Все"
            });
        }
        return arr;
    };

    const ppeOptions = (ppe) => {
        return ppe.map(ppe => {
            return {
                value: ppe.name,
                label: ppe.ru_name
            }
        })
    };

    const ReduxFormPpeSelect = ({input}) => {
        const isMulti = true;
        const options = ppeOptions(addEmptyValuePPE(ppeList));
        const placeholder = 'Выберите типы СИЗ';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };


    return (
        <form onSubmit={handleSubmit} className="">
            <div className="form-group">
                <div className="title">
                    <p>Период дат:</p>
                </div>
                <div className="input dates">
                    <Field name="from" component={DatePickerInput} onChange={changeDateFrom}
                           placeholder="введите дату начала"/>
                    <Field name="to" component={DatePickerInput} onChange={changeDateTo}
                           placeholder="введите дату окончания"/>
                </div>
            </div>
            {/*<div className="form-group">*/}
            {/*    <div className="title">*/}
            {/*        <p>Камера:</p>*/}
            {/*    </div>*/}
            {/*    <div className="input">*/}
            {/*        <Field name="room" component="select" onChange={changeCam} >*/}
            {/*            {selectOptions(objectsListToOptions([...cameras, ...thermalCameras]))}*/}
            {/*        </Field>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="form-group select-multi">
                <div className="title">
                    <p>Камера:</p>
                </div>
                <div className="input">
                    <Field
                        name="camerasList"
                        component={ReduxFormRoomSelect}
                        onChange={(e) => setCameraArray(e)}
                    />
                </div>
            </div>
            <div className="form-group select-multi">
                <div className="title">
                    <p>Типы СИЗ:</p>
                </div>
                <div className="input">
                    <Field
                        name="ppeList"
                        component={ReduxFormPpeSelect}
                        onChange={(e) => setPpeArray(e)}
                    />
                </div>
            </div>
            <div className="btn-wrapper report-wrapper-btn">
                <button onClick={getReportPdf}>
                    Сформировать PDF
                </button>
                {isDownloadingPdf &&
                <div className="spinner-small-wrapper">
                    <SpinnerSmall/>
                </div>
                }
            </div>
        </form>
    )
}

const mapStateToProps = (state) => ({
    cameras: state.camera.flatList || [],
    thermalCameras: state.thermalCamera.list || [],
    ppeList: state.regulatoryDocsSecondary.list,
    initialValues: {
        ppeList: {value: "0", label: "Все"},
        camerasList: {value: "0", label: "Все"},
        from: getDateNow(-DEFAULT_INTERVAL_HOURS),
        to: getDateNow(),
    }
})

export default connect(mapStateToProps)(
    reduxForm({form: 'EventsReportIndexForm', enableReinitialize: false})(EventsReportIndexForm)
);
