import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup, InputSimpleWithTitle} from '../inputs';
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";
import ZonesCupboard from "../components/ZonesCupboard";
import {cameraInObject} from "../utils/utils";

const required = value => value ? undefined : 'Обязательное поле';

const empty = {
    value: null,
    label: "Все"
};

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: `${entry.name} (${entry.power_unit.name})`
        }
    })
};

const cameraActive = (camera) => {
        return {
            value: camera.id,
            label: camera.name
        }
};

const camerasOptions = (cameras) => {
    return cameras.map(camera => {
        return {
            value: camera.id,
            label: camera.name
        }
    })
};

const cupboardOptions = (cupboards) => {
    return cupboards.map(cupboard => {
        return {
            value: cupboard.id,
            label: cupboard.name
        }
    })
};


const CupboardLayoutForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, cameras, formValues, cupboardOur = [], cupboardNotOur = [], formValuesPolygon, objects, initialValues}) => {
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';

    const [objectId, setObjectId] = useState(null);

    useEffect(() => {
        const activeCupboard = cupboardOur.find(item => item.id === initialValues.cabinet_int?.value);
        setObjectId(activeCupboard ? activeCupboard.object_observation.id : '')
        // setObjectId(formValues.cabinet_int.object_observation.id);
    }, []);

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = objSelectOptions(objects);
        options.unshift(empty);
        const placeholder = 'Выберите помещение';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    const ReduxFormRoomSelect = ({input}) => {
        const isMulti = false;
        const activeCameras = cameras?.filter(item => item.is_enabled === true);
        const options = camerasOptions(objectId ?
            cameraInObject(objectId, activeCameras) : activeCameras);
        const placeholder = 'Выберите камеры';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };


    const ReduxFormCupboardSelect = ({input}) => {
        const isMulti = false;
        const notMappedCupboard = cupboardOur.filter(item => item.layout_added === false);
        const options = cupboardOptions(objectId ?
            notMappedCupboard.filter(item => item.object_observation.id === objectId) : notMappedCupboard);
        const placeholder = 'Выберите шкаф';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormCupboardNotSelect = ({input}) => {
        const isMulti = false;
        const options = cupboardOptions(cupboardNotOur.filter(item => item.mapped === false));
        const placeholder = 'Выберите шкаф на станции';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ChangeObjOs = (e) => {
        const activeCamera = cameras.find(item => item.id === e.value);
        dispatch(change('CupboardLayoutForm', 'object_observation', activeCamera.obj_id));
        // dispatch(change('CupboardLayoutForm', 'object_observation_name', activeCamera.obj_id));
    };

    const ChangeCap = (e) => {
        const activeCupboard= cupboardOur.find(item => item.id === e.value);
        console.log("activeCamera", activeCupboard);
        setObjectId(activeCupboard.object_observation.id)
        dispatch(change('CupboardLayoutForm', 'object_observation_name', activeCupboard.object_observation.name));
    };

    return (
        <form onSubmit={handleSubmit} >
            <div className="border-wrapper expanded row">
                <div className="small-8">
            <fieldset>
                <Legend title="Выбор локации">
                    <InputGroup title="Помещение" >
                        <Field
                            name="object_observation_val"
                            className={className('object_observation_val')}
                            component={ReduxFormObjectsSelect}
                            onChange={(e) => {
                                setObjectId(e.value);
                            }}
                            validate={[]}
                        />
                    </InputGroup>
                    <InputGroup title="Камера">
                        <Field
                            name="camera"
                            validate={[required]}
                            component={ReduxFormRoomSelect}
                            onChange={ChangeObjOs}
                        />
                    </InputGroup>
                    <InputGroup title="Шкаф в системе">
                        <Field
                            name="cabinet_int"
                            validate={[required]}
                            component={ReduxFormCupboardSelect}
                        />
                    </InputGroup>
                    {/*<InputGroup title="Шкаф не наш">*/}
                    {/*    <Field*/}
                    {/*        name="cabinet_ext"*/}
                    {/*        validate={[required]}*/}
                    {/*        component={ReduxFormCupboardNotSelect}*/}
                    {/*    />*/}
                    {/*</InputGroup>*/}
                </Legend>
            </fieldset>
                </div>
                <div className="small-16">
                    <fieldset>
                <Legend title="Отрисовка полигона" >
                    {formValues.camera &&
                        // <Zones objId={formValues.object_observation} camId={formValues.camera.value}/>
                        <ZonesCupboard
                            objId={formValues.object_observation}
                            camId={formValues.camera.value}
                            zones={formValues.polygon}
                            onSave={data => {
                                dispatch(change('CupboardLayoutForm', 'polygonForm', data.polygon));
                                formValues["polygonForm"] =  data.polygon
                            }
                            }
                        />
                    }
                </Legend>
            </fieldset>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state, props) => {
    if (!state.cupboardLayout.current?.id) {
        return {
            cameras: state.camera.flatList,
            cupboardOur: state.cupboardManage.listOur || [],
            cupboardNotOur: state.cupboardManage.listNotOur || [],
            formValues: getFormValues('CupboardLayoutForm')(state) || {},
            formValuesPolygon: getFormValues('ZoneEditFormCupboard')(state) || {},
            initialValues: {
                polygonForm: {},
            },
        };
    }
    return {
        cameras: state.camera.flatList,
        formValues: getFormValues('CupboardLayoutForm')(state) || {},
        formValuesPolygon: getFormValues('ZoneEditFormCupboard')(state) || {},
        cupboardOur: state.cupboardManage.listOur || [],
        cupboardNotOur: state.cupboardManage.listNotOur || [],
        initialValues: {
            ...state.cupboardLayout.current,
            camera: cameraActive(state.cupboardLayout.current.camera),
            cabinet_int: cameraActive(state.cupboardLayout.current.cabinet_int),
            // cabinet_ext: cameraActive(state.cupboardLayout.current.cabinet_ext),
            object_observation: state.camera.flatList[0].obj_id || 0,
            object_observation_val: cameraActive(state.cupboardLayout.current.cabinet_int.object_observation),
            polygonForm: {},
        },
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'CupboardLayoutForm',
        enableReinitialize: true,
    })(CupboardLayoutForm)
)
