const WEBSOCKETS = {
    MESSAGE: 'WEBSOCKETS_MESSAGE',
}

const initialState = {};

const websocketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case WEBSOCKETS.MESSAGE:
            return {
                //message: action.payload
                message: {

                }
            }
        default:
            return state;
    }
}

export default websocketsReducer;

export const messageReceived = (message) => dispatch => {
    dispatch({
        type: WEBSOCKETS.MESSAGE,
        payload: message
    })
}
