import axios from 'axios';

const CUPBOARDLAYOUT = {
    LIST:       'CUPBOARDLAYOUT_LIST',
    LIST_PENDING: 'CUPBOARDLAYOUT_PENDING',
    LIST_ERROR: 'CUPBOARDLAYOUT_LIST_ERROR',
    DETAIL:     'CUPBOARDLAYOUT_DETAIL',
    CREATE:     'CUPBOARDLAYOUT_CREATE',
    EDIT:       'CUPBOARDLAYOUT_EDIT',
    DELETE:     'CUPBOARDLAYOUT_DELETE',
    CLEAR:      'CUPBOARDLAYOUT_CLEAR',
    CURRENT_CLEAR:      'CUPBOARDLAYOUT_CURRENT_CLEAR',
};

const initialState = {
    current: {},
};

const cupboardLayoutReducer = (state = initialState, action) => {
    switch(action.type) {
        case CUPBOARDLAYOUT.LIST:
            return {
                ...state,
                list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case CUPBOARDLAYOUT.DETAIL:
        case CUPBOARDLAYOUT.CREATE:
        case CUPBOARDLAYOUT.EDIT:
        case CUPBOARDLAYOUT.DELETE:
            return {
                ...state,
                current: action.payload,
                isListPending: false,
            };
        case CUPBOARDLAYOUT.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case CUPBOARDLAYOUT.LIST_ERROR:
            return {
                isListError: true,
            }
        case CUPBOARDLAYOUT.CLEAR:
            return initialState;
        case CUPBOARDLAYOUT.CURRENT_CLEAR:
            return {
                ...state,
                current: undefined
            };
        default:
            return state;
    }
};

export default cupboardLayoutReducer;

export const cupboardLayoutList = () => dispatch => {
    dispatch({
        type: CUPBOARDLAYOUT.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/kola-mapping/cabinet-mapping-list/')
        .then(res => {
            dispatch({
                type: CUPBOARDLAYOUT.LIST,
                payload: res.data
            });
        })
};


/* Загрузка сопоставления */
export const cupboardLayoutLoad = (cabId) => dispatch => {
    dispatch({
        type: CUPBOARDLAYOUT.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/kola-mapping/cabinet-mapping/${cabId}/`)
        .then(res => {
            dispatch({
                type: CUPBOARDLAYOUT.DETAIL,
                payload: res.data,
            });
        });
};

/* Создание нового сопоставления */
export const cupboardLayoutCreate = (data) => dispatch => {
    axios.post(`/api/statistics/kola-mapping/cabinet-mapping/`, data)
        .then(res => {
            dispatch({
                type: CUPBOARDLAYOUT.CREATE,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: CUPBOARDLAYOUT.CREATE,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

export const cupboardLayoutDelete = (cabId) => dispatch => {
    axios.delete(`/api/statistics/kola-mapping/cabinet-mapping/${cabId}/`)
        .then(res => {
            let data = {isReloadList: true};
            dispatch({
                type: CUPBOARDLAYOUT.DELETE,
                payload: data,
            });
        });
};

export const cupboardLayoutClearState = () => dispatch => {
    dispatch({
        type: CUPBOARDLAYOUT.CLEAR,
        payload: null,
    });
};

export const cupboardLayoutCurrentClearState = () => dispatch => {
    dispatch({
        type: CUPBOARDLAYOUT.CURRENT_CLEAR,
        payload: null,
    });
};

export const cupboardLayoutEdit = (subId, data) => dispatch => {
    axios.put(`/api/statistics/kola-mapping/cabinet-mapping/${subId}/`, data)
        .then(res => {
            dispatch({
                type: CUPBOARDLAYOUT.EDIT,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: CUPBOARDLAYOUT.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};
