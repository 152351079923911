import axios from 'axios';

const PPE = {
    LIST: ' PPE_LIST',
    LIST_PENDING: 'PPE_PENDING',
    CLEAR: 'PPE_CLEAR',
    ERROR: 'PPE_ERROR',
}

const initialState = {};

const ppeReducer = (state = initialState, action) => {
    switch (action.type) {
        case PPE.LIST:
            return {
                list: action.payload.events_categories
            };
        case PPE.LIST_PENDING:
            return {
                isPending: true
            };
        case PPE.ERROR:
            return {
                isError: true
            }
        case PPE.CLEAR:
            return initialState;
        default:
            return state;
    }
}

export default ppeReducer;

export const loadPPEs = () => dispatch => {
    dispatch({
        type: PPE.LIST_PENDING,
        payload: null,
    });
   axios.get('/api/kola/categories/types/')
        .then(res => {
            dispatch({
                type: PPE.LIST,
                payload: res.data
            });
        })
        .catch(e => {
            dispatch({
                type: PPE.ERROR,
                payload: null
            })
        })
}

export const clearPPEs = () => dispatch => {
    dispatch({
        type: PPE.CLEAR,
        payload: null,
    });
};
