import axios from 'axios';
import img2base64 from '../utils/img2base64';

const OBJECT_OBSERVATION = {
    LIST: 'OBJECT_OBSERVATION_LIST',
    DETAIL: 'OBJECT_OBSERVATION_DETAIL',
    HOVER_CHANGED: 'OBJECT_OBSERVATION_DETAIL_HOVER_CHANGED',
    CHANGE: 'OBJECT_OBSERVATION_CHANGE',
    SAVED: 'OBJECT_OBSERVATION_SAVED',
    SAVE_ERROR: 'OBJECT_OBSERVATION_SAVE_ERROR',
    LIST_PENDING: 'OBJECT_OBSERVATION_LIST_PENDING',
    LIST_ERROR: 'OBJECT_OBSERVATION_LIST_ERROR',
    EDIT: 'PAGE_OBJECT_OBSERVATION_EDIT',
    DELETED: 'PAGE_OBJECT_OBSERVATION_DELETED',
    CLEAR: 'PAGE_OBJECT_OBSERVATION_CLEAR',
};

const initialState = {
    current: {},
};

const objectObservationReducer = (state = initialState, action) => {
    switch (action.type) {
        case OBJECT_OBSERVATION.LIST:
            return {
                ...state,
                isListPending: false,
                list: action.payload,
            };
        case OBJECT_OBSERVATION.DETAIL:
        case OBJECT_OBSERVATION.CHANGE:
        case OBJECT_OBSERVATION.HOVER_CHANGED:
        case OBJECT_OBSERVATION.SAVED:
        case OBJECT_OBSERVATION.SAVE_ERROR:
        case OBJECT_OBSERVATION.DELETED:
            return {
                ...state,
                current: action.payload,
            };
        case OBJECT_OBSERVATION.CLEAR:
            return initialState;
        case OBJECT_OBSERVATION.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case OBJECT_OBSERVATION.LIST_ERROR:
            return {
                ...state,
                isListError: true,
            }
        default:
            return state;
    }
};

export default objectObservationReducer;

export const loadObjectObservation = (objId) => dispatch => {
    axios.get(`/api/statistics/kola-backend/objects/${objId}/`)
        .then(res => {
            let cameras = res.data.cameras;
            cameras.sort((a, b) => (a.id > b.id) ? 1 : -1);
            dispatch({
                type: OBJECT_OBSERVATION.DETAIL,
                payload: {
                    id: res.data.id,
                    name: res.data.name,
                    // picture_map: res.data.picture_map,
                    // icon: res.data.icon,
                    cameras: cameras,
                },
            });
        });
};

export const loadEventLog = (objId) => dispatch => {
    axios.get(`/api/video/object-observations/${objId}/event-log/`)
        .then(async (res) => {

        });
};

export const loadObjectImages = (objId) =>
    axios.get(`/api/statistics/kola-backend/objects/${objId}/`)
        .then(async (res) => {
            const pictureMapUrl = res.data.picture_map;
            const pictureMap = await img2base64.convert(pictureMapUrl);

            const iconUrl = res.data.icon;
            let icon = iconUrl ? await img2base64.convert(iconUrl) : null;

            return ({
                picture_map: pictureMap,
                ...(icon && {icon}),
            })
        });

export const loadObjectObservationEvents = (objId) => dispatch => {
    axios.get(`/api/event/object-observation/${objId}/list/`)
        .then(res => {
            dispatch({
                type: OBJECT_OBSERVATION.EVENT_LIST,
                payload: res.data,
            });
        });
};

/* Показ изображения, при наведении на карте метки камеры */
export const hoverChanged = (obj, area, hoverVal) => dispatch => {
    let st = Object.assign({}, obj);
    st.hoveredArea = area;
    if (area) {
        st.hoveredImage = area.screenshot;
    }
    dispatch({
        type: OBJECT_OBSERVATION.HOVER_CHANGED,
        payload: st,
    });
};

/* Создание энергоблока */
export const createObjectObservation = (data) => dispatch => {
    axios.post('/api/statistics/kola-backend/power_units/', data)
        .then(res => {
            dispatch({
                type: OBJECT_OBSERVATION.SAVED,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: OBJECT_OBSERVATION.SAVE_ERROR,
                payload: {
                    isReloadList: true,
                    isError: true,
                },
            })
        })
};

/* Создание помещения */
export const createRoom = (data) => dispatch => {
    axios.post('/api/statistics/kola-backend/objects/', data)
        .then(res => {
            dispatch({
                type: OBJECT_OBSERVATION.SAVED,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: OBJECT_OBSERVATION.SAVE_ERROR,
                payload: {
                    isReloadList: true,
                    isError: true,
                },
            })
        })
};

export const editObjectObservation = (objId, data) => dispatch => {
    // loadObjectImages(objId).then(imagesData => {
        axios.put(`/api/statistics/kola-backend/power_units/${objId}/`, {
            ...data,
            // ...imagesData
        })
            .then(res => {
                dispatch({
                    type: OBJECT_OBSERVATION.SAVED,
                    payload: {isReloadList: true},
                });
            })
            .catch(err => {
                dispatch({
                    type: OBJECT_OBSERVATION.SAVE_ERROR,
                    payload: {
                        isReloadList: true,
                        isError: true,
                    },
                })
            });
    // });
};

export const editRoom = (objId, data) => dispatch => {
    // loadObjectImages(objId).then(imagesData => {
        axios.put(`/api/statistics/kola-backend/objects/${objId}/`, {
            ...data,
            // ...imagesData
        })
            .then(res => {
                dispatch({
                    type: OBJECT_OBSERVATION.SAVED,
                    payload: {isReloadList: true},
                });
            })
            .catch(err => {
                dispatch({
                    type: OBJECT_OBSERVATION.SAVE_ERROR,
                    payload: {
                        isReloadList: true,
                        isError: true,
                    },
                })
            });
    // });
};


export const deleteObjectObservation = (objId) => dispatch => {
    const url = `/api/statistics/kola-backend/power_units/${objId}/`;
    axios.delete(url)
        .then(res => {
            dispatch({
                type: OBJECT_OBSERVATION.DELETED,
                payload: {isReloadList: true},
            });
        });
};

export const deleteRoom = (objId, blockId) => dispatch => {
    const url = `/api/statistics/kola-backend/objects/${objId}/?power_unit_id=${blockId}`;
    axios.delete(url)
        .then(res => {
            dispatch({
                type: OBJECT_OBSERVATION.DELETED,
                payload: {isReloadList: true},
            });
        });
};

export const objectObservationClearState = () => dispatch => {
    dispatch({
        type: OBJECT_OBSERVATION.CLEAR,
        payload: {},
    });
};

/* Загрузка списка доступных объектов */
export const loadObjectsObservationList = () => dispatch => {
    dispatch({
        type: OBJECT_OBSERVATION.LIST_PENDING,
        payload: null,
    });
    // axios.get('/api/video/object-observations/')
    axios.get('/api/statistics/kola-backend/power_units/')
        .then(res => {
            dispatch({
                type: OBJECT_OBSERVATION.LIST,
                payload: res.data,
            });
        })
        .catch(e => {
            dispatch({
                type: OBJECT_OBSERVATION.LIST_ERROR,
                payload: null
            })
        })
};
