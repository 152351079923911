import React, {useState} from 'react';
import {Field, reduxForm, reset} from 'redux-form';

const Feedback = ({handleSubmit, handleCancel, forms, pristine, reset, submitting}) => {
    return (
        <form onSubmit={handleSubmit} >
            <fieldset className="settings-wrapper feedback-wrapper" >
                <div className="form-input textarea">
                    <div className="input">
                        <Field name="text" rows="4" component="textarea" />
                    </div>
                </div>
                <button type="submit" disabled={pristine ||submitting}>
                    Отправить
                </button>
            </fieldset>
        </form>
    );
};

const afterSubmit = (result, dispatch) =>
    dispatch(reset('Feedback'));

export default reduxForm({
    form: 'Feedback',
    onSubmitSuccess: afterSubmit,
})(Feedback)
