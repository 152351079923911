import React from 'react';
import {connect} from 'react-redux';
import {Gitgraph} from '@gitgraph/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSync} from '@fortawesome/free-solid-svg-icons';

import {checkout, loadCurrentCommit, loadGitLog, updateModelRepository} from '../store/Git';
import Spinner from './Spinner';
import TaskIndicator from './TaskIndicator';

class ModelRepository extends React.Component {
    constructor(props) {
        super(props);
        document.title = 'Хранилище моделей';
    }

    componentDidMount() {
        this.props.loadGitLog();
        this.props.loadCurrentCommit();
    }

    checkoutCommit = (commitId) => {
        if (this.props.isPendingTask) {
            return;
        }
        this.props.checkout(commitId);
    }

    getCommitId = async (commit) => {
        return new Promise((resolve, reject) => {
            for (const ch of commit.children) {
                if (ch.tagName !== 'defs') {
                    continue;
                }
                for (const circle of ch.children) {
                    if (circle.tagName === 'circle') {
                        resolve(circle.id);
                        return;
                    }
                }
            }
        });
    }

    // костыль, самый настоящий
    setStyleChildren = (item, color) => {
        // плохо - не получилось добавить в=цвет на активный коммит
        if (item.tagName === 'circle'
            || (item.tagName === 'text' && item.getAttribute('alignment-baseline') === 'central')) {
            item.style.fill = color;
        }
        for (const child of item.children) {
            this.setStyleChildren(child, color);
        }
    };

    setStyleActiveBranch = () => {
        const divGitGraph = document.getElementById('git-graph')
        if (!divGitGraph) {
            return;
        }
        const svg = divGitGraph.children[0];
        if (!svg.children || !svg.children.length || !svg.children[0].children || !svg.children[0].children.length) {
            return;
        }
        for (const ch of svg.children[0].children) {
            if (!ch || !ch.children || !ch.children.length) {
                continue;
            }
            for (const commit of ch.children) {
                this.getCommitId(commit)
                    .then(commitId => {
                        if (commit.onclick === null) {
                            commit.onclick = (e) => {
                                this.checkoutCommit(commitId);
                            }
                        }
                        this.setStyleChildren(commit, commitId === this.props.currentCommit.hash ? '#f00000' : '#979797');
                    });
            }
        }
    };

    componentDidUpdate() {
        if (this.props.log === null) {
            return;
        }
        setTimeout(this.setStyleActiveBranch, 200);
    }

    updateModelRepository = (event) => {
        event.preventDefault();
        const rv = window.confirm('Обновить хранилище?');
        if (rv === true) {
            this.props.updateModelRepository();
            this.props.loadCurrentCommit();
        }
    }

    isShowSpinner = () => this.props.git === null || this.props.isPending;

    render() {
        if (this.isShowSpinner()) {
            return <Spinner/>;
        }
        return (
            <div className="modelRepository">
                <div className="btn-wrapper">
                    <button className="table-control-btn btn-create" onClick={this.updateModelRepository}>
                        <FontAwesomeIcon icon={faSync} size="lg" color="#919191"/>
                        Обновить хранилище
                    </button>
                </div>

                <div>
                    <span>Текущий коммит: </span>
                    {this.props.currentCommit &&
                    <span style={{color: '#f00000'}}>
                        {this.props.currentCommit.hash.substr(0, 7)}
                    </span>}
                    {this.props.taskResult &&
                    <div>
                        <span>Статус: </span>
                        {this.props.taskResult.error.join(' ')}
                    </div>}
                </div>

                <div id="git-graph">
                    {this.props.log &&
                    <Gitgraph>
                        {gitgraph => {
                            gitgraph.import(this.props.log)
                        }}
                    </Gitgraph>}
                </div>

                {this.props.isPendingTask && <TaskIndicator/>}
            </div>
        );
    }
}

export default connect(
    state => ({
        log: state.git.log,
        currentCommit: state.git.currentCommit,
        isPending: state.git.isPending,
        isPendingTask: state.git.isPendingTask,
        taskResult: state.git.taskResult,
    }),
    dispatch => ({
        loadGitLog: () => {
            dispatch(loadGitLog());
        },
        loadCurrentCommit: () => {
            dispatch(loadCurrentCommit());
        },
        checkout: (hash) => {
            dispatch(checkout(hash));
        },
        updateModelRepository: () => {
            dispatch(updateModelRepository());
        }
    }),
)(ModelRepository);
