export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('kf-state');
        return JSON.parse(serializedState)
    } catch (ignored) {
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('kf-state', serializedState)
    } catch (ignored) {
    }
};
