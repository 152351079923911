import axios from 'axios';
import {eventNotificationQueryString} from "../utils/string";
import {PAGE_SIZE, START_PAGE} from "../utils/constants";

const NOTIFICATION_LOG = {
    LOAD: 'NOTIFICATION_LOG_LOAD',
    PENDING: 'NOTIFICATION_LOG_PENDING',
    ERROR: 'NOTIFICATION_LOG_ERROR',
}

const initialState = {};

const NotificationLogReducer = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_LOG.LOAD:
            return {
                log: action.payload,
            }
        case NOTIFICATION_LOG.PENDING:
            return {
                isPending: true,
            }
        case NOTIFICATION_LOG.ERROR:
            return {
                isError: true,
            }
        default:
            return state
    }
};

export default NotificationLogReducer;

const setTimezone = (date) => {
    const newDate = Date.parse(date);
    return Math.floor(newDate / 1000);
};

export const loadNotificationLog = (query, pageNumber, pageSize) => dispatch => {
    dispatch({
        type: NOTIFICATION_LOG.PENDING,
        payload: null,
    });
    let url = `/api/statistics/notifications/history/?page=${pageNumber}&size=${pageSize}`;
    const dateBegin = setTimezone(query.dateBegin);
    const dateEnd = setTimezone(query.dateEnd);
    url += eventNotificationQueryString(query, dateBegin, dateEnd);
    axios.get(url)
        .then(res => {
            dispatch({
                type: NOTIFICATION_LOG.LOAD,
                payload: {
                    currentPage: pageNumber,
                    ...res.data,
                },
            })
        })
        .catch(() => {
            dispatch({
                type: NOTIFICATION_LOG.ERROR,
                payload: null,
            })
        })
}
