import axios from 'axios';

const REGULATORY_DOCS_PRIMARY = {
  DOC_PRIMARY_LIST_REQUEST: '@@docs_primary/DOC_PRIMARY_LIST_REQUEST',
  DOC_PRIMARY_LIST_REQUEST_SUCCESS: '@@docs_primary/DOC_PRIMARY_LIST_REQUEST_SUCCESS',
  DOC_PRIMARY_LIST_REQUEST_ERROR: '@@docs_primary/DOC_PRIMARY_LIST_REQUEST_ERROR',

  DOC_PRIMARY_LOAD_REQUEST: '@@docs_primary/DOC_PRIMARY_LOAD_REQUEST',
  DOC_PRIMARY_LOAD_REQUEST_SUCCESS: '@@docs_primary/DOC_PRIMARY_LOAD_REQUEST_SUCCESS',
  DOC_PRIMARY_LOAD_REQUEST_ERROR: '@@docs_primary/DOC_PRIMARY_LOAD_REQUEST_ERROR',

  DOC_PRIMARY_UPDATE_REQUEST: '@@docs_primary/DOC_PRIMARY_UPDATE_REQUEST',
  DOC_PRIMARY_UPDATE_REQUEST_SUCCESS: '@@docs_primary/DOC_PRIMARY_UPDATE_REQUEST_SUCCESS',
  DOC_PRIMARY_UPDATE_REQUEST_ERROR: '@@docs_primary/DOC_PRIMARY_UPDATE_REQUEST_ERROR',

  DOC_PRIMARY_REMOVE_REQUEST: '@@docs_primary/DOC_PRIMARY_REMOVE_REQUEST',
  DOC_PRIMARY_REMOVE_REQUEST_SUCCESS: '@@docs_primary/DOC_PRIMARY_REMOVE_REQUEST_SUCCESS',
  DOC_PRIMARY_REMOVE_REQUEST_ERROR: '@@docs_primary/DOC_PRIMARY_REMOVE_REQUEST_ERROR',
}

const initialState = {
  error: null,
  busy: false,
  list: [],
  current: null
};

const RegulatoryDocsPrimaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_LIST_REQUEST:
    case REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_LOAD_REQUEST:
    case REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_UPDATE_REQUEST:
    case REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_REMOVE_REQUEST:
      return {
        ...state,
        busy: true,
        error: null
      };
    case REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        busy: false,
        list: action.payload
      };
    case REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_LOAD_REQUEST_SUCCESS:
      return {
        ...state,
        current: action.payload,
        busy: false,
      }
    case REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        current: action.payload,
        list: state.list.map(doc => doc.name === action.payload.name ? action.payload : doc),
        busy: false
      }
    case REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_REMOVE_REQUEST_SUCCESS:
      return {
        ...state,
        current: action.payload,
        list: state.list.map(doc => doc.name === action.payload.name ? action.payload : doc),
        busy: false
      }
    case REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_LIST_REQUEST_ERROR:
    case REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_LOAD_REQUEST_ERROR:
    case REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_UPDATE_REQUEST_ERROR:
    case REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_REMOVE_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        busy: false
      }
    default:
      return state;
  }
}

export default RegulatoryDocsPrimaryReducer;

export const loadRegulatoryDocsPrimary = () => dispatch => {
  dispatch({ type: REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_LIST_REQUEST });
  axios.get('/api/statistics/regulatory-docs/primary/')
    .then(({ data: payload }) => {
      dispatch({
        type: REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_LIST_REQUEST_SUCCESS,
        payload
      });
    })
    .catch(error => {
      dispatch({
        type: REGULATORY_DOCS_PRIMARY.ERROR,
        payload: error
      })
    })
}

export const loadRegulatoryDocPrimary = (category_name) => dispatch => {
  dispatch({ type: REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_LOAD_REQUEST });
  axios.get(`/api/statistics/regulatory-docs/primary/${category_name}/`)
    .then(({ data: payload }) => {
      dispatch({
        type: REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_LOAD_REQUEST_SUCCESS,
        payload
      })
    }).catch(error => {
    dispatch({
      type: REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_LOAD_REQUEST_ERROR,
      payload: error
    })
  })
}

export const updateRegulatoryDocPrimary = (data) => dispatch => {
  dispatch({ type: REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_UPDATE_REQUEST });
  axios.put(`/api/statistics/regulatory-docs/primary/${ data.name }/`,  data)
    .then(({ data: payload }) => {
      dispatch({
        type: REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_UPDATE_REQUEST_SUCCESS,
        payload
      })
    })
    .catch(error => {
      dispatch({
        type: REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_UPDATE_REQUEST_ERROR,
        payload: error
      })
    })
}

export const removeRegulatoryDocPrimary = ({ name }) => dispatch => {
  dispatch({ type: REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_REMOVE_REQUEST });
  axios.delete(`/api/statistics/regulatory-docs/primary/${ name }/`)
    .then(({ data: payload }) => {
      dispatch({
        type: REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_REMOVE_REQUEST_SUCCESS,
        payload
      })
    })
    .catch(error => {
      dispatch({
        type: REGULATORY_DOCS_PRIMARY.DOC_PRIMARY_REMOVE_REQUEST_ERROR,
        payload: error
      })
    })
}
