import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {apiDataToEvent, apiDataToEventObj} from '../utils/utils';
import {clearEvent, loadEvent, updateEvent} from '../store/Event';
import EventForm from '../forms/EventForm';
import Spinner from './Spinner';
import {
    addEmploeePhoto,
    clearEmploeesState,
    clearEmploeeState,
    loadEmploee,
    loadEmploeesList
} from "../store/Employees";
import {getFormValues, formValueSelector} from "redux-form";
import {clearEventReport, loadEventReport} from "../store/Events";
import {omitUndefinedAndNullFields} from "../utils/objects";

const Event = ({eventId, loadEvent, updateEvent, clearEvent, event, closeModal, isListPending, employees, loadEvents, reload, defaultQuery, filtersList, page, sizePerPage, user, addEmploeePhoto, loadEmploee, employee, isEmploeePending, clearEmploee}) => {
    useEffect(() => {
        loadEvent(eventId);
        return clearEvent;
    }, [eventId]);

    const [firstLoad, setFirstLoad] = useState(true);
    const [activeUser, setActiveUser] = useState(0);

    useEffect(() => {
        clearEmploee();
        if (activeUser !== 0) {
            loadEmploee(activeUser);
        }
    }, [activeUser])

    const save = (val) => {
        updateEvent(event.id, val.violation, 1, val.person_id || 0);
        if (val.add_person) {
            addEmploeePhoto(val.person_id, val.values.photo_person, event.id);
        }
        setTimeout(()=> {
            reload();
            // loadEvents(page, sizePerPage, defaultQuery);
        }, 600);
        closeModal();
    };

    const onChangePerson = (val) => {
        if (val.value !== activeUser) {
            setFirstLoad(false);
            setActiveUser(val.value)
        }
    };

    if (!event || isListPending) {
        return <Spinner/>
    }

    const onSubmit = (data) => {
        saveEvent(data)
    };
    const saveEvent = (val, values2) => {
        save(val);
    };
    return <EventForm
        onSubmit={onSubmit}
        event={apiDataToEventObj(event)}
        employees={employees}
        employee={employee}
        onChangePerson={onChangePerson}
        isEmploeePending={isEmploeePending}
        user={user}
    />
};

export default connect(
    state => ({
        event: state.event.event,
        user: state.user,
        employee: state.employees.current,
        isListPending: state.employees.isListPending || state.event.isPending,
        isEmploeePending: state.employees.isUserPending,
    }),
    dispatch => ({
        loadEvents: (pageNumber, pageSize, query) => {
            dispatch(clearEventReport());
            dispatch(loadEventReport(query, pageNumber, pageSize));
        },
        loadEvent: (eventId) => {
            dispatch(loadEvent(eventId));
        },
        clearEvent: () => {
            dispatch(clearEvent());
        },
        updateEvent: (eventId, hasViolation, userId, personId) => {
            dispatch(updateEvent(eventId, hasViolation, userId, personId));
        },
        addEmploeePhoto: (id, data, eventId) => {
            dispatch(addEmploeePhoto(id, data, eventId))
        },
        loadEmploee: (id) => {
            dispatch(loadEmploee(id));
        },
        clearEmploee: () => {
            dispatch(clearEmploeeState());
        },
    }),
)(Event);
