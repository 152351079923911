import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {loadModelStorage} from '../store/ModelStorage';
import {loadDecisionMatrix, saveDecisionMatrix} from '../store/DecisionMatrix';
import DecisionMatrixTableForm from '../tables/DecisionMatrixTableForm';
import Spinner from '../components/Spinner';
import withDataErrorIndication from '../components/withDataErrorIndication';
import {cameraList} from "../store/Camera";
import {loadObjectsObservationList} from "../store/ObjectObservation";
import RoomsColumns from "../tables/RoomsColumns";
import {omitRooms, onlyRooms} from "../utils/utils";
import RoomWorkTableForm from "../tables/RoomWorkTableForm";
import OutfitToleranceTableForm from "../tables/OutfitToleranceTableForm";
import {createJobDocument, deleteJobDocument, loadJobDocumentsList, saveJobDocument} from "../store/JobDocuments";
import {clearEmploeesState, loadEmploeesList} from "../store/Employees";
import {cupboardLayoutClearState, cupboardLayoutList} from "../store/CupboardLayout";

const type = 0; // тип документа - помещения +

const DecisionMatrix = ({isPending, decisionMatrix, actions, jobDocuments, cameras, objects, employees, cupboardLayoutList}) => {
    const {loadModel, loadDecisionMatrix, loadAllCupboardLayout, loadAllCameras, loadObjects, loadEmployeesList, saveJobDocument, createJobDocument, deleteJobDocument} = actions;

    useEffect(() => {
        loadModel();
        loadDecisionMatrix();
        loadAllCameras();
        loadObjects();
        loadEmployeesList();
        loadAllCupboardLayout();
    }, []);

    document.title = 'Помещения + работы';

    if (!jobDocuments || isPending || !cameras ) {
        return <Spinner/>;
    }

    const onSaveDoc = (data) => {
        if (data.id) {
            saveJobDocument(data.id, data, type)
        } else {
            createJobDocument(data, type)
        }
    }

    const onDelDoc = (id) => {
        deleteJobDocument(id, type);
    }


    return (
        <RoomWorkTableForm
            dataRows={jobDocuments}
            modelstorage={jobDocuments}
            handleSave={onSaveDoc}
            deleteDoc={onDelDoc}
            cameras={cameras}
            employees={employees}
            objects={onlyRooms(objects)}
            cupboardLayoutList={cupboardLayoutList}
        />
    )
};

export default connect(
    state => ({
        jobDocuments: state.jobDocuments.list,
        cupboardLayoutList: state.cupboardLayout.list,
        employees: state.employees.list,
        objects: state.object.list,
        cameras: state.camera.list || [],
        isPending: state.camera.isListPending || state.cupboardLayout.isListPending || state.object.isListPending || state.employees.isListPending || state.jobDocuments.isListPending,
        isError: state.jobDocuments ? state.jobDocuments.isError : false,
    }),
    dispatch => ({
        actions: {
            loadModel: () => {
                dispatch(loadJobDocumentsList(type));
            },
            loadObjects: () => {
                dispatch(loadObjectsObservationList());
            },
            loadAllCameras: () => {
                dispatch(cameraList());
            },
            loadDecisionMatrix: () => {
                dispatch(loadDecisionMatrix());
            },
            saveDecisionMatrix: (data) => {
                dispatch(saveDecisionMatrix(data));
            },
            loadEmployeesList: () => {
                dispatch(clearEmploeesState());
                dispatch(loadEmploeesList());
            },
            saveJobDocument: (id, data, type) => {
                dispatch(saveJobDocument(data.id, data, type))
            },
            createJobDocument: (data, type) => {
                dispatch(createJobDocument(data, type))
            },
            deleteJobDocument: (id, type) => {
                dispatch(deleteJobDocument(id, type))
            },
            loadAllCupboardLayout: () => {
                dispatch(cupboardLayoutClearState());
                dispatch(cupboardLayoutList());
            },
        }
    }),
)(withDataErrorIndication(DecisionMatrix));
