import React, {Component} from 'react'

import close from "./close.png"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {userFio} from "../../utils/userUtils";

const maxFileSize = 500;

class FileInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileSize: false,
        };
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    onFileChange = async (e) => {
        const { input } = this.props;
        const targetFile = e.target.files[0];
        if (targetFile) {
            const fileName = targetFile.name;
            const ext = fileName.split('.').pop();
            const val = await this.getBase64(targetFile);

            const res = val.split('base64,');

            const contentData = res[1];

            const newVal = `data:application/${ext};base64,${contentData}`;

            this.setState({
                fileSize: targetFile.size,
            });

            input.onChange({'name': targetFile.name, 'base64': newVal});
        } else {
            input.onChange(null)
        }
    };

    deleteFile = () => {
        const { input } = this.props;
        // input.onChange(null)
        this.props.onDelete();
    };


    render() {
        // console.log('ff', this.props);
        return (
            <>
                <div className="file-info" >
                    { (this.props.input.value || this.props.href) && this.props.isOnlyFileAdd === false ?
                        <>
                            <div className="file-wrapper">
                                <img className="main-img" src={this.props.href} />
                                <img src={close} onClick={this.deleteFile} alt=""/>
                            </div>
                            {this.props.employees && this.state.fileSize > maxFileSize &&
                                <div className="invalid-feedback red">Размер фото превышает {maxFileSize}КБ</div>
                            }
                        </>
                        :
                        <>
                            {this.props.employees &&
                                <>
                                    <div className="invalid-feedback">Фото не выбрано</div>
                                    <div className="invalid-feedback">Размер фото должен быть не более {maxFileSize}КБ</div>
                                </>
                            }
                            <label htmlFor="file-upload" className="attach-label">
                                <FontAwesomeIcon icon={faPlus} className="attach-label--icon"/>
                                Прикрепить
                            </label>
                            <input id="file-upload" type="file" onChange={this.onFileChange}/>
                        </>
                    }
                </div>
            </>
        )
    }
}

export default FileInput;
