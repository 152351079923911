import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {loadModelStorage} from '../store/ModelStorage';
import {loadDecisionMatrix, saveDecisionMatrix} from '../store/DecisionMatrix';
import DecisionMatrixTableForm from '../tables/DecisionMatrixTableForm';
import Spinner from '../components/Spinner';
import withDataErrorIndication from '../components/withDataErrorIndication';
import {cameraList} from "../store/Camera";
import {loadObjectsObservationList} from "../store/ObjectObservation";
import RoomsColumns from "../tables/RoomsColumns";
import {omitRooms, onlyRooms} from "../utils/utils";
import {clearEmploeesState, loadEmploeesList} from "../store/Employees";
import {thermalCameraList} from "../store/ThermalCamera";

const DecisionMatrix = ({isPending, decisionMatrix, actions, model, cameras, thermalCameras, objects, employees}) => {
    const {loadModel, loadDecisionMatrix, saveDecisionMatrix, loadAllCameras, loadThermalCamerasData, loadObjects, loadEmployeesList} = actions;

    useEffect(() => {
        loadModel();
        loadDecisionMatrix();
        loadAllCameras();
        loadThermalCamerasData();
        loadObjects();
        loadEmployeesList();
    }, []);

    document.title = 'Матрица параметров детектирования';

    if (!model || model.isPending || !decisionMatrix || decisionMatrix.isPending || isPending || !cameras ) {
        return <Spinner/>;
    }


    return (
        <DecisionMatrixTableForm
            dataRows={decisionMatrix}
            modelstorage={model}
            handleSave={saveDecisionMatrix}
            cameras={[...cameras, ...thermalCameras]}
            employees={employees}
            objects={onlyRooms(objects)}
        />
    )
};

export default connect(
    state => ({
        model: state.model,
        objects: state.object.list,
        decisionMatrix: state.decisionMatrix,
        employees: state.employees.list,
        cameras: state.camera.list || [],
        thermalCameras: state.thermalCamera.list || [],
        isPending: state.camera.isListPending || state.thermalCamera.isListPending || state.object.isListPending || state.employees.isListPending,
        isError: state.decisionMatrix ? state.decisionMatrix.isError : false,
    }),
    dispatch => ({
        actions: {
            loadModel: () => {
                dispatch(loadModelStorage());
            },
            loadObjects: () => {
                dispatch(loadObjectsObservationList());
            },
            loadAllCameras: () => {
                dispatch(cameraList());
            },
            loadThermalCamerasData: () => {
                dispatch(thermalCameraList());
            },
            loadDecisionMatrix: () => {
                dispatch(loadDecisionMatrix());
            },
            saveDecisionMatrix: (data) => {
                dispatch(saveDecisionMatrix(data));
            },
            loadEmployeesList: () => {
                dispatch(clearEmploeesState());
                dispatch(loadEmploeesList());
            },
        }
    }),
)(withDataErrorIndication(DecisionMatrix));
