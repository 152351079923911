import React from 'react';

import ExitScreenIcon from './ScreenIcon.svg';
import FullScreenIcon from './FullScreen.svg';

import "./MjpegPlayerNoIframe.css";

const IFrame = (props) => (
    <iframe
        src={props.src}
        frameBorder = "0"
        allow = "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    >
        Ваш браузер не поддерживает плавающие фреймы!
    </iframe>
);


const playerStyle = {
    top: 0,
    left: 0,
    objectFit: 'contain',
    width: '100%',
    height: '100%',
};

class MjpegPlayerNoIframe extends React.Component {
    constructor(props) {
        super(props);

        // this.escFunction = this.escFunction.bind(this);
        this.onEventFull = this.onEventFull.bind(this);

        this.state = {
            isFull: false,
            // isEnableFull: false;
        };
    }

    escFunction(event) {
        console.log("++++event", event.keyCode);
        if(event.keyCode === 27) {
            this.exitFull();
        }
    }

    onEventFull() {
        const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
        if (fullscreenElement && document.fullscreenEnabled) {
            this.setState({ isFull: true });
            window.document.body.style.overflow = "hidden";
        } else {
            this.setState({ isFull: false });
            window.removeEventListener("fullscreenchange", this.onEventFull);
            document.body.style.overflow = "auto";
        }
    }

    goFull() {
        this.setState({ isFull: true });
        if (document.fullscreenEnabled) {
            try {
                window.document.documentElement.requestFullscreen();
                document.addEventListener('fullscreenchange', this.onEventFull, false);
            } catch(e) {
                console.error(e);
            }
        }
    }

    exitFull() {
        const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
        if (fullscreenElement && document.fullscreenEnabled) {
            try {
                window.document.exitFullscreen();

            } catch (e) {
                console.error(e);
            }
        }
        document.body.style.overflow = "auto";
        this.setState({isFull: false});
    }

    render () {
        console.log('FULL>', this.state.isFull);
        return (
            <div className="vz-wrapper">
                {
                    (this.state.isFull)
                        ?
                        (
                            <div className="fullscreen">
                                <div style={{height: '100vh', overflow: 'hidden'}}>
                                    <img style={playerStyle} src={this.props.src}/>
                                    <img src={ExitScreenIcon} alt="fullscreen" onClick={(e) => this.exitFull()}/>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div style={{height: '720px', backgroundColor:'#000'}} >
                                <img style={playerStyle} src={this.props.src} />
                                <img  src={FullScreenIcon} alt="fullscreen" onClick={(e) => this.goFull()}/>
                            </div>
                        )
                }
            </div>
        );
    }
}


export default MjpegPlayerNoIframe;
