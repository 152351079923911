const initialState = null;

const USERS = {
    NOTIFICATION_SETTINGS: 'NOTIFICATION_SETTINGS',
}

const userSettingsReducer = (state = initialState, action) => {
    switch(action.type) {
        case USERS.LOAD_USER_NOTIFICATION_SETTINGS:
            return action.payload;
        default:
            return state;
    }
};

export default userSettingsReducer;
