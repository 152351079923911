import axios from 'axios';
import {userFio} from '../utils/userUtils';
import {employeesReportQueryString} from "../utils/employeesQuery";
import {PAGE_SIZE} from "../utils/constants";

const EMPLOYEES = {
    LIST: 'EMPLOYEES_LIST',
    LIST_PENDING: 'EMPLOYEES_LIST_PENDING',
    LIST_ERROR: 'EMPLOYEES_LIST_ERROR',
    EMPLOYEE: 'EMPLOYEE_LOAD',
    EMPLOYEE_PENDING: 'EMPLOYEE_PENDING',
    SAVED: 'EMPLOYEE_SAVED',
    CREATED: 'EMPLOYEE_CREATED',
    CLEAR: 'EMPLOYEES_CLEAR',
    EMPLOYEE_CLEAR: 'EMPLOYEE_CLEAR',
}

const initialState = {};

const employeesReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMPLOYEES.LIST:
            return {
                ...state,
                list: action.payload.persons
                    .map(user => ({
                        ...user,
                    })),
                persons_count: action.payload.persons_count,
                // list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case EMPLOYEES.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case EMPLOYEES.LIST_ERROR:
            return {
                ...state,
                isListError: true,
            }
        case EMPLOYEES.EMPLOYEE:
            return {
                ...state,
                current: action.payload,
                isUserPending: false,
            }
        case EMPLOYEES.SAVED:
            return {
                ...state,
                current: null,
                isUserPending: false,
            }
        case EMPLOYEES.EMPLOYEE_PENDING:
            return {
                ...state,
                isUserPending: true,
            }
        case EMPLOYEES.CLEAR:
            return {
                ...state,
                list: undefined
            };
        case EMPLOYEES.EMPLOYEE_CLEAR:
            return {
                ...state,
                current: undefined
            };
        default:
            return state;
    }
};

export default employeesReducer;

export const loadEmploeesList = (query) => dispatch => {
    dispatch({
        type: EMPLOYEES.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/facecloud/person-storage/persons/`)
        .then(res => {
            dispatch({
                type: EMPLOYEES.LIST,
                payload: res.data,
            });
        })
        .catch(() => {
            dispatch({
                type: EMPLOYEES.LIST_ERROR,
                payload: null,
            })
        })
};

export const loadEmploeesListMenagement = (query, pageNumber, pageSize) => dispatch => {
    dispatch({
        type: EMPLOYEES.LIST_PENDING,
        payload: null,
    });

    let url = `/api/facecloud/person-storage/persons/?page=${pageNumber}&size=${pageSize}`;
    url += employeesReportQueryString(query);

    axios.get(url)
        .then(res => {
            window.scrollTo(0, 0); // мотаю страницу на верх
            dispatch({
                type: EMPLOYEES.LIST,
                payload: res.data,
                // payload: {
                //     currentPage: pageNumber,
                //     ...res.data,
                // },
            });
        })
        .catch(() => {
            dispatch({
                type: EMPLOYEES.LIST_ERROR,
                payload: null,
            })
        })
};

export const loadEmploee = (id) => dispatch => {
    dispatch({
        type: EMPLOYEES.EMPLOYEE_PENDING,
        payload: null,
    });
    axios.get(`/api/facecloud/person-storage/persons/${id}/`)
        .then(res => {
            dispatch({
                type: EMPLOYEES.EMPLOYEE,
                payload: res.data,
            })
        })
}

export const saveEmploee = (id, data) => dispatch => {
    dispatch({
        type: EMPLOYEES.EMPLOYEE_PENDING,
        payload: null,
    });
    axios.put(`/api/facecloud/person-storage/persons/${id}/`, data)
        .then(res => {
            dispatch({
                type: EMPLOYEES.SAVED,
                payload: data,
            });
            // dispatch(clearEmploeesState());
            // dispatch(loadEmploeesListMenagement(query, pageNumber, pageSize));
        });
};

export const addEmploeePhoto = (id, data, eventId) => dispatch => {
    dispatch({
        type: EMPLOYEES.EMPLOYEE_PENDING,
        payload: null,
    });
    axios.post(`/api/facecloud/person-storage/persons/${id}/images/`, {"img_url": data, "event_id": eventId})
        .then(res => {
            dispatch({
                type: EMPLOYEES.SAVED,
                payload: data,
            });
        });
};


export const createEmploee = (data) => dispatch => {
    axios.post('/api/facecloud/person-storage/persons/', data)
        .then(res => {
            // dispatch(loadEmploeesList());
        });
};

export const deleteEmploee = (id) => dispatch => {
    dispatch({
        type: EMPLOYEES.LIST_PENDING,
        payload: null,
    });
    axios.delete(`/api/facecloud/person-storage/persons/${id}/`)
        .then(res => {
            // dispatch(clearEmploeesState());
            // dispatch(loadEmploeesList());
        });
};

export const clearEmploeesState = () => dispatch => {
    dispatch({
        type: EMPLOYEES.CLEAR,
        payload: null,
    });
};

export const clearEmploeeState = () => dispatch => {
    dispatch({
        type: EMPLOYEES.EMPLOYEE_CLEAR,
        payload: null
    })
};
