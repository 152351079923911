import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import TextInputWithButtons from '../tables/TextInputWithButtons';
import withDataErrorIndication from '../components/withDataErrorIndication';
import {
    roomNotOurClearState,
    roomNotOurDelete,
    roomNotOurList,
} from "../store/RoomNotOur";
import {
    roomOurEdit,
} from "../store/RoomOur";
import CupboardLayoutCheckedIcon from "../assets/img/icons/CupboardLayoutCheckedIcon";
import CupboardLayoutNotCheckedIcon from "../assets/img/icons/CupboardLayoutNotCheckedIcon";


const RoomNotOurMenagment = ({actions, roomNotOurs = [], isListPendingNotOur}) => {
    const {loadAllRoomNotOur, deleteRoomNotOur, updateRoomOur} = actions;

    const loadData = () => {
        loadAllRoomNotOur();
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (roomNotOurs?.isReloadList) {
            loadData();
        }
    }, [roomNotOurs]);

    const Columns = (cellCancelHandler) => [{
        text: '№',
        dataField: 'id',
        headerStyle: () => ({width: '59px'}),
        editable: false,
    }, {
        text: 'Разметка',
        dataField: 'mapped',
        sort: true,
        headerStyle: () => ({width: '120px'}),
        editable: false,
        formatter: (cell) => <div className="layout-wrapper">{cell ? <CupboardLayoutCheckedIcon/> : <CupboardLayoutNotCheckedIcon/>}</div>,
    }, {
        text: 'Код',
        dataField: 'code',
        sort: true,
        headerStyle: () => ({width: '220px'}),
        editable: false,
    }, {
        text: 'Название',
        dataField: 'name',
        sort: true,
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons
                {...editorProps}
                onCancel={cellCancelHandler} className="required-field"/>
        ),
    }, {
        text: 'Энгергоблок',
        dataField: 'power_unit',
        sort: true,
        editable: false,
    }
    ].map(col => ({
        ...col,
        editable: false,
        classes: 'px-0 py-0 table-cell',
    }));

    document.title = 'Помещения';

    return (
        <>
            <div className="row expanded">
                <div className="small-24 room-wrapper">
                    <GeneralObjectsTable
                        isPending={isListPendingNotOur}
                        objects={roomNotOurs}
                        isEditable={false}
                        actions={{
                            update: updateRoomOur,
                            create: () => {
                            },
                            purge: deleteRoomNotOur,
                            reload: loadData,
                        }}
                        getColumns={Columns}
                        itemDummy={{
                            id: 0,
                            code: 0,
                            obj: '',
                            name: '',
                            protocol: '',
                            host: '',
                            suffix: '',
                            credentials: [],
                            is_enabled: true,
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default connect(
    state => ({
        roomNotOurs: state.roomNotOur.list,
        roomNotOur: state.roomNotOur.current,
        isListPendingNotOur: state.roomNotOur.isListPending,
        isErrorNotOur: state.roomNotOur.isListError,

        roomOur: state.roomOur.current,
        isListPendingOur: state.roomOur.isListPending || state.roomNotOur.isListPending,
        isErrorOur: state.roomOur.isListError,
    }),
    dispatch => ({
        actions: {
            loadAllRoomNotOur: () => {
                dispatch(roomNotOurClearState());
                dispatch(roomNotOurList());
            },
            deleteRoomNotOur: (room) => {
                dispatch(roomNotOurDelete(room));
            },

            updateRoomOur: (room) => {
                dispatch(roomOurEdit(room.id, room));
            },
        },
    }),
)(withDataErrorIndication(RoomNotOurMenagment));
