import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {loadCleanSettings, loadScheduleSettings, saveClearSettings} from '../store/CleanSettings';
import withDataErrorIndication from '../components/withDataErrorIndication';
import ClearSettingsForm from "../forms/ClearSettingsForm";

const CleanSettings = ({actions, clearSettings, isListPending}) => {
    const {loadClearValues, saveClearValues} = actions;

    const loadData = () => {
        loadClearValues();
    };

    useEffect(() => {
        loadData();
    }, []);

    const submit = (val) => {
        let data = {
            values: {
                events_cleanup_interval_sec: parseInt(val.events_cleanup_interval_sec),
                events_storing_duration_days: parseInt(val.events_storing_duration_days)
            },
            schedule: {
                cron_report_day: parseInt(val.cron_report_day),
                cron_report_time: val.cron_report_time,
            }
        };
        saveClearValues(data);
        return false;
    };

    document.title = 'Настройки очистки устаревших событий ';

    return (
        <>
            <div className="settings-form">
                <ClearSettingsForm onSubmit={submit}/>
            </div>
        </>
    )
}

export default connect(
    state => ({
        isPanding: state.clearSetting.isValuesPending,
        clearSettings: state.clearSetting.values,
        schedule: state.clearSetting.schedule
    }),
    dispatch => ({
        actions: {
            loadClearValues: () => {
                dispatch(loadCleanSettings());
                dispatch(loadScheduleSettings());
            },
            saveClearValues: (data) => {
                dispatch(saveClearSettings(data));
            }
        },
    }),
)(withDataErrorIndication(CleanSettings));
