import createRouter from 'router5';
import browserPlugin from 'router5-plugin-browser';

import routes, {ROUTES} from './routes';
import {PAGES_AVAILABLE} from '../roles';

export default function configureRouter() {
    const router = createRouter(routes, {
        defaultRoute: ROUTES.CAMERAS,
    });

    router.usePlugin(browserPlugin());

    return router
}
