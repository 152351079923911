import axios from 'axios';

const CUPBOARDMANAGE = {
    OUR_LIST:       'CUPBOARDMANAGE_OUR_LIST',
    NOT_OUR_LIST:       'CUPBOARDMANAGE_NOT_OUR_LIST',
    LIST_PENDING: 'CUPBOARDMANAGE_PENDING',
    LIST_ERROR: 'CUPBOARDMANAGE_LIST_ERROR',
    DETAIL:     'CUPBOARDMANAGE_DETAIL',
    CREATE:     'CUPBOARDMANAGE_CREATE',
    EDIT:       'CUPBOARDMANAGE_EDIT',
    DELETE:     'CUPBOARDMANAGE_DELETE',
    CLEAR:      'CUPBOARDMANAGE_CLEAR',
    CURRENT_CLEAR:      'CUPBOARDMANAGE_CURRENT_CLEAR',
};

const initialState = {
    current: {},
};

const cupboardManageReducer = (state = initialState, action) => {
    switch(action.type) {
        case CUPBOARDMANAGE.OUR_LIST:
            return {
                ...state,
                listOur: action.payload,
                isListPending: false,
                isListError: false,
            };
        case CUPBOARDMANAGE.NOT_OUR_LIST:
            return {
                ...state,
                listNotOur: action.payload,
                isListPending: false,
                isListError: false,
            };
        case CUPBOARDMANAGE.DETAIL:
        case CUPBOARDMANAGE.CREATE:
        case CUPBOARDMANAGE.EDIT:
        case CUPBOARDMANAGE.DELETE:
            return {
                ...state,
                current: action.payload,
                isListPending: false,
            };
        case CUPBOARDMANAGE.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case CUPBOARDMANAGE.LIST_ERROR:
            return {
                isListError: true,
            }
        case CUPBOARDMANAGE.CLEAR:
            return initialState;
        case CUPBOARDMANAGE.CURRENT_CLEAR:
            return {
                ...state,
                current: undefined
            };
        default:
            return state;
    }
};

export default cupboardManageReducer;

export const cupboardManageOurList = () => dispatch => {
    dispatch({
        type: CUPBOARDMANAGE.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/kola-mapping/cabinet-int/')
        .then(res => {
            dispatch({
                type: CUPBOARDMANAGE.OUR_LIST,
                payload: res.data
            });
        })
};

export const cupboardManageNotOurList = () => dispatch => {
    dispatch({
        type: CUPBOARDMANAGE.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/kola-mapping/cabinet-ext/')
        .then(res => {
            dispatch({
                type: CUPBOARDMANAGE.NOT_OUR_LIST,
                payload: res.data
            });
        })
};


/* Загрузка шкафа нашего */
export const cupboardManageOurLoad = (subId) => dispatch => {
    dispatch({
        type: CUPBOARDMANAGE.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/kola-mapping/cabinet-int/${subId}/`)
        .then(res => {
            dispatch({
                type: CUPBOARDMANAGE.DETAIL,
                payload: res.data,
            });
        });
};

/* Загрузка шкафа не нашего */
export const cupboardManageNotOurLoad = (subId) => dispatch => {
    dispatch({
        type: CUPBOARDMANAGE.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/kola-mapping/cabinet-ext/${subId}/`)
        .then(res => {
            dispatch({
                type: CUPBOARDMANAGE.DETAIL,
                payload: res.data,
            });
        });
};

/* Создание шкафа нашего */
export const cupboardManageOurCreate = (data) => dispatch => {
    axios.post(`/api/statistics/kola-mapping/cabinet-int/`, data)
        .then(res => {
            dispatch({
                type: CUPBOARDMANAGE.CREATE,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: CUPBOARDMANAGE.CREATE,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

/* Создание шкафа не нашего */
export const cupboardManageNotOurCreate = (data) => dispatch => {
    axios.post(`/api/statistics/kola-mapping/cabinet-ext/`, data)
        .then(res => {
            dispatch({
                type: CUPBOARDMANAGE.CREATE,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: CUPBOARDMANAGE.CREATE,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

export const cupboardManageOurDelete = (subId) => dispatch => {
    axios.delete(`/api/statistics/kola-mapping/cabinet-int/${subId}/`)
        .then(res => {
            let data = {isReloadList: true};
            dispatch({
                type: CUPBOARDMANAGE.DELETE,
                payload: data,
            });
        });
};

export const cupboardManageNotOurDelete = (subId) => dispatch => {
    axios.delete(`/api/statistics/kola-mapping/cabinet-ext/${subId}/`)
        .then(res => {
            let data = {isReloadList: true};
            dispatch({
                type: CUPBOARDMANAGE.DELETE,
                payload: data,
            });
        });
};

export const cupboardManageClearState = () => dispatch => {
    dispatch({
        type: CUPBOARDMANAGE.CLEAR,
        payload: null,
    });
};

export const cupboardManageCurrentClearState = () => dispatch => {
    dispatch({
        type: CUPBOARDMANAGE.CURRENT_CLEAR,
        payload: null,
    });
};

export const cupboardManageOurEdit = (subId, data) => dispatch => {
    axios.put(`/api/statistics/kola-mapping/cabinet-int/${subId}/`, data)
        .then(res => {
            dispatch({
                type: CUPBOARDMANAGE.EDIT,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: CUPBOARDMANAGE.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

export const cupboardManageNotOurEdit = (subId, data) => dispatch => {
    axios.put(`/api/statistics/kola-mapping/cabinet-ext/${subId}/`, data)
        .then(res => {
            dispatch({
                type: CUPBOARDMANAGE.EDIT,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: CUPBOARDMANAGE.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};
