import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {
    createObjectObservation, createRoom,
    deleteObjectObservation, deleteRoom,
    editObjectObservation, editRoom,
    loadObjectsObservationList,
    objectObservationClearState
} from '../store/ObjectObservation';
import {base64Pixel} from '../utils/string';
import {omitRooms, onlyRooms} from '../utils/utils';
import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import RoomsColumns from '../tables/RoomsColumns';
import BlocksColumns from '../tables/BlocksColumns';
import withDataErrorIndication from '../components/withDataErrorIndication';
import Spinner from "../components/Spinner";

const ObjectsManagement = ({type, actions, isListPending, objects, object}) => {
    const {loadObjects} = actions;

    useEffect(() => {
        loadObjects();
    }, []);

    useEffect(() => {
        if (object.isReloadList) {
            loadObjects();
        }
    }, [object]);

    if (isListPending) {
        return <Spinner/>;
    }

    const [title, getColumns, elements] = type === 'rooms' ?
        ['Помещения', RoomsColumns(objects), onlyRooms(objects)]
        : ['Объекты', BlocksColumns(), omitRooms(objects)];

    document.title = title;

    const sort = (items) => {
        items.sort(function(a, b){
            return a.id-b.id
        });
        return items;
    };

    return (
            <GeneralObjectsTable
                isPending={isListPending}
                objects={sort(elements)}
                actions={{
                    update: type === 'rooms' ? actions.updateRoom : actions.updateObject,
                    create: type === 'rooms' ? actions.createRoom : actions.createObject,
                    purge: type === 'rooms' ? actions.deleteRoom : actions.deleteObject,
                    reload: actions.reloadObjects,
                }}
                getColumns={getColumns}
                itemDummy={{
                    id: 0,
                    name: '',
                    description: '',
                    parent: '',
                }}
            />
    )
};

export default connect(
    state => ({
        objects: state.object.list,
        object: state.object.current,
        isListPending: state.object.isListPending,
        isError: state.object.isListError,
    }),
    dispatch => ({
        actions: {
            loadObjects: () => {
                dispatch(loadObjectsObservationList());
            },
            reloadObjects: () => {
                dispatch(objectObservationClearState());
                dispatch(loadObjectsObservationList());
            },
            createObject: (data) => {
                dispatch(createObjectObservation({
                    name: data.name,
                    // description: data.description,
                    // picture_map: base64Pixel,
                    // parent: data.parent,
                }))
            },
            createRoom: (data) => {
                dispatch(createRoom({
                    code: data.code,
                    name: data.name,
                    description: data.description,
                    // picture_map: base64Pixel,
                    power_unit_id: data.parent,
                }))
            },
            updateObject: (objectId, data) => {
                dispatch(editObjectObservation(objectId, {
                    name: data.name,
                    description: data.description,
                    parent: data.parent,
                }))
            },
            updateRoom: (objectId, data) => {
                dispatch(editRoom(objectId, {
                    code: data.code,
                    name: data.name,
                    description: data.description,
                    power_unit_id: data.parent,
                }))
            },
            deleteObject: (objectId) => {
                dispatch(deleteObjectObservation(objectId))
            },
            deleteRoom: (objectId, data) => {
                dispatch(deleteRoom(objectId, data.parent))
            }
        }
    }),
)(withDataErrorIndication(ObjectsManagement));
