import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {loadObjectsObservationList} from '../store/ObjectObservation';
import {clearEventReport, loadEventReport} from '../store/Events';
import {omitUndefinedAndNullFields} from '../utils/objects';
import EventList from './EventList';
import Modal from '../components/Modal';
import Event from '../components/Event';
import {DEFAULT_INTERVAL_HOURS, PAGE_SIZE, START_PAGE} from '../utils/constants';
import withDataErrorIndication from '../components/withDataErrorIndication';
import Spinner from "../components/Spinner";
import {cameraClearState, cameraFlatDetailedList} from "../store/Camera";
import RemotePagination from "./RemotePagination";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {customFilter, FILTER_TYPES, selectFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import {apiDataToEventObj, cameraInObject, omitRooms, onlyRooms, roomsInObject} from "../utils/utils";
import {objectsListToOptions} from "../utils/inputs";
import RangeFilter from "../components/RangeFilter";
import {getDateNow} from "../utils/time";
import {clearEmploeesState, loadEmploeesList} from "../store/Employees";
import {userFio} from "../utils/userUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faFile, faPlus, faSyncAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {thermalCameraList} from "../store/ThermalCamera";
import {clearStatisticReport, loadStatisticReport} from "../store/Statistic";

const StatisticPage = (props) => {
    const {
        defaultQuery, loadStatistic, clearStatistic, isPending, statistic
    } = props;

    const [filtersList, setfiltersList] = useState(null);
    const [maxCount, setMaxCount] = useState(0);

    useEffect(() => {
        loadStatistic(defaultQuery);
    }, []);

    useEffect(() => {
        reloadQuery();
    }, [filtersList]);

    // if (isPending) {
    //     return <Spinner/>;
    // }

    const reload = () => {
        reloadQuery();
    };

    const reloadQuery = () => {
        const filtersQuery = {
            ...(filtersList?.category_name && {
                dateBegin: filtersList?.category_name.filterVal.from,
                dateEnd: filtersList?.category_name.filterVal.to
            }),
        };
        loadStatistic({
            ...defaultQuery,
            ...omitUndefinedAndNullFields(filtersQuery)
        });
    }

    const onTableChange = (type, {sizePerPage, page, filters, sortField, sortOrder}) => {
        setfiltersList(filters);
    };


    const columns = [{
        text: '№',
        dataField: 'id',
        headerStyle: () => ({width: '59px'})
    }, {
        text: 'Дата и время',
        dataField: 'category_name',
        headerStyle: () => ({width: '350px'}),
        headerClasses: 'table-date',
        filter: customFilter({
            type: FILTER_TYPES.DATE,
        }),
        filterRenderer: (onFilter, column) =>
            <RangeFilter
                onFilter={onFilter}
                labelFrom="с" labelTo="по"
                valueFrom={getDateNow(-DEFAULT_INTERVAL_HOURS)}
                valueTo={getDateNow()}
            />
    }, {
        text: 'Количество',
        headerStyle: () => ({width: '250px'}),
        dataField: 'count',
    }, {
        text: 'Процент от общего количества',
        dataField: 'percent',
    }]
        .map(col => ({
            ...col,
            editable: false,
            classes: 'px-0 py-0 table-cell',
        }));

    let tableRef;

    const apiEvents = (statisticData) => {
        let sorted = statisticData.sort((a, b) => b.count - a.count);
        let maxNumber = 0;
        for (let item of sorted){
            maxNumber += item.count;
        }
        const newArr = sorted.map((item, key) => {
            return {
                id: key + 1,
                category_name: item.category_name,
                count: item.count,
                percent: `${(item.count*100/maxNumber).toFixed()}%`
            }
        });
        return newArr;
    }

    return (
        <>
            <div className="btn-wrapper with-other-btns">
                <div className="btn-wrapper-left">
                    <button className="table-control-btn btn-reload" onClick={reload}>
                        <FontAwesomeIcon icon={faSyncAlt} size="lg" color="#919191"/>
                        Обновить
                    </button>
                </div>
            </div>
            <BootstrapTable
                remote={{filter: true, sort: true}}
                ref={el => tableRef = el}
                keyField="category_name"
                data={statistic ? apiEvents(statistic) : []}
                columns={columns}
                defaultSorted={[{dataField: 'count', order: 'asc'}]}
                filter={filterFactory()}
                onTableChange={onTableChange}
                noDataIndication={() => isPending ? <Spinner/> : 'Данные отсутствуют'}
            />
        </>
    );
};

export default connect(
    state => ({
        statistic: state.statistic.list,
        isPending: state.statistic.isPending,
        isError: state.statistic.isError,
    }),
    dispatch => ({
        loadStatistic: (query) => {
            // dispatch(clearEventReport());
            dispatch(loadStatisticReport(query));
        },
        clearStatistic: () => {
            dispatch(clearStatisticReport());
        },


    }),
)(withDataErrorIndication(StatisticPage));
