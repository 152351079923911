import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {onLogin} from '../store/Auth';
import Icons from '../Icons';
import AuthForm from '../forms/AuthForm';
import Spinner from '../components/Spinner';

const AuthPage = ({ user, dispatch }) => {
    document.title = 'Вход в систему';

    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        setShowSpinner(false);
    }, [user]);

    const handleSubmit = (data) => {
        const {login, password} = data;
        setShowSpinner(true);
        dispatch(onLogin(login, password))
    };

    if (showSpinner) {
        return <Spinner />;
    }
    return (
        <div className="main-wrapper">
            <div className="form-container">
                <div className="form-wrapper">
                    <div className="form-title">
                        <img src={Icons.logo} alt=""/>
                        <p>Система видеоанализа соблюдения техники
                            безопасности и промышленной безопасности</p>
                    </div>
                    <AuthForm onSubmit={handleSubmit}/>
                </div>
            </div>
        </div>
    );
};

export default connect(
    state => ({
        user: state.user,
    }),
    dispatch => ({ dispatch }),
)(AuthPage);
