import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup} from '../inputs';
import {splitCameraUri, isBlock} from "../utils/utils";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";
import ZonesThermal from "../components/ZonesThermal";
import ZonesThermal3 from "../components/ZonesThermal3";
import Spinner from "../components/Spinner";

const required = value => value ? undefined : 'Обязательное поле';

const optionsType = [{
    value: 1,
    label: "Наблюдение за людьми"
}, {
    value: 2,
    label: "Наблюдение за зонами"
}];

const setActiveType = (peopleValue) => {
    return peopleValue ? optionsType[0] : optionsType[1]
}

const typeSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry,
            label: entry
        }
    })
};

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.name ? `${entry.name} (${entry.power_unit.name})` : entry.ru_name
        }
    })
};

const objectsSelectActiveOptions = (objects, obj_id) => {
    const activeObj = objects.find(item => item.id === obj_id);
    if (activeObj) {
        return {
            value: activeObj.id,
            label: `${activeObj.name} (${activeObj.power_unit.name})`
        }
    } else {
        return
    }
};

const ThermalCameraForm = ({handleSubmit, handleCancel, forms, formValues, isPending, pristine, reset, submitting, dispatch, userId, isEditMode, objectsList, initialValues, ppes, onChange}) => {
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';
    const [firstDownload, setFirstDownload] = useState(true);

    useEffect(() => {
        if (initialValues && firstDownload){
            onChange(initialValues.face_temp_mon.is_enabled ? 1 : 2);
            setFirstDownload(false);
        }
    }, [initialValues])

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = objSelectOptions(isBlock(objectsList));
        const placeholder = 'Выберите помещение';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormObservationSelect = ({input, validate}) => {
        const isMulti = false;
        const options = optionsType;
        const placeholder = 'Выберите тип наблюдения';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };


    return (
                <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
                    <fieldset disabled={!isEditMode}>
                        <div className={initialValues.id !== 0 ? "border-wrapper expanded row" : ''}>
                            <div className={initialValues.id !== 0 ? "small-8" : ''}>
                                <Legend title="Основные данные">
                                    <Field name="name" type="text" label="Название"
                                           component={InputField} className={className('name')}
                                           validate={[required]}/>
                                    <InputGroup title="Помещение">
                                        <Field
                                            name="object"
                                            className={className('object')}
                                            component={ReduxFormObjectsSelect}
                                            validate={[required]}
                                        />
                                    </InputGroup>
                                </Legend>
                                <Legend title="Параметры">
                                    <Field name="media_uri" type="text" label="URL"
                                           component={InputField} className={className('media_uri')}
                                           validate={[required]}/>
                                    <Field name="temp_thresh_celsius" type="text" label="Значение пороговой температуры, °C"
                                           component={InputField} className={className('temp_thresh_celsius')}
                                           validate={[required]}/>
                                    {/* <Field name="test_dataset_path" type="text" label="test_dataset_path"
                                           component={InputField} className={className('test_dataset_path')}
                                           validate={[]}/> */}
                                    <Field
                                        name="is_enabled"
                                        component={EnableCheckbox}
                                        labelText="Вкл"
                                    />
                                    {initialValues.id !== 0 &&
                                    <InputGroup title="Тип наблюдения">
                                        <Field
                                            onChange={(e) => onChange(e.value)}
                                            name="observation"
                                            className={className('observation')}
                                            component={ReduxFormObservationSelect}
                                            validate={[]}
                                        />
                                    </InputGroup>
                                    }
                                </Legend>
                            </div>
                            {/*{initialValues.id !== 0 &&*/}
                            <div className={initialValues.id !== 0 && formValues.observation?.value === 2? "zone-wrapper small-16" : 'small-16 zone-wrapper zone-none'}>
                                <Legend title="Разметка зон">
                                        <ZonesThermal3
                                            preview={initialValues.preview}
                                            preview_size={initialValues.preview_size}
                                            zones={initialValues.id !== 0 && initialValues.zones && initialValues.zones.length !== 0 ? [initialValues.zones] : [[[250, 250], [500, 250], [500, 500], [250, 500]]]}
                                            // zones={[initialValues.zone_temp_mon?.zones.slice(-1)[0]?.vertices]}
                                            onSave={data => {
                                                setTimeout(() => {
                                                    console.log("data1111", data);
                                                    dispatch(change('ThermalCameraZoneForm', 'polygonForm', data.polygon));
                                                    formValues["polygonForm"] = data.polygon
                                                }, 1000)
                                            }
                                            }
                                        />
                                </Legend>
                            </div>
                            {/*}*/}
                        </div>
                    </fieldset>
                </form>
    );
};

const mapStateToProps = (state) => {
    if (!state.thermalCamera.current.id) {
        return {
            initialValues: {
                id: 0,
                observation: {
                    value: 0,
                    label: ""
                },
                face_temp_mon: {
                    is_enabled: 0
                }
            },
        };
    }
    return {
        initialValues: {
            ...state.thermalCamera.current,
            zones: state.thermalCamera.current.zone_temp_mon?.zones.slice(-1)[0]?.vertices,
            temp_thresh_celsius: state.thermalCamera.current?.face_temp_mon.temp_thresh_celsius.toFixed(1),
            observation: setActiveType(state.thermalCamera.current?.face_temp_mon.is_enabled),
            object: objectsSelectActiveOptions(isBlock(state.object.list ? state.object.list : []), state.thermalCamera.current.obj_id),
        },
        formValues: getFormValues('ThermalCameraForm')(state) || {},
        cameraId: state.thermalCamera.current.id,
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'ThermalCameraForm',
        enableReinitialize: true,
    })(ThermalCameraForm)
)
