import PolygonEditor from "../../components/PolygonEditor/PolygonEditor";
import React from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {positionDefault} from "../../components/PolygonEditor/helpers";

const required = value => value ? undefined : 'Required';

const selectOptions = [
  { value: '#00ff00', label: 'Green' },
  { value: '#ff0000', label: 'Red' },
  { value: '#0000ff', label: 'Blue' },
];

const renderInputField = ({ input, label, type, disabled, meta: { touched, error, warning } }) => {
  return (
    <div className="zoneeditform-inputs--control">
      <label htmlFor="name" className="zoneeditform-inputs--control__label">Название</label>
      <input {...input} placeholder={label} type={type} disabled={disabled}
             className={error ? 'zoneeditform-inputs--control__field is-invalid' : 'zoneeditform-inputs--control__field'}
             autoComplete="off"
      />
      <div className="col-sm-10">
      </div>
    </div>
  );
};

const renderColorSelectInput = ({input, options, color}) => {
  const stateOptions = () => selectOptions.map(option => (
    <option value={option.value} key={option.value}>
      {`${option.label}`}
    </option>
  ));
  return (
    <div className="zoneeditform-inputs--control zoneeditform-inputs--control__right">

      <label htmlFor="name" className="zoneeditform-inputs--control__label">Цвет</label>
      <select
        value={input.value}
        onChange={(e) => input.onChange(e.target.value)}
      >
        {stateOptions()}
      </select>
    </div>
  );
}


const renderPolygonField =  (props) => {
  const { input, screenshot, color, initZones, zoneConfigName, isNewZone } = props;

  let zones = initZones;
  if (isNewZone === true) {
    zones = [{
      polygon: positionDefault(screenshot.sizes),
      name: zoneConfigName,
      color: color,
    }];
  } else if (input.value) {
    zones = input.value;
  }

  return (
    <PolygonEditor
      zones={zones}
      zoneEditedCallback={(data) => {
        input.onChange(data)
      }}
      screenshot={screenshot}
      color={color}
      edit={true}
    />
  );
}

const ZoneEditForm  = (props) => {

  const { handleSubmit } = props;

  if (!props.initialValues.screenshot) {
    return <form/>;
  }

  return (
    <form onSubmit={handleSubmit} className='zoneeditform'>
      <div className='zoneeditform-inputs'>
        <Field
          name="name"
          type="text"
          label="Название зоны"
          component={renderInputField}
          className={
            props.forms &&
            props.forms.camera &&
            !props.forms.camera.name.valid ? 'form-control is-invalid' : 'form-control'
          }
          initialValues={"defaultValues.name"}
          validate={[required,]}
          disabled={true}
        />
        <Field name="color"
               component={renderColorSelectInput}
               options={selectOptions}
        />
        <button type="button" className="zoneeditform-trashbutton" onClick={props.showConfirmDialog}>
          <FontAwesomeIcon icon={faTrash}/>
        </button>
      </div>
      <Field name="polygon"
             component={renderPolygonField}
             validate={[required,]}
             screenshot={props.initialValues.screenshot}
             zoneConfigName={props.initialValues.name}
             initZones={props.initZones}
             isNewZone={props.isNewZone}
             color={props.color}
      />
      <div className='zoneeditform-submitwrapper'>
        <div className="zoneeditform-submitwrapper--button">
          <button type="button" onClick={e => props.handleCancel(e)}
                  className="zoneeditform-submitwrapper--button__cancel">
            Отмена
          </button>
        </div>
        <div className="zoneeditform-submitwrapper--button">
          <button type="submit" className="zoneeditform-submitwrapper--button__submit">
            Сохранить
          </button>
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    initialValues: {
      name: state.zone.current.zone_name_cfg || state.zone.current.name,
      screenshot: {
        img: state.camera.current.image,
        sizes: state.camera.current.image_size
      },
      color: state.zone.current.color_hex || selectOptions[0].value
    }
  }
}

export default connect(mapStateToProps)(reduxForm({ form: 'zoneEditForm', enableReinitialize: true})(ZoneEditForm));
