import React, {useState} from "react";
import {connect} from "react-redux";
import Modal from "./Modal";
import Spinner from "./Spinner";
import RemoteSubmitButton from "../forms/RemoteSubmitButton";
import WorkwearForm from "../forms/WorkwearForm";
import TabsWithContent from "./TabsWithContent";

export const parseFileName = name => {
  if (name) {
    const arr = name.split('/');
    return arr[arr.length - 1];
  }
  return '';
}

class WorkwearModal extends React.Component {
  state = {
    isEditMode: false
  };

  workwearFormValues = {};

  onEditForm() {
    this.setState({isEditMode: true});
  };

  cancelEditForm() {
    this.setState({ isEditMode: false });
  }

  render() {
    const {onClose, onSave, isPending} = this.props;
    return (
      <Modal
        title={`Добавить документ `}
        isOpen
        showEditButton={!this.state.isEditMode}
        closeButtonWithText={true}
        onClose={onClose}
        onEdit={() => this.onEditForm()}
        className="workwear-modal"
      >{isPending ?
        <Spinner/> :
        <>
        <TabsWithContent
          titles={['Данные']}
          tabs={[
          <>
            <WorkwearForm
              isEditMode={this.state.isEditMode}
              selected={this.props.selectedDoc}
              onSubmit={values => {
                this.workwearFormValues = { ...values };
                const { attached } = this.workwearFormValues;
                const prevFile = parseFileName(this.props.selectedDoc.file_uri);
                onSave({
                  isEdited: !!attached ? attached.name !== prevFile : !!prevFile,
                  file: attached && attached.base64 ? attached.base64 : '',
                  name: this.props.selectedDoc.name,
                  description: this.workwearFormValues.description,
                  article: this.workwearFormValues.article
                });
              }}
            />
            <div className="submit-wrapper">
              { this.state.isEditMode &&
              <>
              <RemoteSubmitButton
                forms={['WorkwearForm']}
                className=""
                >
                Сохранить
                </RemoteSubmitButton>
                <button
                type="button"
                onClick={() => this.cancelEditForm()}
                >
                  Отмена
                </button>
              </>
              }
            </div>
          </>
          ]} />
        </>
        }</Modal>
    )
  }
}

export default connect(
  state => ({
    isPending: state.regulatoryDocsPrimary.busy,
    selectedDoc: state.regulatoryDocsPrimary.current,
  })
)(WorkwearModal);
