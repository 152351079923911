import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

const DataErrorIndication = () => (
    <div className="error-wrapper" >
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <h3>Ошибка загрузки данных</h3>
        <p>Перезагрузите страницу спустя некоторое время.<br/>
            Если ошибка сохраняется, обратитесь к администратору</p>
    </div>
)

export default DataErrorIndication;
