import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {loadModelStorage} from '../store/ModelStorage';
import {loadObjectObservation} from '../store/ObjectObservation';
import {
    cleanZone,
    cleanZoneList,
    loadZoneById,
    loadZoneList,
    setZone,
    zoneCreate,
    zoneDelete,
    zoneEdit
} from '../store/Zone';
import {cameraLoad} from '../store/Camera';

import ZoneEditFormThermal from "../forms/ZoneEditForm/ZoneEditFormThermal";
import Spinner from './Spinner';
import ZoneViewThermal from "./ZoneViewThermal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

const ZonesThermal = (props) => {
    const {
        objId, camId, camera, zonesList, model,
        loadModelStorage, loadCamera,
        loadZoneList,
        saveZone,
        cleanZone,
        deleteZone,
        setZoneToState,
        createZone,
        preview,
        preview_size
    } = props;

    const [isEditMode, setEditMode] = useState(false);
    const [isNewZone, setIsNewZone] = useState(false);
    const [selectedZoneId, setSelectedZoneId] = useState('');
    const [selectedZone, setSelectedZone] = useState(null);
    const [objectZones, setObjectZones] = useState(null);
    const [shouldShowModal, setShowModal] = useState(false);

    useEffect(() => {
        reinitZones();
        loadModelStorage();
    }, []);

    useEffect(() => {
        console.log("zones1111", zonesList)
        setObjectZones(zonesList);
    }, [zonesList]);

    useEffect(() => {
        loadCamera(objId, camId);
    }, [objId, camId]);

    const selectNewZone = (zone) => {
        if (!zone) {
            setSelectedZoneId('');
            return;
        }
        setIsNewZone(true);
        setZoneToState(zone ? model.config_zones.find(val => val.zone_id_cfg === zone) : null);
        setSelectedZoneId(zone);
        setEditMode(true);
    }

    const selectZone = (zone) => {
        setIsNewZone(false);
        setZoneToState(zone);
        setSelectedZone(zone);
        setEditMode(true);
    }

    const handleSave = async (values) => {
        const data = {
            polygon: values.polygon[0].polygon,
            color_hex: values.color,
            zid: isNewZone ? selectedZoneId : selectedZone.zid,
            is_active: true,
            power_unit_id: objId,
        };
        if (isNewZone) {
            await createZone(objId, camId, data);
            reinitZones();
        } else {
            await saveZone(objId, camId, selectedZone.id, data)
            reinitZones();
        }

    }

    const removeZone = async () => {
        await deleteZone(objId, camId, selectedZone.id);
        reinitZones();
    }

    const handleCancel = () => {
        setSelectedZoneId('');
        setEditMode(false);
    }

    const reinitZones = async () => {
        setEditMode(false);
        setShowModal(false);
        cleanZone();
        setObjectZones(null);
        setSelectedZoneId('');
        await loadZoneList(objId, camId);
    }

    if (!camera || !model || !zonesList) {
    // if (!camera || !model || !objectZones) {
        return <Spinner/>;
    }

    const screenshot = {
        img: preview,
        sizes: preview_size
    }

    return (
      <>
          {isEditMode ?
              <ZoneEditFormThermal
                handleCancel={() => handleCancel()}
                onSubmit={data => handleSave(data)}
                showConfirmDialog={() => setShowModal(true)}
                initZones={[selectedZone]}
                screenshot={screenshot}
                isNewZone={isNewZone}
              /> :
              <ZoneViewThermal
                zones={objectZones}
                zoneOnClickCallback={(zone) => {
                    selectZone(zone);
                }}
                selectedZone={selectedZoneId}
                screenshot={screenshot}
                selectAction={val => selectNewZone(val)}
                modelstorage={model && model.config_zones ? model.config_zones : []}
              />
          }
          {shouldShowModal && <ConfirmDeleteModal
            onClose={() => setShowModal(false)}
            onDelete={() => removeZone()}
          />}
    </>
    )
}

export default connect(
    state => ({
        camera: state.thermalCamera.current,
        object: state.object,
        // zones: state.zone.list,
        zoneDetails: state.zone.zone,
        model: state.model,
    }),
    dispatch => ({
        loadObjectObservation: (objId) => {
            dispatch(loadObjectObservation(objId));
        },
        loadCamera: (objId, cameraId) => {
            dispatch(cameraLoad(objId, cameraId));
        },
        loadZoneList: async (objId, cameraId) => {
            return await dispatch(loadZoneList(objId, cameraId));
        },
        cleanZoneList: () => {
            dispatch(cleanZoneList());
        },
        loadZone: (objId, camId, zoneId) => {
            dispatch(loadZoneById(objId, camId, zoneId));
        },
        cleanZone: () => {
            dispatch(cleanZone());
        },
        loadModelStorage: () => {
            dispatch(loadModelStorage());
        },
        setZoneToState: (zone) => {
            dispatch(setZone(zone));
        },
        deleteZone: async (objId, cameraId, zoneId) => {
            await dispatch(zoneDelete(objId, cameraId, zoneId));
        },
        saveZone: async (objId, cameraId, zoneId, data) => {
            return await dispatch(zoneEdit(objId, cameraId, zoneId, data));
        },
        createZone: async (objId, cameraId, data) => {
            return await dispatch(zoneCreate(objId, cameraId, data));
        },
    }),
)(ZonesThermal);
