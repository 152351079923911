import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-regular-svg-icons';

import Modal from './Modal';
import TabsWithContent from './TabsWithContent';
import RemoteSubmitButton from '../forms/RemoteSubmitButton';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import {cameraClearState, cameraCreate, cameraEdit, cameraList, cameraLoad} from '../store/Camera';
import CameraForm from "../forms/CameraForm";
import CameraPermissionsForm from "../forms/CameraPermissionsForm";
import {cameraToApiData} from "../utils/utils";
import {clearPPEs, loadPPEs} from "../store/PPEs";
import {clearWorkwear, loadWorkwear} from "../store/Workwear";
import CameraFormWithTabs from "../forms/CameraFormWithTabs";
import {
    subdivisionClearState,
    subdivisionCreate,
    subdivisionDelete,
    subdivisionEdit,
    subdivisionLoad
} from "../store/Subdivision";
import SubdivisionForm from "../forms/SubdivisionForm";

const objToIdsArr = (items) => items.map(item => item.name);

class SubdivisionModal extends React.Component {
    state = {
        isEditMode: false,
    };

    userFormValues = {};

    // componentDidMount() {
    //     this.props.actions.loadSubdivision();
    // }

    onEditForm() {
        this.setState({isEditMode: true})
    };

    render() {
        const {onClose, onCancel, isPending, isError, subdivision} = this.props;
        const {updateSubdivision, createSubdivision} = this.props.actions;
        this.userFormValues = subdivision;

        return (
            <Modal
                title={`Подразделение `}
                isOpen
                showEditButton={!this.state.isEditMode}
                closeButtonWithText={true}
                onClose={onClose}
                onEdit={() => this.onEditForm()}
                className="user-modal"
            >
                {isPending
                    ?
                    isError ?
                        <p>Ошибка при загрузке данных</p>
                        :
                        <Spinner/>
                    :
                    <>
                        <TabsWithContent
                            titles={['Данные']}
                            tabs={[
                                <>
                                    <SubdivisionForm
                                        handleCancel={onCancel}
                                        onSubmit={values => {
                                            this.userFormValues = values;
                                            if (this.userFormValues.id) {
                                                updateSubdivision(this.userFormValues.id, this.userFormValues);
                                            } else {
                                                createSubdivision(this.userFormValues);
                                            }
                                            onClose();
                                        }}
                                        isEditMode={this.state.isEditMode}
                                    />
                                </>
                            ]}
                        />
                        {isPending}
                        <div className="submit-wrapper">
                            {this.state.isEditMode &&
                            <RemoteSubmitButton
                                forms={['SubdivisionForm']}
                                className=""
                            >
                                Сохранить
                            </RemoteSubmitButton>}
                        </div>
                    </>
                }

            </Modal>
        )
    }
}

export default connect(
    state => ({
        subdivision: state.subdivision.current,
        isPending: state.subdivision.isListPending,
        isError: state.subdivision.isListError,
    }),
    dispatch => ({
        actions: {
            createSubdivision: (subdivision) => {
                dispatch(subdivisionCreate(subdivision));
            },
            loadSubdivision: (subdivision) => {
                dispatch(subdivisionClearState());
                dispatch(subdivisionLoad(subdivision.id));
            },
            updateSubdivision: (subdivisionId, subdivision) => {
                dispatch(subdivisionEdit(subdivisionId, subdivision));
            },
            deleteSubdivision: (subdivision) => {
                dispatch(subdivisionDelete(subdivision.id));
            },
        }
    })
)(SubdivisionModal);
