import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {cameraFlatDetailedList} from '../store/Camera';
import Spinner from '../components/Spinner';
import CameraItem from '../components/CameraItem';
import DataErrorIndication from '../components/DataErrorIndication';
import {thermalCameraClearState, thermalCameraFlatDetailedList} from "../store/ThermalCamera";
import {SystemInfoList} from "../store/SystemInfo";
import {isADMIN, isHEAD} from "../utils/userUtils";
import SystemInfo from "../components/SystemInfo";

const CAMERAS_UPDATE_INTERVAL = 50000000;

const Cameras = ({ user, info, loadAllCameras, loadAllThermalCameras, loadSystemInfo, isPending, isError, cameras = [], thermalCameras = [] }) => {
    const [camerasList, setCamerasList] = useState([]);
    const [thermalCamerasList, setThermalCamerasList] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);

    const loadData = () => {
        loadAllCameras();
        loadAllThermalCameras();
        setFirstLoad(false);
        if (isHEAD(user.role)){
            loadSystemInfo();
        }
    };

    useEffect(() => {
        // setCamerasList(cameras);
        // setThermalCamerasList(thermalCameras);
        if (cameras.length && thermalCameras.length) {
            setFirstLoad(false);
        }
    }, [cameras, thermalCameras]);

    useEffect(() => {
        loadData();
        const timerId = setInterval(loadData, CAMERAS_UPDATE_INTERVAL);
        return () => {
            clearInterval(timerId);
        }
    }, []);

    document.title = 'Видеопотоки камер';

    if (isError) {
        return <DataErrorIndication/>
    }

    if (!cameras.length && !thermalCameras.length) {
        return isPending ? <Spinner/> : 'Пусто';
    }

    if (isPending && firstLoad) {
        return <Spinner/> ;
    }


    return (
        <>
            {isHEAD(user.role) && info &&
                <SystemInfo
                    info={info}
                />
            }
            <div className="cameras-list">
                {cameras.map(
                    (camera, index) => <CameraItem camera={camera} key={index} thermal={false}/>
                )}
                {thermalCameras.map(
                    (camera, index) => <CameraItem camera={camera} key={index} thermal={true}/>
                )}
            </div>
        </>
    );
};

export default connect(
    state => ({
        user: state.user,
        cameras: state.camera.flatList,
        info: state.systemInfo.list,
        thermalCameras: state.thermalCamera.flatList,
        isPending: state.camera.isListPending || state.thermalCamera.isListPending || state.systemInfo.isListPending,
        cameraPending: state.camera.isListPending,
        thermalCameraPending: state.thermalCamera.isListPending,
        isError: state.camera.isListError,
    }),
    dispatch => ({
        loadAllCameras: () => {
            dispatch(cameraFlatDetailedList());
            // dispatch(thermalCameraFlatDetailedList());
        },
        loadAllThermalCameras: () => {
            dispatch(thermalCameraFlatDetailedList());
        },
        loadSystemInfo: () => {
            dispatch(SystemInfoList());
        },
    }),
)(Cameras);
