import React, {useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-regular-svg-icons';

import Modal from './Modal';
import TabsWithContent from './TabsWithContent';
import RemoteSubmitButton from '../forms/RemoteSubmitButton';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import {
    cameraClearState,
    cameraCreate,
    cameraCurrentClearState,
    cameraEdit,
    cameraList,
    cameraLoad
} from '../store/Camera';
import CameraForm from "../forms/CameraForm";
import CameraPermissionsForm from "../forms/CameraPermissionsForm";
import {cameraToApiData} from "../utils/utils";
import {clearPPEs, loadPPEs} from "../store/PPEs";
import {clearWorkwear, loadWorkwear} from "../store/Workwear";
import CameraFormWithTabs from "../forms/CameraFormWithTabs";
import {
    subdivisionClearState,
    subdivisionCreate,
    subdivisionDelete,
    subdivisionEdit,
    subdivisionLoad
} from "../store/Subdivision";
import SubdivisionForm from "../forms/SubdivisionForm";
import CupboardLayoutForm from "../forms/CupboardLayoutForm";
import {cupboardLayoutCreate, cupboardLayoutEdit} from "../store/CupboardLayout";

const objToIdsArr = (items) => items.map(item => item.name);

const isEmpty = val => val === null || !(Object.keys(val) || val).length;

class CupboardLayoutModal extends React.Component {
    state = {
        isEditMode: false,
    };

    userFormValues = {};

    onEditForm() {
        this.setState({isEditMode: true})
    };

    render() {
        const {onClose, onCancel, isPending, isError, cameraActive, objects} = this.props;
        const {cupboardLayoutCreate, cupboardLayoutUpdate} = this.props.actions;

        this.userFormValues = cameraActive;

        return (
            <Modal
                title={`Разметка шкафа `}
                isOpen
                closeButtonWithText={true}
                onClose={onClose}
                onEdit={() => this.onEditForm()}
                // className="user-modal"
                className="user-modal big_modal"
            >
                {isPending
                    ?
                    isError ?
                        <p>Ошибка при загрузке данных</p>
                        :
                        <Spinner/>
                    :
                    <>
                        <TabsWithContent
                            titles={['Данные']}
                            tabs={[
                                <>
                                    <CupboardLayoutForm
                                        handleCancel={onCancel}
                                        cameraActive={cameraActive}
                                        objects={objects}
                                        onSubmit={ async values => {
                                            this.userFormValues = values;
                                            await console.log("values111", values);
                                            this.userFormValues.camid = this.userFormValues.camera.value;
                                            this.userFormValues.cabinet_int_id = this.userFormValues.cabinet_int.value;
                                            // this.userFormValues.cabinet_ext_id = this.userFormValues.cabinet_ext.value;
                                            this.userFormValues.polygon = this.userFormValues.polygonForm;
                                            if (this.userFormValues.id) {
                                                cupboardLayoutUpdate(this.userFormValues.id, this.userFormValues);
                                            } else {
                                                cupboardLayoutCreate(this.userFormValues);
                                            }
                                            onClose();
                                        }}
                                        isEditMode={this.state.isEditMode}
                                    />
                                </>
                            ]}
                        />
                        <div className="submit-wrapper">
                            <RemoteSubmitButton
                                forms={['CupboardLayoutForm', 'ZoneEditFormCupboard']}
                                className=""
                            >
                                Сохранить
                            </RemoteSubmitButton>
                        </div>
                    </>
                }

            </Modal>
        )
    }
}

export default connect(
    state => ({
        // subdivision: state.subdivision.current,
        cupboardLayout: state.cupboardLayout.current,
        isPending: state.cupboardLayout.isListPending,
        // isError: state.subdivision.isListError,

        cameras: state.camera.flatList || [],
        thermalCameras: state.thermalCamera.list || [],
    }),
    dispatch => ({
        actions: {
            cupboardLayoutCreate: (data) => {
                dispatch(cupboardLayoutCreate(data))
            },
            cupboardLayoutUpdate: (id, data) => {
                dispatch(cupboardLayoutEdit(id, data))
            },
            loadCamera: (objId, cameraId) => {
                dispatch(cameraCurrentClearState());
                dispatch(cameraLoad(objId, cameraId));
            },

        }
    })
)(CupboardLayoutModal);
