export const FAQ_ACTIONS = {
  SHOW_MODAL: '@@faq/SHOW_MODAL',
  CLOSE_MODAL: '@@faq/CLOSE_MODAL',
  NEXT_SLIDE: '@@faq/NEXT_SLIDE'
};

const initialState = {
  modal: null
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAQ_ACTIONS.SHOW_MODAL:
      return {
        modal: action.payload
      }
    case  FAQ_ACTIONS.CLOSE_MODAL:
      return {
        modal: null
      }
    default: return state;
  }
}

export default faqReducer;

export const openFaq = payload => dispatch => {
  dispatch({ type: FAQ_ACTIONS.SHOW_MODAL, payload });
}

export const closeFaq = () => dispatch => {
  dispatch({ type: FAQ_ACTIONS.CLOSE_MODAL });
}
