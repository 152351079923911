import React from 'react';

const CupboardManagementIcon = () => (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_407_579)">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.56863 1C1.7023 1 1 1.7023 1 2.56863V14.4902C1 15.3565 1.7023 16.0588 2.56863 16.0588L2.56863 17H4.45098V16.0588H12.6078V17H14.4902V16.0588C15.3565 16.0588 16.0588 15.3565 16.0588 14.4902V2.56863C16.0588 1.7023 15.3565 1 14.4902 1H2.56863ZM9.47059 2.56863H7.58824V14.4902H9.47059V2.56863ZM6.33333 7.58824H4.45098V9.47059H6.33333V7.58824ZM11.0392 7.58824H12.9216V9.47059H11.0392V7.58824Z" fill="#4C82B1"/>
        </g>
    </svg>
);

export default CupboardManagementIcon;