import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm, getFormSubmitErrors} from 'redux-form';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup} from '../inputs';
import {onlyRooms, cameraInObject, camerasWithoutDevices} from "../utils/utils";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";
import {userFio} from "../utils/userUtils";

const required = value => value ? undefined : 'Обязательное поле';

const validURL = (str) =>  {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    console.log("!!pattern.test(str)", !!pattern.test(str));
    return !!pattern.test(str) === false ? undefined : "Неверная ссылка";
  }

const empty = {
    value: null,
    label: "Все"
};

const objectsSelectActiveOptions = (object) => {
    return {
        value: object.id,
        label: object.name
    }
};

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: `${entry.name} (${entry.power_unit.name})`
        }
    })
};

const empSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: userFio(entry)
        }
    })
};

const camSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: `${entry.name}`
        }
    })
};

const NotificationDevicesForm = ({handleSubmit, initialValues, camerasList, forms, dispatch, userId, isEditMode, objectsList, cameras, employees, submitErrors}) => {
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';
    dispatch(change('NotificationDevicesForm', 'id', userId)); // скрытое поле в форме с id юзера
    const [objectId, setObjectId] = useState(null);

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = objSelectOptions(onlyRooms(objectsList));
        const placeholder = 'Выберите помещение';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormPersonsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = empSelectOptions(employees);
        const placeholder = 'Выберите работника';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormCamerasSelect = ({input, validate}) => {
        const isMulti = false;
        const options = camSelectOptions(objectId ?
            cameraInObject(objectId, camerasWithoutDevices(camerasList, cameras)) : camerasWithoutDevices(camerasList, cameras));
        options.unshift(empty);
        const placeholder = 'Выберите камеру';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    console.log("SubmissionError", initialValues.submitErrors);
    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="Основные данные">
                <Field name="name" type="text" label="Название"
                           component={InputField} className={className('name')}
                           validate={[required]}/>
                <Field name="device_uri" type="text" label="IP адрес устройства"
                           component={InputField} className={className('device_uri')}
                           validate={[]}/>
                {/* <InputGroup title="Помещение" >
                        <Field
                            name="object_observation"
                            className={className('object_observation')}
                            component={ReduxFormObjectsSelect}
                            onChange={(e) => {
                                setObjectId(e.value);
                            }}
                            validate={[]}
                        />
                    </InputGroup>
                    <InputGroup title="Камера" >
                        <Field
                            name="cameraCurrent"
                            className={className('camera')}
                            component={ReduxFormCamerasSelect}
                            validate={[required]}
                        />
                    </InputGroup> */}
                <Field
                        name="is_enabled"
                        component={EnableCheckbox}
                        labelText="Вкл"
                    />
                    {/* <InputGroup title="Помещение" >
                    <Field
                        name="object_observation"
                        className={className('object_observation')}
                        component={ReduxFormObjectsSelect}
                        onChange={(e) => {
                            setObjectId(e.value);
                        }}
                        validate={[]}
                    />
                </InputGroup>*/}
                  {/*  <InputGroup title="Работник" >
                        <Field
                            name="workers"
                            className={className('workers')}
                            component={ReduxFormPersonsSelect}
                            validate={[required]}
                        />
                    </InputGroup> */}
                </Legend>
                {/*<Legend title="Параметры устройства">
                    <InputGroup title="Тип устройства" >
                            <Field
                                name="cameraCurrent"
                                className={className('camera')}
                                component={ReduxFormObjectsSelect}
                                validate={[]}
                            />
                        </InputGroup>
                    <InputGroup title="Режим работы" >
                            <Field
                                name="cameraCurrent"
                                className={className('camera')}
                                component={ReduxFormObjectsSelect}
                                validate={[]}
                            />
                        </InputGroup>
                    <InputGroup title="Протокол обмена" >
                            <Field
                                name="cameraCurrent"
                                className={className('camera')}
                                component={ReduxFormObjectsSelect}
                                validate={[]}
                            />
                    </InputGroup>
                </Legend>*/}
                <Legend title="Параметры сервера">
                    <Field name="login" type="text" label="Логин"
                           component={InputField} className={className('login')}
                           validate={[required]}/>
                    <Field name="password" type="text" label="Пароль"
                           component={InputField} className={className('password')}
                           validate={[]}/>
                </Legend>

                {initialValues.submitErrors &&
                    <Legend title="Ошибка">
                        <div className='errorContainer'>
                            <p>{initialValues.submitErrors}</p>
                        </div>
                    </Legend>
                }
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state, props) => {
    if (!state.notificationDevices.current?.id) {
        return {
            initialValues: {
                is_enabled: false,
                submitErrors: state.notificationDevices.current?.msg
            },
            formValues: getFormValues('NotificationDevicesForm')(state) || {},
        };
    }
    return {
        initialValues: {
            ...state.notificationDevices.current,
            // object_observation: objectsSelectActiveOptions(state.notificationDevices.current.object_info),
            login: state.notificationDevices.current.credentials.login,
            password: "",
            submitErrors: state.notificationDevices.current?.msg
            // camera_id: state.notificationDevices.current.camera_info.id,
        },
        cameraId: state.notificationDevices.current.id,
        formValues: getFormValues('NotificationDevicesForm')(state) || {},
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'NotificationDevicesForm',
        // enableReinitialize: true,
    })(NotificationDevicesForm)
)
