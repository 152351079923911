import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-regular-svg-icons';

import notificationDevicesMappingReducer, {
    notificationDevicesMappingClearState,
    notificationDevicesMappingCreate,
    notificationDevicesCurrentMappingClearState,
    notificationDevicesMappingDelete,
    notificationDevicesMappingEdit,
    notificationDevicesMappingList,
    notificationDevicesMappingLoad
} from '../store/NotificationDevicesMapping';
import {loadObjectsObservationList, objectObservationClearState} from '../store/ObjectObservation';
import {apiDataToCameras, cameraToApiData, omitRooms, apiDataToNotificationDevicesMapping} from '../utils/utils';
import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import withDataErrorIndication from '../components/withDataErrorIndication';
import VideoRecorderUpVideoModal from '../components/VideoRecorderUpVideoModal';
import {clearEmploeesState, loadEmploeesList} from "../store/Employees";
import NotificationDevicesModal from '../components/NotificationDevicesModal';
import {cameraList} from "../store/Camera";
import NotificationDevicesMappingModal from "../components/NotificationDevicesMappingModal";
import {notificationDevicesClearState, notificationDevicesList} from "../store/NotificationDevices";

const NotificationDevicesMapping = ({actions, cameras, notificationDevices, objects = [], isListPending, employees, notificationDevicesList}) => {
    const {loadAllCameras, loadDevices, createCamera, loadAllCamerasList, updateCamera, deleteCamera, loadObjects, loadCamera, clearCamera, loadEmployeesList} = actions;

    const loadData = () => {
        loadAllCameras();
        loadObjects();
        loadAllCamerasList();
        loadDevices();
    }

    const [shouldShowModal, setShowModal] = useState(false);
    const [cameraActive, setCameraActive] = useState();

    useEffect(() => {
        loadData();
    }, [])

    const Columns = (cellCancelHandler) => [{
        text: '№',
        dataField: 'id',
        sort: true,
        headerStyle: () => ({width: '59px'}),
        editable: false,
    }, {
        text: 'Название',
        dataField: 'name',
        sort: true,
        editable: false,
        required: true,
    },{
        text: 'Помещение',
        dataField: 'object_name',
        sort: true,
        editable: false,
        required: true,
    },{
        text: 'Камера',
        dataField: 'camera',
        sort: true,
        formatter: (cell) => cell?.name,
        editable: false,
        required: true,
    }];

    document.title = 'Привязка устройств оповещения';


    return (
        <>
            <GeneralObjectsTable
                isPending={isListPending}
                objects={apiDataToNotificationDevicesMapping(notificationDevices)}
                isEditable={false}
                empty={true}
                actions={{
                    update: updateCamera,
                    // create: createCamera,
                    create: () => {
                        setShowModal(true);
                    },
                    purge: deleteCamera,
                    reload: loadData,
                }}
                getColumns={Columns}
                itemDummy={{
                    id: 0,
                    code: 0,
                    obj: '',
                    name: '',
                    protocol: '',
                    host: '',
                    suffix: '',
                    credentials: [],
                    is_enabled: true,
                }}
                rowEvents={{
                    onDoubleClick: (e, row, rowIndex) => {
                        loadCamera(row);
                        setShowModal(true);
                    }
                }}
            />
            {shouldShowModal &&
            <NotificationDevicesMappingModal
                objectsList={objects}
                cameras={notificationDevices}
                employees={employees}
                camerasList={cameras}
                notificationDevicesList={notificationDevicesList}
                onCancel={() => {
                    setShowModal(false);
                    clearCamera();
                }}
                onClose={() => {
                    setShowModal(false);
                    clearCamera();
                }}
            />}
        </>
    )
}

export default connect(
    state => ({
        user: state.user,
        cameras: state.camera.list || [],
        notificationDevices: state.notificationDevicesMapping.list?.result,
        camera: state.notificationDevicesMapping.current,
        employees: state.employees.list,
        isListPending:  state.camera.isListPending || state.notificationDevicesMapping.isListPending || state.object.isListPending || state.employees.isListPending || state.notificationDevices.isListPending,
        objects: state.object.list,
        isError: state.notificationDevicesMapping.isListError,
        notificationDevicesList: state.notificationDevices.list?.result,
    }),
    dispatch => ({
        actions: {
            loadAllCamerasList: () => {
                dispatch(cameraList(true));
            },
            loadAllCameras: () => {
                dispatch(notificationDevicesMappingClearState());
                dispatch(notificationDevicesMappingList());
            },
            createCamera: (camera) => {
                const apiData = cameraToApiData(camera);
                dispatch(notificationDevicesMappingCreate(camera.object, apiData));
            },
            loadCamera: (camera) => {
                dispatch(notificationDevicesCurrentMappingClearState());
                dispatch(notificationDevicesMappingLoad(camera.id));
            },
            updateCamera: (cameraId, camera) => {
                const apiData = cameraToApiData(camera);
                dispatch(notificationDevicesMappingEdit(cameraId, apiData));
            },
            deleteCamera: (cameraId) => {
                dispatch(notificationDevicesMappingDelete(cameraId));
            },
            loadObjects: () => {
                dispatch(objectObservationClearState());
                dispatch(loadObjectsObservationList());
            },
            clearCamera: () => {
                dispatch(notificationDevicesCurrentMappingClearState());
            },
            loadEmployeesList: () => {
                dispatch(clearEmploeesState());
                dispatch(loadEmploeesList());
            },
            loadDevices: () => {
                dispatch(notificationDevicesClearState());
                dispatch(notificationDevicesList());
            },
        },
    }),
)(withDataErrorIndication(NotificationDevicesMapping));
