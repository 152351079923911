import React from 'react';

const Objects = () => {
    document.title = 'Objects';

    return (
        <div>Objects</div>
    );
};

export default Objects;
