import React from 'react'
import { connect } from 'react-redux'
import { submit } from 'redux-form'

const RemoteSubmitButton = ({ dispatch, forms, children, className='', disable=false }) => (
    <button
        disabled={disable}
        type="button"
        onClick={() => {
            forms.forEach(form => {
                dispatch(submit(form));
            })
        }}
        className={`${className} ${disable && 'btn-disable-confirm'}`}
    >
        {children}
    </button>
);

export default connect()(RemoteSubmitButton)
