import React, {useEffect} from 'react';

import Modal from './Modal';
import TabsWithContent from './TabsWithContent';
import RemoteSubmitButton from '../forms/RemoteSubmitButton';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import {cameraToApiData, thermalCameraToApiData} from "../utils/utils";
import {clearPPEs, loadPPEs} from "../store/PPEs";
import {clearWorkwear, loadWorkwear} from "../store/Workwear";
import ThermalCameraForm from "../forms/ThermalCameraForm";
import {
    thermalCameraClearState,
    thermalCameraCreate, thermalCameraEdit,
    thermalCameraList,
    thermalCameraLoad
} from "../store/ThermalCamera";
import CupboardLayoutForm from "../forms/CupboardLayoutForm";
import ThermalCameraZoneForm from "../forms/ThermalCameraZoneForm";
import {getFormValues} from "redux-form";

class ThermalCameraModal extends React.Component {
    state = {
        isEditMode: false,
        isZoneMode: 0,
    };

    userFormValues = {};

    componentDidMount() {
        // this.props.actions.loadCamerasData();
        this.props.actions.loadWorkwear();
        //this.props.actions.loadPPEs();
    }

    onEditForm() {
        this.setState({isEditMode: true})
    };

    onEditType(type) {
        this.setState({isZoneMode: type})
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isZoneMode !== prevState.isZoneMode) {
            if (this.props.thermalCamera.face_temp_mon) {
                this.setState({isZoneMode: this.props.thermalCamera.face_temp_mon.is_enabled ? 1 : 2})
            }
        }
    }

    render() {
        const {onClose, onCancel, isPending, isError, objectsList, ppes, workwear, thermalCamera, formValuesPolygon} = this.props;
        const {createCamera, updateCamera} = this.props.actions;
        this.userFormValues = thermalCamera;

        // useEffect(() => {
        //     this.setState({isZoneMode: thermalCamera.face_temp_mon.is_enabled ? 1 : 2})
        // }, [thermalCamera]);


        return (
            <Modal
                title={`Тепловизионная камера `}
                isOpen
                showEditButton={!this.state.isEditMode}
                closeButtonWithText={true}
                onClose={onClose}
                onEdit={() => this.onEditForm()}
                className={this.userFormValues.id ? "user-modal big_modal" : "user-modal"}
            >
                {isPending
                    ?
                    isError ?
                        <p>Ошибка при загрузке данных</p>
                        :
                        <Spinner/>
                    :
                    <>
                        <TabsWithContent
                            titles={['Данные']}
                            tabs={[
                                <>
                                    <ThermalCameraForm
                                        handleCancel={onCancel}
                                        onSubmit={values => {
                                            this.userFormValues = values;
                                            if (!this.userFormValues.is_enabled) {
                                                this.userFormValues.is_enabled = false;
                                            };
                                            this.userFormValues.face_temp_mon = {
                                                is_enabled: this.state.isZoneMode === 1,
                                                temp_thresh_celsius: parseFloat(this.userFormValues.temp_thresh_celsius.replace(',', '.'))
                                            };
                                            if (this.userFormValues.id) {
                                                this.userFormValues.zone_temp_mon = {
                                                    is_enabled: this.state.isZoneMode === 2,
                                                    zones: [{
                                                        is_enabled: this.state.isZoneMode === 2,
                                                        name: "zone_none",
                                                        temp_thresh_celsius: parseFloat(this.userFormValues.temp_thresh_celsius.replace(',', '.')),
                                                        vertices: formValuesPolygon.polygon ? formValuesPolygon?.polygon[0].polygon[0] : [],
                                                    }]
                                                };
                                            }
                                            console.log("this.userFormValues", this.userFormValues);
                                            this.userFormValues.temp_thresh_celsius = parseFloat(this.userFormValues.temp_thresh_celsius.replace(',', '.'));
                                           if (this.userFormValues.id) {
                                               updateCamera(this.userFormValues.id, this.userFormValues);
                                           } else {
                                               createCamera(this.userFormValues);
                                           }
                                           onClose();
                                        }}
                                        isEditMode={this.state.isEditMode}
                                        onChange={(val) => (typeof val === 'number') && this.setState({isZoneMode: val})}
                                        objectsList={objectsList}
                                        isPending={isPending}
                                    />
                                </>,
                                // this.userFormValues.id &&
                                //     <ThermalCameraZoneForm
                                //         handleCancel={onCancel}
                                //         onSubmit={values => {
                                //             this.userFormValues = {...values, ...this.userFormValues};
                                //
                                //             console.log("this.userFormValues222", values.formValuesPolygon.polygon[0].polygon);
                                //             // this.userFormValues.temp_thresh_celsius = parseFloat(this.userFormValues.temp_thresh_celsius.replace(',', '.'));
                                //
                                //             this.userFormValues.zone_temp_mon = {
                                //                 is_enabled: this.state.isZoneMode === 2,
                                //                 zones: values.formValuesPolygon.polygon && values.formValuesPolygon?.polygon[0].polygon,
                                //             };
                                //             if (this.userFormValues.id) {
                                //                  updateCamera(this.userFormValues.id, this.userFormValues);
                                //              } else {
                                //                  createCamera(this.userFormValues);
                                //              }
                                //              //onClose();
                                //         }}
                                //         isEditMode={this.state.isEditMode}
                                //     />

                            ]}
                        />
                        <div className="submit-wrapper">
                            {this.state.isEditMode && this.userFormValues.id ?
                                <RemoteSubmitButton
                                    forms={['ThermalCameraForm', 'ZoneEditFormThermal']}
                                    className=""
                                >
                                    Сохранить
                                </RemoteSubmitButton>
                                 :
                                 <RemoteSubmitButton
                                     forms={['ThermalCameraForm']}
                                     className=""
                                 >
                                     Сохранить
                                 </RemoteSubmitButton>
                             }
                        </div>
                    </>
                }

            </Modal>
        )
    }
}

export default connect(
    state => ({
        isPending: !state.thermalCamera.flatList || state.thermalCamera.isListPending || state.object.isListPending,
        isError: state.workwear.isError || state.thermalCamera.isListError,
        // ppes: state.ppes.list,
        workwear: state.workwear.list,
        thermalCamera: state.thermalCamera.current,
        formValuesPolygon: getFormValues('ZoneEditFormThermal')(state) || {},
    }),
    dispatch => ({
        actions: {
            loadCamerasData: () => {
                dispatch(thermalCameraList());
            },
            createCamera: (camera) => {
                const apiData = thermalCameraToApiData(camera);
                dispatch(thermalCameraCreate(apiData.object_observation, apiData));
            },
            loadCamera: (camera) => {
                dispatch(thermalCameraClearState());
                dispatch(thermalCameraLoad(camera.object, camera.id));
            },
            updateCamera: (cameraId, camera) => {
                const apiData = thermalCameraToApiData(camera);
                dispatch(thermalCameraEdit(camera.obj_id, cameraId, apiData));
            },
            // loadPPEs: () => {
            //     dispatch(clearPPEs());
            //     dispatch(loadPPEs());
            // },
            loadWorkwear: () => {
                dispatch(clearWorkwear());
                dispatch(loadWorkwear());
            },
        }
    })
)(ThermalCameraModal);
