import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Field, FieldArray, getFormValues, reduxForm, change} from 'redux-form';
import Multiselect from "react-widgets/Multiselect";

import {cameraIdToName, objectIdToName} from '../utils/userUtils';
import Legend from './Legend';
import Tree, { TreeNode } from 'rc-tree';
import {ActiveObjectsToKeys, CameraAndObjectsToApiData} from "../utils/utils";
import {EnableCheckbox, InputField, InputGroup} from "../inputs";


const UserSettingsForm = ({handleSubmit, formValues, cameras, dirty, reset, submitting, dispatch, isEditMode, objects, userPermissions}) => {
    const onCheck = (checkedKeys, info) => {
        console.log("checkedKeys", checkedKeys);
        // formValues["permissionsList"] = checkedKeys;

        dispatch(change('UserSettingsForm', 'permissionsList', checkedKeys));
    };

    const data = CameraAndObjectsToApiData(userPermissions, cameras);

    const defaultCheckedKeys = ActiveObjectsToKeys(userPermissions);
    // formValues["permissionsList"] = defaultCheckedKeys;
    // dispatch(change('UserSettingsForm', 'permissionsList', defaultCheckedKeys));

    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode} >
                <div className={isEditMode ? '' : 'disabled-fieldset'}>
                    <div className="">
                        <Legend title="Параметры" className="long-title">
                            <Field
                                name="is_periodic_reports_enable"
                                component={EnableCheckbox}
                                labelText="Еженедельный отчет о проблемах"
                            />
                        </Legend>
                        <Legend title="Настройки оповещений" className="long-title">
                            <Field name="permissionsList" component={Multiselect} type="hidden" style={{ display: "none" }}/>
                            <Tree
                                className="myCls"
                                defaultExpandAll
                                checkable
                                height={435}
                                onCheck={onCheck}
                                treeData={data}
                                defaultCheckedKeys={defaultCheckedKeys}
                            />
                        </Legend>
                    </div>
                </div>
            </fieldset>
        </form>
    );
};


// Получить активное значение по сравнению с объектом сравнения
const getActiveOption = (options, comparisonObject) => {
    const choices = options || [];
    const active = choices.find(choice => choice[0] === comparisonObject);
    return {
        value: active ? active[0] : '',
        label: active ? active[1] : ''
    }
}

// Получить все варианты выбора
const getSelectOptions = (options) => {
    const choices = options || [];
    return choices.map(choice => ({
        value: choice[0],
        label: choice[1]
    }))
};

const getSendAtValues = (notificationSettings) => {
    const send_at = notificationSettings.send_at || [];
    return send_at.map(value => ({time: value}))
};

const getUserCameras = (notificationSettings, cameras) => {
    const userCameras = notificationSettings.cameras || [];
    return userCameras.map(value => ({
        ...value,
        id: {value: value.id, label: cameraIdToName(value.id, cameras)}
    }))
}

const getUserObjects = (notificationSettings, objects) => {
    const userObjects = notificationSettings.object_observations || [];
    return userObjects.map(value => ({
        ...value,
        id: {value: value.id, label: objectIdToName(value.id, objects)}
    }))
}

const mapStateToProps = (state) => {
    const sendTypeOptions = state.userNotificationSettings.send_type_choices;
    const notificationOptions = state.userNotificationSettings.notification_format_choices;
    return {
        initialValues: {
            isActive: state.users.current?.is_notify ? state.users.current.is_notify : false,
            cameras: getUserCameras(state.userNotificationSettings, state.camera.list),
            object_observations: getUserObjects(state.userNotificationSettings, state.camera.list),
            sendType: {
                active: getActiveOption(sendTypeOptions, state.userNotificationSettings.send_type),
                options: getSelectOptions(sendTypeOptions),
            },
            notificationFormat: {
                active: getActiveOption(notificationOptions, state.userNotificationSettings.notification_format),
                options: getSelectOptions(notificationOptions),
            },
            sendInterval: state.userNotificationSettings.send_interval,
            sendAt: getSendAtValues(state.userNotificationSettings),
            permissionsList: ActiveObjectsToKeys(state.users.current?.permissions.power_units),
            is_periodic_reports_enable: state.users.current?.permissions.is_periodic_reports_enable
        },
        cameras: state.camera.list,
        objects: state.object.list,
        userPermissions: state.users.current?.permissions.power_units,
        formValues: getFormValues('UserSettingsForm')(state) || {},
        isSaving: false, //state.settings.isSaving,
    }
};

export default connect(mapStateToProps)(
    reduxForm({
        form: 'UserSettingsForm',
        enableReinitialize: true
    })(UserSettingsForm)
)
