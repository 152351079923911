import React, {useState} from 'react';
import {connect} from 'react-redux';

import {onLogout} from '../store/Auth';
import Icons from '../Icons';
import RoleSwitcher from './RoleSwitcher';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faComment,} from '@fortawesome/free-solid-svg-icons';
import Modal from "./Modal";
import Feedback from "../forms/Feedback";
import {clearFeedBack, sendFeedBack} from "../store/FeedBack";
import {openFaq} from "../store/FAQ";
import scenario from "./Faq/scenario2";
import {useRoute} from "react-router5";

const Header = ({ user, logout, feedback, sendFeedback, clearFeedback, openFaq }) => {
    const [openModal, setOpenModal] = useState(false);
    const router = useRoute();

    const openFaqModal = () => {
        openFaq(user, router);
    }

    return (
        <>
            <header>
                <div className="row expanded align-middle">
                    <div className="logo large-7">
                        <img src={Icons.logo} alt=""/>
                        <p>Система видеоанализа соблюдения ТБ и ПБ</p>
                    </div>
                    <div className="large-4 user">
                        <a href="">
                            <img src={Icons.user} alt=""/>
                            <div className="name-wrapper">
                                <p>{user.username}</p>
                            </div>
                        </a>
                    </div>
                    <RoleSwitcher/>
                    <div className="large-8 large-offset-0 lern">
                        <a className="feedback-wrapper" title="Обратная связь" href="#" onClick={()=> setOpenModal(true)}>
                            {!feedback.isPending ?
                                <>
                                    <FontAwesomeIcon icon={faComment}/>
                                    {/*<p>Обратная связь</p>*/}
                                </>
                                :
                                <>
                                    <p>Отправка...</p>
                                </>
                            }
                        </a>
                        <a href="#" onClick={e => { e.preventDefault(); openFaqModal() }} >
                            <img src={Icons.graduate} alt=""/>
                            <p>Обучение</p>
                        </a>
                        <a href="/files/UserGuide.pdf" target="_blank">
                            <img src={Icons.questionMark} alt=""/>
                            <p>Помощь</p>
                        </a>
                        <a onClick={logout} style={{cursor: 'pointer'}}>
                            <img src={Icons.logout} alt=""/>
                            <p>Выход</p>
                        </a>
                    </div>
                </div>
            </header>
            <Modal
                title="Обратная связь"
                className="small_modal"
                isOpen={openModal}
                onClose={()=> setOpenModal(false)}
            >
                <Feedback
                    onSubmit={values => {
                        sendFeedback(values);
                        setOpenModal(false);
                    }}
                />
            </Modal>
        </>
    );
};

export default connect(
    state => ({
        user: state.user,
        feedback: state.feedback,
    }),
    dispatch => ({
        logout: () => {
            dispatch(onLogout());
        },
        sendFeedback: (data) => {
            dispatch(sendFeedBack(data))
        },
        clearFeedback: () => {
            dispatch(clearFeedBack())
        },
        openFaq: (user, { route }) => {
            const faq = scenario[user.role] ? scenario[user.role][route.name] : null;
            if ( faq ) {
                dispatch(openFaq(faq));
            }
        }
    }),
)(Header);

