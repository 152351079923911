export const centerCalc = (arr) => {
  const x = arr.map (x => x[0]);
  const y = arr.map (x => x[1]);
  const cx = (Math.min (...x) + Math.max (...x)) / 2;
  const cy = (Math.min (...y) + Math.max (...y)) / 2;
  return [cx, cy];
};

export const styleMap = {
  'width': '100%',
  'height': '100%',
  'display': 'block',
  'backgroundColor': '#ccc',
  'zIndex': 'auto!important',
};

export const positionDefault = ({ width, height }) => {
  return width > height
      ? [[[300, 300], [600, 300], [600, 600], [300, 600]]]
      : [[[600, 0], [600, 300], [0, 300], [0, 0]]]
}

export const positionDefaultCupboard = ({ width, height }) => {
  return width > height
      ? [[[250, 250], [500, 250], [500, 500], [250, 500]]]
      : [[[600, 0], [600, 300], [0, 300], [0, 0]]]
}

export const positionDefaultThermal = ({ width, height }) => {
  return width > height
      ? [[[250, 250], [500, 250], [500, 500], [250, 500]]]
      : [[[600, 0], [600, 300], [0, 300], [0, 0]]]
}

export const offsetCenter = (coord, htmlText) => // смещение относительно центра
{
  const x = coord[1];
  const y = coord[0];
  return [y + htmlText.length / 2, x - htmlText.length / 2];
}

export const toListCoords = (coords) => {
  return coords.map(c => {
    const y = c.lat;
    const x = c.lng;
    return [y, x];
  });
}

export const fromLeafletFormat = (coords, koef, divider = 1, height) => {
  return coords.map(c => {
    const y = height - c.lat;
    const x = c.lng;
    return [parseInt(x), parseInt(y)];
  });
}

export const toLeafletFormat = (coords, height, koef, divider = 1) => { // Трансформация в формат Leaflet
  try {
    return coords.map(coord => {
      console.log(height);
      const lat = height - coord[1];
      const lng = coord[0];
      return [parseInt(lat / divider), parseInt(lng / divider)];
    });
  } catch (e) {
    return [[0, 0], [0, 0]];
  }
}
