import React from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup} from '../inputs';
import {splitCameraUri, isBlock} from "../utils/utils";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";

const required = value => value ? undefined : 'Обязательное поле';

const SubdivisionForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, userId, isEditMode, objectsList, ppes}) => {
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';

    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="Основные данные">
                    <Field name="name" type="text" label="Название"
                           component={InputField} className={className('name')}
                           validate={[required]}/>
                </Legend>
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state) => {
    if (!state.subdivision.current?.id) {
        return {};
    }
    return {
        initialValues: {
            ...state.subdivision.current,
        },
        subdivisionId: state.subdivision.current?.id,
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'SubdivisionForm',
        enableReinitialize: true,
    })(SubdivisionForm)
)
