import axios from 'axios';


const logsMapper = data => data.map(userLog =>{
  const { user } = userLog;
  return {
   user_name: `${user?.surname} ${user?.firstname} ${user?.patronymic}`,
   uniqKey: userLog.message + Math.floor(Math.random() * 100) + Date.now() + userLog.date,
   ...userLog
 };
});

const LOGS = {
  LOGS_LIST_REQUEST: '@@logs/LOGS_LIST_REQUEST',
  LOGS_LIST_REQUEST_SUCCESS: '@@logs/LOGS_LIST_REQUEST_SUCCESS',
  LOGS_LIST_REQUEST_ERROR: '@@logs/LOGS_LIST_REQUEST_ERROR'
}

const initialState = {
  error: null,
  busy: false,
  list: [],
  limit: 0,
  total: 0
};

const LogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGS.LOGS_LIST_REQUEST:
      return {
        ...state,
        error: null,
        busy: true,
        limit: 0,
        total: 0
      }
    case LOGS.LOGS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        busy: false,
        list: logsMapper(action.payload.audit_records),
        limit: action.payload.records_limit,
        total: action.payload.records_total
      }
    case LOGS.LOGS_LIST_REQUEST_ERROR:
      return {
        ...state,
        busy: false,
        error: action.payload
      }
    default: return state;
  }
}

export const loadLogs = params => dispatch => {
  dispatch({ type: LOGS.LOGS_LIST_REQUEST });
  axios.get('/api/statistics/audit/logs/', { params })
    .then(({ data: payload }) => {
      dispatch({ type: LOGS.LOGS_LIST_REQUEST_SUCCESS, payload })
    })
    .catch((error) => dispatch({ type: LOGS.LOGS_LIST_REQUEST_ERROR, payload: error }));
}

export default LogsReducer;
