import axios from 'axios';

const MODEL_STORAGE = {
    IS_PENDING: 'MODEL_STORAGE_IS_PENDING',
    ACCESSIBLE_ZONES: 'MODEL_STORAGE_ACCESSIBLE_ZONES',
};

const initialState = {};

const modelStorageReducer = (state = initialState, action) => {
    switch(action.type) {
        case MODEL_STORAGE.IS_PENDING:
            return {
                ...state,
                isPending: true,
            };
        case MODEL_STORAGE.ACCESSIBLE_ZONES:
            return {
                ...action.payload,
                isPending: false,
            };
        default:
            return state;
    }
};

export default modelStorageReducer;

/* Загрузка списка доступных зон */
export const loadModelStorage = () => dispatch => {
    dispatch({
        type: MODEL_STORAGE.IS_PENDING,
        payload: null,
    });
    axios.get('/svs/ms/api/kola/inference/models-storage/')
        .then(res =>
          dispatch({
              type: MODEL_STORAGE.ACCESSIBLE_ZONES,
              payload: res.data
          })
        );
};
