import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-regular-svg-icons';

import {nbsp} from '../utils/string';
import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import SelectWithButtons from '../tables/SelectWithButtons';
import TextInputWithButtons from '../tables/TextInputWithButtons';
import CredentialsInputWithButtons from '../tables/CredentialsInputWithButtons';
import withDataErrorIndication from '../components/withDataErrorIndication';
import {
    subdivisionClearState,
    subdivisionCreate,
    subdivisionList,
    subdivisionLoad,
    subdivisionEdit,
    subdivisionDelete,
    subdivisionCurrentClearState
} from "../store/Subdivision";
import SubdivisionModal from "../components/SubdivisionModal";
import {clearUserState} from "../store/Users";

const HiddenTextFormatter = ({text}) => {
    const [isTextHidden, setIsTextHidden] = useState(true);

    return (
        <div onMouseDown={() => setIsTextHidden(false)}
             onMouseUp={() => setIsTextHidden(true)}>
            {isTextHidden ? <FontAwesomeIcon icon={faEye}/> : <>{text || nbsp}</>}
        </div>
    );
}

const SubdivisionManagement = ({actions, subdivision, subdivisions = [], isListPending}) => {
    const {loadAllSubdivision, createSubdivision, updateSubdivision, deleteSubdivision, loadSubdivision, clearSubdivision} = actions;

    const loadData = () => {
        loadAllSubdivision();
    };

    const [shouldShowModal, setShowModal] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (subdivision?.isReloadList) {
            loadData();
        }
    }, [subdivision]);

    const Columns = (cellCancelHandler) => [{
        text: '№',
        dataField: 'id',
        sort: true,
        headerStyle: () => ({width: '59px'}),
        editable: false,
    }, {
        text: 'Название',
        dataField: 'name',
        sort: true,
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons
                {...editorProps}
                onCancel={cellCancelHandler} className="required-field"/>
        ),
        headerStyle: () => ({width: '200px'}),
        required: true,
    }];

    document.title = 'Подразделения';

    return (
        <>
            <GeneralObjectsTable
                isPending={isListPending}
                objects={subdivisions}
                isEditable={false}
                actions={{
                    update: updateSubdivision,
                    // create: createCamera,
                    create: () => {
                        setShowModal(true);
                    },
                    purge: deleteSubdivision,
                    reload: loadData,
                }}
                getColumns={Columns}
                itemDummy={{
                    id: 0,
                    code: 0,
                    obj: '',
                    name: '',
                    protocol: '',
                    host: '',
                    suffix: '',
                    credentials: [],
                    is_enabled: true,
                }}
                rowEvents={{
                    onDoubleClick: (e, row, rowIndex) => {
                        loadSubdivision(row);
                        setShowModal(true);
                    }
                }}
            />
            {shouldShowModal &&
            <SubdivisionModal
                onCancel={() => {
                    setShowModal(false);
                    clearSubdivision();
                }}
                onClose={() => {
                    setShowModal(false);
                    clearSubdivision();
                }}
            />}
        </>
    )
}

export default connect(
    state => ({
        subdivisions: state.subdivision.list,
        subdivision: state.subdivision.current,
        isListPending: state.subdivision.isListPending,
        isError: state.subdivision.isListError,
    }),
    dispatch => ({
        actions: {
            loadAllSubdivision: () => {
                dispatch(subdivisionClearState());
                dispatch(subdivisionList());
            },
            createSubdivision: (subdivision) => {
                dispatch(subdivisionCreate(subdivision));
            },
            loadSubdivision: (subdivision) => {
                // dispatch(subdivisionCurrentClearState());
                dispatch(subdivisionLoad(subdivision.id));
            },
            updateSubdivision: (subdivision) => {
                dispatch(subdivisionEdit(subdivision.id, subdivision));
            },
            deleteSubdivision: (subdivision, data) => {
                dispatch(subdivisionDelete(subdivision));
            },
            clearSubdivision: () => {
                dispatch(subdivisionCurrentClearState());
            },
        },
    }),
)(withDataErrorIndication(SubdivisionManagement));
