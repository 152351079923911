import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {loadObjectsObservationList} from '../store/ObjectObservation';
import {clearRejectedEventReport, loadRejectedEventReport} from '../store/RejectedEvents';
import {omitUndefinedAndNullFields} from '../utils/objects';
import EventList from './EventList';
import Modal from '../components/Modal';
import Event from '../components/Event';
import {DEFAULT_INTERVAL_HOURS, PAGE_SIZE, START_PAGE} from '../utils/constants';
import withDataErrorIndication from '../components/withDataErrorIndication';
import Spinner from "../components/Spinner";
import {cameraClearState, cameraFlatDetailedList} from "../store/Camera";
import RemotePagination from "./RemotePagination";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {customFilter, FILTER_TYPES, selectFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import {apiDataToRejectedEventObj, cameraInObject, omitRooms, onlyRooms, roomsInObject} from "../utils/utils";
import {objectsListToOptions} from "../utils/inputs";
import RangeFilter from "../components/RangeFilter";
import {getDateNow} from "../utils/time";
import {clearEmploeesState, loadEmploeesList} from "../store/Employees";
import {userFio} from "../utils/userUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faFile, faPlus, faSyncAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {thermalCameraList} from "../store/ThermalCamera";

const RejectedEventsPage = (props) => {
    const {
        defaultQuery, loadEvents, clearEvents, loadObjects, employees, loadEmployeesList,
        events, objects, isViolationsOnly, isPendingEvents, loadAllCameras, cameras = [], eventsTotal, isPendingObjects,
        thermalCameras = [], loadThermalCamerasData
    } = props;


    const [page, setPage] = useState(START_PAGE);
    const [sizePerPage, setSizePerPage] = useState(PAGE_SIZE);
    const [filtersList, setfiltersList] = useState(null);
    const [event, setEvent] = useState(null);

    const [unitId, setUnitId] = useState(null);
    const [objectId, setObjectId] = useState(null);
    const [camId, setCamId] = useState(null);

    useEffect(() => {
        loadObjects();
        loadAllCameras();
        loadThermalCamerasData();
        loadEmployeesList();
        loadEvents(START_PAGE, PAGE_SIZE, defaultQuery);
    }, []);

    const reloadQuery = () => {
        // if (filtersList !== null) {
            const filtersQuery = {
                ...(filtersList?.power_unit && {power_unit: filtersList.power_unit.filterVal}),
                ...(filtersList?.room && {object_id: filtersList.room.filterVal}),
                ...(filtersList?.camera_name && {cameraId: filtersList.camera_name.filterVal}),
                ...(filtersList?.date && {
                    dateBegin: filtersList?.date.filterVal.from,
                    dateEnd: filtersList?.date.filterVal.to
                }),
                ...(filtersList?.confirmation_status && {hasViolation: filtersList?.confirmation_status.filterVal}),
            };
            loadEvents(page, sizePerPage, {
                ...defaultQuery,
                ...omitUndefinedAndNullFields(filtersQuery)
            });
        // }
    }

    useEffect(() => {
        reloadQuery();
    }, [filtersList, sizePerPage, page]);

    if (isPendingObjects) {
        return <Spinner/>;
    }

    const reload = () => {
        // loadEvents(page, sizePerPage, defaultQuery);
        // onTableChange("filter", {sizePerPage: sizePerPage, page: page, filters: filtersList});
        reloadQuery();
    };


    const onTableChange = (type, {sizePerPage, page, filters, sortField, sortOrder}) => {
        // clearEvents();
        if (type === 'filter') {
            // console.debug(filters)
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }
        setfiltersList(filters);
        // const filtersQuery = {
        //     ...(filters.power_unit && {power_unit: filters.power_unit.filterVal}),
        //     ...(filters.room && {object_id: filters.room.filterVal}),
        //     ...(filters.camera_name && {cameraId: filters.camera_name.filterVal}),
        //     ...(filters.date && {
        //         dateBegin: filters.date.filterVal.from,
        //         dateEnd: filters.date.filterVal.to
        //     }),
        //     ...(filters.confirmation_status && {hasViolation: filters.confirmation_status.filterVal}),
        // };
        // loadEvents(page, sizePerPage, {
        //     ...defaultQuery,
        //     ...omitUndefinedAndNullFields(filtersQuery)
        // });
    };

    const onCloseModal = () => {
        setEvent(null);
    };

    // const FilteredData = Object.entries(events || []).filter(item => item[1].primary_objects.length !== 0).filter(item => item[1].primary_objects[0].secondary_objects.length !== 0).filter(item => item[1].object_observation !== null);
    const FilteredData = Object.entries(events || []);
    const apiEvents = FilteredData.map((event) => apiDataToRejectedEventObj(event[1], objects.list));

    //

    const dateFormatter = (date) => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return new Date(date * 1000).toLocaleString('ru-RU', { timeZone });
    }

    const columns = [{
        text: 'uuid id',
        dataField: 'id',
        headerStyle: () => ({width: '280px'})
    }, {
        text: 'Блок',
        dataField: 'power_unit',
        headerClasses: 'table-block',
        filter: selectFilter({
            options: objectsListToOptions(omitRooms(objects.list)),
            placeholder: 'Все энергоблоки',
        }),
        headerStyle: () => ({width: '159px'}),
        // formatter: cell => objectsListToOptions[cell],
    }, {
        text: 'Помещение',
        dataField: 'room',
        filter: selectFilter({
            options: objectsListToOptions(unitId ?
                roomsInObject(unitId, onlyRooms(objects.list)) : onlyRooms(objects.list)
            ),
            placeholder: 'Все помещения'
        }),
        headerStyle: () => ({width: '159px'})
    }, {
        text: 'Камера',
        dataField: 'camera_name',
        filter: selectFilter({
            options: objectsListToOptions(objectId ?
                // cameraInObject(objectId, cameras) : cameras
                cameraInObject(objectId, [...cameras, ...thermalCameras]) : [...cameras, ...thermalCameras]
            ),
            placeholder: 'Все камеры'
        }),
        headerStyle: () => ({width: '159px'}),
    }, {
        text: 'Дата и время',
        dataField: 'date',
        headerStyle: () => ({width: '220px'}),
        formatter: (date) => dateFormatter(date),
        headerClasses: 'table-date',
        filter: customFilter({
            type: FILTER_TYPES.DATE,
        }),
        filterRenderer: (onFilter, column) =>
            <RangeFilter
                onFilter={onFilter}
                labelFrom="с" labelTo="по"
                valueFrom={getDateNow(-DEFAULT_INTERVAL_HOURS)}
                valueTo={getDateNow()}
            />
    }, {
        text: 'Индекс',
        dataField: 'score',
        headerStyle: () => ({width: '79px'})
    }, {
        text: 'Причина отклонения',
        dataField: 'type',
    }]
        .map(col => ({
            ...col,
            editable: false,
            classes: 'px-0 py-0 table-cell',
        }));

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            setEvent(row);
        }
    };

    let tableRef;

    return (
        <>
            {/*<EventList*/}
            {/*    ref={el => tableRef = el}*/}
            {/*    apiEvents={events ? events : []}*/}
            {/*    eventsCount={eventsTotal}*/}
            {/*    cameras={cameras}*/}
            {/*    page={page}*/}
            {/*    sizePerPage={sizePerPage}*/}
            {/*    onTableChange={onTableChange}*/}
            {/*    objects={objects.list}*/}
            {/*    filterByStatus={!isViolationsOnly}*/}
            {/*    onSelect={data => {setEvent(data)}}*/}
            {/*    isPending={isPendingEvents}*/}
            {/*/>*/}
            <div className="btn-wrapper with-other-btns">
                <div className="btn-wrapper-left">
                    <button className="table-control-btn btn-reload" onClick={reload}>
                        <FontAwesomeIcon icon={faSyncAlt} size="lg" color="#919191"/>
                        Обновить
                    </button>
                </div>
            </div>
            <RemotePagination
                data={apiEvents ? apiEvents : []}
                totalSize={eventsTotal}
                sizePerPage={sizePerPage}
                page={page}
                onSizePerPageChange={(...params) => {
                    const filters = tableRef.filterContext.currFilters;
                    onTableChange(null, {sizePerPage: params[0], page: params[1], filters});
                    // onTableChange(null, {sizePerPage: params[0], page: page, filters});
                }}
                onTableChange={(type, options) => {
                    if (type === 'filter') {
                        const object = options.filters.room;
                        setObjectId(object ? parseInt(object.filterVal) : null);
                        const unit = options.filters.power_unit;
                        setUnitId(unit ? parseInt(unit.filterVal) : null);
                    }
                    // onTableChange(type, options);
                    onTableChange(type, {sizePerPage: options.sizePerPage || sizePerPage, page: options.page, filters: options.filters});
                }}
            >
                <BootstrapTable
                    rowEvents={rowEvents}
                    remote={{filter: true, sort: true}}
                    ref={el => tableRef = el}
                    keyField="uniqKey"
                    data={apiEvents ? apiEvents : []}
                    columns={columns}
                    defaultSorted={[{dataField: 'date', order: 'asc'}]}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                    // onTableChange={onTableChange}
                    onTableChange={() => {}}
                    noDataIndication={() => isPendingEvents ? <Spinner/> : 'Данные отсутствуют'}
                />
            </RemotePagination>
        </>
    );
};

export default connect(
    state => ({
        user: state.user,
        event: state.event.event,
        events: state.rejectedEvents.list?.events,
        eventsTotal: state.rejectedEvents.list?.total_events,
        cameras: state.camera.flatList || [],
        thermalCameras: state.thermalCamera.list || [],
        objects: state.object,
        employees: state.employees.list,
        isPendingEvents: state.rejectedEvents.isPending,
        isPendingObjects: state.object.isListPending || state.camera.isListPending || state.thermalCamera.isListPending || state.employees.isListPending,
        isError: state.rejectedEvents.isError,
    }),
    dispatch => ({
        loadEvents: (pageNumber, pageSize, query) => {
            // dispatch(clearEventReport());
            dispatch(loadRejectedEventReport(query, pageNumber, pageSize));
        },
        clearEvents: () => {
            dispatch(clearRejectedEventReport());
        },
        loadObjects: () => {
            dispatch(loadObjectsObservationList());
        },
        loadAllCameras: () => {
            // dispatch(cameraClearState());
            dispatch(cameraFlatDetailedList());
        },
        loadThermalCamerasData: () => {
            dispatch(thermalCameraList());
        },
        loadEmployeesList: () => {
            dispatch(clearEmploeesState());
            dispatch(loadEmploeesList());
        },
    }),
)(withDataErrorIndication(RejectedEventsPage));
