import React from 'react';

import CONFIG from '../../config';
import MjpegPlayerNoIframe from '../../components/MjpegPlayerNoIframe';
import VideoPlayerHls from '../../components/VideoPlayerHls';


import './SelectPlayer.css';

const labelStyle = {
    borderRadius: 0,
    marginLeft: 0,
    width: '80px'
};



const SelectPlayer = ({children, hlsUrl, mjpegURL}) => {
    const HLS_PLAYER = "HLS";
    const MJPEG_PLAYER = "MJPEG";
    const [selectedPlayer, setSelectedPlayer] = React.useState(HLS_PLAYER);

    return (
        <>
            <div className="row no-gutters player">
                <div className="col-12 ">
                    {mjpegURL &&
                    <>
                        <label
                            className={selectedPlayer === MJPEG_PLAYER ? "float-right btn btn-warning" : "float-right btn btn-secondary"}
                            style={labelStyle}
                            onClick={(e) => {
                                setSelectedPlayer(MJPEG_PLAYER)
                            }}
                        >
                            MJPEG
                        </label>

                        <label
                            className={selectedPlayer === HLS_PLAYER ? "float-right btn btn-warning" : "float-right btn btn-secondary"}
                            style={labelStyle}
                            onClick={(e) => {
                                setSelectedPlayer(HLS_PLAYER)
                            }}
                        >
                            HLS
                        </label>
                    </>
                    }

                </div>
            </div>
            <div className="row player">
                <div className="col-12">
                    {
                        selectedPlayer === HLS_PLAYER &&
                        <VideoPlayerHls controls={!CONFIG.USE_DEMO_CONFIG} src={hlsUrl}/>
                    }
                    {
                        selectedPlayer === MJPEG_PLAYER &&
                        <MjpegPlayerNoIframe src={mjpegURL} width={"100%"} />
                    }
                </div>
            </div>
        </>
    );
};

export default SelectPlayer;