import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import filterFactory, {customFilter, FILTER_TYPES} from "react-bootstrap-table2-filter";
import RangeFilter from "../components/RangeFilter";
import {getDateNow} from "../utils/time";
import {DEFAULT_INTERVAL_HOURS, PAGE_SIZE, START_PAGE} from "../utils/constants";
import Spinner from "../components/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import withDataErrorIndication from "../components/withDataErrorIndication";
import {loadLogs} from "../store/Logs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import RemotePagination from "../tables/RemotePagination";
import paginationFactory from "react-bootstrap-table2-paginator";

const dateFormatter = ({date}) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return new Date(date * 1000).toLocaleString('ru-RU', { timeZone });
}

const timestamp = date => new Date(date).getTime() / 1000;

const UserLogs = ({busy, actions, list = [], total, limit}) => {
  document.title = 'Лог системы';


  const defaultQuery = {
    start_date: timestamp(getDateNow(-DEFAULT_INTERVAL_HOURS)),
    end_date: timestamp(getDateNow())
  }

  const [dateRange, setDateRange] = useState(defaultQuery);
  const [page, setPage] = useState(START_PAGE);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [offset, setOffset] = useState(0);

  const {
    getLogs
  } = actions;

  useEffect(() => {
    loadDataWithParams();
  }, [dateRange, pageSize, offset]);

  const reload = () => {
    loadDataWithParams(dateRange);
  }

  const loadDataWithParams = () => {
    getLogs({ ...dateRange, records_limit: pageSize, records_skip: offset })
  }

  const Columns = () => [{
    text: 'ФИО сорудника',
    dataField: 'user_name',
    headerStyle: () => ({width: '300px'}),
    editable: false
  }, {
    text: 'Дата и время',
    dataField: 'date',
    headerStyle: () => ({width: '220px'}),
    headerClasses: 'table-date',
    filter: customFilter({
      type: FILTER_TYPES.DATE,
    }),
    formatter: (date) => dateFormatter({ date }),
    filterRenderer: (onFilter, column) =>
      <RangeFilter
        onFilter={onFilter}
        labelFrom="с" labelTo="по"
        valueFrom={getDateNow(-DEFAULT_INTERVAL_HOURS)}
        valueTo={getDateNow()}
      />
  }, {
    text: 'Действие',
    dataField: 'message',
    editable: false
  }];

  let tableRef;

  return (
    <>
      <div className="btn-wrapper">
        <button className="table-control-btn btn-reload" onClick={reload}>
          <FontAwesomeIcon icon={faSyncAlt} size="lg" color="#919191"/>
          Обновить
        </button>
      </div>
      <RemotePagination
        data={list}
        totalSize={total}
        sizePerPage={limit}
        page={page}
        onSizePerPageChange={(...params) => {
          setPageSize(params[0])
        }}
        onTableChange={(type, options) => {
          if (type === 'filter') {
            const { from, to } = options.filters.date.filterVal;
            setDateRange({
              start_date: timestamp(from),
              end_date: timestamp(to)
            })
          }
          if (type === 'pagination' && page !== options.page) {
            const pageNum = options.page;
            setPage(pageNum);
            setOffset(pageNum === 1 ? 0 : (pageNum - 1) * limit);
          }
        }}
      >
        <BootstrapTable
          remote={{filter: true, sort: true}}
          ref={el => tableRef = el}
          keyField="uniqKey"
          data={list}
          columns={Columns()}
          defaultSorted={[{dataField: 'date', order: 'asc'}]}
          filter={filterFactory()}
          pagination={paginationFactory()}
          onTableChange={() => {}}
          noDataIndication={() => busy ? <Spinner/> : 'Данные отсутствуют'}
        />
      </RemotePagination>
    </>
  )
}

export default connect(
  state => ({
    list: state.logs.list,
    busy: state.logs.busy,
    errors: state.logs.errors,
    total: state.logs.total,
    limit: state.logs.limit
  }),
  dispatch => ({
    actions: {
      getLogs: params => {
        dispatch(loadLogs(params));
      }
    }
  })
)(withDataErrorIndication(UserLogs));
