import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {
  loadRegulatoryDocsPrimary,
  loadRegulatoryDocPrimary, updateRegulatoryDocPrimary, removeRegulatoryDocPrimary
} from '../store/RegulatoryDocsPrimary';
import withDataErrorIndication from '../components/withDataErrorIndication';
import WorkwearModal from "../components/WorkwearModal";
import WorkwearTable from "../tables/WorkwearTable";

const LinkTextFormatter = ({text}) => {
  let linkText = ''
  if (text) {
    const arr = text.split('/');
    linkText = arr[arr.length - 1];
  }
  return (
    <div>{text ? <a href={text} target="_blank">{linkText}</a> : <></>}</div>
  );
}

const Workwear = ({ isPending, actions, list = [], selectedDoc}) => {
    document.title = 'Типы спецодежды';

    const {
      selectDoc,
      loadRegulatoryDocs,
      saveDoc,
      removeDoc
    } = actions;

    useEffect(() => {
        loadRegulatoryDocs();
    }, []);

    const [shouldShowModal, setShowModal] = useState(false);
    const [workwearFormValues, setWorkwearFormValues] = useState({});

    useEffect(() => {
      const fields = {};
      let valuesChanged = [];
      let saved = false;
      const hasData = !!Object.keys(workwearFormValues).length;
      if (hasData) {
        fields.name = workwearFormValues.name;
        fields.description = workwearFormValues.description;
        fields.article = workwearFormValues.article;
        valuesChanged = Object.keys(fields).reduce((acc, key) => {
          if (fields[key] !== selectedDoc[key]) {
            acc.push(fields[key])
          }
          return acc;
        }, []);
      }
      if(workwearFormValues.isEdited && workwearFormValues.file) {
        saveDoc({
          ...fields,
          file: workwearFormValues.file
        });
        saved = true;
      }
      if(workwearFormValues.isEdited && !workwearFormValues.file) {
        removeDoc({
          name: fields.name
        });
      }
      if(valuesChanged.length && !saved) {
        saveDoc(fields);
      }
      setShowModal(false);
    }, [workwearFormValues])

    const Columns = () => [{
        text: 'Нарушение',
        dataField: 'ru_name',
        headerStyle: () => ({width: '300px'}),
        editable: false
    }, {
      text: 'Расшифровка нарушения',
      dataField: 'description',
      headerStyle: () => ({width: '300px'}),
      editable: false
    }, {
      text: ' статья  ТБ и ПБ',
      dataField: 'article',
      headerStyle: () => ({width: '300px'}),
      editable: false
    }, {
        text: 'Cсылка на документ по ТБ и ПБ',
        dataField: 'file_uri',
        editable: false,
        formatter: (cell) => (
          <LinkTextFormatter text={cell}/>
        )
    }];

    return (
      <>
        <WorkwearTable
          objects={list}
          getColumns={Columns}
          isEditable={false}
          isPending={isPending}
          rowEvents={{ onDoubleClick: (e, { name }) => {
            selectDoc(name);
            setShowModal(true);
          }}}
          actions={{
              reload: loadRegulatoryDocs
          }}
        />
          {shouldShowModal &&
          <WorkwearModal
            onCancel={() => {
              setShowModal(false);
            }}
            onClose={() => {
              setShowModal(false);
            }}
            onSave={(data) => {
              setWorkwearFormValues(data);
            }}
          />}
      </>
    )
}

export default connect(
    state => ({
        list: state.regulatoryDocsPrimary.list,
        isPending: state.regulatoryDocsPrimary.busy,
        isError: state.regulatoryDocsPrimary.error,
        selectedDoc: state.regulatoryDocsPrimary.current,
    }),
    dispatch => ({
      actions: {
        loadRegulatoryDocs: () => {
          dispatch(loadRegulatoryDocsPrimary());
        },
        selectDoc: name => {
          dispatch(loadRegulatoryDocPrimary(name));
        },
        saveDoc: data => {
          dispatch(updateRegulatoryDocPrimary(data));
        },
        removeDoc: data => {
          dispatch(removeRegulatoryDocPrimary(data));
        }
      }
    }),
)
(withDataErrorIndication(Workwear));
