import axios from 'axios';

const THERMAL_CAMERA = {
    LIST:       'THERMAL_CAMERA_LIST',
    FLAT_LIST:  'THERMAL_CAMERA_FLAT_LIST',
    LIST_PENDING: 'THERMAL_CAMERA_LIST_PENDING',
    LIST_ERROR: 'THERMAL_CAMERA_LIST_ERROR',
    DETAIL:     'THERMAL_CAMERA_DETAIL',
    CREATE:     'THERMAL_CAMERA_CREATE',
    EDIT:       'THERMAL_CAMERA_EDIT',
    DELETE:     'THERMAL_CAMERA_DELETE',
    ALL_INFO:   'THERMAL_CAMERA_ALL_INFO',
    CLEAR:      'THERMAL_CAMERA_CLEAR',
};

const initialState = {
    current: {},
};

const thermalCameraReducer = (state = initialState, action) => {
    switch(action.type) {
        case THERMAL_CAMERA.LIST:
            return {
                ...state,
                list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case THERMAL_CAMERA.FLAT_LIST:
            return {
                ...state,
                flatList: action.payload.filter(camera => camera.obj_id !== null),
                isListPending: false,
                isListError: false,
            };
        case THERMAL_CAMERA.DETAIL:
        case THERMAL_CAMERA.CREATE:
        case THERMAL_CAMERA.EDIT:
        case THERMAL_CAMERA.DELETE:
        case THERMAL_CAMERA.ALL_INFO:
            return {
                ...state,
                isListPending: false,
                current: action.payload,
            };
        case THERMAL_CAMERA.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case THERMAL_CAMERA.LIST_ERROR:
            return {
                isListError: true,
            }
        case THERMAL_CAMERA.CLEAR:
            return initialState;
        default:
            return state;
    }
};

export default thermalCameraReducer;

export const thermalCameraList = () => dispatch => {
    dispatch({
        type: THERMAL_CAMERA.LIST_PENDING,
        payload: null,
    });
    axios.get('api/statistics/kola-backend/thermal-cameras/')
        .then(res => {
            dispatch({
                type: THERMAL_CAMERA.LIST,
                payload: res.data
            });
        }).catch(err => {
        console.error('error>', err);
        dispatch({
            type: THERMAL_CAMERA.LIST_ERROR,
            payload: null,
        });
    })
};

export const thermalCameraFlatDetailedList = () => dispatch => {
    dispatch({
        type: THERMAL_CAMERA.LIST_PENDING,
        payload: null,
    });
    const getCamerasIds = (cameras) => {
        let ids = [];
        for (const camera of cameras) {
            ids.push(camera.id)
        }
        return ids;
    };
    const setCamerasImgs = (cameras, camera_img) => {
        for (const cam of cameras) {
            cam.screenshot_preview = camera_img;
        }
    };


    axios.get('api/statistics/kola-backend/thermal-cameras/')
        .then(res => {
            let cameras = res.data;
            cameras.sort((a, b) => (a.id > b.id) ? 1 : -1);

            setCamerasImgs(cameras, cameras.preview); // присваиваю картинки на камеры
            dispatch({
                type: THERMAL_CAMERA.FLAT_LIST,
                payload: cameras
            });
        })
        .catch(err => {
            console.error('error>', err);
            dispatch({
                type: THERMAL_CAMERA.LIST_ERROR,
                payload: null,
            });
        })
};


// export const cameraFlatDetailedList = () => dispatch => {
//     dispatch({
//         type: CAMERA.LIST_PENDING,
//         payload: null,
//     });
//     axios.get('/api/kola/cameras/')
//         .then(res => {
//             dispatch({
//                 type: CAMERA.FLAT_LIST,
//                 payload: res.data
//             });
//         })
//         .catch(() => {
//             dispatch({
//                 type: CAMERA.LIST_ERROR,
//                 payload: null,
//             });
//         })
// };

/* Загрузка камеры (cameraId) из объекта наблюдения (objId) */
export const thermalCameraLoad = (objId, cameraId) => dispatch => {
    dispatch({
        type: THERMAL_CAMERA.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/kola-backend/objects/${objId}/thermal-cameras/${cameraId}/`)
        .then(res => {
            dispatch({
                type: THERMAL_CAMERA.DETAIL,
                payload: res.data,
            });
        });
};

/* Создание новой камеры */
export const thermalCameraCreate = (objId, data) => dispatch => {
    // axios.post(`/api/statistics/kola-backend/objects/cameras/`, data)
    axios.post(`/api/statistics/kola-backend/objects/${objId}/thermal-cameras/`, data)
        .then(res => {
            dispatch({
                type: THERMAL_CAMERA.CREATE,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: THERMAL_CAMERA.CREATE,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

export const thermalCameraDelete = (objId, cameraId) => dispatch => {
    axios.delete(`/api/statistics/kola-backend/objects/${objId}/thermal-cameras/${cameraId}/`)
        .then(res => {
            let data = {isReloadList: true};
            dispatch({
                type: THERMAL_CAMERA.DELETE,
                payload: data,
            });
        });
};

export const thermalCameraClearState = () => dispatch => {
    dispatch({
        type: THERMAL_CAMERA.CLEAR,
        payload: {},
    });
};

export const thermalCameraEdit = (objId, cameraId, data) => dispatch => {
    axios.put(`/api/statistics/kola-backend/objects/${objId}/thermal-cameras/${cameraId}/`, data)
        .then(res => {
            dispatch({
                type: THERMAL_CAMERA.EDIT,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: THERMAL_CAMERA.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

export const thermalCameraFullInformationLoad = (objId, camId) => dispatch => {
    axios.get(`/api/statistics/kola-backend/objects/${objId}/thermal-cameras/${camId}/`)
        .then(res => {
            dispatch({
                type: THERMAL_CAMERA.ALL_INFO,
                payload: res.data,
            });
        })
};
