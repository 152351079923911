import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Spinner from "./Spinner";
import TabsWithContent from "./TabsWithContent";
import {loadJobDocumentSetting, clearJobDocumentsState} from "../store/JobDocuments";
import BootstrapTable from "react-bootstrap-table-next";
import IconPlusPhotoSmall from "../assets/img/icons/IconPlusPhotoSmall";

class SwitchingFormInfoModal extends React.Component {
    state = {
        isEditMode: false,
    };

    activePhotoModal = 0;

    componentDidMount() {
        this.props.loadJobDocumentSetting(this.props.id);
    }

    render() {
        const {onClose, onSave, isPending, jobDocument, onCheckPhoto} = this.props;

        const cupboardActive = (val) => {
            if (val) {
                return <p>Шкаф №{this.props.cupboardLayoutList.find(empI => empI.id === val)?.cabinet_int.name}</p>
            } else {
                return <p></p>
            }
        }

        const columns = [
            {
                text: 'Номер шага',
                dataField: 'number',
                headerStyle: () => ({width: '150px'}),
                editable: false,
                isKey: true,
            },{
                text: 'Шкаф бланка',
                dataField: 'cabinet_id',
                headerStyle: () => ({width: '170px'}),
                editable: false,
                formatter: cell => cupboardActive(cell),
            }, {
                text: 'Шкаф зафиксированный',
                dataField: 'fixed_cabinet_id',
                headerStyle: () => ({width: '170px'}),
                editable: false,
                formatter: cell => cupboardActive(cell),
            }, {
                text: 'Статус',
                dataField: 'status',
                headerStyle: () => ({width: '170px'}),
                editable: false,
                formatter: (cell) => {
                    if (cell === 0) {
                        return <p>Не пройден</p>
                    } else if (cell === 1){
                        return <p>Верный порядок обхода</p>
                    } else if (cell === 2){
                        return <p>Нарушен порядок обхода</p>
                    }
                }
            }, {
                text: 'Фото',
                dataField: 'image_url',
                headerStyle: () => ({width: '170px'}),
                editable: false,
                formatter: (cell,row) => {
                    // return cell ? <img className="img-employee-table" alt="img-employee" src={cell}/> : ''
                    return cell ?
                    <>
                        <div className="img-modal-wrapper">
                            <img className="img-modal" src={cell} alt=""/>
                            <div className="modal">
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    onCheckPhoto(cell, row.number)
                                }}>
                                    <IconPlusPhotoSmall/>
                                </a>
                            </div>
                        </div>
                        </>
                        :
                        ''
                }
            }]
            .map(col => ({
                ...col,
                editable: false,
                classes: 'px-0 py-0 table-cell',
            }));

        let tableRef;

        return (
            <>
            {isPending ?
                <Spinner/> :
                <>
                    <TabsWithContent
                        titles={['Данные']}
                        tabs={[
                            <>
                                <div className="modal-content">
                                    <BootstrapTable
                                        remote={{filter: false, sort: true}}
                                        ref={el => tableRef = el}
                                        keyField="number"
                                        data={jobDocument ? jobDocument.stages : []}
                                        columns={columns}
                                        defaultSorted={[{dataField: 'number', order: 'asc'}]}
                                        noDataIndication={() => isPending ? <Spinner/> : 'Данные отсутствуют'}
                                    />
                                </div>
                            </>
                        ]} />
                </>
            }</>
        )
    }
}

export default connect(
    state => ({
        jobDocument: state.jobDocuments.current,
        isPending: state.jobDocuments.isDocumentPending,
    }),
    dispatch => ({
        loadJobDocumentSetting: (id) => {
            // dispatch(clearJobDocumentsState());
            dispatch(loadJobDocumentSetting(id));
        },
    }),
)(SwitchingFormInfoModal);
