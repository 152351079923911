import React from 'react';

import Modal from './Modal';
import TabsWithContent from './TabsWithContent';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import {cameraClearState, cameraCreate, cameraEdit, cameraList, cameraLoad} from '../store/Camera';
import {clearPPEs, loadPPEs} from "../store/PPEs";
import {clearWorkwear, loadWorkwear} from "../store/Workwear";
import VideoRecorderUserForm from '../forms/VideoRecorderUserForm';
import RemoteSubmitButton from '../forms/RemoteSubmitButton';
import {
    videoRecorderUserList,
    videoRecorderUserClearState,
    videoRecorderUserCurrentClearState,
    videoRecorderUserHandoutEdit,
    videoRecorderUserReturnEdit,
} from '../store/VideoRecorderUsers';

class VideoRecorderUsersModal extends React.Component {
    state = {
        isEditMode: false,
    };

    userFormValues = {};

    componentDidMount() {
        // console.log("this.props.cameraActive", this.props.cameraActive);
        // this.props.actions.loadCamerasData();
        //this.props.actions.loadWorkwear();
        //this.props.actions.loadPPEs();
        // this.props.actions.loadCamera();
    }

    onEditForm() {
        this.setState({isEditMode: true})
    };

    render() {
        const {onClose, onCancel, isPending, isError, objectsList, videoRecorderList, camera, employees, modalHandout, activeDevice} = this.props;
        const {createCamera, updateCamera, createHandout, createReturn} = this.props.actions;
        this.userFormValues = camera;
        return (
            <Modal
                title={modalHandout ? `Выдача видеорегистратора` : `Возврат видеорегистратора`}
                isOpen
                showEditButton={!this.state.isEditMode}
                closeButtonWithText={true}
                onClose={onClose}
                onEdit={() => this.onEditForm()}
                className="user-modal"
            >
                {isPending
                    ?
                    isError ?
                        <p>Ошибка при загрузке данных</p>
                        :
                        <Spinner/>
                    :
                    <>
                        <TabsWithContent
                            titles={['Данные']}
                            tabs={[
                                <>
                                    <VideoRecorderUserForm
                                        handleCancel={onCancel}
                                        onSubmit={values => {
                                            this.userFormValues = values;
                                            console.log("camera!!!!3", this.userFormValues);
                                            this.userFormValues.dashcam_id = this.userFormValues.dashcam.value;
                                            this.userFormValues.person_id = this.userFormValues.person?.value;
                                            this.userFormValues.timestamp = new Date(this.userFormValues.timestamp_str).getTime() / 1000;
                                            if (modalHandout) {
                                                createHandout(this.userFormValues);
                                            } else {
                                                createReturn(this.userFormValues);
                                            }
                                            onCancel();
                                        }}
                                        isEditMode={this.state.isEditMode}
                                        objectsList={objectsList}
                                        employees={employees}
                                        modalHandout={modalHandout}
                                        videoRecorderList={videoRecorderList}
                                        activeDevice={activeDevice}
                                    />
                                    <div className="submit-wrapper">
                                        {this.state.isEditMode &&
                                        <RemoteSubmitButton
                                            forms={['VideoRecorderUserForm']}
                                            className=""
                                        >
                                            Сохранить
                                        </RemoteSubmitButton>}
                                    </div>
                                </>
                            ]}
                        />
                    </>
                }

            </Modal>
        )
    }
}

export default connect(
    state => ({
        isPending: state.object.isListPending,
        isError: state.ppes.isError || state.workwear.isError,
        ppes: state.ppes.list,
        workwear: state.workwear.list,
        camera: state.camera.current,
    }),
    dispatch => ({
        actions: {
            loadCamerasData: () => {
                dispatch(cameraList());
            },
            createHandout: (camera) => {
                dispatch(videoRecorderUserHandoutEdit(camera.dashcam_id, camera));
            },

            loadCamera: (camera) => {
                // dispatch(cameraClearState());
                dispatch(cameraLoad(camera.object, camera.id));
            },
            createReturn: (camera) => {
                dispatch(videoRecorderUserReturnEdit(camera.dashcam_id, camera));
            },
            loadPPEs: () => {
                dispatch(clearPPEs());
                dispatch(loadPPEs());
            },
            loadWorkwear: () => {
                dispatch(clearWorkwear());
                dispatch(loadWorkwear());
            },
        }
    })
)(VideoRecorderUsersModal);
