import axios from 'axios';
import imageUtils from "../utils/imageUtils";
import {clearEmploeesState, loadEmploeesList} from "./Employees";

const VIDEO_RECORDER_USER = {
    LIST:       'VIDEO_RECORDER_USER_LIST',
    LIST_PENDING: 'VIDEO_RECORDER_USER_LIST_PENDING',
    LIST_ERROR: 'VIDEO_RECORDER_USER_LIST_ERROR',
    DETAIL:     'VIDEO_RECORDER_USER_DETAIL',
    CREATE:     'VIDEO_RECORDER_USER_CREATE',
    EDIT:       'VIDEO_RECORDER_USER_EDIT',
    DELETE:     'VIDEO_RECORDER_USER_DELETE',
    CLEAR:      'VIDEO_RECORDER_USER_CLEAR',
    CURRENT_CLEAR:      'VIDEO_RECORDER_USER_CURRENT_CLEAR',
};

const initialState = {
    current: {},
};

const videoRecorderUserReducer = (state = initialState, action) => {
    switch(action.type) {
        case VIDEO_RECORDER_USER.LIST:
            return {
                ...state,
                list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case VIDEO_RECORDER_USER.DETAIL:
        case VIDEO_RECORDER_USER.CREATE:
        case VIDEO_RECORDER_USER.EDIT:
        case VIDEO_RECORDER_USER.DELETE:
        case VIDEO_RECORDER_USER.ALL_INFO:
            return {
                ...state,
                current: action.payload,
                isListPending: false,
            };
        case VIDEO_RECORDER_USER.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case VIDEO_RECORDER_USER.LIST_ERROR:
            return {
                isListError: true,
            }
        case VIDEO_RECORDER_USER.CLEAR:
            return initialState;
        case VIDEO_RECORDER_USER.CURRENT_CLEAR:
            return {
                ...state,
                current: []
            };
        default:
            return state;
    }
};

export default videoRecorderUserReducer;

export const videoRecorderUserList = () => dispatch => {
    dispatch({
        type: VIDEO_RECORDER_USER.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/kola-dashcam/handing-out-log/')
        .then(res => {
            dispatch({
                type: VIDEO_RECORDER_USER.LIST,
                payload: res.data
            });
        })
};

export const videoRecorderUserHandoutEdit = (dashcam_id, data) => dispatch => {
    axios.post(`/api/statistics/kola-dashcam/dashcams/${dashcam_id}/handout/`, data)
        .then(res => {
            dispatch({
                type: VIDEO_RECORDER_USER.EDIT,
                payload: {isReloadList: false},
            });
            dispatch(videoRecorderUserClearState());
            dispatch(videoRecorderUserList());
        })
        .catch(err => {
            dispatch({
                type: VIDEO_RECORDER_USER.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

export const videoRecorderUserReturnEdit = (dashcam_id, data) => dispatch => {
    axios.post(`/api/statistics/kola-dashcam/dashcams/${dashcam_id}/return/`, data)
        .then(res => {
            dispatch({
                type: VIDEO_RECORDER_USER.EDIT,
                payload: {isReloadList: false},
            });
            dispatch(videoRecorderUserClearState());
            dispatch(videoRecorderUserList());
        })
        .catch(err => {
            dispatch({
                type: VIDEO_RECORDER_USER.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

export const videoRecorderUserClearState = () => dispatch => {
    dispatch({
        type: VIDEO_RECORDER_USER.CLEAR,
        payload: null,
    });
};

export const videoRecorderUserCurrentClearState = () => dispatch => {
    dispatch({
        type: VIDEO_RECORDER_USER.CURRENT_CLEAR,
        payload: null,
    });
};
