import PolygonEditor from "./PolygonEditor/PolygonEditor";
import React, {useEffect, useState} from 'react';

const ZoneView = (props) => {
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    const zones = props.zones.map(zone => zone.zid);
    setStateOptions(props.modelstorage
      .filter(item => !zones.includes(item.zone_id_cfg))
      .map(model => (
      <option value={model.zone_id_cfg} key={model.zone_name_cfg}>
        {`Создать "${model.zone_name_cfg}"`}
      </option>)
      )
    );
  }, [props.modelstorage]);

  return (
    <div className="zone-objects_wrapper">
      <div className="zone-objects_wrapper-selectors">
        <div className="zone-objects_wrapper-selectors__selector">
          <select
            className="zone-objects_wrapper-selectors__selector--field"
            value={props.selectedZone}
            onChange={(e) => {
              props.selectAction(e.target.value);
            }}>
            <option value="">Действие</option>
            {stateOptions}
          </select>
        </div>
      </div>
      <PolygonEditor {...props} />
    </div>
  )
}

export default ZoneView;
