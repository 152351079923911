import React from 'react';
import {Link} from 'react-router5';

import {ROUTES} from '../router/routes';
import imagePlaceholder from '../assets/img/image-placeholder.png';

const SystemInfo = ({ info }) => {
    console.log("info", info);
    return (
        <div className="info-wrapper">
            <div className="row expanded">
                <div className="column small-8">
                    <div className="info-item">
                        <p>Всего пользователей</p>
                        <input type="text" readOnly value={info.users_count}/>
                    </div>
                </div>
                <div className="column small-8">
                    <div className="info-item">
                        <p>Необработанных событий</p>
                        <input type="text" readOnly value={info.events_count}/>
                    </div>
                </div>
                <div className="column small-8">
                    <div className="info-item">
                        <p>Всего нарушений</p>
                        <input type="text" readOnly value={info.confirmed_events_count}/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SystemInfo;
