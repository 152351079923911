import React, {useEffect, useState} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faSyncAlt, faTimes, faArrowDown, faPlus, faArrowUp} from '@fortawesome/free-solid-svg-icons';

import Spinner from '../components/Spinner';
import filterFactory from 'react-bootstrap-table2-filter';
import SpinnerSmall from "../components/SpinnerSmall";

const NEW_ROW_INDEX = 0;

const GeneralObjectsTable = ({actions, getColumns, defaultSorted, videoRecorder, className, uploadVideoRecorder, objects, empty, isPending, isDownloadingDocs, itemDummy, progress, rowEvents = {}, isEditable = true, uploadFile, getEmploeeDocx, onFileChange}) => {
    const {update, create, purge, reload, handout, returnItem} = actions;

    const [items, setItems] = useState(objects);
    const [isAddMode, setIsAddMode] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    const resetSelection = () => {
        setSelectedRowIds([]);
        setSelectedRow(null);
    }

    useEffect(() => {
        setItems(objects);
        setIsAddMode(false);
        resetSelection();
    }, [JSON.stringify(objects)]);

    const addNewRow = () => {
        setIsAddMode(true);
        setItems([itemDummy, ...items]);
        if (!tableRef || !tableRef.table) {
            return;
        }
        let columnIndex = tableRef.props.columns.findIndex(
            column => column.required
        );
        if (columnIndex === -1) {
            columnIndex = 1
        }
        tableRef.cellEditContext.startEditing(NEW_ROW_INDEX, columnIndex);
    };

    const _editAnotherColumnByIndex = (index) => {
        setTimeout(// чтобы вначале завершилось редактирование текущей ячейки
            () => {
                tableRef.cellEditContext.startEditing(NEW_ROW_INDEX, index);
            }, 0);
    };

    const _nextRequiredFieldColumnIndex = (currentColumnDataField) => {
        const columns = tableRef.props.columns;
        const curColumnIndex = columns.findIndex(
            col => col.dataField === currentColumnDataField
        );
        return columns.findIndex(
            (col, index) => index > curColumnIndex && col.required
        );
    }

    const saveRow = (oldValue, newValue, row, column) => {
        if (row.id) { // редактирование
            update(row.id, row);
        } else { // создание
            const nextIndex = _nextRequiredFieldColumnIndex(column.dataField);
            if (nextIndex !== -1) {
                _editAnotherColumnByIndex(nextIndex);
            } else {
                create(row);
                setIsAddMode(false);
            }
        }
    };

    const cancelNewRow = () => {
        if (!isAddMode) {
            return;
        }
        setItems(items.slice(1));
        setIsAddMode(false);
    };

    const deleteRow = () => {
        if (!selectedRowIds.length || !selectedRow) {
            return;
        }
        purge(selectedRowIds[0], selectedRow);
        resetSelection();
    }

    const manageSelection = (row, isSelect, rowIndex, e) => {
        // эта функция нужна, чтобы можно было выделять только по одной строке
        setTimeout(() => {
            if (isSelect) {
                setSelectedRowIds([row.id]);
                setSelectedRow(row);
            } else {
                resetSelection();
            }
        }, 0);
    };

    let tableRef;

    return (
        <>
            <div className="btn-wrapper with-other-btns">
                <div className="btn-wrapper-left">
                    {!videoRecorder &&
                        <>
                            <button className="table-control-btn btn-reload" onClick={reload}>
                                <FontAwesomeIcon icon={faSyncAlt} size="lg" color="#919191"/>
                                Обновить
                            </button>
                            <button className="table-control-btn btn-create" disabled={isAddMode}
                                    onClick={isEditable ? addNewRow : create}>
                                <FontAwesomeIcon icon={faFile} size="lg" color="#919191"/>
                                {uploadVideoRecorder ? 'Загрузить видео' : 'Создать'}
                            </button>
                            {!uploadVideoRecorder &&
                                <button className="table-control-btn btn-delete" disabled={!selectedRowIds.length} onClick={deleteRow}>
                                    <FontAwesomeIcon icon={faTimes} size="lg" color="#919191"/>
                                    Удалить
                                </button>
                            }
                        </>
                    }
                    {videoRecorder &&
                        <>
                            <button className="table-control-btn btn-reload" onClick={handout}>
                                <FontAwesomeIcon icon={faArrowUp} size="lg" color="#919191"/>
                                Выдача видеорегистратора
                            </button>
                            {/* <button className="table-control-btn btn-create" onClick={returnItem}>
                                <FontAwesomeIcon icon={faArrowDown} size="lg" color="#919191"/>
                                Возврат видеорегистратора
                            </button> */}
                        </>
                    }
                    {empty &&
                        <>
                        </>
                    }
                </div>
                {getEmploeeDocx &&
                    <div className="btn-wrapper-right">
                        {isDownloadingDocs ?
                            <p className="upload-title margin-right">Загрузка... </p>
                            :
                            <>
                                <button className="table-control-btn btn-reload" onClick={getEmploeeDocx}>
                                    <FontAwesomeIcon icon={faArrowDown} size="lg" color="#919191"/>
                                    Выгрузить список работников
                                </button>
                            </>
                        }
                        {/*<button className="table-control-btn btn-reload" onClick={getEmploeeDocx}>*/}
                        {/*    <FontAwesomeIcon icon={faArrowDown} size="lg" color="#919191"/>*/}
                        {/*    Выгрузить список работников*/}
                        {/*</button>*/}
                        {/*<p>Загружено {progress} %</p>*/}
                        {uploadFile ?
                            <p className="upload-title margin-right">Загрузка... </p>
                            :
                            <>
                            <label htmlFor="docs-upload" className="attach-label">
                                <FontAwesomeIcon icon={faPlus} className="attach-label--icon"/>
                                Загрузить .zip
                            </label>
                            <input id="docs-upload" type="file" onChange={(e) => onFileChange(e)}/>
                            </>
                        }
                    </div>
                }
            </div>
            <BootstrapTable
                ref={el => tableRef = el}
                keyField="id"
                data={items}
                columns={getColumns(cancelNewRow)}
                defaultSorted={defaultSorted ? defaultSorted : [{dataField: 'id', order: 'asc'}]}
                cellEdit={cellEditFactory({
                    mode: 'dbclick',
                    blurToSave: false,
                    afterSaveCell: saveRow,
                })}
                selectRow={{
                    mode: 'checkbox',
                    selected: selectedRowIds,
                    hideSelectAll: true,
                    clickToSelect: true,
                    clickToEdit: true,
                    classes: 'selection-row',
                    hideSelectColumn: true,
                    onSelect: manageSelection,
                    onSelectAll: () => {
                    },
                }}
                noDataIndication={() => isPending ? <Spinner/> : 'Данные отсутствуют'}
                filter={filterFactory()}
                rowEvents={rowEvents}
            />
        </>
    )
}

export default GeneralObjectsTable;
