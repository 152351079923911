import React, {useEffect} from 'react';

import Modal from './Modal';
import TabsWithContent from './TabsWithContent';
import RemoteSubmitButton from '../forms/RemoteSubmitButton';
import {connect} from 'react-redux';
import Spinner from './Spinner';
import {
    notificationDevicesClearState,
    notificationDevicesCreate,
    notificationDevicesEdit,
    notificationDevicesList,
    notificationDevicesLoad
} from '../store/NotificationDevices';
import {clearPPEs, loadPPEs} from "../store/PPEs";
import {clearWorkwear, loadWorkwear} from "../store/Workwear";
import DevicesPermissionsForm from "../forms/DevicesPermissionsForm";
import NotificationDevicesForm from '../forms/NotificationDevicesForm';
import NotificationDevicesMappingForm from "../forms/NotificationDevicesMappingForm";
import {cameraLoad} from "../store/Camera";
import {cameraToApiData} from "../utils/utils";
import {notificationDevicesMappingCreate, notificationDevicesMappingEdit} from "../store/NotificationDevicesMapping";


const modCategoryDetViolActive = (ppes, camera) => {
    let modData = [];

    if (ppes.length !== 0) {
        if (camera && Object.keys(camera).length !== 0 && ppes.length !== 0) {
            modData = ppes[0].ppes.map(ppe => {
                if (camera.dangerous_categories?.find(object => object.name === ppe.name)) {
                    return {
                        value: "viol",
                        name: ppe.name,
                        label: ppe.label
                    }
                } else if (camera.usual_categories?.find(object => object.name === ppe.name)) {
                    return {
                        value: "detect",
                        name: ppe.name,
                        label: ppe.label
                    }
                } else {
                    return {
                        value: "pass",
                        name: ppe.name,
                        label: ppe.label
                    }
                }
            })
        } else {
            modData = ppes[0].ppes.map(ppe => {
                return {
                    value: "pass",
                    name: ppe.name,
                    label: ppe.label
                }
            })
        }
    }

    return modData;
};

const modCategoryDetViol = (ppes) => {
    let modData = [];

    if (ppes.length !== 0) {
        modData = ppes.map(ppe => {
            return {
                name: ppe.name,
                id: ppe.id,
                ppes: ppe.violation_categories.length !== 0 ? ppe.violation_categories.map(item => {
                            return {
                                value: "pass",
                                name: item.name,
                                label: item.ru_name
                            }
                        })
                    :
                        []
            }
        })
    }
    return modData;
};

const valuesToApiForm = (cam, values) => {
    let detectionCategories = [];
    let violationCategories = [];
    for (const item of Object.entries(values)) {
        if (item[1] === "detect") {
            detectionCategories.push(item[0])
        } else if (item[1] === "viol") {
            violationCategories.push(item[0])
        }
    }

    return [detectionCategories.length !== 0 ? detectionCategories : [], violationCategories.length !== 0 ? violationCategories : []];
}
    

class NotificationDevicesMappingModal extends React.Component {
    state = {
        isEditMode: false,
        activeCams: 0,
        reload: false,
    };


    componentDidMount() {
        // this.props.actions.loadCamerasData();
        this.props.actions.loadWorkwear();
        this.props.actions.loadPPEs();
        // this.props.actions.loadCamera(6);
    }

    onEditForm() {
        this.setState({isEditMode: true})
    };

    render() {
        const {onClose, onCancel, isPending, isError, objectsList, ppes, camerasList, notificationDevicesList, camera, employees, cameras, notificationDevices, cameraTest, camerasOptical} = this.props;
        const {createCamera, updateCamera} = this.props.actions;
        this.userFormValues = camera;

        const filterCameras = (activeCam, listCams) => {
            return listCams.filter(f => f.id === activeCam);
        }

        if (Object.keys(camera).length !== 0 && this.state.reload === false) {
            this.setState({activeCams: this.props.camera.camera_info.id, reload: true})
        }

        const filterCamerasActive = (camerasOpticalList) => {
            const cameraList = [];
            const deviceActive = notificationDevicesList.find(device => device.id === this.props.camera.device_info.id);
            deviceActive.available_cameras.map(cam => {
                const allowedCam = camerasOpticalList.find(camList => camList.id === cam.id)
                allowedCam && cameraList.push(allowedCam)
            })
            return cameraList;
        }

        return (
            <Modal
                title={`Сопоставление устройства оповещения `}
                isOpen
                showEditButton={!this.state.isEditMode}
                closeButtonWithText={true}
                onClose={onClose}
                onEdit={() => this.onEditForm()}
                className="user-modal"
            >
                {isPending
                    ?
                    isError ?
                        <p>Ошибка при загрузке данных</p>
                        :
                        <Spinner/>
                    :
                    <>
                        <TabsWithContent
                            titles={['Данные', 'Детекторы']}
                            tabs={[
                                <>
                                    <NotificationDevicesMappingForm
                                        handleCancel={onCancel}
                                        onSubmit={values => {
                                            this.userFormValues = values;
                                            this.userFormValues.device_type = 1;
                                            this.userFormValues.object_id = this.userFormValues.object_observation.value;
                                            // if (this.userFormValues.id) {
                                            //     updateCamera(this.userFormValues.id, this.userFormValues);
                                            // } else {
                                            //     createCamera(this.userFormValues);
                                            // }
                                            // onClose();
                                        }}
                                        isEditMode={this.state.isEditMode}
                                        objectsList={objectsList}
                                        employees={employees}
                                        cameras={cameras}
                                        camerasList={filterCamerasActive(camerasOptical)}
                                        notificationDevicesList={notificationDevicesList}
                                        onChangeCams={(e) => this.setState({activeCams: e})}
                                    />
                                </>,
                                 <>
                                 <DevicesPermissionsForm
                                     id={this.state.activeCams}
                                     handleCancel={onCancel}
                                     onSubmit={values => {
                                         let [detectionCategories, violationCategories] = valuesToApiForm(this.state.activeCams, values);
                                         this.userFormValues.usual_category_names = detectionCategories;
                                         this.userFormValues.dangerous_category_names = violationCategories;
                                         this.userFormValues.dangerous_categories_signals_count = 2;
                                         this.userFormValues.usual_categories_signals_count = 1;
                                         this.userFormValues.relay_number = 1;
                                         this.userFormValues.device_id = this.userFormValues.name.value;
                                         this.userFormValues.cam_id = this.userFormValues.cameraCurrent.value;
                                         this.userFormValues.old_cam_id = this.userFormValues.camera_info.id;
                                         this.userFormValues.new_cam_id = this.userFormValues.cameraCurrent.value;
                                         //this.userFormValues.usual_category_names = detectionCategories;
                                         if (!this.userFormValues.is_new) {
                                            updateCamera(this.userFormValues.device_id, this.userFormValues);
                                         } else {
                                            createCamera(this.userFormValues);
                                         }
                                         onClose();
                                     }}
                                     activeCams={this.state.activeCams}
                                     camerasActive={filterCameras(this.state.activeCams, camerasOptical)}
                                     isEditMode={this.state.isEditMode}
                                     objectsList={objectsList}
                                     modPpes={modCategoryDetViolActive(modCategoryDetViol(filterCameras(this.state.activeCams, camerasOptical)), camera)}
                                 />
                                 <div className="submit-wrapper">
                                     {this.state.isEditMode &&
                                     <RemoteSubmitButton
                                         forms={['NotificationDevicesMappingForm', 'DevicesPermissionsForm']}
                                         className=""
                                     >
                                         Сохранить
                                     </RemoteSubmitButton>}
                                 </div>
                             </>
                            ]}
                        />
                    </>
                }

            </Modal>
        )
    }
}

export default connect(
    state => ({
        isPending: state.notificationDevicesMapping.isListPending || state.object.isListPending || state.notificationDevices.isListPending,
        isError: state.workwear.isError || state.notificationDevicesMapping.isError,
        ppes: state.ppes.list,
        workwear: state.workwear.list,
        camera: state.notificationDevicesMapping.current,
        cameraTest: state.camera.current,
        camerasOptical: state.camera.list || [],
    }),
    dispatch => ({
        actions: {
            loadCamerasData: () => {
                dispatch(notificationDevicesList());
            },
            createCamera: (camera) => {
                dispatch(notificationDevicesMappingCreate(camera));
            },
            loadCamera: (camera) => {
                // dispatch(cameraClearState());
                dispatch(cameraLoad(2, camera));
            },
            updateCamera: (deviceId, camera) => {
                dispatch(notificationDevicesMappingEdit(deviceId, camera));
            },
            loadPPEs: () => {
                dispatch(clearPPEs());
                dispatch(loadPPEs());
            },
            loadWorkwear: () => {
                dispatch(clearWorkwear());
                dispatch(loadWorkwear());
            },
        }
    })
)(NotificationDevicesMappingModal);
