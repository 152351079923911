import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-regular-svg-icons';

import {
    videoRecorderUpList,
    videoRecorderUpVideo,
    videoRecorderUpClearState,
    videoRecorderUpCurrentClearState
} from '../store/VideoRecorderUp';
import {loadObjectsObservationList, objectObservationClearState} from '../store/ObjectObservation';
import {apiDataToCameras, cameraToApiData, omitRooms, apiDataToVideoRecorderUp} from '../utils/utils';
import {nbsp} from '../utils/string';
import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import withDataErrorIndication from '../components/withDataErrorIndication';
import VideoRecorderUpVideoModal from '../components/VideoRecorderUpVideoModal';
import {clearEmploeesState, loadEmploeesList} from "../store/Employees";
import {userFio} from "../utils/userUtils";
import {
    videoRecorderClearState,
    videoRecorderList
} from '../store/VideoRecorder';
import {clearUsersState, loadUsersList} from '../store/Users';
import Spinner from "../components/Spinner";

const LinkTextFormatter = ({text}) => {
    let linkText = ''
    if (text) {
        const arr = text.split('/');
        linkText = arr[arr.length - 1];
    }
    return (
      <div>{text ? <a href={text} target="_blank">{linkText}</a> : <></>}</div>
    );
}

const VideoRecorderUpVideo = ({actions, videoRecorderUpList, users, videoRecorderList, objects = [], isListPending, employees}) => {
    const {loadAllCameras, loadAllVideoRecorder, updateCamera, loadUsersList, deleteCamera, clearCamera, loadEmployeesList, loadObjects} = actions;

    const loadData = () => {
        loadAllCameras();
        loadAllVideoRecorder();
        // loadEmployeesList();
        loadObjects();
        loadUsersList();
    }

    const [shouldShowModal, setShowModal] = useState(false);
    const [cameraActive, setCameraActive] = useState();

    useEffect(() => {
        loadData();
    }, [])

    const Columns = (cellCancelHandler) => [{
        text: '№',
        dataField: 'id',
        headerStyle: () => ({width: '59px'}),
        editable: false,
    }, {
        text: 'Блок',
        dataField: 'dashcam',
        editable: false,
        formatter: (cell) => cell.power_unit_info.name,
        required: true,
    }, {
        text: 'Название регистратора',
        dataField: 'dashcam',
        editable: false,
        formatter: (cell) => cell.number,
        required: true,
    }, {
        text: 'Дата и время загрузки',
        dataField: 'file_upload_timestamp',
        editable: false,
        sort: true,
        formatter: (cell) => cell ? new Date(cell * 1000).toLocaleString() : '',
        required: true,
    }, {
        text: 'Пользователь загрузивший файл',
        dataField: 'user_uploaded',
        editable: false,
        formatter: (cell) => cell ? userFio(cell) : '',
        required: true,
    },{
    //     text: 'Ссылка на файл',
    //     dataField: 'video_url',
    //     editable: false,
    //     formatter: (cell, row) =>  (
    //         <LinkTextFormatter text={cell}/>
    //     ),
    //     required: true,
    // },{
        text: 'Название файла',
        dataField: 'filename',
        editable: false,
        required: true,
        formatter: (cell, row) =>  (
            <div>{cell && <a href={row.video_url} target="_blank">{cell}</a>}</div>
        ),
    },{
        text: 'Размер файла',
        dataField: 'file_size',
        editable: false,
        headerStyle: () => ({width: '120px'}),
        formatter: (cell) => cell ? `${(cell / 1024 / 1024).toFixed(2)} МБ` : '',
        required: true,
    },{
        text: 'Длительность записи',
        dataField: 'video_length',
        editable: false,
        headerStyle: () => ({width: '160px'}),
        formatter: (cell) => cell ? `${cell} c` : '',
        required: true,
    },{
        text: 'Дата и время начала записи',
        dataField: 'record_start_timestamp',
        editable: false,
        formatter: (cell) => cell ? new Date(cell * 1000).toLocaleString() : '',
        required: true,
    },{
    //     text: 'Работник',
    //     dataField: 'person_uploaded',
    //     sort: true,
    //     editable: false,
    //     formatter: (cell) => cell ? userFio(cell) : '',
    //     required: true,
    // },{
        text: 'Обработано?',
        dataField: 'processed',
        editable: false,
        formatter: (cell) => <input type="checkbox" checked={cell} className="tableCheckbox"/>,
        type: 'bool',
        headerStyle: () => ({width: '110px'}),
    }];

    document.title = 'Загрузка видеофайлов с регистратора';

    if (isListPending) {
        return <Spinner/> ;
    }

    return (
        <>
            <div className='video-recorder-up-video-table'>
                <GeneralObjectsTable
                    isPending={isListPending}
                    objects={apiDataToVideoRecorderUp(videoRecorderUpList)}
                    defaultSorted={[{dataField: 'file_upload_timestamp', order: 'desc'}]}
                    isEditable={false}
                    uploadVideoRecorder={true}
                    actions={{
                        update: updateCamera,
                        // create: createCamera,
                        create: () => {
                            setShowModal(true);
                        },
                        purge: deleteCamera,
                        reload: loadData,
                    }}
                    getColumns={Columns}
                    itemDummy={{
                        id: 0,
                        code: 0,
                        obj: '',
                        name: '',
                        protocol: '',
                        host: '',
                        suffix: '',
                        credentials: [],
                        is_enabled: true,
                    }}
                    // rowEvents={{
                    //     onDoubleClick: (e, row, rowIndex) => {
                    //         // loadCamera(row);
                    //         // setShowModal(true);
                    //     }
                    // }}
                />
            </div>
            {shouldShowModal &&
            <VideoRecorderUpVideoModal
                cameras={videoRecorderList}
                employees={users}
                objectsList={objects}
                onCancel={() => {
                    setShowModal(false);
                    clearCamera();
                }}
                onClose={() => {
                    setShowModal(false);
                    clearCamera();
                }}
            />}
        </>
    )
}

export default connect(
    state => ({
        users: state.users.list,
        user: state.user,
        videoRecorderUpList: state.videoRecorderUp.list,
        videoRecorderList: state.videoRecorder.list,
        camera: state.camera.current,
        employees: state.employees.list,
        isListPending: state.users.isListPending || state.videoRecorderUp.isListPending || state.videoRecorder.isListPending || state.object.isListPending,
        objects: state.object.list,
        isError: state.camera.isListError || state.videoRecorder.isListError || state.users.isListError,
    }),
    dispatch => ({
        actions: {
            loadAllCameras: () => {
                dispatch(videoRecorderUpClearState());
                dispatch(videoRecorderUpList());
            },
            loadUsersList: (accountType) => {
                dispatch(clearUsersState());
                dispatch(loadUsersList(accountType));
            },
            loadAllVideoRecorder: () => {
                dispatch(videoRecorderClearState());
                dispatch(videoRecorderList());
            },
            loadObjects: () => {
                dispatch(objectObservationClearState());
                dispatch(loadObjectsObservationList());
            },
            clearCamera: () => {
                dispatch(videoRecorderUpCurrentClearState());
            },
            loadEmployeesList: () => {
                dispatch(clearEmploeesState());
                dispatch(loadEmploeesList());
            },
        },
    }),
)(withDataErrorIndication(VideoRecorderUpVideo));
