import axios from 'axios';
import {userFio} from '../utils/userUtils';

const CLEAN_SETTINGS = {
    VALUES: 'CLEAN_SETTINGS_VALUES',
    VALUES_PENDING: 'CLEAN_SETTINGS_VALUES_PENDING',
    VALUES_ERROR: 'CLEAN_SETTINGS_VALUES_ERROR',
    SCHEDULE: 'SCHEDULE_SETTINGS',
    SCHEDULE_PENDING: 'SCHEDULE_SETTINGS_PENDING',
    SCHEDULE_ERROR: 'SCHEDULE_SETTINGS_ERROR',
    SAVED: 'CLEAN_SETTINGS_SAVED',
    CLEAR: 'CLEAN_SETTINGS_CLEAR',
}

const initialState = {};

const clearSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAN_SETTINGS.VALUES:
            return {
                ...state,
                values: action.payload,
                isValuesPending: false,
                isListError: false,
            };
        case CLEAN_SETTINGS.SCHEDULE:
            return  {
                ...state,
                schedule: action.payload,
                isValuesPending: false,
                isListError: false,
            };
        case CLEAN_SETTINGS.VALUES_PENDING:
        case CLEAN_SETTINGS.SCHEDULE_PENDING:
            return {
                ...state,
                isValuesPending: true,
            }
        case CLEAN_SETTINGS.VALUES_ERROR:
        case CLEAN_SETTINGS.SCHEDULE_ERROR:
            return {
                ...state,
                isValuesError: true,
            }
        case CLEAN_SETTINGS.SAVED:
            return {
                ...state,
                isValuesPending: false,
            }
        case CLEAN_SETTINGS.CLEAR:
            return {
                ...state,
                values: undefined
            };
        default:
            return state;
    }
};

export default clearSettingsReducer;

export const loadCleanSettings = () => dispatch => {
    dispatch({
        type: CLEAN_SETTINGS.VALUES_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/event-storage/app_settings/')
        .then(res => {
            dispatch({
                type: CLEAN_SETTINGS.VALUES,
                payload: res.data,
            });
        })
        .catch(() => {
            dispatch({
                type: CLEAN_SETTINGS.VALUES_ERROR,
                payload: null,
            })
        })
};

export const saveClearSettings = (data) => dispatch => {
    dispatch({
        type: CLEAN_SETTINGS.VALUES_PENDING,
        payload: null,
    });
    axios.put(`/api/statistics/event-storage/app_settings/`, data.values)
        .then(res => {
            dispatch({
                type: CLEAN_SETTINGS.SAVED,
                payload: data,
            });
            dispatch(saveScheduleSettings(data.schedule));
        });
};

export const loadScheduleSettings = () => dispatch => {
    dispatch({
        type: CLEAN_SETTINGS.SCHEDULE_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/kola-backend/app-settings/')
      .then(res => {
          dispatch({
              type: CLEAN_SETTINGS.SCHEDULE,
              payload: res.data,
          });
      })
      .catch(() => {
          dispatch({
              type: CLEAN_SETTINGS.SCHEDULE_ERROR,
              payload: null,
          })
      })
};

export const saveScheduleSettings = (data) => dispatch => {
    dispatch({
        type: CLEAN_SETTINGS.SCHEDULE_PENDING,
        payload: null,
    });
    axios.put(`/api/statistics/kola-backend/app-settings/`, data)
      .then(res => {
          dispatch({
              type: CLEAN_SETTINGS.SAVED,
              payload: data,
          });
          dispatch(clearSettings());
          dispatch(loadCleanSettings());
          dispatch(loadScheduleSettings());
      });
};



export const clearSettings = () => dispatch => {
    dispatch({
        type: CLEAN_SETTINGS.CLEAR,
        payload: null,
    });
};
