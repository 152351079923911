import axios from 'axios';

const ROOM_OUR = {
    LIST:       'ROOM_OUR_LIST',
    LIST_PENDING: 'ROOM_OUR_PENDING',
    LIST_ERROR: 'ROOM_OUR_LIST_ERROR',
    DETAIL:     'ROOM_OUR_DETAIL',
    CREATE:     'ROOM_OUR_CREATE',
    EDIT:       'ROOM_OUR_EDIT',
    DELETE:     'ROOM_OUR_DELETE',
    CLEAR:      'ROOM_OUR_CLEAR',
    CURRENT_CLEAR:  'ROOM_OUR_CURRENT_CLEAR',
};

const initialState = {
    current: {},
};

const roomOurReducer = (state = initialState, action) => {
    switch(action.type) {
        case ROOM_OUR.LIST:
            return {
                ...state,
                list: action.payload,
                isListPending: false,
                isListError: false,
            };
        case ROOM_OUR.DETAIL:
        case ROOM_OUR.CREATE:
        case ROOM_OUR.EDIT:
        case ROOM_OUR.DELETE:
            return {
                ...state,
                current: action.payload,
                isListPending: false,
            };
        case ROOM_OUR.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case ROOM_OUR.LIST_ERROR:
            return {
                isListError: true,
            }
        case ROOM_OUR.CLEAR:
            return initialState;
        case ROOM_OUR.CURRENT_CLEAR:
            return {
                ...state,
                current: undefined
            };
        default:
            return state;
    }
};

export default roomOurReducer;

export const roomOurList = () => dispatch => {
    dispatch({
        type: ROOM_OUR.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/kola-mapping/object-observation-int/')
        .then(res => {
            dispatch({
                type: ROOM_OUR.LIST,
                payload: res.data
            });
        })
};


/* Загрузка помещения */
export const roomOurLoad = (subId) => dispatch => {
    dispatch({
        type: ROOM_OUR.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/kola-mapping/object-observation-int/${subId}/`)
        .then(res => {
            dispatch({
                type: ROOM_OUR.DETAIL,
                payload: res.data,
            });
        });
};

/* Создание новой комнаты */
export const roomOurCreate = (data) => dispatch => {
    axios.post(`/api/statistics/kola-mapping/object-observation-int/`, data)
        .then(res => {
            dispatch({
                type: ROOM_OUR.CREATE,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: ROOM_OUR.CREATE,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

export const roomOurDelete = (subId) => dispatch => {
    axios.delete(`/api/statistics/kola-mapping/object-observation-int/${subId}/`)
        .then(res => {
            let data = {isReloadList: true};
            dispatch({
                type: ROOM_OUR.DELETE,
                payload: data,
            });
        });
};

export const roomOurClearState = () => dispatch => {
    dispatch({
        type: ROOM_OUR.CLEAR,
        payload: null,
    });
};

export const roomOurCurrentClearState = () => dispatch => {
    dispatch({
        type: ROOM_OUR.CURRENT_CLEAR,
        payload: null,
    });
};

export const roomOurEdit = (subId, data) => dispatch => {
    axios.put(`/api/statistics/kola-mapping/object-observation-int/${subId}/`, data)
        .then(res => {
            dispatch({
                type: ROOM_OUR.EDIT,
                payload: {isReloadList: true},
            });
        })
        .catch(err => {
            dispatch({
                type: ROOM_OUR.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};
