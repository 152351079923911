import React from 'react';

import TextInputWithButtons from './TextInputWithButtons';

const BlocksColumns = () => (cellCancelHandler) => [{
    text: '№',
    dataField: 'id',
    headerStyle: () => ({width: '59px'})
}, {
    text: 'Название',
    dataField: 'name',
    headerStyle: () => ({width: '450px'}),
    editorRenderer: (editorProps) => (
        <TextInputWithButtons {...editorProps} onCancel={cellCancelHandler}
                              className="required-field"/>
    ),
    required: true,
// }, {
//     text: 'Описание',
//     dataField: 'description',
//     editorRenderer: (editorProps) => (
//         <TextInputWithButtons {...editorProps} onCancel={cellCancelHandler}/>
//     ),
//     // TODO: Когда экшены будут возвращать промис - перенести сохранение сюда
//     // validator: (newValue, row, column, done) => {
//     //     saveRow(null, newValue, row, column)
//     //         .then(() => done({valid: true}))
//     //         .catch(() => done({valid: false, message: 'Ошибка при сохранении'}))
//     //     return {
//     //         async: true
//     //     };
//     // }
}];

export default BlocksColumns;
