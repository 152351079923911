import React from 'react';
import {Link} from 'react-router5';

import {ROUTES} from '../router/routes';
import imagePlaceholder from '../assets/img/image-placeholder.png';

const CameraItem = ({ camera, thermal }) => {
    const cameraImage = camera.preview === null || camera.is_active === false ?
        imagePlaceholder : camera.preview;

    return (
        <Link routeName={ROUTES.CAMERA}
              routeParams={{
                  camId: camera.id,
                  objId: camera.obj_id,
                  type: thermal ? "termal" : "optical"
              }}
              className="item"
        >
            <img src={cameraImage} alt=""/>
            <div className="on-off">
                <p className={camera.is_enabled ? 'on' : 'off'}>
                    {camera.is_enabled ? 'вкл' : 'выкл'}
                </p>
            </div>
            <div className="lable">
                <p>{camera.name}</p>
            </div>
        </Link>
    )
};

export default CameraItem;
