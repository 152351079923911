import React, {useState, useEffect} from 'react';
import {Field, getFormValues, reduxForm} from 'redux-form';
import axios from 'axios';

import {DatePickerInput, objectsListToOptions, ReduxFormSelectValid, selectOptions} from '../utils/inputs';
import {onlyRooms} from '../utils/utils';
import {downloadFile} from '../utils/requests';
import {connect} from 'react-redux';
import {getDateNow} from '../utils/time';
import {DEFAULT_INTERVAL_HOURS} from '../utils/constants';
import SpinnerSmall from "../components/SpinnerSmall";

const timestamp = date => new Date(date).getTime() / 1000;

const UserReportAccessesForm = (props) => {
    const {cameras, thermalCameras, handleSubmit, pristine, reset, submitting, formValues} = props;
    const [dateFrom, setDateFrom] = useState(timestamp(getDateNow(-DEFAULT_INTERVAL_HOURS)));
    const [dateTo, setDateTo] = useState(timestamp(getDateNow()));
    const [cameraId, setCameraId] = useState("");
    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
    const [cameraArray, setCameraArray] = useState([{value: "0", label: "Все"}]);

    const [isAccessed, setIsAccessed] = useState(true);

    const getReportPdf = () => {
        let pageNumber = 1;
        // let url = `/api/statistics/reports/accessed_cameras/pdf/?is_accessed=${isAccessed}`;
        let url = `/api/statistics/reports/accessed_cameras/pdf/`;
        if (cameraArray){
            url += `?`;
            cameraArray.map((camera, i) => {
                if (i > 0) {
                    if (camera.value !== "0") {
                        url += `&cameras_ids=${camera.value}`
                    }
                } else {
                    if (camera.value !== "0") {
                        url += `cameras_ids=${camera.value}`
                    }
                }
            });
        } else {
            return;
        }
        setIsDownloadingPdf(true);
        downloadFile(url, 'report.pdf')
            .then(() => {
                setIsDownloadingPdf(false);
            })
    }

    const addEmptyValue = (arr) => {
        if (arr.find((i) => i.id === "0") === undefined) {
            arr.unshift({
                id: "0",
                name: "Все"
            });
        }
        return arr;
    };

    const changeCam = (activeId) => {
        setIsAccessed(activeId.target.value)
    };

    const camerasOptions = (cameras) => {
        return cameras.map(camera => {
            return {
                value: camera.id,
                label: camera.name
            }
        })
    };

    const ReduxFormRoomSelect = ({input}) => {
        const isMulti = true;
        const options = camerasOptions(addEmptyValue([...cameras, ...thermalCameras]));
        const placeholder = 'Выберите камеры';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const accessedList = [
        {id: true, name: 'Доступ есть'},
        {id: false, name: 'Доступа нет'},
    ];


    return (
        <form onSubmit={handleSubmit} className="">
            <div className="form-group select-multi">
                <div className="title">
                    <p>Камера:</p>
                </div>
                <div className="input">
                    <Field
                        name="camerasList"
                        component={ReduxFormRoomSelect}
                        onChange={(e) => setCameraArray(e)}
                    />
                </div>
            </div>
            {/*<div className="form-group">*/}
            {/*    <div className="title">*/}
            {/*        <p>Доступ:</p>*/}
            {/*    </div>*/}
            {/*    <div className="input">*/}
            {/*        <Field name="is_accessed" component="select" onChange={changeCam}>*/}
            {/*            {selectOptions(objectsListToOptions(accessedList))}*/}
            {/*            /!*<select value="true">Доступ есть</select>*!/*/}
            {/*            /!*<select value="false">Доступа нет</select>*!/*/}
            {/*        </Field>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="btn-wrapper report-wrapper-btn">
                <button onClick={getReportPdf}>
                    Сформировать PDF
                </button>
                {isDownloadingPdf &&
                <div className="spinner-small-wrapper">
                    <SpinnerSmall/>
                </div>
                }
            </div>
        </form>
    )
}

const mapStateToProps = (state) => ({
    cameras: state.camera.flatList || [],
    thermalCameras: state.thermalCamera.list || [],
    formValues: getFormValues('UserReportAccessesForm')(state) || {},
    initialValues: {
        is_accessed: {value: true, label: "Доступ есть"},
        camerasList: {value: "0", label: "Все"},
        from: getDateNow(-DEFAULT_INTERVAL_HOURS),
        to: getDateNow(),
    }
})

export default connect(mapStateToProps)(
    reduxForm({form: 'UserReportAccessesForm', enableReinitialize: false})(UserReportAccessesForm)
);
