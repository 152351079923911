import React from 'react';
import {ROLES} from '../roles';
import {TimestampToIntAndLocal} from "./time";

export const hasValidRole = (user) => {
    const validRoles = [...Object.values(ROLES), 'AUTH_ROLE_ADMIN'];
    return user.roles.some(role => validRoles.includes(role));
};

export const isAuthUser = (user) => !!user.accessToken;

export const isAuthError = (user) => !!(user && user.isError);

const hasViolationVerbose = (options, hasViolation) => {
    const option = options.find(option => option[0] === hasViolation);
    return option ? option[1] : hasViolation;
}

const objectName = (objectId, objects) => {
    const object = objects.find(obj => obj.id === objectId);
    if (object) {
        return object.name;
    }
};

const isRoom = (objectObservation) => objectObservation.parent !== null;

export const isBlock = (objectObservations) => {
    const blockList = [];
    objectObservations.forEach((object) => {
        if (object.objects.length !== 0) {
            object.objects.forEach((item) => {
                blockList.push({
                    ...item,
                    parent: object.id,
                })
            })
        }
    });
    return blockList;
};

export const omitRooms = (objects = []) => (objects); // блоки

export const onlyRooms = (objects = []) => (isBlock(objects)); // помещения

// eslint-disable-next-line eqeqeq
export const roomsInObject = (objectId, objects) => objects.filter(obj => obj.parent === objectId);

export const cameraInObject = (objectId, objects) => objects.filter(obj => obj.obj.id === objectId);

export const cameraInObjectById = (objectId, objects) => objects.filter(obj => obj.id === objectId);

export const dashcamInObject = (objectId, objects) => objects.filter(obj => obj.obj.id === objectId);

export const dashcamInPower = (objectId, objects) => objects.filter(obj => obj.power_unit_info.id === objectId);

export const camerasWithoutDevices = (cameras, devices) => {
    let devicesFilter = devices.map(device => { return device.camera_settings[0].camera_info.id; });
    return cameras.filter(item => !devicesFilter.includes(item.id));
};

// eslint-disable-next-line eqeqeq
export const objectFromRoom = (objectId, rooms) => {
    let res = [];
    rooms.forEach((room) => {
        if (room.objects.length !== 0) {
            if (room.objects.filter(item => item.id === objectId).length !== 0) {
                res.push(room)
            }
        }
    });
    return res;
}

export const apiDataToEvent = (data) => {
    const primary = data.primary_objects[0];
    if (!primary) {
        return {id: data.id};
    }
    const objectObservation = data.camera.object_observation;
    const secondary = primary.secondary_objects[0];
    const lastLogRecord = data.logs && data.logs.length ?
        data.logs[data.logs.length - 1] : null
    const score = Math.round(parseFloat(secondary.score) * 100);
    return {
        id: data.id,
        object: isRoom(objectObservation) ? objectObservation.parentName : objectObservation.name,
        room: isRoom(objectObservation) ? objectObservation.name : '',
        camera: data.camera.name,
        date: new Date(data.date).toLocaleString(),
        score,
        type: `${secondary.secondary_category.ru_name} ${score}%`,
        description: primary.primary_category.name,
        operatorMark: lastLogRecord ? {
            has_violation_verbose: hasViolationVerbose(
                data.has_violation_choices, lastLogRecord.change_message.has_violation.value
            ),
            date: new Date(lastLogRecord.action_time).toLocaleString(),
            comment: lastLogRecord.change_comment,
            username: lastLogRecord.username,
        } : {},
        has_violation: data.has_violation,
        has_violation_verbose: data.has_violation_verbose === null ?
            data.has_violation_choices[0][1] : data.has_violation_verbose,
        crop: primary.crop.file,
        violationCrop: primary.vis_crop.file,
    }
};

export const apiDataToEventObj = (data, objects) => {
    if (!data.primary_objects) {
        return {id: data.id};
    }
    // if (!data.primary_objects[0].secondary_objects[0]) {
    //     return {id: data.id};
    // }
    const primary = data.primary_objects[0];
    const objectObservation = data.object_observation;
    const secondary = primary.secondary_objects[0];
    const lastLogRecord = data.logs && data.logs.length ?
        data.logs[data.logs.length - 1] : null
    const score = secondary ? Math.round(parseFloat(secondary.score) * 100) : 0;
    const status = data.confirmation_status === "WITHOUT_REVIEW" ? "NO_STATUS" : data.confirmation_status;
    return {
        id: data.id,
        object: objectFromRoom(objectObservation.id, omitRooms(objects)),
        article: secondary.article,
        confirmation_status: status,
        power_unit: data.power_unit?.name,
        room: isRoom(objectObservation) ? objectObservation.name : '',
        camera: data.camera_id,
        camera_name: data.camera_name || data.camera_id,
        date: new Date(data.date).toLocaleString(),
        // score: primary.score,
        score: `${score}%`,
        type: `${secondary.ru_name}`,
        description: primary.ru_name,
        operatorMark: lastLogRecord ? {
            has_violation_verbose: hasViolationVerbose(
                data.has_violation_choices, lastLogRecord.change_message.has_violation.value
            ),
            date: new Date(lastLogRecord.action_time).toLocaleString(),
            comment: lastLogRecord.change_comment,
            username: lastLogRecord.username,
        } : {},
        has_violation: data.has_violation,
        has_violation_verbose: data.has_violation_verbose === null ?
            data.has_violation_choices[0][1] : data.has_violation_verbose,
        crop: data.primary_objects[0].crop_url.img_url,
        violationCrop: data.primary_objects[0].vis_crop_url.img_url,
        person: primary.person.person_id,
        photo_person: primary.person.faces[0]?.img_url,
        operator_decision: data.operator_decision,
        head_decision: data.head_decision,
        video: data.video_url,
    }
};


export const apiDataToRejectedEventObj = (data, objects) => {
    if (!data.primary_category) {
        return {id: data.id};
    }
    const primary = data.primary_category;
    const objectObservation = data.object_observation;
    const lastLogRecord = data.logs && data.logs.length ?
        data.logs[data.logs.length - 1] : null
    const status = data.confirmation_status === "WITHOUT_REVIEW" ? "NO_STATUS" : data.confirmation_status;
    return {
        id: data.message_uuid,
        confirmation_status: status,
        power_unit: data.power_unit?.name,
        room: isRoom(objectObservation) ? objectObservation.name : '',
        camera: data.camera_id,
        camera_name: data.camera.name || data.camera.id,
        date: data.timestamp,
        // score: primary.score,
        score: `${data.secondary_object_score}%`,
        description: primary.ru_name,
        operatorMark: lastLogRecord ? {
            has_violation_verbose: hasViolationVerbose(
                data.has_violation_choices, lastLogRecord.change_message.has_violation.value
            ),
            date: new Date(lastLogRecord.action_time).toLocaleString(),
            comment: lastLogRecord.change_comment,
            username: lastLogRecord.username,
        } : {},
        has_violation: data.has_violation,
        has_violation_verbose: data.has_violation_verbose === null ?
            data.has_violation_choices[0][1] : data.has_violation_verbose,
        operator_decision: data.operator_decision,
        head_decision: data.head_decision,
        video: data.video_url,
        type: data.rejection_reason,
    }
};

export const apiDataToNotificationObj = (data, objects) => {
    const objectObservation = data.object_observation;
    const lastLogRecord = data.logs && data.logs.length ?
        data.logs[data.logs.length - 1] : null
    return {
        id: data.id,
        object: objectFromRoom(objectObservation.id, omitRooms(objects)),
        power_unit: data.power_unit?.name,
        room: isRoom(objectObservation) ? objectObservation.name : '',
        camera: data.camera_id,
        camera_name: data.camera.name || data.camera_id,
        date: new Date(data.timestamp*1000).toLocaleString(),
        date_sent: data.seen_timestamp ? new Date(data.seen_timestamp*1000).toLocaleString() : '',
        operator_decision: data.operator_decision,
        head_decision: data.head_decision,
        user_info: data.user_info,
    }
};

const rtspHost = (rtspUri) => {
    const regex = rtspUri.indexOf('@') === -1 ?
        new RegExp('//(.*?)(:?/|$)') : new RegExp('@(.*?)(:?/|$)');
    const match = rtspUri.match(regex);
    if (!match) {
        return;
    }
    return match[1];
}

const rtspSuffix = (rtspUri) => {
    const host = rtspHost(rtspUri);
    if (!host) {
        return rtspUri;
    }
    const start = rtspUri.indexOf(host) + host.length + 1;
    return rtspUri.substring(start);
}

export const rtspCredentials = (rtspUri) => {
    if (rtspUri.indexOf('@') === -1) {
        return [];
    }
    const regex = new RegExp('//(?:/?)(.*):(.*)@');
    const match = rtspUri.match(regex);
    if (!match) {
        return [];
    }
    return [match[1], match[2]]
}

export const splitCameraUri = (rtspUri) => {
    const protocolPattern = '(file:\\/{3}|http:\\/{2}|https:\\/{2}|rtsp:\\/{2})';
    const hostPattern = '(.+?)';
    const pathPattern = '(.*)';
    const authPattern = rtspUri.indexOf('@') === -1 ? '' : '(?:.*)@';
    const regex = new RegExp(`^${protocolPattern}${authPattern}${hostPattern}\/${pathPattern}$`);
    const match = rtspUri.match(regex);
    const defaultValue = '';
    //console.debug(match)
    if (!match) {
        return {
            protocol: defaultValue,
            host: defaultValue,
            suffix: defaultValue,
        }
    }
    return {
        protocol: match[1] || defaultValue,
        host: match[2] || defaultValue,
        suffix: match[3] || defaultValue,
    }
}

export const apiDataToCameras = (data = []) => {
    return data.map(element => {
        const credentials = rtspCredentials(element.media_uri);
        return ({
            id: element.id,
            code: element.id, // иначе в таблице будут одинаковые key у двух столбцов => ворнинги
            name: element.name,
            object: element.obj.id,
            object_name: element.obj.name,
            is_enabled: element.is_enabled,
            media_uri: element.media_uri,
            power_unit: element.power_unit,
            // temp_thresh_celsius: element.face_temp_mon.temp_thresh_celsius,
            ...splitCameraUri(element.media_uri),
            credentials,
        })
    });
}

export const apiDataToVideoRecorderUp = (data = []) => {
    return data.map(element => {
        return ({
            ...element,
            id: element.id,
            code: element.id, // иначе в таблице будут одинаковые key у двух столбцов => ворнинги
        })
    });
}

export const apiDataToNotificationDevices = (data = []) => {
    return data.map(element => {
        return ({
            id: element.id,
            code: element.id, // иначе в таблице будут одинаковые key у двух столбцов => ворнинги
            name: element.name,
            object: element.object_info?.id,
            object_name: element.object_info?.name,
            is_enabled: element.is_enabled,
            media_uri: element.media_uri,
            device_uri: element.device_uri,
            camera: element.camera_settings,
            power_unit: element.power_unit,
            temp_thresh_celsius: element.temp_thresh_celsius,
        })
    });
}

export const apiDataToNotificationDevicesMapping = (data = []) => {
    return data.map(element => {
        return ({
            id: element.camera_info.id,
            code: element.id, // иначе в таблице будут одинаковые key у двух столбцов => ворнинги
            name: element.device_info.name,
            object: element.object_info.id,
            object_name: element.object_info.name,
            is_enabled: element.is_enabled,
            media_uri: element.media_uri,
            device_uri: element.device_uri,
            camera: element.camera_info,
            power_unit: element.power_unit,
            temp_thresh_celsius: element.temp_thresh_celsius,
        })
    });
}


export const apiDataToVideoRecorder = (data = []) => {
    return data.map(element => {
        return ({
            id: element.id,
            code: element.id, // иначе в таблице будут одинаковые key у двух столбцов => ворнинги
            model: element.model,
            power_unit_info: element.power_unit_info,
            number: element.number,
            manuf_dt: element.manuf_dt,
            handed_out: element.handed_out,
            dashcam_type: element.dashcam_type,
        })
    });
}

export const apiDataToVideoRecorderUser = (data = []) => {
    return data.map(element => {
        return ({
            id: element.id,
            code: element.id, // иначе в таблице будут одинаковые key у двух столбцов => ворнинги
            handed_out: element.handed_out,
            handing_out_ts: element.handing_out_ts,
            person: element.person,
            return_ts: element.return_ts,
            dashcam: element.dashcam,
        })
    });
}

export const cameraToApiData = (camera) => {
    // const credentials = camera.credentials.join(':');
    const camCategory = camera.violation_categories;
    const credentials = camera.credentials !== '' ? camera.credentials : '';
    return {
        ...camera.id && ({id: camera.id}),
        name: camera.name,
        object_observation: camera.object ? camera.object : camera.obj_id,
        is_enabled: camera.is_enabled,
        media_uri: `${camera.protocol}${credentials}${credentials ? '@' : ''}${camera.host}${camera.suffix ? '/' + camera.suffix : ''}`,
        // media_uri: `${camera.protocol}${camera.host}/${camera.suffix}`,
        violation_categories: camera.violation_categories,
        detection_categories: camera.detection_categories,
    }
}

export const thermalCameraToApiData = (camera) => {
    // const credentials = camera.credentials.join(':');
    const camCategory = camera.violation_categories;
    return {
        ...camera.id && ({id: camera.id}),
        name: camera.name,
        object_observation: camera.object ? camera.object.value : camera.obj_id,
        is_enabled: camera.is_enabled,
        camera_type: "MOBOTIX_TR",
        media_uri: camera.media_uri,
        //temp_thresh_celsius: camera.temp_thresh_celsius,
        test_dataset_path: camera.test_dataset_path || '',
        face_temp_mon: camera.face_temp_mon,
        zone_temp_mon: camera.zone_temp_mon
        // violation_categories: camera.violation_categories,
        // detection_categories: camera.detection_categories
    }
}

export const thermalCamerasToApiData = (data = []) => {
    return data.map(element => {
        const credentials = rtspCredentials(element.media_uri);
        return ({
            id: element.id,
            code: element.id, // иначе в таблице будут одинаковые key у двух столбцов => ворнинги
            name: element.name,
            object: element.obj.id,
            object_name: element.obj.name,
            is_enabled: element.is_enabled,
            media_uri: element.media_uri,
            power_unit: element.power_unit,
            temp_thresh_celsius: element.face_temp_mon.temp_thresh_celsius,
            ...splitCameraUri(element.media_uri),
            credentials,
        })
    });
}

export const StatusToRus = (status) => {
    if (!status) return '';
    switch (status) {
        case "CONFIRMED":
            return "Нарушение";
        case "DENIED":
            return "Не нарушение";
        default:
            return "Не проверено";
    }
};

export const CameraAndObjectsToApiData = (objects, cameraList) => {
    let dataToTree = [];
    Object.values(objects).forEach((room, y) => {
        dataToTree.push({
            title: room.name,
            key: `${room.id}`,
            children: room.objects.map((item, j) => (
                {
                    title: item.name,
                    key: `${room.id}-${item.id}`,
                    children: [
                        ...item.cameras.map((camera, j) => (
                            {
                                title: camera.name,
                                key: `${room.id}-${item.id}-${camera.id}`,
                                children: camera.violation_categories.map((ppe, k) => (
                                    {
                                        title: ppe.ru_name,
                                        key: `${room.id}-${item.id}-${camera.id}-${ppe.name}`,
                                    }
                                ))
                            }
                        )),
                        ...item.thermal_cameras.map((camera, j) => (
                            {
                                title: camera.name,
                                key: `${room.id}-${item.id}-${camera.id}`,
                                children: camera.violation_categories.map((ppe, k) => (
                                    {
                                        title: ppe.name,
                                        key: `${room.id}-${item.id}-${camera.id}-${ppe.name}`,
                                    }
                                ))
                            }
                        ))
                    ]
                }
            ))
        })
    });
    return dataToTree;
};

export const CameraAndObjectsWithoutCatToApiData = (objects, cameraList) => {
    let dataToTree = [];
    omitRooms(objects).forEach((room, y) => {
        dataToTree.push({
            title: room.name,
            key: `${room.id}`,
            children: room.objects.map((item, j) => (
                {
                    title: item.name,
                    key: `${room.id}-${item.id}`,
                    children: [
                        ...item.cameras.map((camera, j) => (
                                {
                                    title: camera.name,
                                    key: `${room.id}-${item.id}-${camera.id}`
                                }
                            )
                        ),
                        ...item.thermal_cameras.map((camera, j) => (
                                {
                                    title: camera.name,
                                    key: `${room.id}-${item.id}-${camera.id}`
                                }
                            )
                        )
                    ]
                }
            ))
        })
    });
    return dataToTree;
};

export const ActiveObjectsToKeys = (permissionList) => {
    const activeList = [];
    if (permissionList) {
        for (const room of Object.values(permissionList)) {
            for (const item of room.objects) {
                for (const camera of item.cameras) {
                    for (const ppe of camera.violation_categories) {
                        if (ppe.is_notifications_enabled) {
                            activeList.push(`${room.id}-${item.id}-${camera.id}-${ppe.name}`)
                        }
                    }
                }
                for (const thermalCamera of item.thermal_cameras) {
                    // activeList.push(`${room.id}-${item.id}-${thermalCamera.id}`);
                    for (const ppe of thermalCamera.violation_categories) {
                        if (ppe.is_notifications_enabled) {
                            activeList.push(`${room.id}-${item.id}-${thermalCamera.id}-${ppe.name}`)
                        }
                    }
                }
            }
        }
    }
    return activeList;
};

export const ActiveAccessesObjectsToKeys = (accessesList) => {
    const activeList = [];
    if (accessesList) {
        for (const room of Object.values(accessesList)) {
            if (room.is_accessed) {
                activeList.push(`${room.id}`)
            }
            for (const item of room.objects) {
                if (item.is_accessed) {
                    activeList.push(`${room.id}-${item.id}`)
                }
                for (const camera of item.cameras) {
                    if (camera.is_accessed) {
                        activeList.push(`${room.id}-${item.id}-${camera.id}`)
                    }
                }
                for (const thermalCamera of item.thermal_cameras) {
                    if (thermalCamera.is_accessed) {
                        activeList.push(`${room.id}-${item.id}-${thermalCamera.id}`)
                    }
                }
            }
        }
    }
    return activeList;
};