import React from 'react';

const CellSaveButtons = ({onSave, onCancel}) => (
    <div className="buttons">
        <button onClick={onSave}>Сохранить</button>
        <button onClick={onCancel}>Отмена</button>
    </div>
);

export default CellSaveButtons;
