import React from 'react';
import {connect} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';

import Legend from './Legend';
import {EnableCheckbox, InputField, InputGroup} from '../inputs';
import {splitCameraUri, isBlock} from "../utils/utils";
import {ReduxFormSelect, ReduxFormSelectValid} from "../utils/inputs";

const required = value => value ? undefined : 'Обязательное поле';

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: entry.name ? `${entry.name} (${entry.power_unit.name})` : entry.ru_name
        }
    })
};

const objectsSelectActiveOptions = (objects, obj_id) => objects.map(object => {
    const activeObj = objects.find(item => item.id === obj_id);
    if (activeObj) {
        return {
            value: activeObj.id,
            label: `${activeObj.name} (${activeObj.power_unit?.name})`
        }
    }
});

const cupboardActive = (cupboard) => {
    if (cupboard){
        return {
            value: cupboard.id,
            label: cupboard.name
        }
    } else {
        return {}
    }
};

const cupboardOptions = (cupboards) => {
    return cupboards.map(cupboard => {
        return {
            value: cupboard.id,
            label: cupboard.name
        }
    })
};

const CupboardManagementForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, userId, isEditMode, objectsList, our, cupboardNotOur}) => {
    const className = (fieldName) => (forms && forms.camera && !forms.camera[fieldName].valid) || !(forms) ?
        'is-invalid' : '';

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = objSelectOptions(isBlock(objectsList));
        const placeholder = 'Выберите помещение';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    const ReduxFormCupboardNotSelect = ({input}) => {
        const isMulti = false;
        const options = cupboardOptions(cupboardNotOur.filter(item => item.mapped === false));
        const placeholder = 'Выберите шкаф на станции';
        return ReduxFormSelectValid(isMulti, options, placeholder)({input});
    };

    return (
        <form onSubmit={handleSubmit} className={isEditMode ? '' : 'disabled'}>
            <fieldset disabled={!isEditMode}>
                <Legend title="Основные данные">
                    <Field name="name" type="text" label="Название"
                           component={InputField} className={className('name')}
                           validate={[required]}/>
                    <Field name="code" type="text" label="Код"
                           component={InputField} className={className('code')}
                           validate={[required]}/>
                    {our &&
                        <>
                            <InputGroup title="Шкаф на станции">
                                <Field
                                    name="cabinet_ext"
                                    validate={[required]}
                                    component={ReduxFormCupboardNotSelect}
                                />
                            </InputGroup>
                            <InputGroup title="Помещение">
                                <Field
                                    name="object_observation"
                                    className={className('object_observation')}
                                    component={ReduxFormObjectsSelect}
                                    validate={[required]}
                                />
                            </InputGroup>
                        </>
                    }
                </Legend>
            </fieldset>
        </form>
    );
};

const mapStateToProps = (state) => {
    if (!state.cupboardManage.current?.id) {
        return {
            cupboardNotOur: state.cupboardManage.listNotOur || [],
        };
    }
    return {
        cupboardNotOur: state.cupboardManage.listNotOur || [],
        initialValues: {
            ...state.cupboardManage.current,
            cabinet_ext: cupboardActive(state.cupboardManage.current.cabinet_ext),
            object_observation: objectsSelectActiveOptions(isBlock(state.object.list), state.cupboardManage.current.object_observation?.id) || [],
        },
        subdivisionId: state.cupboardManage.current?.id,
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'CupboardManagementForm',
        enableReinitialize: true,
    })(CupboardManagementForm)
)
