import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-regular-svg-icons';

import {
    videoRecorderClearState,
    videoRecorderCreate,
    videoRecorderCurrentClearState,
    videoRecorderDelete,
    videoRecorderEdit,
    videoRecorderList,
    videoRecorderLoad
} from '../store/VideoRecorder';
import {loadObjectsObservationList, objectObservationClearState} from '../store/ObjectObservation';
import {apiDataToCameras, cameraToApiData, apiDataToVideoRecorder} from '../utils/utils';
import {nbsp} from '../utils/string';
import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import TextInputWithButtons from '../tables/TextInputWithButtons';
import withDataErrorIndication from '../components/withDataErrorIndication';
import VideoRecorderModal from '../components/VideoRecorderModal';

const HiddenTextFormatter = ({text}) => {
    const [isTextHidden, setIsTextHidden] = useState(true);

    return (
        <div onMouseDown={() => setIsTextHidden(false)}
             onMouseUp={() => setIsTextHidden(true)}>
            {isTextHidden ? <FontAwesomeIcon icon={faEye}/> : <>{text || nbsp}</>}
        </div>
    );
}

const VideoRecorderManagement = ({actions, videoRecorderList, videoRecorder, objects = [], isListPending}) => {
    const {loadAllVideoRecorder, createCamera, updateVideoRecorder, deleteVideoRecorder, loadObjects, loadVideoRecorder, clearVideoRecorder} = actions;

    const loadData = () => {
        loadAllVideoRecorder();
        loadObjects();
    }

    const [shouldShowModal, setShowModal] = useState(false);
    const [videoRecorderActive, setVideoRecorder] = useState();

    useEffect(() => {
        loadData();
    }, [])

    const Columns = (cellCancelHandler) => [{
        text: '№',
        dataField: 'id',
        sort: true,
        headerStyle: () => ({width: '59px'}),
        editable: false,
    }, {
        text: 'Блок',
        dataField: 'power_unit_info',
        editable: false,
        headerStyle: () => ({width: '200px'}),
        formatter: (cell) => cell.name,
        required: true,
    }, {
        text: 'Номер видеорегистратора',
        dataField: 'number',
        sort: true,
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons
                {...editorProps}
                onCancel={cellCancelHandler} className="required-field"/>
        ),
        required: true,
    },{
        text: 'Тип',
        dataField: 'dashcam_type',
        sort: true,
        editable: false,
        required: true,
    }
];

    document.title = 'Видеорегистраторы';

    return (
        <>
            <GeneralObjectsTable
                isPending={isListPending}
                objects={apiDataToVideoRecorder(videoRecorderList)}
                isEditable={false}
                actions={{
                    update: updateVideoRecorder,
                    // create: createCamera,
                    create: () => {
                        setShowModal(true);
                    },
                    purge: deleteVideoRecorder,
                    reload: loadData,
                }}
                getColumns={Columns}
                itemDummy={{
                    id: 0,
                    code: 0,
                    obj: '',
                    name: '',
                    protocol: '',
                    host: '',
                    suffix: '',
                    credentials: [],
                    is_enabled: true,
                }}
                rowEvents={{
                    onDoubleClick: (e, row, rowIndex) => {
                        loadVideoRecorder(row);
                        setShowModal(true);
                    }
                }}
            />
            {shouldShowModal &&
                <VideoRecorderModal
                    objectsList={objects}
                    onCancel={() => {
                        setShowModal(false);
                        clearVideoRecorder();
                    }}
                    onClose={() => {
                        setShowModal(false);
                        clearVideoRecorder();
                    }}
                />
            }
        </>
    )
}

export default connect(
    state => ({
        user: state.user,
        videoRecorderList: state.videoRecorder.list,
        videoRecorder: state.videoRecorder.current,
        isListPending: state.videoRecorder.isListPending || state.object.isListPending,
        objects: state.object.list,
        isError: state.videoRecorder.isListError,
    }),
    dispatch => ({
        actions: {
            loadAllVideoRecorder: () => {
                dispatch(videoRecorderClearState());
                dispatch(videoRecorderList());
            },
            createVideoRecorder: (camera) => {
                const apiData = cameraToApiData(camera);
                dispatch(videoRecorderCreate(apiData));
            },
            loadVideoRecorder: (camera) => {
                dispatch(videoRecorderCurrentClearState());
                dispatch(videoRecorderLoad(camera.id));
            },
            updateVideoRecorder: (cameraId, camera) => {
                const apiData = cameraToApiData(camera);
                dispatch(videoRecorderEdit(cameraId, apiData));
            },
            deleteVideoRecorder: (cameraId, camera) => {
                dispatch(videoRecorderDelete(cameraId));
            },
            loadObjects: () => {
                dispatch(objectObservationClearState());
                dispatch(loadObjectsObservationList());
            },
            clearVideoRecorder: () => {
                dispatch(videoRecorderCurrentClearState());
            },
        },
    }),
)(withDataErrorIndication(VideoRecorderManagement));
