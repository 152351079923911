import React, {useEffect, useState} from 'react';
import {PAGE_SIZE, START_PAGE, VIOLATION, VIOLATION_SETTINGS} from '../utils/constants';
import {change, Field, formValues, getFormValues, reduxForm} from "redux-form";
import {InputField, InputGroup, InputSimple, SelectInput} from "../inputs";


import {connect} from "react-redux";
import Legend from "./Legend";

const required = value => value ? undefined : 'Обязательное поле';

const RoomWorkForm = ({handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, event, saveEvent, employees, onSubmit, row, initialValues}) => {
    const className = (fieldName) => forms && forms.user && !forms.user[fieldName].valid ?
        'is-invalid' : '';
    return (
        <div className="modal-content">
            <form onSubmit={values => {}} className="eventForm ">
                <Legend title="Нарушение" className="long-title">
                    <Field name="priority" type="text" label="Тип работ"
                           component={InputField} className={className('priority')}
                           validate={[required]}/>
                    <Field name="object_observation" type="text" label="Работы"
                           component={InputField} className={className('object_observation')}
                           validate={[required]}/>
                    <Field name="time_from" type="text" label="Дата и время от"
                           component={InputField} className={className('time_from')}
                           validate={[required]}/>
                    <Field name="time_to" type="text" label="Дата и время до"
                           component={InputField} className={className('time_to')}
                           validate={[required]}/>
                    <Field name="solution" type="text" label="Статус текущий"
                           component={InputField} className={className('solution')}
                           validate={[required]}/>
                </Legend>
                <div className="submit-wrapper">
                    <button onClick={handleSubmit(values => {
                        onSubmit(values)
                    })}
                    >
                        Сохранить
                    </button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        initialValues: {
            ...props.row
        }
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'RoomWorkForm',
        enableReinitialize: true,
    })(RoomWorkForm)
)

// export default EventForm;
