import React from 'react';

import {getDateNow} from '../utils/time';
import EventsPage from '../tables/EventsPage';
import {DEFAULT_INTERVAL_HOURS} from '../utils/constants';
import StatisticPage from "../tables/StatisticPage";

const Statistic = () => {
    document.title = 'Все детектированные события';

    return (
        <StatisticPage
            defaultQuery={{
                dateBegin: getDateNow(-DEFAULT_INTERVAL_HOURS),
                dateEnd: getDateNow(),
            }}
        />
    );
}

export default Statistic;
