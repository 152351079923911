import React from 'react';
import Lf from 'leaflet';
import {FeatureGroup, ImageOverlay, Map as LeafletMap} from 'react-leaflet';
import resizebase64 from 'resize-base64';

import 'leaflet.path.drag/src/Path.Drag.js';
import 'leaflet-editable/src/Leaflet.Editable';
import 'leaflet-textpath/leaflet.textpath.js';
import 'leaflet-label/src/Marker.Label'
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './PolygonEditor.css';

import imageUtils from '../../utils/imageUtils';
import {
  centerCalc,
  offsetCenter,
  styleMap,
  toListCoords,
  fromLeafletFormat,
  toLeafletFormat
} from "./helpers";

class PolygonEditorThermal extends React.Component {
    DIVIDER = 1;
    DST_WIDTH = 1280;
    DST_HEIGHT = 720;

    WIDTH = 1080;
    HEIGHT = 1920;

    origin = [0, 0];
    wh = [];

    k = 1;
    album = true;

    mapParams = {
      crs: Lf.CRS.Simple,
      minZoom: -0.5,
      maxZoom: -0.5,
      zoom: -0.5,
      attributionControl: false,
      zoomControl: true,
      doubleClickZoom: false,
      scrollWheelZoom: false,
      dragging: false,
      animate: false
    };

    constructor(props) {
        super(props);

        this.polygons = [];

        this.WIDTH = props.screenshot.sizes.width;
        this.HEIGHT = props.screenshot.sizes.height;

        this.album = this.WIDTH > this.HEIGHT;

      if (!this.album) {
        // this.k = this.DST_WIDTH / props.screenshot.sizes.width;
        this.k = 1;
        this.DIVIDER = 2;
      } else {
        this.k = this.DST_HEIGHT / props.screenshot.sizes.height;
        this.DIVIDER = 1;
        this.DST_HEIGHT = 1000;
        this.DST_WIDTH = 1280;
      }

        this.wh = [this.HEIGHT / this.DIVIDER, this.WIDTH / this.DIVIDER]

        this.mapParams = {
          ...this.mapParams,
          boundsOptions: {padding: Lf.point(this.wh.map(n => -n / 2))},
          center: [this.HEIGHT / (this.DIVIDER * 2), this.WIDTH / (this.DIVIDER * 2)],
          bounds: [this.origin, this.wh],
          maxBounds: [this.origin, this.wh],
        }

        this.state = {
            width: this.WIDTH,
            height: this.HEIGHT,
            zones: [],
            screenshot: null,
            originWidth: 1,
            originHeight: 1,
            isInit: false,
        };

        console.log(props);
        console.log(this.mapParams);
    }

    createLeaflet(props) {
    let screenshot = this.props.screenshot.img;
    if (screenshot) {
      let {width, height} = this.props.screenshot.sizes;
      const originWidth = 1;
      const originHeight = 1;

      this.setState({
        width: width,
        height: height,
        zones: props.zones,
        screenshot: screenshot,
        originWidth: originWidth,
        originHeight: originHeight
      });
    }
    if (this.state.isInit === true || !this.refs.map) {
      return;
    }

    this.setState({isInit: true});
    const map = this.refs.map.leafletElement;

    map.on('editable:editing', e => {
      this.leafletEventEditing(e);
      this.leafletMoveTextMarkers();
    });
    map.on('editable:drag', e => {
      this.leafletEventEditing(e);
      this.leafletMoveTextMarkers();
    });

    props.zones.map((zone, index) => {
      const coordsPolygon = this.album
        ? toLeafletFormat(zone.polygon[0], this.state.height, this.k)
        : zone.polygon[0].map(i => [i[0] / this.DIVIDER, i[1] / this.DIVIDER]);
      const zoneName = zone.zone_name_cfg ?? zone.name;
      const markerHtmlText = `<div style="width:150px;z-index:1;">${zoneName}</div>`;
      const textMarker = Lf.divIcon({html: markerHtmlText, className: 'vz-icon-style'});
      const centerCoords = centerCalc(coordsPolygon);
      const marker = Lf.marker(offsetCenter(centerCoords, markerHtmlText), {icon: textMarker});

      const polygon = Lf.polygon(coordsPolygon, //multiPolygon
        {
          id: zone.id,
          color: zone.color_hex || '#00f000',
          weight: 1,
          fillOpacity: 0.1,
          textMarker: textMarker,
          markerHtmlText: markerHtmlText,
          marker: null,
        }
      );
      marker.addTo(map);
      polygon.options.marker = marker;

      map.addLayer(polygon);

      if (this.props.edit === true) {
        polygon.enableEdit();
      }
      polygon.on('click', e => {
        if (this.props.zoneOnClickCallback !== undefined) {
          this.props.zoneOnClickCallback({...zone});
        }
      });
      this.polygons.push(polygon);

      // для формы нужно сделать событие
      setTimeout(() => this.leafletEventEditing(null), 100);
    });
  }

    leafletEventEditing(e) {
        const zones = [];
        for (const p of this.polygons) {
            for (const z of this.state.zones) {
                if (z.id === p.options.id) {
                  console.group('editing')
                  console.log('editing >>>', this.k);
                  console.log('from leaflet >>>', fromLeafletFormat(p._latlngs[0], this.k, this.DIVIDER, this.HEIGHT))
                  console.groupEnd();
                    const polygon = this.album
                      ? fromLeafletFormat(p._latlngs[0], this.k, this.DIVIDER, this.HEIGHT)
                      : p._latlngs[0].map(i => [i.lat * this.DIVIDER, i.lng * this.DIVIDER])
                    const zone = {
                        id: p.options.id,
                        polygon: [polygon],
                    };
                    zones.push(zone);
                }
            }
        }
        if (this.props.zoneEditedCallback !== undefined) {
            this.props.zoneEditedCallback(zones);
        }
    }

    leafletMoveTextMarkers() {
        // перемещение текстового описания зоны
        const map = this.refs.map.leafletElement;
        for (const p of this.polygons) {
            const coordsPolygon = p._latlngs[0];

            map.removeLayer(p.options.marker);

            const c = centerCalc(toListCoords(coordsPolygon));
            const marker = Lf.marker(offsetCenter(c, p.options.markerHtmlText), {icon: p.options.textMarker});
            marker.addTo(map);

            p.options.marker = marker;
        }
    }

    render() {

        let imgOverlay = undefined;

        if (this.state.screenshot) {
          imgOverlay = (<ImageOverlay url={this.state.screenshot} bounds={this.mapParams.bounds}/>);
        }

        const html = (
          <div className='leaflet-map-container' style={{'height' : '750px'}}>
            <LeafletMap editable={true} ref='map' style={styleMap} {...this.mapParams}>
              <FeatureGroup>
                {imgOverlay}
              </FeatureGroup>
            </LeafletMap>
          </div>
        );
        if (!this.state.isInit) {
          this.createLeaflet(this.props);
        }
        return html;
    }
}

export default PolygonEditorThermal;
