import axios from 'axios';

const DECISION_MATRIX = {
    LOG: 'DECISION_MATRIX_LOG',
    SAVE: 'DECISION_MATRIX_SAVE',
    ERROR: 'DECISION_MATRIX_ERROR',
    IS_PENDING: 'DECISION_MATRIX_PENDING',
};

const initialState = null;

const decisionMatrixReducer = (state = initialState, action) => {
    switch(action.type) {
        case DECISION_MATRIX.LOG:
        case DECISION_MATRIX.SAVE:
            return action.payload;
        case DECISION_MATRIX.ERROR:
            return {
                isError: true,
            }
        case DECISION_MATRIX.IS_PENDING:
            return {
                ...state,
                isPending: true,
            }
        default:
            return state;
    }
};

export default decisionMatrixReducer;

export const loadDecisionMatrix = () => dispatch => {
    dispatch({
        type: DECISION_MATRIX.IS_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/decision-matrix/')
        .then(res => {
            dispatch({
                type: DECISION_MATRIX.LOG,
                payload: res.data,
            });
        })
        .catch(() => {
            dispatch({
                type: DECISION_MATRIX.ERROR,
                payload: null,
            })
        })
};

export const saveDecisionMatrix = (data) => dispatch => {
    dispatch({
        type: DECISION_MATRIX.IS_PENDING,
        payload: null,
    });
    axios.post('/api/statistics/decision-matrix/', data)
        .then(res => {
            const localTime = new Date();
            const datetime = localTime.toLocaleDateString('ru-RU') + ' ' + localTime.toLocaleTimeString('ru-RU');
            data.message = `Сохранено ${datetime}`;
            dispatch({
                type: DECISION_MATRIX.SAVE,
                payload: data,
            });
        });
};
