import axios from 'axios';

const USER = {
    CAMERAS_LIST: 'USERS_CAMERAS_LIST',
    NOTIFICATION_SETTINGS: 'NOTIFICATION_SETTINGS',
}

const initialState = [];

const usersNotificationSettingsReducer = (state = initialState, action) => {
    switch(action.type) {
        case USER.CAMERAS_LIST:
            return {
                state,
                ...action.payload[0],
            };
        case USER.NOTIFICATION_SETTINGS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default usersNotificationSettingsReducer;

// export const loadUserNotificationSettings = () => dispatch => {
//     axios.get('/api/statistics/auth/users/')
//         .then(res => {
//             dispatch({
//                 type: USER.CAMERAS_LIST,
//                 payload: res.data
//             });
//         });
// };
//
// export const loadSingleUserNotificationSettings = (userId) => dispatch => {
//     axios.get(`/api/statistics/auth/users/${userId}/`)
//         .then(res => {
//             dispatch({
//                 type: USER.NOTIFICATION_SETTINGS,
//                 payload: res.data
//             });
//         })
//         .catch(err => {
//             dispatch({
//                 type: USER.NOTIFICATION_SETTINGS,
//                 payload: {}
//             });
//         });
// };

// export const saveUserNotificationSettings = (id, data) => dispatch => {
//     axios.put(`/api/notification/user/${id}/`, data)
//         .then(res => {
//             dispatch({
//                 type: USER.NOTIFICATION_SETTINGS,
//                 payload: res.data
//             })
//         })
// };
