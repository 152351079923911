import axios from 'axios';

export const downloadFile = (url, name) => {
    return axios({
        url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    });
};


export const uploadFile = (url, data, filename) => {
    return axios.post(url,
        {
            "document": data,
            "filename": filename
        }
        )
        .then((res) => {
            return res.data;
        })
        .catch(err => {
            console.error('error>', err);
            return err;
        });
};

export const uploadFileWithBody = (url, formData) => {
    const headers =  {
        "Content-Type": "multipart/form-data",
        "Accept": "application/zip",
        "type": "formData"
    }
    return axios.post(url, formData,
        {headers: headers}
        )
        .then((res) => {
            return res.data;
        })
        .catch(err => {
            console.error('error>', err);
            return err;
        });
};
