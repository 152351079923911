import React, {useEffect, useState} from 'react';
import {change, Field, formValues, getFormValues, reduxForm} from "redux-form";
import {InputField, InputGroup, InputSimple, InputSimpleWithTitle, InputSimpleWithTitleTop} from "../inputs";
import {createTextMask } from 'redux-form-input-masks';

import {connect} from "react-redux";
import Legend from "./Legend";
import {objectsListToOptions, ReduxFormSelect, ReduxFormSelect1} from "../utils/inputs";
import {userFio} from "../utils/userUtils";
import {cameraInObject, omitRooms} from "../utils/utils";

const required = value => value ? undefined : 'Обязательное поле';

const timeMask = createTextMask({
    pattern: '99:99:99',
});

const empty = {
    value: null,
    label: "Все"
};

const objSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: `${entry.name} (${entry.power_unit.name})`
        }
    })
};

const camSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: `${entry.name} (${entry.obj?.name}) (${entry.power_unit.name})`
        }
    })
};

const empSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.id,
            label: userFio(entry)
        }
    })
};

const primarySelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.name,
            label: entry.ru_name
        }
    })
};

const zonesSelectOptions = (options) => {
    return options.map(entry => {
        return {
            value: entry.zone_id_cfg,
            label: entry.zone_name_cfg
        }
    })
};

const resolveTypes = [
    {value: 0, label: 'Пропустить'},
    {value: 1, label: 'Зафиксировать'},
];

const activeSolution = (activeId) => {
    if (activeId) {
        for (const cat of resolveTypes) {
            if (cat.label === activeId) {
                return cat;
            }
        }
    }
};

const emptyCron = ["", "", "", "", "", ""];

const cronVal = (cron) => {
    if (cron) {
        return cron.split(' ');
    } else {
        return emptyCron;
    }
};

const personsActive = (actIds, employees) => {
    let activeArr = [];
    if (actIds) {
        for (const actId of actIds) {
            const employee = employees.find(item => item.id === actId);
            if (employee) {
                activeArr.push({
                    value: employee.id,
                    label: userFio(employee)
                })
            }
        }
    }
    return activeArr;
}

const DecisionMatrixForm = ({initialValues, handleSubmit, handleCancel, forms, pristine, reset, submitting, dispatch, event, saveEvent, employees, objects, onSubmit, primaryCategories, zones, secondaryCategories, cameras}) => {
    const className = (fieldName) => forms && forms.user && !forms.user[fieldName].valid ?
        'is-invalid' : '';

    const [objectId, setObjectId] = useState(null);

    const ReduxFormObjectsSelect = ({input, validate}) => {
        const isMulti = false;
        const options = objSelectOptions(objects);
        options.unshift(empty);
        const placeholder = 'Выберите помещение';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    const ReduxFormCamerasSelect = ({input, validate}) => {
        const isMulti = false;
        const options = camSelectOptions(objectId ?
            cameraInObject(objectId, cameras) : cameras);
        options.unshift(empty);
        const placeholder = 'Выберите камеру';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    const ReduxFormPersonsSelect = ({input, validate}) => {
        const isMulti = true;
        const options = empSelectOptions(employees);
        const placeholder = 'Выберите работников';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    const ReduxFormPrimarySelect = ({input, validate}) => {
        const isMulti = false;
        const options = primarySelectOptions(primaryCategories);
        options.unshift(empty);
        const placeholder = 'Выберите первичную категорию';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    const ReduxFormZonesSelect = ({input, validate}) => {
        const isMulti = false;
        const options = zonesSelectOptions(zones);
        options.unshift(empty);
        const placeholder = 'Выберите зону';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    const ReduxFormSecondSelect = ({input, validate}) => {
        const isMulti = false;
        const options = primarySelectOptions(secondaryCategories);
        options.unshift(empty);
        const placeholder = 'Выберите вторичную категорию';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    const ReduxFormSolutionSelect = ({input, validate}) => {
        const isMulti = false;
        const options = resolveTypes;
        const placeholder = 'Выберите решение';
        return ReduxFormSelect(isMulti, options, placeholder)({input});
    };

    return (
        <div className="modal-content">
            <form onSubmit={values => handleSubmit(values)} className="eventForm ">
                <Legend title="Правило" className="long-title">
                    <Field name="priority" type="text" label="Приоритет"
                           component={InputField} className={className('priority')}
                           validate={[]}/>
                    <InputGroup title="Помещение" >
                        <Field
                            name="object_observation"
                            className={className('object_observation')}
                            component={ReduxFormObjectsSelect}
                            onChange={(e) => {
                                setObjectId(e.value);
                            }}
                            validate={[]}
                        />
                    </InputGroup>
                    <InputGroup title="Камера" >
                        <Field
                            name="camera"
                            className={className('camera')}
                            component={ReduxFormCamerasSelect}
                            validate={[]}
                        />
                    </InputGroup>
                    <InputGroup title="Первичная категория" >
                        <Field
                            name="primary_category"
                            className={className('primary_category')}
                            component={ReduxFormPrimarySelect}
                            validate={[]}
                        />
                    </InputGroup>
                    <InputGroup title="Зона" >
                        <Field
                            name="zone_config"
                            className={className('zone_config')}
                            component={ReduxFormZonesSelect}
                            validate={[]}
                        />
                    </InputGroup>
                    <InputGroup title="Вторичная категория" >
                        <Field
                            name="secondary_category"
                            className={className('secondary_category')}
                            component={ReduxFormSecondSelect}
                            validate={[]}
                        />
                    </InputGroup>
                    {/*<Field name="time_from" type="text" label="Время от" {...timeMask}*/}
                    {/*       component={InputField} className={className('time_from')}*/}
                    {/*       validate={[]}/>*/}
                    {/*<Field name="time_to" type="text" label="Время до" {...timeMask}*/}
                    {/*       component={InputField} className={className('time_to')}*/}
                    {/*       validate={[]}/>*/}
                    <InputGroup title="Время от">
                        <Field name="time_from" type="text" component={InputSimpleWithTitleTop}
                               className={className('day_month')} {...timeMask}
                               placeholder="Время указывается в часовом поясе Мск"
                               validate={[]}/>
                    </InputGroup>
                    <Field name="time_to" type="text" label="Время до" {...timeMask}
                           component={InputField} className={className('time_to')}
                           validate={[]}/>

                    <InputGroup title="Кронтаб">
                        <div className="full-name">
                            {/*<Field name="minute" type="text" component={InputSimpleWithTitle}*/}
                            {/*       className={className('minute')}*/}
                            {/*       placeholder="Минуты"*/}
                            {/*       validate={[]}/>*/}
                            {/*<Field name="hour" type="text" component={InputSimpleWithTitle}*/}
                            {/*       className={className('hour')}*/}
                            {/*       placeholder="Часы"*/}
                            {/*       validate={[]}/>*/}
                            <Field name="day_month" type="text" component={InputSimpleWithTitle}
                                   className={className('day_month')}
                                   placeholder="День месяца"
                                   validate={[]}/>
                            <Field name="month" type="text" component={InputSimpleWithTitle}
                                   className={className('month')}
                                   placeholder="Месяц"
                                   validate={[]}/>
                            <Field name="day_week" type="text" component={InputSimpleWithTitle}
                                   className={className('day_week')}
                                   placeholder="День недели"
                                   validate={[]}/>
                            <Field name="year" type="text" component={InputSimpleWithTitle}
                                   className={className('year')}
                                   placeholder="Год"
                                   validate={[]}/>
                        </div>
                    </InputGroup>
                    <Field name="event_threshold" type="text" label="Пороговое значение"
                           component={InputField} className={className('event_threshold')}
                           validate={[]}/>
                    <InputGroup title="Cписок работников" >
                        <Field
                            name="person_violator_ids"
                            className={className('person_violator_ids')}
                            component={ReduxFormPersonsSelect}
                            validate={[]}
                        />
                    </InputGroup>
                    <InputGroup title="Решение" >
                        <Field
                            name="solution"
                            className={className('solution')}
                            component={ReduxFormSolutionSelect}
                            validate={[]}
                        />
                    </InputGroup>
                </Legend>
                <div className="submit-wrapper">
                    <button type="submit"
                    >
                        Сохранить
                    </button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        initialValues: {
            ...props.row,
            object_observation: {
                value: props.row.object_observation_id,
                label: props.row.object_observation
            },
            camera: {
                value: props.row.camera_id,
                label: props.row.camera
            },
            primary_category: {
                value: props.row.primary_category_id,
                label: props.row.primary_category
            },
            zone_config: {
                value: props.row.zone,
                label: props.row.zone_config
            },
            secondary_category: {
                value: props.row.secondary_category_id,
                label: props.row.secondary_category
            },
            person_violator_ids: personsActive(props.row.person_violator_ids, props.employees),
            time_from: props.row.time_from.split(':').join(''),
            time_to: props.row.time_to.split(':').join(''),
            solution: activeSolution(props.row.solution),

            minute: cronVal(props.row.cron_datetime)[0],
            hour: cronVal(props.row.cron_datetime)[1],
            day_month: cronVal(props.row.cron_datetime)[2],
            month: cronVal(props.row.cron_datetime)[3],
            day_week: cronVal(props.row.cron_datetime)[4],
            year: cronVal(props.row.cron_datetime)[5],
        }
    }
};

export default connect(mapStateToProps)(reduxForm({
        form: 'DecisionMatrixForm',
        enableReinitialize: true,
    })(DecisionMatrixForm)
)

// export default EventForm;
